import mixpanel from 'mixpanel-browser';

export const trackEvent = (event, properties) => {
  mixpanel.track(event, properties);
};

export const identifyUser = (userId) => {
  mixpanel.identify(userId);
};

export const setUser = (userId, properties) => {
  mixpanel.people.set({
    ...properties,
    $distinct_id: userId
  });
};

export const resetTrackedUser = (userId) => {
  mixpanel.reset();
};
