import React from 'react';
import styled from 'styled-components';
import ReceiptsTable from './ReceiptsTable';
import { useNavigate } from 'react-router-dom';
import useSubscriptionFeatures from '@/hooks/useSubscriptionFeatures';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import useWallet from '@/hooks/useWallet';

const Billing = () => {
  const navigate = useNavigate();
  const { userSubscription } = useSubscriptionFeatures();
  const { userWallet } = useWallet();

  return (
    <BillingView>
      <CardsView>
        <CardView>
          <div className="line1">
            <h4>Subscription plan</h4>
            <button
              className="change"
              onClick={() => {
                navigate('/settings?tab=subscriptions&view=plans&state=monthly');
              }}>
              Change
            </button>
          </div>

          <div className="line2">
            <h4>
              {userSubscription?.subscriptionPlan?.name} plan{' '}
              <span>{userSubscription?.duration}</span>
            </h4>
            <p>₦{numberWithCommas(userSubscription?.amount || '00.00')}</p>
          </div>
        </CardView>
        <CardView>
          <div className="line1">
            <h4>Amount Due</h4>
          </div>

          <div className="line2">
            <p>
              Your next bill is for{' '}
              <strong>₦{numberWithCommas(userSubscription?.amount || '00.00')}</strong> on{' '}
              <strong>
                {userSubscription?.endDate
                  ? format(new Date(userSubscription?.endDate), 'do MMM, yyyy')
                  : '-'}
              </strong>
            </p>
          </div>
        </CardView>
        <CardView>
          <div className="line1">
            <h4>Payment method</h4>
            {/* <button className="change">Update</button> */}
          </div>

          <div className="line2">
            <h4>Debit card</h4>
            {/* <p>Bank Balance: ₦{numberWithCommas(userWallet?.currentBalance || '00.00')}</p> */}
          </div>
        </CardView>
      </CardsView>

      <ReceiptsTable />
    </BillingView>
  );
};

export default Billing;

const BillingView = styled.div`
  width: 100%;
  height: 100%;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.secondary};
    margin: 0;
    margin-top: 30px;
  }
`;

const CardsView = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  scroll-behavior: smooth;
  gap: 1.2rem;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  margin-top: 0px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CardView = styled.div`
  width: calc(100% / 2 - 20px);
  height: 150px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.line};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  scroll-snap-align: start;
  min-width: 330px;

  .line1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors.black};
      margin: 0;
    }

    .change {
      font-size: 0.8rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.primary};
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .line2 {
    width: 100%;

    h4 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black};
      margin: 0;
      margin-bottom: 8px;

      span {
        font-size: 0.8rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.gray};
        margin-left: 10px;
        background-color: ${(props) => props.theme.colors.line};
        padding: 2px 8px;
        border-radius: 20px;
      }
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors.black};
      margin: 0;

      strong {
        font-weight: 600;
      }
    }
  }
`;
