/* eslint-disable import/no-unresolved */
import { ReactComponent as StoreIcon } from '@/assets/icons/store.icon.svg';
import { ReactComponent as FirmIcon } from '@/assets/icons/firm.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icons/arrow-right.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/file.icon.svg';
import { ReactComponent as UploadIcon } from '@/assets/icons/upload.icon.svg';
import { ReactComponent as BankIcon } from '@/assets/icons/bank.icon.svg';
import { ReactComponent as CardIcon } from '@/assets/icons/card2.icon.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.icon.svg';
import { ReactComponent as PayNowIcon } from '@/assets/icons/paynow.icon.svg';
import { ReactComponent as PaySmallIcon } from '@/assets/icons/paysmall.icon.svg';
import { ReactComponent as PayLaterIcon } from '@/assets/icons/paylater.icon.svg';
import { ReactComponent as BillboxxIcon } from '@/assets/icons/billboxx.icon.svg';
import { ReactComponent as RequestPayIcon } from '@/assets/icons/request-pay.icon.svg';
import { ReactComponent as InvoiceIcon } from '@/assets/icons/invoice.icon.svg';

export const userTypes = [
  {
    userType: 'A Business',
    description: 'Pay vendor invoices & get paid by customers',
    icon: StoreIcon,
    slug: 'business',
    id: 'clf6nhqlp0007v2mh8w9qv2wn'
  },

  {
    userType: 'Accounting firm',
    description: 'Manage clients Accounts Payable and Accounts Receivable.',
    icon: FirmIcon,
    slug: 'accounting-firm',
    id: 'clf6nhrnb0009v2mhk4r9i8g9'
  }
];

export const billVolumes = [
  {
    volume: 'Below 10',
    icon: ArrowDownIcon
  },
  {
    volume: 'Up to 50',
    icon: ArrowRightIcon
  },
  {
    volume: 'Up to 100',
    icon: ArrowRightIcon
  },
  {
    volume: 'Above 100',
    icon: ArrowUpIcon
  }
];

export const billPayments = [
  {
    volume: 'Below 100k',
    icon: ArrowDownIcon
  },
  {
    volume: 'Up to 500k',
    icon: ArrowRightIcon
  },
  {
    volume: 'Up to 1M',
    icon: ArrowRightIcon
  },
  {
    volume: 'Above 1M',
    icon: ArrowUpIcon
  }
];

export const invoiceVolume = [
  {
    volume: 'Below 10',
    icon: ArrowDownIcon
  },
  {
    volume: 'Up to 50',
    icon: ArrowRightIcon
  },
  {
    volume: 'Up to 100',
    icon: ArrowRightIcon
  },
  {
    volume: 'Above 100',
    icon: ArrowUpIcon
  }
];

export const listedBanks = [
  {
    code: '058',
    logo: 'https://nigerianbanks.xyz/logo/guaranty-trust-bank.png',
    name: 'Guaranty Trust Bank',
    slug: 'guaranty-trust-bank',
    ussd: '*737#'
  },

  {
    code: '044',
    logo: 'https://nigerianbanks.xyz/logo/access-bank.png',
    name: 'Access Bank',
    slug: 'access-bank',
    ussd: '*901#'
  },

  {
    code: '057',
    logo: 'https://nigerianbanks.xyz/logo/zenith-bank.png',
    name: 'Zenith Bank',
    slug: 'zenith-bank',
    ussd: '*966#'
  }
];

export const newBillMethods = [
  {
    title: 'Add a bill manually',
    description: 'Easy with just a few clicks',
    value: 'manual',
    icon: FileIcon
  },
  {
    title: 'Upload a bill file',
    description: 'Add a PDF invoice or image from any device.',
    value: 'upload-file',
    icon: UploadIcon
  },
  {
    title: 'Upload a list of bill',
    description: 'Add a CSV Spreadsheet bill',
    value: 'upload-csv',
    icon: UploadIcon
  }
];

export const newInvoiceMethods = [
  {
    title: 'Enter an invoice detail',
    description: 'Add amount, due date & other details',
    value: 'manual',
    icon: EditIcon
  },
  {
    title: 'Upload an invoice file',
    description: 'Auto-scans any digital invoice',
    value: 'upload-file',
    icon: UploadIcon
  },
  {
    title: 'Upload a list of invoices',
    description: 'Add a CSV Spreadsheet of invoices',
    value: 'upload-csv',
    icon: UploadIcon
  },
  {
    title: 'Create an invoice document',
    description: 'Enter items, descriptions, quantities, amounts & other details.',
    value: 'create-invoice',
    icon: FileIcon
  }
];

export const requestPaymentMethods = [
  {
    title: 'Send Payment request',
    description: 'Generate and send payment link',
    value: 'request-payment',
    icon: RequestPayIcon
  },
  {
    title: 'Invoice Discounting',
    description: 'Apply to get paid now instead of later',
    value: 'invoice-discounting',
    icon: InvoiceIcon
  }
];

export const paymentMethods = [
  {
    title: 'Debit/Credit Card',
    description: 'Pay with a debit or credit card',
    subText: 'Payment charges will apply',
    value: 'card',
    icon: CardIcon,
    available: true
  },
  {
    title: 'Billboxx Balance',
    description: 'Pay from your Billboxx balance',
    subText: '',
    value: 'billboxx-balance',
    icon: BillboxxIcon,
    available: true
  },
  {
    title: 'Bank transfer',
    description: 'Transfer to bank account',
    subText: '',
    value: 'bank-transfer',
    icon: BankIcon,
    available: true
  }
];

export const paymentMethods2 = [
  {
    title: 'Debit card/Transfer',
    description: 'Pay with card or bank transfer.',
    subText: 'Payment charges will apply',
    value: 'transfer',
    icon: CardIcon,
    available: true
  },
  {
    title: 'Financing (Loan)',
    description: 'Apply to pay with loan',
    subText: 'Interest charges will apply',
    value: 'credit-card',
    icon: CardIcon,
    available: true
  },
  {
    title: 'Bank Transfer',
    description: 'Pay with bank transfer',
    subText: '',
    value: 'bank-transfer',
    icon: BankIcon,
    available: true
  }
];

export const paymentMethods3 = [
  {
    title: 'Pay Now',
    description: null,
    subText: null,
    text: null,
    value: 'pay-now',
    icon: PayNowIcon,
    available: true,
    list: ['Debit/Credit Card', 'Billboxx Balance', 'Bank Transfer']
  },
  {
    title: 'Pay Later',
    description: 'Apply to defer payment',
    subText: 'Interest charges may apply',
    text: 'For verified businesses only',
    value: 'pay-later',
    icon: PayLaterIcon,
    available: true,
    list: null
  },
  {
    title: 'Pay Small Small',
    description: 'Pay in instalments',
    subText: 'Interest charges will apply',
    text: 'For businesses & individuals',
    value: 'pay-small',
    icon: PaySmallIcon,
    available: false,
    list: null
  }
];

export const genderTypes = [
  {
    id: '1',
    title: 'Male',
    value: 'MALE'
  },
  {
    id: '2',
    title: 'Female',
    value: 'FEMALE'
  },
  {
    id: '3',
    title: 'Others',
    value: 'OTHERS'
  }
];

export const documentTypes = [
  {
    id: '1',
    title: 'Purchase Order',
    value: 'purchase-order'
  },
  {
    id: '2',
    title: 'Contract',
    value: 'contract'
  },
  {
    id: '3',
    title: 'Others',
    value: 'OTHERS'
  }
];

export const designationTypes = [
  {
    id: '1',
    title: 'Chief Executive Officer',
    value: 'Chief Executive Officer'
  },
  {
    id: '2',
    title: 'Chief Operating Officer',
    value: 'Chief Operating Officer'
  },
  {
    id: '3',
    title: 'Managing Director',
    value: 'Managing Director'
  },
  {
    id: '4',
    title: 'General Manager',
    value: 'General Manager'
  },
  {
    id: '5',
    title: 'Finance Manager',
    value: 'Finance Manager'
  },
  {
    id: '6',
    title: 'Others',
    value: 'Others'
  }
];

export const businessRegTypes = [
  {
    id: '1',
    title: 'Business Name',
    value: 'business-name'
  },
  {
    id: '2',
    title: 'Private Incorporated Company',
    value: 'private-incorporated-company'
  },
  {
    id: '3',
    title: 'Public Incorporated Company',
    value: 'public-incorporated-company'
  },
  {
    id: '4',
    title: 'Incorporated Trustee',
    value: 'incorporated-trustee'
  },
  {
    id: '5',
    title: 'Free Zone Entity',
    value: 'free-zone-entity'
  },
  {
    id: '6',
    title: 'Government Entity',
    value: 'government-entity'
  },
  {
    id: '7',
    title: 'Cooperative Society',
    value: 'cooperative-society'
  }
];

export const businessRegYears = [
  {
    id: '1',
    title: 'Pre 2017',
    value: 'pre-2017'
  },
  {
    id: '2',
    title: '2017 - 2020',
    value: '2017-2020'
  },
  {
    id: '3',
    title: 'Post 2020',
    value: 'post-2020'
  }
];

export const referralPoints = [
  {
    id: '1',
    name: 'Twitter',
    value: 'twitter'
  },
  {
    id: '2',
    name: 'Facebook',
    value: 'facebook'
  },
  {
    id: '3',
    name: 'LinkedIn',
    value: 'linkedin'
  },
  {
    id: '4',
    name: 'Thread',
    value: 'thread'
  },
  {
    id: '5',
    name: 'Instagram',
    value: 'instagram'
  },
  {
    id: '6',
    name: 'Website',
    value: 'website'
  },
  {
    id: '7',
    name: 'Friend',
    value: 'friend'
  },
  {
    id: '8',
    name: 'None',
    value: 'none'
  }
];

export const auth2faOptions = [
  {
    title: 'Email verification',
    description: 'Get verification codes via email when you log in to your account.',
    subText: '',
    value: 'email',
    icon: CardIcon,
    available: true
  },
  {
    title: 'Authenticator app',
    description:
      'Get verification codes from an authenticator app when you log in to your account.',
    subText: '',
    value: 'google-authenticator',
    icon: CardIcon,
    available: false
  },
  {
    title: 'SMS verification',
    description: 'Get verification codes via SMS when you log in to your account.',
    subText: '',
    value: 'sms',
    icon: CardIcon,
    available: false
  }
];
