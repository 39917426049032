import ProfileDropdown from '@/components/ProfileDropdown';
import React from 'react';
import { InboxxStyles } from './inboxx.styles';
import { ReactComponent as SoonIcon } from '@/assets/icons/soon.svg';
import withAuthentication from '@/hooks/withAuthentication';

const Inboxx = () => {
  return (
    <InboxxStyles>
      <div className="inboxx-header">
        <div className="title-view">{/* <h3>ABC Ventures</h3> */}</div>
        <ProfileDropdown />
      </div>
      <div className="inboxx-wrapper">
        <div className="soon">
          <SoonIcon />
          <h3>This feature is currently unavailable!</h3>
        </div>
      </div>
    </InboxxStyles>
  );
};

export default withAuthentication(Inboxx);
