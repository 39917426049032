import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';
import { appSelector, setDisbursementData } from '@/redux/features/app.slice';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const paymentTypes = [
  {
    title: 'Pay from bank balance',
    description: 'Send money from your Providus Bank Balance',
    subText: '',
    value: 'wallet',
    icon: icons.sendIcon,
    available: true
  },
  {
    title: 'Pay by bank transfer',
    description: 'Send money to Lender’s Providus bank account.',
    subText: '',
    value: 'bank-transfer',
    icon: icons.sendIcon,
    available: true
  }
];

function PaymentMethodModal({
  setShowModal,
  showModal,
  setShowPaymentTypeModal,
  requestId,
  paymentMethod,
  setPaymentMethod
}) {
  const dispatch = useDispatch();
  const { disbursedPaymentData } = useSelector(appSelector);

  const handleConfirm = () => {
    dispatch(
      setDisbursementData({
        ...disbursedPaymentData,
        channel: paymentMethod,
        requestId
      })
    );
    setShowModal(false);
    setShowPaymentTypeModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Select payment method</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <ReactSVG src={icons.closeIcon} />
                  </button>
                </div>

                <FormView>
                  <div className="options">
                    {paymentTypes?.map((method) => {
                      return (
                        <div
                          className={`option ${paymentMethod === method?.value ? 'selected' : ''}`}
                          key={method?.value}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            if (method?.available) {
                              setPaymentMethod(method?.value);
                            } else return;
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              setPaymentMethod(method?.value);
                            }
                          }}>
                          {paymentMethod === method?.value && (
                            <ReactSVG src={icons.radioCheckedIcon} className="checked" />
                          )}
                          <div className={`icon ${method?.available ? '' : 'fade'}`}>
                            <ReactSVG src={method?.icon} />
                          </div>
                          <div className={`info ${method?.available ? '' : 'fade'}`}>
                            <h3>{method?.title}</h3>
                            <p>{method?.description}</p>
                            <br />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="cta">
                    <button type="button" onClick={handleConfirm}>
                      Continue
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default PaymentMethodModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme?.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    max-width: 600px;
    padding: 30px 20px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 24px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.mobile} {
      max-width: calc(100% - 32px);
      padding: 20px 16px;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.mobile} {
    width: 100%;
    padding: 20px 0px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme?.colors?.activeTitle};
    margin-top: 10px;
  }

  .options {
    width: 100%;
    margin-top: 30px;

    .option {
      width: 100%;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      padding: 20px 20px;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: all 0.1s ease-in-out;
      margin-bottom: 10px;
      border: 1px solid ${({ theme }) => theme?.colors?.layer};

      @media ${device.phone} {
        height: 100px;
      }

      .checked {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme?.colors?.layer};
        margin-right: 24px;
      }

      .info {
        width: calc(100%);

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme?.colors?.secondary};
          margin-bottom: 8px;
          text-align: left;
        }

        p {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 400;
          text-align: left;
        }

        span {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 300;
        }

        .soon {
          font-size: 0.875rem;
          line-height: 19px;

          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 300;
          margin-top: 10px;
          background-color: ${({ theme }) => theme?.colors?.inputBackground};
          opacity: 1;
          padding: 4px 8px;
          text-align: center;
        }
      }

      .fade {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .selected {
      border: 1px solid ${({ theme }) => theme?.colors?.primary};
    }
  }

  .input-view {
    width: 100%;
    margin: 20px 0;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme?.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme?.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme?.colors?.primary};
      color: ${(props) => props.theme?.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
