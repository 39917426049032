/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';

import useOnClickOutside from '@/hooks/useClickOutside';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as UncheckIcon } from '@/assets/icons/uncheck.icon.svg';
import { ReactComponent as AscIcon } from '@/assets/icons/asc.icon.svg';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as DescIcon } from '@/assets/icons/desc.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import { useSortableData } from '@/hooks/useSortableData';
import InflowDetails from './InflowDetails';
import { device } from '@/constants/breakpoints';

function InflowsTables({ inflows }) {
  const [showMenu, setShowMenu] = useState(false);
  const [, setShowFilterMenu] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedInflow, setSelectedInflow] = useState({});
  const [currentIndex] = useState(-1);
  const ref = useRef();
  const filterRef = useRef();

  useOnClickOutside(ref, () => setShowMenu(false));
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const { items, requestSort, sortConfig } = useSortableData(inflows?.items || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <InflowsTableView>
      <ViewHeader>
        <div className="title">
          <h3>
            Total: ₦{numberWithCommas(inflows?.meta?.totalAmount || '00.00')} (
            {inflows?.meta?.totalCount} Item
            {inflows?.meta?.totalCount > 1 ? 's' : ''})
          </h3>
        </div>

        <div className="actions">
          {/* <button type="button">
            <FilterIcon />
          </button> */}
        </div>
      </ViewHeader>

      {items?.length > 0 ? (
        <>
          <TableView>
            <TableHeader>
              {/* <div className="item icon">
                <button type="button">
                  <UncheckIcon />
                </button>
              </div> */}
              <div
                className={`item date ${getClassNamesFor('date')}`}
                onClick={() => requestSort('date')}>
                <span>
                  Date{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item paymentNo ${getClassNamesFor('paymentNo')}`}
                onClick={() => requestSort('paymentNo')}>
                <span>
                  Payment ref.{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item vendor ${getClassNamesFor('vendor')}`}
                onClick={() => requestSort('vendor')}>
                <span>
                  Customer{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item invoice ${getClassNamesFor('invoiceNo')}`}
                onClick={() => requestSort('invoiceNo')}>
                <span>
                  Invoice No.{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item method ${getClassNamesFor('paymentMethod')}`}
                onClick={() => requestSort('paymentMethod')}>
                <span>
                  Method of Payment{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item amount ${getClassNamesFor('amount')}`}
                onClick={() => requestSort('amount')}>
                <span>
                  Amount{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div className="item action">
                <span></span>
              </div>
            </TableHeader>

            {/* ====== Table body ====== */}
            <TableBody>
              {items.map((inflow, i) => (
                <TableRow key={inflow.id}>
                  {/* <div className="inflows-table__body-row__item icon">
                    <button type="button">
                      <UncheckIcon />
                    </button>
                  </div> */}
                  <div className="inflows-table__body-row__item date">
                    <span>
                      {format(new Date(inflow?.paidAt || inflow?.bill?.paidAt), 'do MMM, yyyy')}
                    </span>
                  </div>
                  <div className="inflows-table__body-row__item paymentNo">
                    <span>{inflow?.reference}</span>
                  </div>
                  <div className="inflows-table__body-row__item vendor">
                    <span>
                      {inflow?.bill?.customer?.companyName || inflow?.bill?.customer?.name || 'N/A'}
                    </span>
                  </div>
                  <div className="inflows-table__body-row__item invoiceNo">
                    <span>{inflow?.bill?.billOrInvoiceNumber}</span>
                  </div>
                  <div className="inflows-table__body-row__item method">
                    <span>{inflow?.paymentMethod}</span>
                  </div>
                  <div className="inflows-table__body-row__item amount">
                    <span>₦{numberWithCommas(inflow?.amount || '00.00')}</span>
                  </div>
                  <div className="inflows-table__body-row__item action">
                    <button
                      type="button"
                      className="btn view"
                      onClick={() => {
                        setSelectedInflow(inflow);
                        setShowViewModal(true);
                      }}>
                      <ViewIcon />
                    </button>

                    {/* ===== Dropdown Menu ====== */}
                    <AnimatePresence>
                      {showMenu && currentIndex === i ? (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          <button type="button">Edit</button>
                          <button type="button">Delete</button>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </TableRow>
              ))}
            </TableBody>
          </TableView>

          <div className="listing">
            {items?.map((item) => (
              <div className="item" key={item?.id}>
                <div className="col1">
                  <span className="name">
                    {item?.bill?.customer?.name || item?.bill?.customer?.companyName}
                  </span>
                  <span className="date">
                    {format(new Date(item?.bill?.paidAt), 'do MMM, yyyy')}
                  </span>
                  <span className="id">{item?.reference}</span>
                </div>
                <button
                  type="button"
                  className="btn more"
                  onClick={() => {
                    setSelectedInflow(item);
                    setShowViewModal(true);
                  }}>
                  <ViewIcon />
                </button>
                <span className="amount">₦{numberWithCommas(item?.amount || '00.00')}</span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyView>
          <EmptyIcon />
          <h3>Nothing to see here!</h3>
        </EmptyView>
      )}

      <InflowDetails
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        inflow={selectedInflow}
      />
    </InflowsTableView>
  );
}

export default InflowsTables;

const InflowsTableView = styled.div`
  width: 100%;

  .inflows-table {
    .inflows-table__header {
      .ascending {
        color: ${({ theme }) => theme.colors?.secondary};

        .up path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .descending {
        color: ${({ theme }) => theme.colors?.secondary};

        .down path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .icon {
        flex: 0.3;
      }

      .customer {
        flex: 1.5;
      }

      .action {
        flex: 0.5;
      }
    }

    .inflows-table__body {
      width: 100%;
      margin-top: 20px;

      .inflows-table__body-row {
      }
    }

    .inflows-table__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const ViewHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

const TableView = styled.div`
  width: 100%;
  margin-top: 20px;

  @media ${device.phone} {
    display: none;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
      }
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors?.secondary};

    .up path {
      stroke: ${({ theme }) => theme.colors?.secondary};
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors?.secondary};

    .down path {
      stroke: ${({ theme }) => theme.colors?.secondary};
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .icon {
    flex: 0.3;
  }

  .customer {
    flex: 1.5;
  }

  .method {
    flex: 1.3;
  }

  .amount {
    flex: 0.6;
  }

  .action {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .inflows-table__body-row__item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
    }
  }

  .icon {
    flex: 0.3;
  }

  .customer {
    flex: 1.5;
  }

  .method {
    flex: 1.3;
  }

  .amount {
    flex: 0.6;
  }

  .action {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view {
      margin-right: 20px;
    }

    .menu-actions {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: ${(props) => props.theme.colors?.white};
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }
`;
