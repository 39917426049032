/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import Button from '@/components/Button/Button';

export default function SupportModal({ showModal, setShowModal }) {

  const onSubmit = () => {
    const emailAddress = 'support@billboxx.com';
    const mailtoUrl = `mailto:${emailAddress}`;
    window.location.href = mailtoUrl;
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Want to subscribe to Enterprise?</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <ContentView>
                  <div className="select-view">
                    <p>
                      To subscribe to the Enterprise plan, please contact support at <a href='mailto:support@billboxx.com' onClick={() => setShowModal(false)}>support@billboxx.com</a>
                    </p>
                    <p>
                      Or you can send a mail to our support team by clicking the <span className='bold'>Contact Support</span> button below
                    </p>
                  </div>
                  <div className="button-view">
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <Button
                      type="button"
                      width="40%"
                      className="btn btn-primary"
                      onClick={() => {
                        onSubmit()
                        setShowModal(false)
                      }}
                    >
                      Contact Support
                    </Button>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 580px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 0;
  padding-bottom: 0;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 15px 30px;
    background-color: ${(props) => props.theme.colors?.layer4};
    text-align: center;
  }

  .select-view {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 20px;
    padding: 0 30px;
    
    p {
      color: ${(props) => props.theme.colors?.secondary};
      line-height: 20px;

      .bold {
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    a {
      color: ${(props) => props.theme.colors?.primary};
    }
  }

  .button-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    padding-bottom: 0;
    margin-top: 20px;
    background-color: ${(props) => props.theme.colors?.white};

    .btn {
      width: 100%;
      height: 64px;
      max-width: 200px;
      font-size: 1rem;
      font-weight: 500;
      padding: 10px 0;
      border-radius: 16px;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.btn-cancel {
        background-color: transparent;
        color: ${(props) => props.theme.colors?.secondary};
        border: 1px solid ${(props) => props.theme.colors?.secondary};
        width: 120px;
      }

      &.btn-primary {
        background-color: ${(props) => props.theme.colors?.primary};
        color: ${(props) => props.theme.colors?.white};
      }
    }
  }
`;
