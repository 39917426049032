import { fetchUser } from '@/services/userApi';
import { useQuery } from '@tanstack/react-query';

const useUser = () => {
  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  if (user?.isFetching) {
    return {
      user: null
    };
  } else {
    return {
      user: user?.data?.data
    };
  }
};

export default useUser;
