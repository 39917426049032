import React from 'react';
import { ReactComponent as SortIcon } from '@/assets/icons/sort.icon.svg';
import { ReactComponent as SortDownIcon } from '@/assets/icons/sort-down.icon.svg';
import { ReactComponent as SortUpIcon } from '@/assets/icons/sort-up.icon.svg';
import styled from 'styled-components';

const SortingComponent = ({ sortOrder, onSortChange }) => {
  // Function to handle button click and update sort order
  const toggleSort = () => {
    onSortChange((prevOrder) => {
      switch (prevOrder) {
        case 'none':
          return 'asc';
        case 'asc':
          return 'desc';
        case 'desc':
          return 'none';
        default:
          return 'none';
      }
    });
  };

  // Function to determine button text based on sort order
  const buttonText = () => {
    switch (sortOrder) {
      case 'none':
        return <SortIcon />;
      case 'asc':
        return <SortDownIcon />; // Sorting is currently ascending; next click will make it descending
      case 'desc':
        return <SortUpIcon />; // Sorting is currently descending; next click will remove sorting
      default:
        return 'Sort';
    }
  };

  return (
    <Container>
      <Button onClick={toggleSort}>{buttonText()}</Button>
    </Container>
  );
};

export default SortingComponent;

const Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors?.secondary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  padding: 0;

  svg {
  }

  &:hover {
    background-color: ${(props) => props.theme.colors?.layer};
  }
`;
