const theme = {
  colors: {
    // Natural Colors
    black: '#000000',
    white: '#ffffff',

    // Greyscale Colors
    activeTitle: '#14142B',
    body: '#4E4B66',
    label: '#6E7191',
    placeholder: '#A0A3BD',
    line: '#D9DBE9',
    inputBackground: '#EFF0F7',
    layer: '#EFF0F6',
    layer2: '#EFF6FF',
    layer3: '#F5F5F5',
    layer4: '#F8F8F8',
    layer5: '#E5E5E5',
    layer6: '#DCEBFF',
    background: '#F4F4F4',
    offWhite: '#FCFCFC',
    info: '#9F9F9F',

    // Primary Colors
    primary: '#92C22C',
    primaryHover: '#80AB25',
    primaryActive: '#526C1A',
    primaryFocus: '#EEFFC9',
    secondary: '#174078',
    warning: '#F4B740',
    warning_200: '#fce9c6',
    error: '#ED2E7E',
    error_200: '#B00020',
    errorBackground: '#FFF3F8',
    success: '#00BA88',
    success_200: '#CBFFF1'
  }
};
export default theme;
