/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { device } from '@/constants/breakpoints';
import OTPInput from 'react-otp-input';
import { createSecurityPin, initiateResetPin } from '@/services/securityApi';
import { fetchUser } from '@/services/userApi';
import SuccessPopup from '@/components/SuccessPopup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as EnterIcon } from '@/assets/icons/nav-arrow-right.icon.svg';
import ChangePinModal from './ChangePinModal';
import ResetPinModal from './ResetPinModal';

function TransactionPin() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [confirmedPin, setConfirmedPin] = useState('');
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [showChangePinModal, setShowChangePinModal] = useState(false);
  const [showResetPinModal, setShowResetPinModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const prevUrl = searchParams.get('state');

  const handleCreatePin = useMutation(createSecurityPin, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);

      if (prevUrl) {
        toast.success('Transaction PIN created successfully');
        if (prevUrl === 'transfer-funds') {
          navigate('/banking?tab=transfer-funds');
        }
      } else {
        setShowSuccessModal(true);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleInitiateResetPin = useMutation(initiateResetPin, {
    onSuccess: () => {
      toast.success('Please check your inbox for a code. Expires in 5 minutes.');
      setShowResetPinModal(true);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const formData = {
      type: 't-pin',
      value: confirmedPin
    };

    if (pin === confirmedPin) {
      handleCreatePin.mutate(formData);
    } else {
      toast.error('Transaction PINs do not match');
    }
  };

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  if (user?.isFetching) {
    return (
      <TransactionPinView>
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#00B87C"
              secondaryColor="#00B87C"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      </TransactionPinView>
    );
  }

  return user?.data?.data?.hasTransactionPin ? (
    <>
      <TransactionPinView>
        <TitleView>
          <h3>Update transaction PIN</h3>
          <p>
            Update your transaction PIN to a new one. <br /> You can update your transaction PIN if
            you have forgotten it.
          </p>
        </TitleView>
        <OptionsView>
          <OptionView>
            {/* <div className="icon">
              <SendIcon />
            </div> */}

            <div className="account-info">
              <h4>Change PIN</h4>
              <p className="description">Change your transaction PIN to a new one.</p>
            </div>

            <div className="proceed-view">
              <button className="icon-copy" onClick={() => setShowChangePinModal(true)}>
                Proceed <EnterIcon />
              </button>
            </div>
          </OptionView>

          <OptionView>
            {/* <div className="icon">
              <SendIcon />
            </div> */}

            <div className="account-info">
              <h4>Reset PIN</h4>
              <p className="description">Forgot your pin? Click here to reset it</p>
            </div>

            <div className="proceed-view">
              {handleInitiateResetPin.isLoading ? (
                <Oval
                  color="#00B87C"
                  secondaryColor="#00B87C"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                <button
                  className="icon-copy"
                  onClick={() => {
                    handleInitiateResetPin.mutate({ securityType: 't-pin', method: 'email' });
                  }}>
                  Proceed <EnterIcon />
                </button>
              )}
            </div>
          </OptionView>
        </OptionsView>
      </TransactionPinView>

      <ChangePinModal showModal={showChangePinModal} setShowModal={setShowChangePinModal} />

      <ResetPinModal showModal={showResetPinModal} setShowModal={setShowResetPinModal} />
    </>
  ) : (
    <TransactionPinView>
      <TitleView>
        <h3>Create transaction PIN</h3>
      </TitleView>

      {!showConfirmPin && (
        <FormView>
          {/* Email */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="email">Enter transaction PIN</label>
            </div>
            <OTPInput
              value={pin}
              onChange={setPin}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              containerStyle="otp-input"
              inputType="number"
              skipDefaultStyles
              shouldAutoFocus
            />
          </div>

          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={() => setShowConfirmPin(true)}
              disabled={pin.length < 6}>
              Continue
            </Button>
          </div>
        </FormView>
      )}

      {/* Re-enter transaction PIN */}
      {showConfirmPin && (
        <FormView>
          {/* Email */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="email">Confirm transaction PIN</label>
            </div>
            <OTPInput
              value={confirmedPin}
              onChange={setConfirmedPin}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              containerStyle="otp-input"
              inputType="number"
              skipDefaultStyles
              shouldAutoFocus
            />
          </div>

          {/* Button view */}
          <div className="button-view">
            <buttton className="back-button" type="button" onClick={() => setShowConfirmPin(false)}>
              Back
            </buttton>
            <Button
              type="button"
              styleType="primary"
              width="70%"
              onClick={onSubmit}
              disabled={confirmedPin.length < 6}>
              {handleCreatePin.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Create transaction PIN'
              )}
            </Button>
          </div>
        </FormView>
      )}

      <SuccessPopup
        showModal={showSuccessModal}
        setShowModal={setShowSuccessModal}
        mainText="Your PIN is set"
        subText="You can now use your new PIN to make transactions"
      />
    </TransactionPinView>
  );
}

export default TransactionPin;

const TransactionPinView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cta-view {
    display: flex;
    align-items: center;

    .reset-pin {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .change-pin {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 70%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FormView = styled.form`
  width: 50%;

  @media ${device.phone} {
    width: 100%;
  }

  .input-view {
    width: 100%;
    margin-bottom: 26px;

    .label-view {
      width: 100%;
      display: flex;
      margin-bottom: 8px;

      label {
        font-size: 1rem;
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 8px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }
  }

  .otp-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    input {
      width: calc(100% / 6 - 10px);
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      text-align: center;
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      border: none;
      -webkit-text-security: disc;

      &:focus {
        outline: 1px solid ${({ theme }) => theme.colors?.primary};
        border: none;
      }
    }
  }

  .button-view {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;

    @media ${device.phone} {
      width: 100%;
    }

    button {
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .back-button {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const OptionsView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .fade {
    opacity: 0.5;
    pointer-events: none;
    position: relative;

    .coming-soon {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${({ theme }) => theme.colors.info};
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};

      p {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

const OptionView = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.layer4};
  padding: 1.5rem;
  border-radius: 0.5rem;

  @media ${device.phone} {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    svg {
      width: 4rem;
      height: 4rem;

      @media ${device.phone} {
        width: 40px;
        height: 40px;
      }
    }
  }

  .account-info {
    width: 50%;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 100%;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
    }
  }

  .proceed-view {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;

    @media ${device.phone} {
      position: absolute;
      bottom: 20px;
      right: 20px;
      top: auto;
    }

    button {
      font-size: 0.875rem;
    }

    .icon-copy {
      border: none;
      outline: none;
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;

      &:hover {
        text-decoration: underline;
        svg {
          transform: translateX(0.5rem);
        }
      }

      svg {
        margin-left: 0.3rem;
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;
