// Desc: Authentication hook

import { useQuery } from '@tanstack/react-query';

import { fetchUser } from '@/services/userApi';
import secureLocalStorage from 'react-secure-storage';

const useAuthentication = () => {
  const authenticated = secureLocalStorage.getItem('auth');

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    enabled: !!authenticated,
    onSuccess: (data) => {}
  });

  return { user, authenticated };
};

export default useAuthentication;
