/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import ProfileDropdown from '@/components/ProfileDropdown';
import { PayablesStyles } from './bills.styles';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/fileUpload.icon.svg';
import Drafts from './components/Drafts';
import { fetchBillsMetric } from '@/services/billsApi';
import TableLoader from '@/components/TableLoader';
import { numberWithCommas, numberWithCommas2, truncate } from '@/utils/helpers';
import PaidBills from './components/PaidBills';
import MobileSidebar from '@/components/MobileSidebar';
import PartiallyPaidBills from './components/PartiallyPaidBills';
import withAuthentication from '@/hooks/withAuthentication';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';
import ComplianceWarnModal from '@/components/ComplianceWarnModal';
import { fetchCompany, fetchUser } from '@/services/userApi';
import ApprovalBills from './components/ApprovalBills';
import PayablesBills from './components/PayablesBills';
import ExportModal from './components/ExportModal';

function Payables() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [openNav, setOpenNav] = useState(false);
  const companyId = secureLocalStorage.getItem('company');
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);
  const [showComplianceWarning, setShowComplianceWarning] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const billsMetrics = useQuery({
    queryKey: ['billsMetrics'],
    queryFn: fetchBillsMetric,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const handleClickNewBill = () => {
    // if (!features?.bill_volume?.is_active) {
    //   setShowUpgradeWarning(true);
    //   return;
    // }
    navigate('/make-payments/bills/new');
  };

  useEffect(() => {
    if (!user?.isLoading && user?.data?.data) {
      if (user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(false);
      }
      if (!user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(true);
      }
    } else {
      setShowComplianceWarning(false);
    }
  }, [user?.data?.data]);

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <PayablesStyles>
        <div className="payables-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{truncate(company?.data?.data?.name || '', 40, true)}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <div className="payables-wrapper">
          <div className="payables-wrapper__page-title">
            <button type="button">
              <BackIcon />
            </button>
            <h3>Make Payments</h3>
          </div>

          <div className="payables-wrapper__header">
            <h3>Bills</h3>
            <div className="left-view">
              {user?.data?.data?.complianceVerified && (
                <button type="button" className="new" onClick={handleClickNewBill}>
                  <PlusIcon /> New Bill
                </button>
              )}
              <button type="button" className="upload" onClick={() => setShowExportModal(true)}>
                <FileIcon />
              </button>
            </div>
          </div>

          <div className="payables-wrapper__tabs">
            <div
              className={`tab drafts ${tab === 'draft' ? 'active' : ''}`}
              onClick={() => {
                setSearchParams('tab=draft&page=1');
              }}>
              <h3>Drafts</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(billsMetrics?.data?.data?.draft?.count || '0')} Items</h4>
                <p></p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab approval ${tab === 'approval' ? 'active' : ''}`}
              onClick={() => {
                setSearchParams('tab=approval&page=1');
              }}>
              <h3>Approval</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(billsMetrics?.data?.data?.pendingApproval?.count || '0')} Items
                </h4>
                <p>
                  ₦
                  {numberWithCommas(
                    billsMetrics?.data?.data?.pendingApproval?.totalAmount || '00.00'
                  )}
                </p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab ready ${tab === 'payables' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=payables&page=1')}>
              <h3>Payables</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(billsMetrics?.data?.data?.ready?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(billsMetrics?.data?.data?.ready?.totalAmount || '00.00')}</p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab ready ${tab === 'partially-paid' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=partially-paid&page=1')}>
              <h3>Partially paid</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(billsMetrics?.data?.data?.partiallyPaid?.count || '0')} Items
                </h4>
                <p>
                  ₦
                  {numberWithCommas(
                    billsMetrics?.data?.data?.partiallyPaid?.totalAmount || '00.00'
                  )}
                </p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab paid ${tab === 'paid' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=paid&page=1')}>
              <h3>Fully paid</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(billsMetrics?.data?.data?.paid?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(billsMetrics?.data?.data?.paid?.totalAmount || '00.00')}</p>
              </div>
            </div>
          </div>

          <div className="payables-wrapper__components">
            {billsMetrics?.isLoading ? (
              <TableLoader />
            ) : tab === 'draft' ? (
              <Drafts enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'approval' ? (
              <ApprovalBills enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'payables' ? (
              <PayablesBills enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'partially-paid' ? (
              <PartiallyPaidBills enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : (
              <PaidBills enabledFetch={user?.data?.data?.complianceVerified || false} />
            )}
          </div>

          {/* <div className="payables-wrapper__pagination">
            <Pagination
              itemsPerPage={pageLimit}
              totalItems={bills?.data?.data?.meta?.totalCount}
              currentPage={bills?.data?.data?.meta?.page}
              onPageChange={onPageChange}
            />
          </div> */}
        </div>
      </PayablesStyles>

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="make-payments/payables?tab=draft"
        text="You have reached your bill limit for your current plan. Upgrade your plan to continue."
      />

      <ComplianceWarnModal
        showModal={showComplianceWarning}
        setShowModal={setShowComplianceWarning}
      />

      <ExportModal showModal={showExportModal} setShowModal={setShowExportModal} />
    </>
  );
}

export default withAuthentication(Payables);
