/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as FullPayIcon } from '@/assets/icons/book.icon.svg';
import { ReactComponent as PartialPayIcon } from '@/assets/icons/pay-card.icon.svg';
import AddVendorBankAccount from '@/pages/Vendors/components/AddVendorBankAccount';
import { device } from '@/constants/breakpoints';

export const paymentTypes = [
  {
    title: 'School Fees Financing',
    description: 'Take a loan to make payment for this school fees',
    subText: '',
    value: 'school',
    icon: FullPayIcon,
    available: true
  },
  {
    title: 'Device Financing',
    description: 'Take a loan to make payment for this device',
    subText: '',
    value: 'invoice',
    icon: PartialPayIcon,
    available: true
  }
];

function CreditPaymentTypeModal({ showModal, setShowModal, bill }) {
  const [paymentType, setPaymentType] = useState('school');
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);

  const handleContinue = (data) => {
    if (bill.vendor?.bankAccounts?.length === 0) {
      setShowAddAccountModal(true);
      return;
    }
    if (paymentType === 'school') {
      window.open('https://forms.gle/zDq2UBu7BiEwpFQo8', '_blank');
    } else {
      window.open('https://forms.gle/pKG1X4AzGkZKMyVF9', '_blank');
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Select Credit card/Financing type</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <FormView>
                  <div className="options">
                    {paymentTypes?.map((method) => {
                      const Icon = method?.icon;
                      return (
                        <div
                          className={`option ${paymentType === method?.value ? 'selected' : ''}`}
                          key={method?.value}
                          role="button"
                          tabIndex={0}
                          onClick={() => setPaymentType(method?.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              setPaymentType(method?.value);
                            }
                          }}>
                          {paymentType === method?.value && <CheckedIcon className="checked" />}
                          <div className="icon">
                            <Icon />
                          </div>
                          <div className={`info ${method?.available ? '' : 'fade'}`}>
                            <h3>{method?.title}</h3>
                            <p>{method?.description}</p>
                            <br />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="cta">
                    <button type="button" onClick={handleContinue}>
                      Continue with application
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <AddVendorBankAccount
        showModal={showAddAccountModal}
        setShowModal={setShowAddAccountModal}
        vendorId={bill?.vendor?.id}
      />
    </>
  );
}

export default CreditPaymentTypeModal;

CreditPaymentTypeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  bill: PropTypes.object.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.activeTitle};
    margin-top: 10px;
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .option {
      width: 48%;
      height: 180px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      padding: 20px 10px;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: all 0.1s ease-in-out;
      margin-bottom: 10px;
      border: 1px solid ${({ theme }) => theme.colors?.layer};

      .checked {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .icon {
        width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors?.layer};
        margin-right: 24px;
        margin-bottom: 20px;
      }

      .info {
        width: calc(100%);

        h3 {
          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};
          margin-bottom: 8px;
          text-align: center;
        }

        p {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 400;
          margin-bottom: 4px;
          text-align: center;
        }

        span {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
        }

        .soon {
          font-size: 0.875rem;
          line-height: 19px;

          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
          margin-top: 10px;
          background-color: ${({ theme }) => theme.colors?.inputBackground};
          opacity: 1;
          padding: 4px 8px;
          text-align: center;
        }
      }

      .fade {
        opacity: 0.4;
      }
    }

    .selected {
      border: 1px solid ${({ theme }) => theme.colors?.primary};
    }
  }

  .input-view {
    width: 100%;
    margin: 20px 0;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 100px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
