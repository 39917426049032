/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';

import useOnClickOutside from '@/hooks/useClickOutside';
import { ReactComponent as DropIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { numberWithCommas, truncateYear } from '@/utils/helpers';
import { device } from '@/constants/breakpoints';
import { fetchAccountChart, fetchAgingChart } from '@/services/dashboardApi';
import DoughnutChart from './DoughnutChart';

const durations = [
  {
    id: 1,
    name: 'This Year',
    type: 'year',
    value: new Date().getFullYear()
  },
  {
    id: 2,
    name: 'Previous Year',
    type: 'year',
    value: new Date().getFullYear() - 1
  },
  {
    id: 3,
    name: 'Last 12 Months',
    type: 'month',
    value: 12
  },
  {
    id: 4,
    name: 'Last 6 Months',
    type: 'month',
    value: 6
  }
];

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <CustomedTooltip className="custom-tooltip">
        <p className="desc">{label}</p>
        <p className="label">{`Receivables: ₦${numberWithCommas(payload[0].value || '00.00')}`}</p>
        <p className="label">{`Paid: ₦${numberWithCommas(payload[1].value || '00.00')}`}</p>
      </CustomedTooltip>
    );
  }

  return null;
}

function AccountReceivableChart() {
  const [openDuration, setOpenDuration] = useState(false);
  const [dateType, setDateType] = useState(durations[2]);
  const ref = useRef();
  const [chartData, setChartData] = useState([]);

  useOnClickOutside(ref, () => setOpenDuration(false));

  const accountPaybaleChart = useQuery({
    queryKey: [
      'cashFlowChart',
      {
        type: dateType?.type,
        yearOrNumberOfMonths: dateType?.value,
        billType: 'receivables'
      }
    ],
    queryFn: fetchAccountChart,
    onSuccess: () => {},
    onError: () => {}
  });

  const agingChart = useQuery({
    queryKey: ['agingChart'],
    queryFn: fetchAgingChart,
    onSuccess: (data) => {
      const colors = ['#92C22C', '#174078', '#C2F457', '#C6DE92', '#75AEFD', '#8DB635'];
      const originalObject = data?.data?.receivablesAging;
      const convertedObject = Object.entries(originalObject).map(([label, value]) => ({
        label,
        value
      }));
      const objectsWithColors = convertedObject.map((obj, index) => ({
        ...obj,
        color: colors[index % colors.length]
      }));
      setChartData(objectsWithColors);
    },
    onError: () => {}
  });

  return (
    <AccountReceivableChartStyles>
      <div className="chart-view">
        <div className="chart-header">
          <div className="chart-header__title">
            <h3>Accounts Receivable</h3>
          </div>
          <div className="chart-header__legends">
            <div className="chart-header__legend">
              <div className="chart-header__legend__color1"></div>
              <div className="chart-header__legend__text">Total Receivables</div>
            </div>
            <div className="chart-header__legend">
              <div className="chart-header__legend__color2"></div>
              <div className="chart-header__legend__text">Payments</div>
            </div>
          </div>
        </div>

        <div className="chart-duration">
          <button
            type="button"
            onClick={() => {
              setOpenDuration(true);
            }}
            className="drop">
            {dateType?.name} <DropIcon />
          </button>

          <AnimatePresence>
            {openDuration ? (
              <motion.div
                className="menu"
                ref={ref}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                {durations.map((duration) => (
                  <button
                    type="button"
                    key={duration?.id}
                    onClick={() => {
                      setOpenDuration(false);
                      setDateType(duration);
                    }}>
                    {duration.name}
                  </button>
                ))}
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>

        {accountPaybaleChart?.isFetching ? (
          <div className="loader">
            <Oval color="#0EBE8E" height={40} width={40} />
          </div>
        ) : (
          <div className="chart-body">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width="100%"
                height="100%"
                data={accountPaybaleChart?.data?.data}
                margin={{
                  top: 10,
                  right: 20,
                  left: -10,
                  bottom: 0
                }}>
                <CartesianGrid strokeDasharray="0 0" horizontal={false} vertical={false} />
                <XAxis
                  dataKey="date"
                  tick={{
                    stroke: '#BFBFBF',
                    strokeWidth: 0.4,
                    fontSize: '0.7rem'
                  }}
                  tickFormatter={(tick) => truncateYear(tick, 3)}
                />
                <YAxis
                  tick={{
                    stroke: '#BFBFBF',
                    strokeWidth: 0.4,
                    fontSize: '0.7rem'
                  }}
                  tickFormatter={(tick) => `${(tick / 1000000).toFixed(1)}M`}
                />
                <Tooltip
                  formatter={(value) => `${(value / 1000000).toFixed(1)}M`}
                  content={<CustomTooltip />}
                  wrapperStyle={{ outline: 'none' }}
                  offset={5}
                />
                <Bar dataKey="totalReceivables" fill="#174078" barSize={6} />
                <Bar dataKey="totalPaid" fill="#92C22C" barSize={6} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
      <div className="doghnut-view">
        <div className="chart-header__title">
          <h3>Receivable Aging</h3>
        </div>
        {agingChart?.isFetching ? (
          <div className="loader">
            <Oval color="#0EBE8E" height={40} width={40} />
          </div>
        ) : (
          <div className="chart">
            <DoughnutChart data={chartData} />
          </div>
        )}
      </div>
    </AccountReceivableChartStyles>
  );
}

export default AccountReceivableChart;

const AccountReceivableChartStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .chart-view {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;

    @media ${device.phone} {
      width: 100%;
    }

    .chart-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chart-header__title {
        h3 {
          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            font-size: 0.875rem;
          }
        }
      }

      .chart-header__legends {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media ${device.phone} {
          width: 45%;
          flex-direction: column;
          align-items: flex-start;
        }

        .chart-header__legend {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 20px;

          @media ${device.phone} {
            margin-bottom: 10px;
          }

          .chart-header__legend__color1 {
            width: 13px;
            height: 13px;
            background-color: ${({ theme }) => theme.colors?.secondary};
            border-radius: 50%;
            margin-right: 10px;
          }

          .chart-header__legend__color2 {
            width: 13px;
            height: 13px;
            background-color: ${({ theme }) => theme.colors?.primary};
            border-radius: 50%;
            margin-right: 10px;
          }

          .chart-header__legend__text {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors?.secondary};
          }
        }
      }
    }

    .chart-duration {
      margin-top: 20px;
      position: relative;
      .drop {
        width: 210px;
        height: 40px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors?.inputBackground};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        color: ${({ theme }) => theme.colors?.secondary};
      }

      .menu {
        position: absolute;
        top: 45px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors?.white};
        box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        z-index: 100;
        width: 210px;
        overflow: hidden;

        button {
          padding: 16px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          width: 100%;
          border: none;
          outline: none;
          border-radius: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: ${({ theme }) => theme.colors?.secondary};

          &:hover {
            background-color: ${({ theme }) => theme.colors?.layer};
          }
        }
      }
    }

    .chart-body {
      width: 100%;
      height: 250px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 12px;
      box-shadow: 0px 18.2453px 54.736px rgba(0, 0, 0, 0.05);
      margin-top: 20px;
      padding: 20px 0;
    }

    .loader {
      width: 100%;
      height: 250px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 12px;
      box-shadow: 0px 18.2453px 54.736px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .doghnut-view {
    width: 35%;

    margin-top: 20px;

    .chart-header__title {
      margin-bottom: 20px;
      h3 {
        font-size: 1rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 0.875rem;
        }
      }
    }

    .chart {
      width: 100%;
      height: 300px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 12px;
      box-shadow: 0px 18.2453px 54.736px rgba(0, 0, 0, 0.05);
      padding: 20px 20px;
    }

    .loader {
      width: 100%;
      height: 300px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 12px;
      box-shadow: 0px 18.2453px 54.736px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media ${device.phone} {
      width: 100%;
    }
  }
`;

const CustomedTooltip = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 12px;
  border: none !important;
  outline: none !important;
  box-shadow:
    0px 4.7644px 4.7644px rgba(50, 50, 71, 0.08),
    0px 4.7644px 9.5288px rgba(50, 50, 71, 0.06);
  padding: 12px;

  .desc {
    font-size: 0.8rem;
    margin-bottom: 4px;
  }

  .label {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    font-weight: 600;
    margin-top: 3px;
  }
`;
