import styled from 'styled-components';

export const InboxxStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  .inboxx-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-view {
      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  .inboxx-wrapper {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 30px;
    border-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .soon {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
        margin-top: 20px;
        text-align: center;
        max-width: 280px;
      }
    }
  }
`;
