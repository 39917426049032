/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import styled from 'styled-components';

import { device } from '@/constants/breakpoints';
import { createSecurityPin, deactivateSecurity, reactivateSecurity } from '@/services/securityApi';
import { fetchUser } from '@/services/userApi';
import { auth2faOptions } from '@/utils/data.utils';
import { ReactComponent as EnabledIcon } from '@/assets/icons/switch-off.icon.svg';
import { ReactComponent as DisabledIcon } from '@/assets/icons/switch-on.icon.svg';

function Auth2faSettings() {
  const queryClient = useQueryClient();
  const [active2fa, setActive2fa] = useState(null);

  const handleEnable2fa = useMutation(createSecurityPin, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      toast.success('2FA enabled successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleReactivateSecurity = useMutation(reactivateSecurity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      toast.success('2FA reactivated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDeactivate = useMutation(deactivateSecurity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      toast.success('2FA deactivated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const formData = {
      type: 'login-2fa'
    };
    if (user?.data?.data?.hasLogin2fa) {
      if (user?.data?.data?.hasLogin2faEnabled) {
        handleDeactivate.mutate(formData);
      } else {
        handleReactivateSecurity.mutate(formData);
      }
    } else {
      handleEnable2fa.mutate(formData);
    }
  };

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  if (user?.isLoading) {
    return (
      <Auth2faSettingsView>
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#00B87C"
              secondaryColor="#00B87C"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      </Auth2faSettingsView>
    );
  }

  return (
    <>
      <Auth2faSettingsView>
        <TitleView>
          <h3>Two-factor authentication</h3>
          <p>
            Protect your account from unauthorized access. Enable Two-factor authentication to add
            an extra layer of security to your account.
          </p>
        </TitleView>

        <OptionsView>
          {auth2faOptions.map((option) => (
            <OptionView key={option?.title}>
              {/* <div className="icon">
              <SendIcon />
            </div> */}

              <div className={`info ${option?.available ? '' : 'fade'}`}>
                <div className="account-info">
                  <h4>{option?.title}</h4>
                  <p className="description">{option?.description}</p>
                </div>

                <div className="proceed-view">
                  <button
                    className="icon-copy"
                    onClick={() => {
                      setActive2fa(option?.value);
                      onSubmit();
                    }}>
                    {option?.available &&
                      (handleReactivateSecurity.isLoading ||
                      handleDeactivate?.isLoading ||
                      (handleEnable2fa.isLoading && active2fa === option?.value) ? (
                        <Oval width={20} height={20} />
                      ) : user?.data?.data?.hasLogin2faEnabled ? (
                        <EnabledIcon />
                      ) : (
                        <DisabledIcon />
                      ))}
                  </button>
                </div>

                {option?.available ? null : <h4 className="coming-soon">Coming soon</h4>}
              </div>
            </OptionView>
          ))}
        </OptionsView>
      </Auth2faSettingsView>
    </>
  );
}

export default Auth2faSettings;

const Auth2faSettingsView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cta-view {
    display: flex;
    align-items: center;

    .reset-pin {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .change-pin {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 70%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const OptionsView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 2rem; */
  flex-wrap: wrap;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }
`;

const OptionView = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.layer4};

  border-radius: 0.5rem;
  margin-bottom: 20px;

  @media ${device.phone} {
    width: 100%;
    height: 130px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    svg {
      width: 4rem;
      height: 4rem;

      @media ${device.phone} {
        width: 40px;
        height: 40px;
      }
    }
  }

  .info {
    padding: 1.5rem;
    width: 100%;
    height: 100%;
  }

  .fade {
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    pointer-events: not-allowed;
    position: relative;

    .coming-soon {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${({ theme }) => theme.colors.inputBackground};
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};
      border-radius: 0 0.5rem 0 0;

      p {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  .account-info {
    width: 70%;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 100%;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
    }
  }

  .proceed-view {
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    right: 1.5rem;

    @media ${device.phone} {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    button {
      font-size: 0.875rem;
    }

    .icon-copy {
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
`;
