import React from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

function TableLoader() {
  return (
    <LoaderView>
      <Oval color="#92c22c" width={50} height={50} strokeWidth={5} />
    </LoaderView>
  );
}

export default TableLoader;

const LoaderView = styled.div`
  width: 100%;
  min-height: 40vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;
