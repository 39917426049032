/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { Oval, RotatingLines } from 'react-loader-spinner';
import ProprTypes from 'prop-types';

import { extractInvoice } from '@/services/receivablesApi';
import { ReactComponent as FileIcon } from '@/assets/icons/file.icon.svg';
import { device } from '@/constants/breakpoints';
import theme from '@/constants/theme';

function DropArea({
  setExtractedFile,
  setShowUploadButton,
  setFile,
  setCustomer,
  setCustomerName,
  setExtractedCustomerName
}) {
  const [uploading, setUploading] = useState(false);

  const handleExtractInvoice = useMutation(extractInvoice, {
    onSuccess: (data) => {
      setExtractedFile(data?.data);
      setCustomer(data?.data?.customer);
      setCustomerName(data?.data?.customer?.name || data?.data?.customer?.companyName);
      setExtractedCustomerName(data?.data?.customer?.name || data?.data?.customer?.companyName);
      setShowUploadButton(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const baseUrl = `https://api.cloudinary.com/v1_1/${
      import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
    }/upload`;

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`File is larger than 10MB`);
        }

        if (err.code === 'file-invalid-type') {
          toast.error(`Error: ${err.message}`);
        }
      });
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      const formData = new FormData();
      formData.append('file', acceptedFile);
      formData.append('upload_preset', `${import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET}`);

      try {
        setUploading(true);
        const response = await fetch(baseUrl, {
          method: 'post',
          body: formData
        });

        const data = await response.json();
        setFile(data?.secure_url);
        const fileData = {
          file: data?.secure_url
        };
        handleExtractInvoice.mutate(fileData);
        setUploading(false);
      } catch (error) {
        setUploading(false);
      }
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      setFile(acceptedFile);
    });

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'image/*': [],
      'image/heic': []
    },
    maxFiles: 1,
    noClick: false,
    noKeyboard: false,
    maxSize: 5242880
  });

  return (
    <DropAreaView>
      <div className="drop-zone">
        <h3>Upload file</h3>
        {uploading ? (
          <div className="loader-uploading">
            <Oval color={theme?.colors?.secondary} width={50} height={50} />
          </div>
        ) : (
          <div className="drop-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <FileIcon />
            <p>Browse your file or Drag and drop here.</p>
          </div>
        )}
      </div>

      {handleExtractInvoice.isLoading && (
        <div className="loader">
          <RotatingLines
            strokeColor="#92C22C"
            strokeWidth="3"
            animationDuration="1.75"
            height={100}
            width={100}
            visible
          />
          <p>Hold still! while we process your document</p>
        </div>
      )}
    </DropAreaView>
  );
}

export default DropArea;

DropArea.propTypes = {
  setExtractedFile: ProprTypes.func.isRequired,
  setShowUploadButton: ProprTypes.func.isRequired,
  setFile: ProprTypes.func.isRequired,
  setCustomerName: ProprTypes.func.isRequired,
  setExtractedCustomerName: ProprTypes.func.isRequired
};

const DropAreaView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader {
    width: 100vw;
    height: 70vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.activeColor};
      width: 280px;
      text-align: center;
      margin-top: 20px;
    }
  }

  .drop-zone {
    width: 50%;
    height: 400px;
    background-color: #f9f9fa;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 20px;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-bottom: 25px;
    }

    .loader-uploading {
      width: 100%;
      height: 170px;
      border: 1px dashed #174078;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
    }

    .drop-container {
      width: 100%;
      height: 170px;
      border: 1px dashed #174078;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      svg {
        width: 40px;
        height: 50px;
      }

      p {
        margin-top: 30px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          max-width: 90%;
        }
      }
    }
  }

  button {
    width: 200px;
    height: 64px;
    background: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    border: none;
    border-radius: 16px;
    font-weight: 600;
  }
`;
