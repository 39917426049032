/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { Oval } from 'react-loader-spinner'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'

import {
  deleteReminder,
  fetchReminders,
  toggleReminder,
} from '@/services/remindersApi'
import { ReactComponent as ReminderIcon } from '@/assets/icons/reminder.icon.svg'
import { ReactComponent as EnabledIcon } from '@/assets/icons/switch-off.icon.svg'
import { ReactComponent as DisabledIcon } from '@/assets/icons/switch-on.icon.svg'
import { ReactComponent as AddIcon } from '@/assets/icons/add.icon.svg'
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.icon.svg'
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg'
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg'
import AddReminder from './AddReminder'
import PromptModal from '@/components/PromptModal'
import { device } from '@/constants/breakpoints'

function Reminders() {
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)
  const [activeReminder, setActiveReminder] = useState(null)
  const [showPromptModal, setShowPromptModal] = useState(false)
  const [selectedReminder, setSelectedReminder] = useState({})

  const reminders = useQuery({
    queryKey: ['reminders'],
    queryFn: fetchReminders,
    onSuccess: () => {},
    onError: () => {},
  })

  const handleToggleReminder = useMutation(
    ({ id, data }) => toggleReminder(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['reminders'] })
        toast.success('Reminder updated successfully')
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message)
      },
    },
  )

  const handleToggle = (id, command) => {
    const data = {
      command,
    }
    handleToggleReminder.mutate({ id, data })
  }

  const handleDeleteReminder = useMutation(deleteReminder, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reminders'] })
      toast.success('Bill deleted successfully')
      setShowPromptModal(false)
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message)
    },
  })

  return (
    <RemindersView>
      {reminders.isFetching ? (
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      ) : (
        <ReminderList>
          <TitleView>
            <h3>Reminders</h3>
            <p>
              Set up reminders to help you stay upto date with your bills and
              invoices.
            </p>
          </TitleView>

          <div className="add-view">
            <button type="button" onClick={() => setShowModal(true)}>
              <AddIcon /> Add Reminder
            </button>
          </div>

          <ReminderItems>
            {reminders?.data?.data?.map((reminder, i) => (
              <RowView key={reminder?.id}>
                <ReminderItem>
                  <div className="left">
                    <div className="item-icon">
                      <ReminderIcon />
                    </div>
                    <div className="text">
                      <p>Reminder {i + 1}:</p>{' '}
                      <p className="bold">
                        {reminder?.days !== 0 && reminder?.days}{' '}
                        {reminder?.days !== 0 && reminder?.days > 1
                          ? 'days'
                          : reminder?.days === 0
                          ? ''
                          : 'day'}{' '}
                        {reminder?.command} due date
                      </p>
                    </div>
                  </div>

                  <div className="right">
                    <button
                      type="button"
                      onClick={() => {
                        handleToggle(
                          reminder?.id,
                          reminder?.enabled ? 'disable' : 'enable',
                        )
                        setActiveReminder(i)
                      }}
                    >
                      {reminder?.enabled ? 'Disable' : 'Enable'}{' '}
                      {handleToggleReminder.isLoading &&
                      activeReminder === i ? (
                        <Oval width={20} height={20} />
                      ) : reminder?.enabled ? (
                        <EnabledIcon />
                      ) : (
                        <DisabledIcon />
                      )}
                    </button>
                  </div>
                </ReminderItem>

                <button
                  className="delete"
                  type="button"
                  onClick={() => {
                    setShowPromptModal(true)
                    setSelectedReminder(reminder)
                  }}
                >
                  <DeleteIcon />
                </button>
              </RowView>
            ))}
          </ReminderItems>
        </ReminderList>
      )}

      <AddReminder showModal={showModal} setShowModal={setShowModal} />

      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete reminder</h3>
          <p>When you delete a reminder, This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteReminder.mutate(selectedReminder?.id)}
          >
            {handleDeleteReminder.isLoading ? (
              <Oval
                color="#fff"
                secondaryColor="#ddd"
                width={30}
                height={30}
                strokeWidth={4}
              />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </RemindersView>
  )
}

export default Reminders

const RemindersView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 70%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const ReminderList = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0 60px;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  .add-view {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    button {
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.colors?.primary};
      border-radius: 8px;
      padding: 14px 20px;
      color: ${({ theme }) => theme.colors?.white};
      font-weight: 500;

      svg {
        margin-right: 10px;
      }
    }
  }
`

const ReminderItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const RowView = styled.div`
  width: calc(100% + 30px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  @media ${device.phone} {
    width: 100%;
  }

  .delete {
    display: none;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 0;
    width: 30px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }

  &:hover {
    .delete {
      display: block;
    }
  }
`

const ReminderItem = styled.div`
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 14px 20px;
  transition: all 0.3s ease-in-out;

  @media ${device.phone} {
    flex-direction: column;
    padding: 14px;
  }

  .left {
    display: flex;
    align-items: center;

    .item-icon {
      width: 30px;
      height: 30px;
      margin-right: 20px;

      @media ${device.phone} {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      display: flex;
      align-items: center;

      p {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.secondary};
      }

      .bold {
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    @media ${device.phone} {
      justify-content: flex-end;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.secondary};

      svg {
        height: 40px;
        margin-left: 10px;
      }
    }
  }
`
