import api from './api';

export const fetchUserInfo = async () => {
  const response = await api.get(`/bill-finance/loans/user`);
  return response.data;
};

export const calculateLoan = async (data) => {
  const response = await api.post(`/bill-finance/calculate`, data);
  return response.data;
};

export const applyForLoan = async (data) => {
  const response = await api.post(`/bill-finance/loans/apply`, data);
  return response.data;
};
