import api from './api';

/**
 * * Create a customer ✅
 * @param {Object} data -Customer data
 * @returns {Promise} - Returns the created customer
 */
export const createCustomer = async (data) => {
  const response = await api.post(`/customers/create`, data);
  return response.data;
};

/**
 * * Fetches all customers ✅
 * @returns {Promise} - Returns all customers
 */
export const fetchCustomers = async ({ queryKey }) => {
  const [, { page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/customers?${queryParams}`);
  return response.data;
};

/**
 * * Fetches a customer ✅
 * @param {Object} queryKey - Query key
 * @returns {Promise} - Returns the vendor
 */
export const fetchCustomer = async ({ queryKey }) => {
  const [_, customerId] = queryKey;
  const response = await api.get(`/customers/${customerId}`);
  return response.data;
};

/**
 * * Deletes a customer
 * @param {String} vendorId - Customer ID
 * @returns {Promise} - Returns the deleted customer
 */
export const deleteCustomer = async (customerId) => {
  const response = await api.delete(`/customers/${customerId}`);
  return response.data;
};

/**
 * * Updates a customer
 * @param {String} id - Customer id
 * @param {Object} data - Customer data
 * @returns {Promise} - Returns the updated Customer
 */
export const updateCustomer = async (id, data) => {
  const response = await api.put(`/customers/${id}`, data);
  return response.data;
};

/**
 * * Fetches a customer's bills
 * @param {Onject} queryKey - Query key
 * @returns {Promise} - Returns the vendor bills
 */
export const fetchCustomerBills = async ({ queryKey }) => {
  const [_, customerId] = queryKey;
  const response = await api.get(`/customer/${customerId}/bills`);
  return response.data;
};
