/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/error.icon.svg'
import { device } from '@/constants/breakpoints'

function InvoiceTestModal({ showModal, setShowModal, errors }) {
  return (
    <AnimatePresence>
      {showModal && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <InvoiceTestModalView
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 1000 }}
            transition={{ duration: 0.3 }}
          >
            <HeaderView>
              <button type="button" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </HeaderView>

            <ContentView>
              <FlexView>
                <div className="col1">
                  <h3>Invalid invoice format</h3>
                  <p>
                    We encountered the following errors while parsing your
                    invoice. Please fix them and try again.
                  </p>
                </div>

                <div className="col2">
                  {errors?.map((error, index) => (
                    <div className="item" key={index}>
                      <ErrorIcon /> <span>{error}</span>
                    </div>
                  ))}
                </div>
              </FlexView>
            </ContentView>
          </InvoiceTestModalView>

          <ButtonView>
            <button type="button" onClick={() => setShowModal(false)}>
              Go back to upload
            </button>
          </ButtonView>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default InvoiceTestModal

const Overlay = styled(motion.div)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  overflow-y: scroll;
`

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors?.white};
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.colors?.line};

  button {
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors?.white};
    height: 63px;
    width: 230px;
    font-size: 1rem;
    font-weight: 600;
  }
`

const InvoiceTestModalView = styled(motion.div)`
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;

  @media ${device.phone} {
    width: 100%;
    padding-bottom: 400px;
  }

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
  }
`

const HeaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media ${device.phone} {
    width: 100%;
  }
`

const ContentView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }
`

const FlexView = styled.div`
  width: 80%;
  display: flex;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .col1 {
    width: 50%;
    padding: 0 40px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
      margin-bottom: 40px;
    }

    h3 {
      font-size: 2rem;
      max-width: 100%;
      font-weight: 400;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors?.secondary};

      span {
        color: ${({ theme }) => theme.colors?.secondary};
        font-weight: 600;
        text-transform: lowercase;
      }
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .col2 {
    width: 50%;

    @media ${device.phone} {
      width: 100%;
      padding-bottom: 200px;
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      background-color: ${({ theme }) => theme.colors?.white};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border-radius: 4px;

      svg {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 19px;
        margin-left: 10px;
        max-width: 90%;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }

    .continue {
      margin-top: 60px;

      button {
        background-color: ${({ theme }) => theme.colors?.primary};
        border-radius: 16px;
        color: ${({ theme }) => theme.colors?.white};
        height: 63px;
        width: 230px;
        font-size: 1rem;
        font-weight: 600;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`
