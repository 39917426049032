import Pagination from '@/components/Pagination';
import { device } from '@/constants/breakpoints';
import { fetchBillingHistory } from '@/services/subscriptionApi';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

const statusClassMap = {
  successful: 'successful',
  failed: 'failed',
  pending: 'pending'
};

const ReceiptsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_LIMIT = 5;

  const billingHistory = useQuery({
    queryKey: ['billingHistory', { page: currentPage, limit: PAGE_LIMIT }],
    queryFn: fetchBillingHistory
  });

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  if (billingHistory.isFetching) {
    return (
      <ReceiptsTableStyles>
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      </ReceiptsTableStyles>
    );
  }

  return (
    <ReceiptsTableStyles>
      <h3>Billing history</h3>
      <TableHeader>
        <div className={`item recipient`}>
          <span>Reference </span>
        </div>
        <div className={`item amount`}>
          <span>Amount </span>
        </div>
        <div className={`item type`}>
          <span>Plan </span>
        </div>
        <div className={`item type`}>
          <span>Payment method </span>
        </div>
        <div className={`item status`}>
          <span>Status </span>
        </div>
        <div className={`item date`}>
          <span>Date </span>
        </div>
      </TableHeader>

      <TableBody>
        {billingHistory?.data?.data?.data?.map((history, i) => (
          <TableRow key={history.id}>
            <div className="item recipient">
              <span>{history?.reference}</span>
            </div>
            <div className="item amount">
              <span>₦{numberWithCommas(history?.amount || '00.00')}</span>
            </div>
            <div className="item type">
              <span>{history?.plan}</span>
            </div>
            <div className="item type">
              <span>{history?.paymentMethod}</span>
            </div>
            <div className={`item status ${statusClassMap[history.status]}`}>
              <span>{history?.status}</span>
            </div>
            <div className="item date">
              <span>{format(new Date(history?.createdAt), 'do MMM, yyyy')}</span>
            </div>
          </TableRow>
        ))}
      </TableBody>

      <div className="pagination">
        <Pagination
          itemsPerPage={PAGE_LIMIT}
          totalItems={billingHistory?.data?.data?.meta?.totalCount}
          currentPage={billingHistory?.data?.data?.meta?.page}
          onPageChange={onPageChange}
        />
      </div>
    </ReceiptsTableStyles>
  );
};

export default ReceiptsTable;

const ReceiptsTableStyles = styled.div`
  width: 100%;
  margin-top: 50px;

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};
  margin-top: 20px;

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &.recipient {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;
    }

    &.date {
      flex: 0.6;
    }
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
      text-transform: capitalize;
    }

    &.recipient {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    &.successful {
      span {
        color: ${(props) => props.theme.colors?.success};
      }
    }

    &.failed {
      span {
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    &.pending {
      span {
        color: ${(props) => props.theme.colors?.warning};
      }
    }

    &.date {
      flex: 0.6;
    }
  }

  .date {
    flex: 0.6;
  }
`;
