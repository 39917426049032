import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ProgressIndicator from './ProgressIndicator';
import { useDispatch } from 'react-redux';
import { setOnboardingProgress } from '@/redux/features/app.slice';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCompany, updateCompany } from '@/services/userApi';
import FetchLoader from '@/components/FetchLoader';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { usePlacesWidget } from 'react-google-autocomplete';
import { device } from '@/constants/breakpoints';
import secureLocalStorage from 'react-secure-storage';

const CompanyLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const companyId = secureLocalStorage.getItem('company');
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [companyAddress, setCompanyAddress] = useState('');
  const { ref } = usePlacesWidget({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    onPlaceSelected: (place) => console.log(place)
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    onSuccess: (data) => {
      setCompanyAddress(data?.data?.address || '');
    },
    onError: (error) => {}
  });

  useEffect(() => {
    if (page === 'company-location') {
      dispatch(setOnboardingProgress(40));
    }
  }, [page, dispatch]);

  const handleUpdateCompany = useMutation(({ id, data }) => updateCompany(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      navigate('/onboarding?page=industry');
      dispatch(setOnboardingProgress(50));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  if (company.isFetching) {
    return <FetchLoader />;
  }

  return (
    <UserInfoStyles>
      <div className="back-view">
        <button
          className="back"
          onClick={() => {
            navigate('/onboarding?page=company-name');
            dispatch(setOnboardingProgress(30));
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>
        <div className="title-view">
          <h1>Where is {company?.data?.data?.name} located?</h1>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>
        <div className="form-view">
          {/* Full name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="companyAddress">Company address</label>
            </div>
            <Input
              ref={ref}
              type="text"
              placeholder="Enter company address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>

          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={() => {
                handleUpdateCompany.mutate({ id: companyId, data: { address: companyAddress } });
              }}>
              {handleUpdateCompany.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className="skip-view">
        <button
          type="button"
          onClick={() => {
            navigate('/onboarding?page=industry');
            dispatch(setOnboardingProgress(50));
          }}>
          Skip
        </button>
      </div>
    </UserInfoStyles>
  );
};

export default CompanyLocation;

const UserInfoStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      margin-bottom: 20px;
    }
  }

  .skip-view {
    width: 100%;
    position: absolute;
    bottom: -100px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors?.black};
      background: ${({ theme }) => theme.colors?.primaryFocus};
      padding: 5px 12px;
      border-radius: 10px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 1.8rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          line-height: 29px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          font-size: 1rem;
        }
      }

      img {
        margin-top: 20px;
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .input-view {
        width: 100%;
        margin-bottom: 26px;

        .label-view {
          width: 100%;
          display: flex;
          margin-bottom: 8px;

          label {
            font-size: 1rem;
          }
        }

        .input-info-view {
          width: 100%;
          display: flex;
          margin-top: 8px;

          span {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
