import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import { useSelector } from 'react-redux';
import { appSelector } from '@/redux/features/app.slice';
import { numberWithCommas } from '@/utils/helpers';
import ReviewDetailsModal from './ReviewDetailsModal';
import { format } from 'date-fns';

function BillDetailsModal({ showModal, setShowModal }) {
  const { loanBill } = useSelector(appSelector);
  const [showReviewDetails, setShowReviewDetails] = useState(false);

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Bill Details</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <p className="info">Kindly confirm all the details are correct</p>

                <DetailsView>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Request from:</span>
                      <p className="details-value">{loanBill?.vendor?.companyName}</p>
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Invoice no.:</span>
                      <p className="details-value">{loanBill?.billOrInvoiceNumber}</p>
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Bill amount:</span>
                      <p className="details-value">
                        ₦{numberWithCommas(loanBill?.amount || '00.00')}
                      </p>
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Payment (Part):</span>
                      <p className="details-value">
                        ₦{numberWithCommas(loanBill?.paidAmount || '00.00')}
                      </p>
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Payment (remaining):</span>
                      <p className="details-value">
                        ₦{numberWithCommas(loanBill?.balance || '00.00')}
                      </p>
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Due date:</span>
                      <p className="details-value">
                        {format(new Date(loanBill?.dueDate), 'do MMM, yyyy')}
                      </p>
                    </div>
                  </div>
                  <div className="details-row">
                    {loanBill?.description && (
                      <div className="details-item">
                        <span className="details-label">Note:</span>
                        <p className="details-value">{parse(loanBill?.description) || 'N/A'}</p>
                      </div>
                    )}
                  </div>
                </DetailsView>

                <ButtonView>
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="confirm"
                    onClick={() => {
                      setShowModal(false);
                      setShowReviewDetails(true);
                    }}>
                    Confirm
                  </button>
                </ButtonView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <ReviewDetailsModal showModal={showReviewDetails} setShowModal={setShowReviewDetails} />
    </>
  );
}

export default BillDetailsModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 90009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .info {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    margin-top: 20px;
  }
`;

const DetailsView = styled.div`
  width: 100%;
  margin-top: 20px;

  .details-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .details-item {
      width: 100%;
      display: flex;
      flex-direction: column;

      .details-label {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .details-value {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 500;
        margin-top: 5px;
      }
    }

    .left {
      .details-label {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        text-align: right;
      }

      .details-value {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 500;
        margin-top: 5px;
        text-align: right;
      }
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  .cancel {
    background-color: transparent;
    color: ${(props) => props.theme.colors?.secondary};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
  }

  .confirm {
    background-color: ${(props) => props.theme.colors?.primary};
    color: ${(props) => props.theme.colors?.white};
    width: 150px;
    height: 50px;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 20px;
  }
`;
