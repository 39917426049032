import Input from '@/components/Input/Input';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SearchDropdown from '@/components/SearchDropdown';
import { businessRegTypes } from '@/utils/data.utils';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setPayLaterData } from '@/redux/features/app.slice';

const schema = yup
  .object({
    cacNumber: yup.string().required('Registration number is required'),
    bvn: yup.string().required('BVN is required'),
    businessName: yup.string().required('Business name is required'),
    businessAddress: yup.string().required('Business address is required')
  })
  .required();

const BusinessInfo = ({ userInfo, setCurrentTab }) => {
  const [businessType, setBusinessType] = useState({});
  const compliance = userInfo?.compliance[0] ?? {};
  const company = userInfo?.companies[0] ?? {};
  const dispatch = useDispatch();
  const { payLaterData } = useSelector(appSelector);

  useEffect(() => {
    const findBusinessType = businessRegTypes.find(
      (item) => item.title === compliance?.businessType
    );
    setBusinessType(findBusinessType);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cacNumber: compliance?.registrationNumber,
      bvn: compliance?.bvn,
      businessName: company?.name,
      businessAddress: compliance?.address ?? ''
    }
  });

  useEffect(() => {
    reset({
      cacNumber: compliance?.registrationNumber,
      bvn: compliance?.bvn,
      businessName: company?.name,
      businessAddress: compliance?.address ?? ''
    });
  }, [userInfo, reset]);

  const onSubmit = (data) => {
    dispatch(
      setPayLaterData({
        ...payLaterData,
        cacNumber: data?.cacNumber,
        bvn: data?.bvn,
        businessName: data?.businessName,
        businessAddress: data?.businessAddress,
        businessType: businessType?.title
      })
    );
    setCurrentTab((prev) => prev + 1);
  };

  return (
    <FormView>
      <form>
        {/* Business name */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="businessName">Business name</label>
          </div>
          <Input
            type="text"
            placeholder="Enter business name"
            {...register('businessName')}
            error={errors?.businessName?.message}
          />
          {errors?.businessName && (
            <span className="error-label">{errors?.businessName?.message}</span>
          )}
        </div>

        {/* BVN */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="bvn">BVN Number</label>
          </div>
          <Input
            type="text"
            placeholder="Enter BVN"
            {...register('bvn')}
            error={errors?.bvn?.message}
          />
          {errors?.bvn && <span className="error-label">{errors?.bvn?.message}</span>}
        </div>

        {/* Business address */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="businessAddress">Business registered address</label>
          </div>
          <Input
            type="text"
            placeholder="Enter business address"
            {...register('businessAddress')}
            error={errors?.businessAddress?.message}
          />
          {errors?.businessAddress && (
            <span className="error-label">{errors?.businessAddress?.message}</span>
          )}
        </div>

        {/* Business reg */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="website">Business registration type</label>
          </div>
          <SearchDropdown
            options={businessRegTypes}
            id="id"
            name="title"
            setValue={setBusinessType}
            value={businessType}
            placeholder="Select business registration type"
          />
        </div>

        {/* RC Number */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="cacNumber">CAC reg number</label>
          </div>
          <Input
            type="text"
            placeholder="Enter CAC reg number"
            {...register('cacNumber')}
            error={errors?.cacNumber?.message}
          />
          {errors?.cacNumber && <span className="error-label">{errors?.cacNumber?.message}</span>}
        </div>

        <div className="continue">
          <button type="button" className="prev" onClick={() => setCurrentTab((prev) => prev - 1)}>
            Previous
          </button>
          <button type="button" onClick={handleSubmit(onSubmit)}>
            Next
          </button>
        </div>
      </form>
    </FormView>
  );
};

export default BusinessInfo;

const FormView = styled.div`
  width: 100%;

  form {
    width: 100%;

    .input-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      input {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 5px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .continue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 70px;

      button {
        width: 180px;
        height: 56px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: 0.3s ease;
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        border-radius: 16px;

        &:hover {
          background-color: ${({ theme }) => theme.colors?.primaryDark};
        }
      }

      .prev {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.primary};
        margin-right: 20px;
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }
    }
  }
`;
