import { device } from '@/constants/breakpoints';
import styled from 'styled-components';

export const ContentView = styled.div`
  width: 100%;
  min-height: 50vh;

  .pagination {
    margin-top: 50px;
  }

  .requests-header {
    .requests-header__actions {
    }
  }

  .requests-table {
    .requests-table__header {
    }

    .requests-table__body {
      .requests-table__body-row {
      }
    }

    .requests-table__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.phone} {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .title-view {
    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  @media ${device.phone} {
    margin-bottom: 20px;
    width: 100%;
  }

  .action-btn {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    border: none;
    background-color: ${(props) => props.theme.colors?.layer2};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      background-color: ${(props) => props.theme.colors?.layer2};
    }

    .more-icon {
      width: 20px;
      height: 20px;
    }
  }

  .menu-actions {
    position: absolute;
    top: 42px;
    right: 0;
    background-color: ${(props) => props.theme.colors?.white};
    border-radius: 6px;
    box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
    width: 180px;
    z-index: 100;
    overflow: hidden;

    button {
      width: 100%;
      padding: 16px 24px;
      border: none;
      background-color: ${(props) => props.theme.colors?.white};
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: ${(props) => props.theme.colors?.layer2};
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  margin-top: 20px;

  @media ${device.tablet} {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media ${device.phone} {
    display: none;
  }

  thead {
    tr {
      background-color: ${({ theme }) => theme?.colors.layer2};
      border-radius: 4px;
    }
  }

  tr {
    color: ${({ theme }) => theme?.colors.secondary};
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;

    position: relative;

    @media ${device.mobile} {
      display: table-row;
    }
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors.background};
    border-radius: 4px;
  }

  th,
  td {
    flex: 1; // Default flex value for equal width distribution
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    min-width: 100px; /* Set a minimum width for cells to avoid shrinking too much */
    padding: 0;
    font-size: 0.875rem;

    &.name,
    &.amount,
    &.value,
    &.tenure,
    &.actions {
      flex-basis: 0; // Allows columns to grow equally based on content
    }

    &.actions {
      flex: 0.1;

      .view {
        margin-right: 10px;
      }
    }

    @media ${device.mobile} {
      display: table-cell;
    }
  }

  th {
    color: ${({ theme }) => theme?.colors.secondary};
    font-weight: 600;
    font-size: 0.875rem;
    text-align: left;
    outline: none;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    padding: 16px 12px;
  }

  td {
    align-items: center;
    margin: 0;
    padding: 16px 12px;
    font-weight: 500;

    &.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      svg {
        margin: 0;
        margin-left: 10px;
      }

      button {
        padding: 0;
        display: block;

        svg {
        }
      }

      .menu-actions {
        position: absolute;
        top: 50px;
        right: 0;
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors.layer2};
          }
        }
      }
    }
  }
`;

export const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }
`;

export const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: calc(100% - 80px);
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;

export const TableMobileView = styled.div`
  display: none;

  @media ${device.phone} {
    display: block;
  }

  .item-view {
    width: 100%;
    background: #f4f4f4;
    border-radius: 13px;
    padding: 20px;
    margin-top: 20px;
    min-height: 120px;
    position: relative;

    .row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 10px;

      .item {
        margin-bottom: 10px;
        p {
          font-size: 0.9rem;
        }
        .value {
          font-size: 1rem;
          font-weight: 600;
          color: ${(props) => props?.theme?.colors?.secondary};
          margin-top: 5px;
        }
      }

      .more {
        padding: 0;
        margin: 0;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .end {
      margin-bottom: 0;
    }

    .menu-actions {
      position: absolute;
      top: 20px;
      right: 30px;
      background-color: ${(props) => props?.theme?.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: ${(props) => props?.theme?.colors?.white};
        font-size: 0.875rem;
        color: ${(props) => props?.theme?.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: ${(props) => props?.theme?.colors?.layer2};
        }
      }
    }
  }
`;
