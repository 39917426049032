import { device } from '@/constants/breakpoints';
import styled from 'styled-components';

export const DashboardStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  .dashboard-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.phone} {
      padding: 20px 16px;
    }

    .title-view {
      .menu {
        display: none;
      }
      @media ${device.phone} {
        display: flex;
        align-items: center;

        .menu {
          display: block;
          margin-right: 10px;
        }
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 1rem;
          display: none;
        }
      }
    }
  }

  .dashboard-wrapper {
    width: 100%;
    background-color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 30px;
    border-radius: 30px;
    padding: 30px;

    @media ${device.phone} {
      border-radius: 0;
      padding: 30px 16px;
    }

    .line-chart-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      @media ${device.phone} {
        flex-direction: column;
      }
    }
  }
`;

export const ActionButtonView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 40px;

  @media ${device.phone} {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ActionButton = styled.button`
  background-color: #eaffbe;
  color: ${({ theme }) => theme.colors?.secondary};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: calc(100% / 4 - 10px);
  height: 60px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 10px;
  }

  @media ${device.phone} {
    width: calc(100% / 2 - 5px);
    margin-bottom: 10px;
    font-size: 0.8rem;
  }
`;
