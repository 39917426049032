import React from 'react'
import { Oval } from 'react-loader-spinner'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function ModalLoader({ message }) {
  return (
    <LoaderView>
      <Oval
        color="#FFF"
        secondaryColor="#ddd"
        width={50}
        height={50}
        strokeWidth={5}
      />
      <p>{message}</p>
    </LoaderView>
  )
}

ModalLoader.propTypes = {
  message: PropTypes.string,
}

export default ModalLoader

const LoaderView = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9010;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: ${(props) => props.theme.colors?.white};
    margin-top: 10px;
  }
`
