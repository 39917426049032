import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  onboardingProgress: 10,
  activeSubmenu: -1,
  showSubMenu: false,
  vendorToBill: null,
  customerToBill: null,
  paymentInfo: null,
  loanBill: null,
  discountInvoice: null,
  loanAmount: 0,
  discountAmount: 0,
  searchedUserEmail: '',
  payLaterData: {},
  invoiceDiscountData: {},
  invoiceId: null,
  showAccountBalance: false,
  disbursedPaymentData: {}
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveSubMenu: (state, { payload }) => {
      state.activeSubmenu = payload;
    },
    setShowSubMenu: (state, { payload }) => {
      state.showSubMenu = payload;
    },
    setOnboardingProgress: (state, { payload }) => {
      state.onboardingProgress = payload;
    },
    setVendorToBill: (state, { payload }) => {
      state.vendorToBill = payload;
    },
    setLoanBill: (state, { payload }) => {
      state.loanBill = payload;
    },
    setDiscountInvoice: (state, { payload }) => {
      state.discountInvoice = payload;
    },
    setLoanAmount: (state, { payload }) => {
      state.loanAmount = payload;
    },
    setDiscountAmount: (state, { payload }) => {
      state.discountAmount = payload;
    },
    setCustomerToBill: (state, { payload }) => {
      state.customerToBill = payload;
    },
    setPaymentInfo: (state, { payload }) => {
      state.paymentInfo = payload;
    },
    setPayLaterData: (state, { payload }) => {
      state.payLaterData = payload;
    },
    setInvoiceDiscountData: (state, { payload }) => {
      state.invoiceDiscountData = payload;
    },
    setInvoiceId: (state, { payload }) => {
      state.invoiceId = payload;
    },
    setSearchedUserEmail: (state, { payload }) => {
      state.searchedUserEmail = payload;
    },
    setShowAccountBalance: (state, { payload }) => {
      state.showAccountBalance = payload;
    },
    setDisbursementData: (state, { payload }) => {
      state.disbursedPaymentData = payload;
    },

    resetAppStateOnLogout: () => {}
  }
});

export const {
  resetAppStateOnLogout,
  setOnboardingProgress,
  setActiveSubMenu,
  setShowSubMenu,
  setVendorToBill,
  setLoanBill,
  setLoanAmount,
  setPaymentInfo,
  setCustomerToBill,
  setSearchedUserEmail,
  setPayLaterData,
  setInvoiceId,
  setDiscountInvoice,
  setDiscountAmount,
  setInvoiceDiscountData,
  setShowAccountBalance,
  setDisbursementData
} = appSlice.actions;
export default appSlice.reducer;
export const appSelector = (state) => state.app;
