import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { numberWithCommas } from '@/utils/helpers';

const hostname = window.location.hostname.split('.')[0];

const url =
  hostname === 'app'
    ? 'https://app.billboxx.com'
    : hostname === 'localhost'
      ? 'http://localhost:3001'
      : 'https://billboxx-staging.vercel.app';

const PaymentSuccessfulModal = ({ showModal, amount }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <section className="modal-content">
              <div className="logo">
                <img
                  src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695118944/Billboxx/Billboxx-web/BillboxxLogo_aedtrv.png"
                  alt="Billboxx Logo"
                />
              </div>

              <div className="content-info">
                <div className="success-info">
                  <img
                    src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695119243/Billboxx/Billboxx-web/success_yyljk6.png"
                    alt="success-img"
                  />
                  <p className="bold-text">Payment successful!</p>
                  <p>
                    Your payment of <strong>{numberWithCommas(amount || '00.00')}</strong> is
                    successful. You can view transaction details in transaction history
                  </p>
                </div>

                <div className="banner">
                  <div className="text">
                    <p>
                      Ready to start paying and getting <br />
                      paid on time, every time?
                    </p>
                    <p>
                      Sign up to Billboxx and take back <br />
                      control of your cash flow!
                    </p>
                  </div>

                  <a href={`${url}/signup`} target="_blank" className="btn">
                    Get started
                  </a>
                  <img
                    className="banner-image"
                    src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695119010/Billboxx/Billboxx-web/payment-image_r672i6.png"
                    alt=""
                  />
                </div>
              </div>
            </section>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default PaymentSuccessfulModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: flex;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    width: 100vw;
    height: 100vh;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
    }

    .modal-content {
      margin: 1.75rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        width: 12%;

        img {
          width: 100%;
        }

        @media ${device.phone} {
          width: 30%;
        }
      }

      @media ${device.phone} {
        margin: 2rem 0;
        gap: 7rem;
      }

      .content-info {
        width: 50%;
        margin-top: 4rem;

        .success-info {
          text-align: center;

          img {
            width: 20%;
          }

          .bold-text {
            font-size: 32px;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
          }

          p {
            margin-top: 1rem;
            color: ${(props) => props.theme.colors?.secondary};
          }

          @media ${device.phone} {
            img {
              width: 30%;
              margin-bottom: 0;
            }

            .bold-text {
              font-size: 26px;
            }

            p {
              font-size: 15px;
            }
          }
        }

        .banner {
          position: relative;
          margin: 2.75rem 0;
          padding: 1.75rem 2rem;
          border-radius: 0.75rem;
          background-color: ${(props) => props.theme.colors?.primaryFocus};

          .text {
            margin-bottom: 2.45rem;
            color: ${(props) => props.theme.colors?.secondary};

            p {
              margin-bottom: 1rem;

              @media ${device.phone} {
                font-size: 14px;
              }
            }
          }

          .btn {
            font-weight: 600;
            padding: 14px 26px;
            border-radius: 12px;
            text-decoration: none;
            color: ${(props) => props.theme.colors?.white};
            background-color: ${(props) => props.theme.colors?.primary};
          }

          .banner-image {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50%;
          }
        }

        @media ${device.phone} {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;
