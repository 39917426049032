import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';

const FilterSort = React.memo(({ onApply }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('none');
  const ref = useRef();
  useOnClickOutside(ref, () => setDropdownOpen(false));
  const filterOptions = [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Current Week',
      value: 'currentWeek'
    },
    {
      label: 'Last Week',
      value: 'lastWeek'
    },
    {
      label: 'Current Month',
      value: 'currentMonth'
    },
    {
      label: 'Last Month',
      value: 'lastMonth'
    },
    {
      label: 'Current Year',
      value: 'currentYear'
    },
    {
      label: 'Last Year',
      value: 'lastYear'
    }
  ];

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const handleApply = () => {
    onApply({ filter });
    toggleDropdown();
  };

  const dropdownVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      transition: {
        when: 'afterChildren'
      }
    },
    visible: {
      opacity: 1,
      height: 'auto',
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1
      }
    }
  };

  return (
    <Container>
      <Button onClick={toggleDropdown}>
        <FilterIcon />
      </Button>
      <AnimatePresence>
        {isDropdownOpen && (
          <Dropdown
            ref={ref}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={dropdownVariants}>
            <OptionBlock>
              <Label>Filter by:</Label>
              {filterOptions.map((option) => (
                <div key={option?.value} className="option">
                  <RadioInput
                    type="radio"
                    value={option?.value}
                    checked={filter === option?.value}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <span>{option?.label}</span>
                </div>
              ))}
            </OptionBlock>
            <button className="apply" onClick={handleApply}>
              Apply Filter
            </button>
          </Dropdown>
        )}
      </AnimatePresence>
    </Container>
  );
});

export default FilterSort;

const Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors?.secondary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  padding: 0;

  svg {
  }

  &:hover {
    background-color: ${(props) => props.theme.colors?.layer};
  }
`;

const Dropdown = styled(motion.div)`
  background: white;
  border: 1px solid ${(props) => props.theme.colors?.layer};
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  width: 250px;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 10;

  .apply {
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: ${(props) => props.theme.colors?.primary};
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;

    &:hover {
      background-color: ${(props) => props.theme.colors?.primary};
    }
  }
`;

const OptionBlock = styled.div`
  margin-bottom: 15px;

  .option {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    input[type='radio'] {
      margin-right: 10px;
      cursor: pointer;
      accent-color: ${(props) => props.theme.colors?.secondary};
      color: ${(props) => props.theme.colors?.secondary};
      width: 15px;
      height: 15px;
    }

    span {
      font-size: 1rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${(props) => props.theme.colors?.layer};
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  font-size: 1rem;
`;

const RadioInput = styled.input`
  margin-right: 10px;
  cursor: pointer;
  accent-color: ${(props) => props.theme.colors?.secondary};
  width: 15px;
  height: 15px;
`;
