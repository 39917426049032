/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import SaveBeneficiaryOtpModal from '@/pages/banking/components/SaveBeneficiaryOtpModal';

export default function SuccessPopup({
  showModal,
  setShowModal,
  selectedAccount,
  mainText,
  subText,
  buttonText,
  buttonAction
}) {
  const [account, setAccount] = useState(null);
  const [verifyModal, setVerifyModal] = useState(false);

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay"
            onClick={() => setShowModal(false)}>
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <span></span>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="content">
                  <div className="lottie">
                    <Player
                      src="https://res.cloudinary.com/dcd1lhe7x/raw/upload/v1700509618/dase8yaelonnjygnnbmh.json"
                      className="player"
                      loop
                      autoplay
                      style={{ height: '200px', width: '200px', margin: '0 auto', padding: '0' }}
                    />
                  </div>
                  <h3>{mainText}</h3>
                  <p>{subText}</p>
                </div>
                {selectedAccount?.data?.toAddAsBeneficiary == true && (
                  <div className="action-btn">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        setShowModal(false);
                        setAccount(null);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setShowModal(false);
                        setVerifyModal(true);
                      }}
                    >
                      Save as Beneficiary
                    </button>
                  </div>
                )}
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <SaveBeneficiaryOtpModal 
        showModal={verifyModal}
        setShowModal={setVerifyModal}
        account={selectedAccount}
        setAccount={setAccount}
      />
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .lottie {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-bottom: 10px;
      margin-top: 0px;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-bottom: 0px;
      margin-top: 0px;
      text-align: center;
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 35px;

    button {
      margin-left: 10px;
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      width: 50%;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      width: 80%;
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;
