import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function TagInput({ input, setInput, tags, setTags }) {
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input?.trim();

    if (key === ',' && trimmedInput?.length && !tags?.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput('');
    }

    if (key === ' ' && trimmedInput?.length && !tags?.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput('');
    }

    if (key === 'Backspace' && !input?.length && tags?.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  return (
    <TagInputStyles>
      <div className="wrapper">
        {tags.map((tag, index) => (
          <div key={tag} className="tag">
            {tag}{' '}
            <button type="button" onClick={() => deleteTag(index)}>
              x
            </button>
          </div>
        ))}
        <input
          value={input}
          placeholder="Enter customer email"
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={onChange}
        />
      </div>
    </TagInputStyles>
  );
}

export default TagInput;

TagInput.propTypes = {
  input: PropTypes.string,
  setInput: PropTypes.func,
  tags: PropTypes.array,
  setTags: PropTypes.func
};

const TagInputStyles = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.inputBackground};
  border-radius: 16px;
  min-height: 64px;
  padding: 0 16px;

  .wrapper {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;

    input {
      min-width: 100%;
      max-width: 100%;
      border: none;
      padding: 10px 0;
      outline: none;
      border: none;
      background-color: ${({ theme }) => theme.colors?.inputBackground};

      &::placeholder {
        color: ${({ theme }) => theme.colors?.placeholder};
        font-size: 1rem;
        font-weight: 300;
      }

      &::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .tag {
      display: flex;
      align-items: center;
      margin: 7px 0;
      margin-right: 10px;
      padding: 0 10px;
      padding-right: 5px;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors?.placeholder};
      white-space: nowrap;
      color: white;

      button {
        display: flex;
        padding: 6px;
        border: none;
        background-color: unset;
        cursor: pointer;
        color: white;
        font-weight: 600;
      }
    }
  }
`;
