/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

import {
  ComponentsView,
  ContactView,
  ContainerHeaderView,
  ContainerView,
  VendorView,
  HeaderView,
  ListItem,
  PageTitleView,
  PaymentView,
  BankAccountView
} from './vendor.styles';
import ProfileDropdown from '@/components/ProfileDropdown';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as ContactIcon } from '@/assets/icons/contact.icon.svg';
import { ReactComponent as PaymentIcon } from '@/assets/icons/payments.icon.svg';
import { ReactComponent as BankIcon } from '@/assets/icons/banks.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { deleteVendor, fetchVendor, fetchVendorBankAccounts } from '@/services/vendorApi';
import FetchLoader from '@/components/FetchLoader';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { setVendorToBill } from '@/redux/features/app.slice';
import BillVendorFrom from './components/BillVendorForm';
import EditVendor from './components/EditVendor';
import PromptModal from '@/components/PromptModal';
import AddVendorBankAccount from './components/AddVendorBankAccount';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';

function Vendor() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [showActions, setShowActions] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const ref = useRef(null);
  const { id } = useParams();
  useOnClickOutside(ref, () => setShowActions(false));

  const vendor = useQuery({
    queryKey: ['vendor', id],
    queryFn: fetchVendor,
    onSuccess: () => {},
    onError: () => {}
  });

  const vendorBankAccounts = useQuery({
    queryKey: ['vendorAccounts', id],
    queryFn: fetchVendorBankAccounts,
    onSuccess: () => {},
    onError: () => {}
  });

  const handleDeleteVendor = useMutation(deleteVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      toast.success('Vendor deleted successfully');
      navigate(-1);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  if (vendor?.isFetching || vendorBankAccounts?.isFetching) {
    return <FetchLoader />;
  }

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <VendorView>
        <HeaderView>
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            {/* <h3>ABC Ventures</h3> */}
          </div>
          <ProfileDropdown />
        </HeaderView>
        <ContainerView>
          <PageTitleView>
            <button type="button" onClick={() => navigate(-1)}>
              <BackIcon />
            </button>
            <h3>Make Payments</h3>
          </PageTitleView>

          <ContainerHeaderView>
            <h3>Vendor</h3>
            <div className="action">
              <button type="button" onClick={() => setShowActions(true)}>
                Action <DownIcon />
              </button>
              <AnimatePresence>
                {showActions ? (
                  <motion.div
                    className="menu-actions"
                    ref={ref}
                    initial={{ y: -20 }}
                    animate={{ y: 0 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(setVendorToBill(vendor?.data?.data));
                        setShowBillModal(true);
                      }}>
                      New Bill
                    </button>
                    <button type="button" onClick={() => setShowAddAccountModal(true)}>
                      Add bank account
                    </button>
                    <button type="button" onClick={() => setShowEditModal(true)}>
                      Edit
                    </button>
                    <button type="button" onClick={() => setShowPromptModal(true)}>
                      Delete
                    </button>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          </ContainerHeaderView>

          <ComponentsView>
            <ContactView>
              <div className="icon-view">
                <div className="icon">
                  <ContactIcon />
                </div>
              </div>
              <h3>Contact</h3>
              <ListItem>
                <span>Vendor’s company name</span>
                <h4>{vendor?.data?.data?.companyName || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Contact’s full name</span>
                <h4>{vendor?.data?.data?.name || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Email address</span>
                <h4>{vendor?.data?.data?.email || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Phone number</span>
                <h4>{vendor?.data?.data?.phoneNumber || 'Nil'}</h4>
              </ListItem>
            </ContactView>
            <PaymentView>
              <div className="icon-view">
                <div className="icon">
                  <PaymentIcon />
                </div>
              </div>
              <h3>Payments</h3>
              <ListItem>
                <span>Open balance</span>
                <h4>₦{numberWithCommas(vendor?.data?.data?.openBalance || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>Total amount</span>
                <h4>₦{numberWithCommas(vendor?.data?.data?.totalPayables || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>Payments made</span>
                <h4>₦{numberWithCommas(vendor?.data?.data?.totalPaid || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>No. of Bills</span>
                <h4>{numberWithCommas2(vendor?.data?.data?.numberOfBills || '00.00')}</h4>
              </ListItem>
            </PaymentView>
            <BankAccountView>
              <div className="icon-view">
                <div className="icon">
                  <BankIcon />
                </div>
              </div>
              <h3>Bank account</h3>
              <ListItem>
                <span>Bank</span>
                <h4>{vendorBankAccounts?.data?.data[0]?.bankName || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Account number</span>
                <h4>{vendorBankAccounts?.data?.data[0]?.accountNumber || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Account name</span>
                <h4>{vendorBankAccounts?.data?.data[0]?.accountName || 'Nil'}</h4>
              </ListItem>
            </BankAccountView>
          </ComponentsView>
        </ContainerView>
      </VendorView>

      <BillVendorFrom showModal={showBillModal} setShowModal={setShowBillModal} />
      <EditVendor
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedVendor={vendor?.data?.data}
      />
      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete vendor</h3>
          <p>When you delete a vendor, you lose all related data. This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteVendor.mutate(vendor?.data?.data?.id)}>
            {handleDeleteVendor.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
      <AddVendorBankAccount
        showModal={showAddAccountModal}
        setShowModal={setShowAddAccountModal}
        vendorId={vendor?.data?.data?.id}
      />
    </>
  );
}

export default withAuthentication(Vendor);
