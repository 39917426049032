import axios from 'axios';
import html2canvas from 'html2canvas';

export function hasNumber(myString) {
  return /\d/.test(myString);
}

export function numberWithCommas2(n) {
  const val = Math.round(Number(n) * 100) / 100;

  const parts = val.toString().split('.');

  const num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');

  return num;
}

export function numberWithCommas(n) {
  const num = Number(parseFloat(n).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2
  });

  return num;
}

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

export function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...';
}

export function truncateYear(str, n) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n); // the original check
  return subString.slice(0, subString.lastIndexOf(''));
}

export function getFirstLetters(str) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export async function convert2DataUrl(blobOrFile) {
  let reader = new FileReader();
  reader.readAsDataURL(blobOrFile);
  await new Promise(
    (resolve) =>
      (reader.onload = function () {
        resolve();
      })
  );
  return reader.result;
}

/**
 * * Convert  a CSV file to Array
 * @param {*} str - CSV file
 * @param {*} delimiter - delimiter
 * @returns {Array} - Array of objects
 */
export function csvToArray(str, delimiter = ',') {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str?.slice(0, str.indexOf('\n'))?.split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str?.indexOf('\n') + 1).split('\n');

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows?.map(function (row) {
    const values = row?.split(delimiter);
    const el = headers?.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}

export function urlToFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function getMimeTypeFromUrl(url) {
  const extension = url?.substring(url?.lastIndexOf('.') + 1)?.toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'svg':
      return 'image/svg+xml';
    case 'pdf':
      return 'application/pdf';
    case 'mp4':
      return 'video/mp4';
    case 'webm':
      return 'video/webm';
    case 'mp3':
      return 'audio/mp3';
    case 'wav':
      return 'audio/wav';
    default:
      return null;
  }
}

export const exportAsImage = async (element, imageFileName) => {
  const html = document.getElementsByTagName('html')[0];
  const body = document.getElementsByTagName('body')[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;
  const newWidth = element.scrollWidth - element.clientWidth;
  if (newWidth > element.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  }
  html.style.width = htmlWidth + 'px';
  body.style.width = bodyWidth + 'px';
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL('image/png', 1.0);
  downloadImage(image, imageFileName);
  html.style.width = null;
  body.style.width = null;
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};
