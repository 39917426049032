import api from './api';

export const fetchCashFlowChart = async ({ queryKey }) => {
  const [_key, data] = queryKey;
  const response = await api.post(`/dashboard/charts/cash-flow`, data);
  return response.data;
};

export const fetchAccountChart = async ({ queryKey }) => {
  const [_key, data] = queryKey;
  const response = await api.post(`/dashboard/charts/accounts`, data);
  return response.data;
};

export const fetchTotalsChart = async () => {
  const response = await api.get(`/dashboard/totals`);
  return response.data;
};

export const fetchAgingChart = async () => {
  const response = await api.get(`/dashboard/charts/aging`);
  return response.data;
};
