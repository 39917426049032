import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { Oval } from 'react-loader-spinner';
import OtpInput from 'react-otp-input';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { loginUser, verify2fa } from '@/services/authApi';
import { fetchUser } from '@/services/userApi';

function Auth2FAModal({ showModal, setShowModal, loginData }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [pin, setPin] = useState('');
  const [timeLeft, setTimeLeft] = useState(60); // Add state for countdown timer

  const handleVerifyPin = useMutation(verify2fa, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      secureLocalStorage.setItem('ut', data?.data?.accessToken);
      secureLocalStorage.setItem('rt', data?.data?.refreshToken);
      secureLocalStorage.setItem('auth', true);

      const user = await queryClient.fetchQuery({
        queryKey: ['user'],
        queryFn: fetchUser
      });

      if (user) {
        const company = user?.data?.company?.id;
        if (company) {
          secureLocalStorage.setItem('company', company);
          navigate('/dashboard', { replace: true });
        } else {
          secureLocalStorage.setItem('auth', true);
          navigate('/onboarding?page=company-name', { replace: true });
        }
      }

      toast.success('Login successful');
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const handleLoginUser = useMutation(loginUser, {
    onSuccess: async (data) => {
      toast.success('Verification code sent to your email');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleSubmitPin = (data) => {
    const pinData = {
      code: pin
    };
    handleVerifyPin.mutate(pinData);
  };

  useEffect(() => {
    if (!showModal) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [showModal]);

  const handleResendOtp = () => {
    handleLoginUser.mutate(loginData);
    setTimeLeft(60); // Reset timer on resend
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <h3>Login Verification</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        setPin('');
                        setTimeLeft(60); // Reset timer on close
                      }}>
                      <CloseIcon />
                    </button>
                  </div>

                  <div className="content">
                    <TitleView>
                      <p>Enter the six digit verification code sent to your mail</p>
                    </TitleView>

                    <FormView>
                      <div className="input-view">
                        <OtpInput
                          value={pin}
                          onChange={setPin}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          containerStyle="otp-input"
                          inputType="number"
                          skipDefaultStyles
                          shouldAutoFocus
                        />
                      </div>
                      <div className="resend-view">
                        {timeLeft > 0 ? (
                          <span>*You can resend OTP in {timeLeft}s</span>
                        ) : (
                          <button type="button" className="resend" onClick={handleResendOtp}>
                            {handleResendOtp.isLoading ? (
                              <Oval color="#92c22c" width={20} height={20} />
                            ) : (
                              'Resend OTP'
                            )}
                          </button>
                        )}
                      </div>
                      <div className="action-btn">
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={() => {
                            setShowModal(false);
                            setPin('');
                            setTimeLeft(60); // Reset timer on cancel
                          }}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={pin.length < 6}
                          onClick={handleSubmitPin}>
                          {handleVerifyPin.isLoading ? (
                            <Oval color="#fff" width={30} height={30} />
                          ) : (
                            'Verify Login'
                          )}
                        </button>
                      </div>
                    </FormView>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default Auth2FAModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 16px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 300px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const TitleView = styled.div`
  width: 100%;

  h3 {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 400;
    margin-bottom: 20px;
  }
`;

const FormView = styled.form`
  width: 100%;

  .input-view {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    input {
      width: 100%;
    }

    label {
      font-size: 0.875rem;
      font-weight: 400;

      span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 5px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .otp-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      input {
        width: calc(100% / 6 - 10px);
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: none;
        background-color: ${({ theme }) => theme.colors?.inputBackground};
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
        text-align: center;
        -webkit-text-security: disc;

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors?.primary};
          border: none;
        }
      }
    }
  }

  .resend-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.secondary};
      font-weight: 400;
    }

    .resend {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.primary};
      font-weight: 600;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 100px;

    button {
      margin-left: 10px;

      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
