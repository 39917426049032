import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@/components/Button/Button';
import {
  ButtonView,
  FormView,
  InputView,
  LoginLeftView,
  LoginRightView,
  LoginView,
  LogoView,
  PasswordInputView,
  RegisterView,
  TitleView
} from './signup.styles';
import Input from '@/components/Input/Input';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import { ReactComponent as EyeeIcon } from '@/assets/icons/eye.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off.icon.svg';
import { ReactComponent as AuthFrameHand } from '@/assets/images/handFrame.image.svg';
import { ReactComponent as AuthFrameInvoice } from '@/assets/images/invoiceFrame.image.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registerUser } from '@/services/authApi';
import { Oval } from 'react-loader-spinner';
import theme from '@/constants/theme';
import toast from 'react-hot-toast';
import useAuthentication from '@/hooks/useAuthentication';
import SearchDropdown from '@/components/SearchDropdown';
import { referralPoints } from '@/utils/data.utils';
import PhoneNumberInput from '@/components/PhoneInput';

const schema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should not be less than 8 characters long')
      .required('Password is required'),
    first_name: yup.string().required('Fullname is required'),
    last_name: yup.string().required('Fullname is required'),
    business_name: yup.string().required('Fullname is required'),
    phone_number: yup.string('Phone number is required').required('Phone number is required')
  })
  .required();

const Signup = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { authenticated } = useAuthentication();
  const [signupReferringPlatform, setSignupReferringPlatform] = useState(null);
  const referral = searchParams.get('ref');

  const email = searchParams.get('email');

  useEffect(() => {
    if (referral) {
      setSignupReferringPlatform(referralPoints.find((point) => point.value === referral));
    }
  }, [referral]);

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email || '',
      password: '',
      first_name: '',
      last_name: '',
      business_name: ''
    }
  });

  useEffect(() => {
    reset({
      email: email || '',
      password: '',
      first_name: '',
      last_name: '',
      business_name: ''
    });
  }, [email, reset]);

  const handleRegisterUser = useMutation(registerUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      toast.success('Registration successful, please verify your email');
      navigate(`/verify-email?email=${data?.data?.email}`, { replace: true });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleRegister = async (data) => {
    const formData = {
      ...data,
      signupReferringPlatform: signupReferringPlatform?.value,
      phone_number: data.phone_number
    };

    if (formData.phone_number.startsWith('+0')) {
      toast.error('Please enter a valid phone number format');
      return;
    }

    try {
      const isValid = await trigger();
      if (isValid) {
        handleRegisterUser.mutate(formData);
      }
    } catch (error) {}
  };

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <LoginView data-testid="login-view">
      <LoginRightView>
        <LogoView>
          <Logo className="logo-icon" data-testid="logo-icon" />
        </LogoView>

        <div className="title">
          <h1>Pay & Get Paid on time, every time</h1>
        </div>

        <div className="auth-frame">
          <div className="auth-frame-hand">
            <AuthFrameHand />
          </div>

          <div className="auth-frame-invoice">
            <AuthFrameInvoice />
          </div>
        </div>
      </LoginRightView>

      <LoginLeftView>
        <TitleView>
          <h3>Create Account</h3>
        </TitleView>
        <FormView onSubmit={handleSubmit(handleRegister)}>
          {/* Fist Name */}
          <InputView>
            <label htmlFor="first_name">First name</label>
            <Input
              type="text"
              id="first_name"
              placeholder="Enter first name"
              {...register('first_name')}
              error={!!errors?.first_name?.message}
            />

            {errors?.first_name && (
              <span className="error-label">{errors?.first_name?.message}</span>
            )}
          </InputView>

          {/* Last Name */}
          <InputView>
            <label htmlFor="last_name">Last name</label>
            <Input
              type="text"
              id="last_name"
              placeholder="Enter last name"
              {...register('last_name')}
              error={!!errors?.last_name?.message}
            />

            {errors?.last_name && <span className="error-label">{errors?.last_name?.message}</span>}
          </InputView>

          {/* Business Name */}
          <InputView>
            <label htmlFor="business_name">Business name</label>
            <Input
              type="text"
              id="business_name"
              placeholder="Enter business name"
              {...register('business_name')}
              error={!!errors?.business_name?.message}
            />

            {errors?.business_name && (
              <span className="error-label">{errors?.business_name?.message}</span>
            )}
          </InputView>

          <InputView>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              placeholder="Enter email"
              {...register('email')}
              error={!!errors?.email?.message}
            />

            {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
          </InputView>

          <InputView>
            <div className="label-view">
              <label htmlFor="phone_number">Phone number</label>
            </div>
            <Controller
              name="phone_number"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <PhoneNumberInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  country="NG"
                  autoFormat
                  enableSearch
                  disableSearchIcon
                  enableAreaCodes
                  error={errors?.phone_number?.message}
                  className={`currency-input ${
                    errors?.phone_number && dirtyFields?.phone_number
                      ? ' has-error'
                      : dirtyFields?.phone_number
                        ? 'valid'
                        : ''
                  }`}
                />
              )}
            />
            {errors?.phone_number && (
              <span className="error-label">{errors?.phone_number?.message}</span>
            )}
          </InputView>

          <PasswordInputView>
            <label htmlFor="password">Password</label>
            <div className="input">
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter password"
                {...register('password')}
                error={!!errors?.password?.message}
              />
              <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeOffIcon /> : <EyeeIcon />}
              </button>
            </div>
            {errors?.password && <span className="error-label">{errors?.password?.message}</span>}
            <div className="password-info">
              <span>
                *Password must be at least 8 characters long and contain at least one uppercase
                letter, one lowercase letter.
              </span>
            </div>
          </PasswordInputView>

          <InputView>
            <label htmlFor="email">How did you hear about us?</label>
            <SearchDropdown
              options={referralPoints}
              id="id"
              name="name"
              setValue={setSignupReferringPlatform}
              value={signupReferringPlatform}
              placeholder="Select an option"
            />
          </InputView>

          <ButtonView>
            <div className="reset-view">
              <span>By Signing up, you agree to Billboxx’s </span>
              <a
                href="https://billboxx.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer">
                Terms of Service and Privacy Policy
              </a>
            </div>
            <Button width="100%" height="64px" type="submit">
              {handleRegisterUser.isLoading ? (
                <Oval
                  color={theme?.colors?.white}
                  secondaryColor={theme.colors?.layer2}
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Create Account'
              )}
            </Button>
          </ButtonView>
        </FormView>

        <RegisterView>
          <span>Already have a Billboxx account? </span>
          <Link to="/login" aria-label="Login">
            Login
          </Link>
        </RegisterView>
      </LoginLeftView>
    </LoginView>
  );
};

export default Signup;
