/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useState, useMemo, useEffect } from 'react';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.icon.svg';
import { device } from '@/constants/breakpoints';
import { numberWithCommas } from '@/utils/helpers';
import { updateManualInvoice } from '@/services/receivablesApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

const taxOptions = [{ value: 'percentage', label: '%' }];

const discountOptions = [
  { value: 'percentage', label: '%' },
  { value: 'fixed', label: 'NGN' }
];

function EditInvoiceItems({
  showModal,
  setShowModal,
  setInvoiceItems,
  invoiceForm,
  setShowPreview,
  setPreviewData,
  invoiceItems,
  invoiceId,
  user
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [rows, setRows] = useState([{ name: '', quantity: 1, rate: '', amount: '' }]);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxType, setTaxType] = useState(taxOptions[0]);
  const [discountType, setDiscountType] = useState(discountOptions[0]);
  const [showTaxOptions, setShowTaxOptions] = useState(false);
  const [showDiscountOptions, setShowDiscountOptions] = useState(false);
  const [saveStatus, setSaveStatus] = useState('draft');

  useEffect(() => {
    setRows(invoiceItems || [{ name: '', quantity: 1, rate: '', amount: '' }]);
    setTax(invoiceForm.tax);
    setDiscount(invoiceForm.discount);
    setTaxType(taxOptions.find((option) => option.value === invoiceForm.taxType) || taxOptions[0]);
    setDiscountType(
      discountOptions.find((option) => option.value === invoiceForm.discountType) ||
        discountOptions[0]
    );
    // setSaveStatus(invoiceForm.status);
  }, [invoiceItems, invoiceForm]);

  const handleInputChange = (index, e) => {
    const { name, value, type } = e.target;
    const updatedValue = type === 'number' ? value.replace(/[^0-9.]/g, '') : value;
    const updatedRows = [...rows];
    updatedRows[index][name] = updatedValue;

    // Calculate the amount for the row
    if (name === 'quantity' || name === 'rate') {
      const quantity = parseFloat(updatedRows[index].quantity);
      const rate = parseFloat(updatedRows[index].rate);
      const amount = isNaN(quantity) || isNaN(rate) ? '' : (quantity * rate).toFixed(2);
      updatedRows[index].amount = amount;
    }

    setRows(updatedRows);
  };

  const handleVatInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tax') {
      setTax(value);
    } else {
      setDiscount(value);
    }
  };

  const handleAddRow = () => {
    setRows([...rows, { name: '', quantity: 1, rate: '', amount: '' }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const calculateSubtotal = useMemo(() => {
    return rows.reduce((subtotal, row) => {
      const amount = parseFloat(row.amount);
      return isNaN(amount) ? subtotal : subtotal + amount;
    }, 0);
  }, [rows]);

  const calculateVAT = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const taxValue = parseFloat(tax) || 0;

    if (taxType.value === 'percentage') {
      return ((subtotal * taxValue) / 100).toFixed(2);
    } else {
      return taxValue.toFixed(2);
    }
  }, [calculateSubtotal, tax, taxType, invoiceForm]);

  const calculateDiscount = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const discountValue = parseFloat(discount) || 0;

    if (discountType.value === 'percentage') {
      return ((subtotal * discountValue) / 100).toFixed(2);
    } else {
      return discountValue.toFixed(2);
    }
  }, [calculateSubtotal, discount, discountType, invoiceForm]);

  const calculateTotal = useMemo(() => {
    return (calculateSubtotal + parseFloat(calculateVAT) - parseFloat(calculateDiscount)).toFixed(
      2
    );
  }, [calculateSubtotal, calculateVAT, calculateDiscount]);

  const handleUpdateInvoice = useMutation(({ id, body }) => updateManualInvoice(id, body), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoice'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (saveStatus === 'sent') {
        if (user?.hasBankAccount) {
          if (invoiceForm?.requiresApproval) {
            navigate(`/get-paid/invoices?tab=${saveStatus}`);
          } else {
            navigate(`/get-paid/invoices/request-payment?id=${data?.data?.id}`);
          }
          toast.success('Invoice updated successfully');
        } else {
          navigate('/settings?tab=bank-account');
          toast.success(
            'Invoice updated successfully, add bank account to start receiving payments'
          );
        }
      } else {
        navigate(`/get-paid/invoices?tab=${data?.data?.status}`);
        toast.success('Invoice updated successfully.');
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleSubmit = (saveType) => {
    setSaveStatus(saveType);
    const newRows = rows.map((row) => {
      return {
        name: row.name,
        amount: parseFloat(row.amount || 0).toFixed(2),
        quantity: parseFloat(row.quantity || 0),
        rate: parseFloat(row.rate || 0)
      };
    });
    const formData = {
      ...invoiceForm,
      status: saveStatus,
      tax: parseFloat(tax || 0),
      discount: parseFloat(discount || 0),
      taxType: taxType.value,
      discountType: discountType.value,
      items: newRows
    };
    setInvoiceItems(newRows);
    formData.items = formData.items.filter((item) => item.name && item.amount);
    handleUpdateInvoice.mutate({ id: invoiceId, body: formData });
  };

  const handlePreview = () => {
    const newRows = rows.map((row) => {
      return {
        name: row.name,
        amount: parseFloat(row.amount || 0).toFixed(2),
        quantity: parseFloat(row.quantity || 0),
        rate: parseFloat(row.rate || 0)
      };
    });
    const formData = {
      ...invoiceForm,
      status: saveStatus,
      tax: parseFloat(tax || 0),
      discount: parseFloat(discount || 0),
      taxType: taxType.value,
      discountType: discountType.value,
      items: newRows
    };
    formData.items = formData.items.filter((item) => item.name && item.amount);
    setPreviewData({
      ...formData,
      subtotal: calculateSubtotal,
      discount: calculateDiscount,
      tax: calculateVAT,
      total: calculateTotal,
      discountType: discountType.value,
      taxType: taxType.value
    });
    setShowPreview(true);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 500 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.2 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>
            <div className="form-view">
              <div className="title-view">
                <h3>Add invoice items</h3>
              </div>
              <div className="preview-view">
                <button type="button" onClick={handlePreview}>
                  Preview Invoice
                </button>
              </div>
              <form>
                {rows.map((row, index) => (
                  <div key={index} className="input-row">
                    <div className="input-left">
                      <div className="input-view">
                        <label htmlFor="item">Item</label>
                        <input
                          type="text"
                          placeholder="Item"
                          name="name"
                          value={row.name}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </div>
                    </div>
                    <div className="input-right">
                      <div className="input-view quantity-view">
                        <label htmlFor="quantity">Quantity</label>
                        <input
                          type="number"
                          placeholder="Quantity"
                          name="quantity"
                          value={row.quantity}
                          onKeyDown={(e) => {
                            const allowedKeys = [8, 46]; // Allow backspace and delete keys
                            if (
                              !allowedKeys.includes(e.keyCode) &&
                              (e.keyCode < 48 || e.keyCode > 57)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </div>
                      <div className="input-view">
                        <label htmlFor="rate">Rate(₦)</label>
                        <input
                          type="number"
                          placeholder="Rate"
                          name="rate"
                          value={row.rate}
                          onKeyDown={(e) => {
                            const allowedKeys = [8, 46]; // Allow backspace and delete keys
                            if (
                              !allowedKeys.includes(e.keyCode) &&
                              (e.keyCode < 48 || e.keyCode > 57)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => handleInputChange(index, e)}
                        />
                      </div>
                      <div className="input-view">
                        <label htmlFor="amount">Amount(₦)</label>
                        <div className="amount">{numberWithCommas(row.amount || '00.00')}</div>
                        {/* <input
                          type="number"
                          placeholder="Amount"
                          name="amount"
                          value={row.amount}
                          readOnly
                        /> */}
                      </div>
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        className="remove"
                        onClick={() => handleRemoveRow(index)}>
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                ))}
                <button type="button" className="add" onClick={handleAddRow}>
                  <PlusIcon /> Add item
                </button>

                <div className="summary-view">
                  <div className="item">
                    <h4 className="name">Subtotal</h4>
                    <h4 className="value">₦{numberWithCommas(calculateSubtotal || '00.00')}</h4>
                  </div>

                  {/* Discount */}
                  <div className="item discount-item">
                    <div className="discount-view">
                      <h4 className="name">Discount</h4>
                      <div className="discount">
                        <input
                          type="number"
                          name="discount"
                          defaultValue={discount}
                          onChange={(e) => handleVatInputChange(e)}
                        />
                        <div
                          className="select-view"
                          onClick={() => {
                            setShowDiscountOptions(!showDiscountOptions);
                            setShowTaxOptions(false);
                          }}>
                          <span>{discountType?.label}</span> <ArrowDownIcon />
                          {showDiscountOptions && (
                            <div className="options">
                              {discountOptions.map((option) => (
                                <button
                                  key={option.value}
                                  className="option"
                                  type="button"
                                  onClick={() => {
                                    setDiscountType(option);
                                    setShowDiscountOptions(false);
                                  }}>
                                  {option?.label}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <h4 className="value">₦{numberWithCommas(calculateDiscount || '00.00')}</h4>
                  </div>

                  {/* VAT */}
                  <div className="item discount-item">
                    <div className="discount-view">
                      <h4 className="name">VAT</h4>
                      <div className="discount">
                        <input
                          type="number"
                          name="tax"
                          defaultValue={tax}
                          onChange={(e) => handleVatInputChange(e)}
                        />
                        <div
                          className="select-view"
                          onClick={() => {
                            setShowDiscountOptions(false);
                            setShowTaxOptions(!showTaxOptions);
                          }}>
                          <span>{taxType?.label}</span>
                        </div>
                      </div>
                    </div>
                    <h4 className="value">₦{numberWithCommas(calculateVAT || '00.00')}</h4>
                  </div>
                  <div className="item total">
                    <h4 className="name">Total</h4>
                    <h4 className="value">₦{numberWithCommas(calculateTotal || '00.00')}</h4>
                  </div>
                </div>
                <div className="cta-view">
                  <button
                    type="button"
                    className="cta-view__save"
                    onClick={() => {
                      setSaveStatus('sent');
                      handleSubmit('sent');
                    }}>
                    {handleUpdateInvoice.isLoading && saveStatus === 'sent' ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Continue'
                    )}
                  </button>
                  <button
                    type="button"
                    className="cta-view__draft"
                    onClick={() => {
                      setSaveStatus('draft');
                      handleSubmit('draft');
                    }}>
                    {handleUpdateInvoice.isLoading && saveStatus === 'draft' ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Save as draft'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default EditInvoiceItems;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .form-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      flex-direction: column;

      .title-view {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        h3 {
          font-size: 1.5rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .preview-view {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 30px;

        button {
          background-color: transparent;
          border: none;
          outline: none;
          color: ${({ theme }) => theme.colors?.primary};
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          text-transform: uppercase;
        }
      }

      form {
        width: 100%;
        max-width: 700px;

        @media ${device.phone} {
          max-width: 100%;
        }

        .input-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          @media ${device.phone} {
            width: calc(100% - 20px);
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
            margin-bottom: 20px;
          }

          .input-right {
            width: 58%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media ${device.phone} {
              width: 100%;
            }

            .input-view {
              width: 38%;
            }

            .quantity-view {
              width: 20%;
            }
          }

          .input-left {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media ${device.phone} {
              width: 100%;
            }
          }

          .input-view {
            width: 100%;
            margin-bottom: 30px;

            @media ${device.phone} {
              width: 100%;
              margin-bottom: 20px;
            }

            label {
              font-size: 0.875rem;
              font-weight: 400;

              span {
                font-size: 0.75rem;
                color: ${({ theme }) => theme.colors?.info};
              }
            }

            .error-label {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.error};
              margin-top: 5px;
            }

            .input-info-view {
              width: 100%;
              display: flex;
              margin-top: 8px;

              span {
                font-size: 0.875rem;
                color: ${({ theme }) => theme.colors?.info};
              }
            }

            .amount {
              color: ${({ theme }) => theme.colors?.activeTitle};
              background-color: ${({ theme }) => theme.colors?.inputBackground};
              border: 1px solid ${({ theme }) => theme.colors?.inputBackground};
              height: 50px;
              border-radius: 8px;
              padding: 0 16px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 5px;
              overflow-x: scroll;
            }

            input {
              width: 100%;
              height: 50px;
              border-radius: 8px;
              border: 1px solid ${({ theme }) => theme.colors?.border};
              padding: 0 16px;
              outline: none;
              font-size: 1rem;
              font-weight: 300;
              transition: all 0.2s ease-in-out;
              margin-top: 5px;
              color: ${({ theme }) => theme.colors?.activeTitle};
              background-color: ${({ theme }) => theme.colors?.inputBackground};
              border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

              &::placeholder {
                color: ${({ theme }) => theme.colors?.placeholder};
                font-size: 0.875rem;
                font-weight: 300;
              }
            }
          }

          .remove {
            position: absolute;
            top: 50%;
            right: -20px;
            transform: translateY(-50%);
            background-color: transparent;
          }
        }

        .summary-view {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          margin-top: 50px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding: 0 20px;
            width: 400px;

            @media ${device.phone} {
              width: 100%;
            }

            .name {
              width: 100px;
              color: ${({ theme }) => theme.colors?.activeTitle};
              font-weight: 500;
              font-size: 0.875rem;
            }

            .value {
              width: 100px;
              text-align: right;
              color: ${({ theme }) => theme.colors?.activeTitle};
              font-weight: 500;
              font-size: 0.875rem;
            }
          }

          .discount-item {
            .discount-view {
              width: 60%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .discount {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                input {
                  width: 60%;
                  border: none;
                  outline: none;
                  background-color: #f0f0f0;
                  border-radius: 6px 0 0 6px;
                  height: 35px;
                  padding: 0 6px;
                  font-size: 0.75rem;
                }

                .select-view {
                  width: 40%;
                  height: 35px;
                  background-color: #dfdfdf;
                  border-radius: 0 6px 0px 0;
                  position: relative;
                  padding: 0 6px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;

                  span {
                    font-weight: 600;
                    font-size: 0.75rem;
                  }

                  svg {
                  }

                  .options {
                    position: absolute;
                    top: 35px;
                    right: 0;
                    width: 100%;
                    background-color: #dfdfdf;
                    display: flex;
                    flex-direction: column;
                    border-radius: 0 0 6px 6px;
                    overflow: hidden;
                    z-index: 100;

                    .option {
                      padding: 5px 0;
                      font-size: 0.75rem;

                      &:hover {
                        background-color: #f0f0f0;
                      }
                    }
                  }
                }
              }
            }
          }

          .total {
            background-color: ${({ theme }) => theme.colors?.inputBackground};
            padding: 10px 20px;

            .name {
              font-weight: 600;
            }

            .value {
              font-weight: 600;
            }
          }
        }

        .cta-view {
          width: 100%;
          display: flex;
          margin-top: 60px;
          margin-bottom: 50px;
          justify-content: center;

          @media ${device.phone} {
            width: 100%;
            flex-direction: column;
          }

          button {
            width: 40%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 16px;

            @media ${device.phone} {
              width: 100%;
            }
          }

          .cta-view__save {
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            margin-bottom: 20px;
            margin-right: 20px;
          }

          .cta-view__draft {
            background-color: ${({ theme }) => theme.colors?.white};
            color: ${({ theme }) => theme.colors?.primary};
            border: 1px solid ${({ theme }) => theme.colors?.primary};
          }
        }

        .add {
          border-radius: 8px;
          border: none;
          outline: none;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;
