/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { fetchCompany, fetchUser, updateCompany } from '@/services/userApi';
import { device } from '@/constants/breakpoints';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.icon.svg';
import { useDropzone } from 'react-dropzone';
import secureLocalStorage from 'react-secure-storage';

const schema = yup
  .object({
    name: yup.string().required('Company name is required'),
    address: yup.string().nullable()
  })
  .required();

function CompanyProfile() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const companyId = secureLocalStorage.getItem('company');
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const baseUrl = `https://api.cloudinary.com/v1_1/${
      import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
    }/upload`;

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`File is larger than 10MB`);
        }

        if (err.code === 'file-invalid-type') {
          toast.error(`Error: ${err.message}`);
        }
      });
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      const formData = new FormData();
      formData.append('file', acceptedFile);
      formData.append('upload_preset', `${import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET}`);

      try {
        setLoading(true);
        const response = await fetch(baseUrl, {
          method: 'post',
          body: formData
        });

        const data = await response.json();
        setLogoUrl(data?.secure_url);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    maxFiles: 1,
    noClick: false,
    noKeyboard: false,
    maxSize: 10485760
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    onSuccess: (data) => {
      setLogoUrl(data?.data?.logoUrl);
    },
    onError: () => {}
  });

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: company?.data?.data?.name,
      address: company?.data?.data?.address
    }
  });

  useEffect(() => {
    reset({
      name: company?.data?.data?.name,
      address: company?.data?.data?.address
    });
  }, [company?.data?.data, reset]);

  const handleUpdateCompany = useMutation(({ id, data }) => updateCompany(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      queryClient.invalidateQueries({ queryKey: ['user'] });
      toast.success('Company profile updated successfully!');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      name: data?.name,
      address: data?.address,
      logoUrl
    };

    handleUpdateCompany.mutate({
      id: companyId,
      data: formData
    });
  };

  return (
    <CompanyProfileView>
      {company?.isFetching || user?.isFetching ? (
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      ) : (
        <form>
          <div className="logo-view">
            <div className="label-view">
              <label htmlFor="name">Logo</label>
            </div>
            {logoUrl ? (
              <div className="logo-image" {...getRootProps()}>
                <input type="file" accept="image/*" {...getInputProps()} />
                <img src={logoUrl} alt="Company logo" />
                <button type="button">
                  {loading ? (
                    <Oval
                      color="#174078"
                      secondaryColor="#D9DBE9"
                      ariaLabel="loading"
                      height={20}
                      width={20}
                    />
                  ) : (
                    <EditIcon />
                  )}
                </button>
              </div>
            ) : (
              <div className="logo-name" {...getRootProps()}>
                <input type="file" accept="image/*" {...getInputProps()} />
                <span>
                  {company?.data?.data?.name?.charAt(0)} {company?.data?.data?.name?.charAt(1)}
                </span>
                <button type="button">
                  {loading ? (
                    <Oval
                      color="#174078"
                      secondaryColor="#D9DBE9"
                      ariaLabel="loading"
                      height={20}
                      width={20}
                    />
                  ) : (
                    <EditIcon />
                  )}
                </button>
              </div>
            )}
          </div>
          {/* First name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="name">Name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter first name."
              {...register('name')}
              error={errors?.name?.message}
            />
            {errors?.name && <span className="error-label">{errors?.name?.message}</span>}
          </div>
          {/* Last name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="address">Company address</label>
            </div>
            <Input
              type="text"
              placeholder="Enter company address."
              {...register('address')}
              error={errors?.address?.message}
            />
            {errors?.address && <span className="error-label">{errors?.address?.message}</span>}
          </div>
          {/* Button view */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleSubmit(onSubmit)}>
              {handleUpdateCompany.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Save changes'
              )}
            </Button>
          </div>
        </form>
      )}
    </CompanyProfileView>
  );
}

export default CompanyProfile;

const CompanyProfileView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  form {
    width: 75%;
    padding: 0 60px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .logo-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .logo-image {
        width: 100px;
        height: 100px;
        border-radius: 16px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 16px;
        }

        button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primary};
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -10px;
          right: -10px;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .logo-name {
        width: 100px;
        height: 100px;
        border-radius: 16px;
        background-color: ${({ theme }) => theme.colors?.secondary};
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        span {
          font-size: 2rem;
          color: ${({ theme }) => theme.colors?.white};
          font-weight: 600;
          text-transform: uppercase;
        }

        button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primary};
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -10px;
          right: -10px;
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }
`;
