import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import { format, isToday, isYesterday } from 'date-fns';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as ReadIcon } from '@/assets/icons/read.icon.svg';
import { ReactComponent as EmptyNotif } from '@/assets/icons/empty.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { device } from '@/constants/breakpoints';
import { Oval } from 'react-loader-spinner';
import useOnClickOutside from '@/hooks/useClickOutside';
import TabView from './TabView';
import CardView from './CardView';
import { fetchAllNotifications, markAllNotificationAsRead } from '@/services/notificationsApi';
import { listenForNotifications, socket, subscribeToNotifications } from '@/services/socket';
import useUser from '@/hooks/useUser';
import SingleAlert from './SingleAlert';
import toast from 'react-hot-toast';
import sound from '@/assets/message.mp3';
import { useWithSound } from '@/hooks/useWithSound';

const formatDate = (dateStr) => {
  const date = dateStr ? new Date(dateStr) : new Date();
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return format(date, 'd MMM, yyyy');
  }
};

const groupNotificationsByDate = (notifications) => {
  return groupBy(notifications ?? [], (notification) => formatDate(notification.createdAt));
};

const NotificationsModal = ({ showModal, setShowModal }) => {
  const { playSound } = useWithSound(sound);
  const queryClient = useQueryClient();
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState('ALL');
  const [toastNotifications, setToastNotifications] = useState(null);

  const {
    data: notifications,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ['notifications', { limit: 50, read: false, channel: selectedTab }],
    queryFn: fetchAllNotifications,
    enabled: showModal
  });

  const groupedNotifications = groupNotificationsByDate([...(notifications?.data || [])]);

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setToastNotifications(null);
    setShowModal(false);
  });

  useEffect(() => {
    if (user) {
      subscribeToNotifications(user?.id);

      listenForNotifications(user?.id, (notification) => {
        playSound();
        if (showModal) {
          refetch();
        } else {
          setToastNotifications(notification?.data);
        }
      });

      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
  }, [user, showModal]);

  const removeToastNotification = () => {
    setToastNotifications(null);
  };

  const handleToastClick = () => {
    setShowModal(true);
    removeToastNotification();
  };

  const handleMarkAllAsRead = useMutation({
    mutationFn: markAllNotificationAsRead,
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
      toast.success('Notifications marked as read');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  return (
    <>
      <AnimatePresence>
        {!showModal && toastNotifications && (
          <SingleAlert
            notification={toastNotifications}
            onRemove={() => removeToastNotification()}
            onClick={() => handleToastClick(toastNotifications)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: -200 }}
              animate={{ y: 0 }}
              exit={{ y: -100, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="modal"
              ref={ref}
              onClick={(e) => e.stopPropagation()}>
              <header>
                <span></span>
                <button
                  onClick={() => {
                    setToastNotifications(null);
                    setShowModal(false);
                  }}>
                  <CloseIcon />
                </button>
              </header>

              <div className="title-header">
                <h2>Notifications</h2>
                <button
                  className="read"
                  type="button"
                  onClick={() => {
                    handleMarkAllAsRead.mutate();
                  }}>
                  {handleMarkAllAsRead.isLoading ? (
                    <Oval
                      color="#92c22c"
                      secondaryColor="#ddd"
                      height={20}
                      width={20}
                      strokeWidth={3}
                      ariaLabel="loading"
                    />
                  ) : (
                    <>
                      <ReadIcon />
                      <span>Mark all as read</span>
                    </>
                  )}
                </button>
              </div>

              <TabView selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

              {isLoading ? (
                <LoaderView>
                  <Oval color="#92c22c" width={30} height={30} strokeWidth={5} />
                </LoaderView>
              ) : (
                <NotificationContainer>
                  {Object.keys(groupedNotifications).map((date) => (
                    <NotificationGroup key={date}>
                      <NotificationDate>{date}</NotificationDate>
                      {groupedNotifications[date].map((notification) => (
                        <CardView key={notification.id} notification={notification}></CardView>
                      ))}
                    </NotificationGroup>
                  ))}
                </NotificationContainer>
              )}

              {Object.keys(groupedNotifications).length === 0 && !isLoading ? (
                <div className="empty-notif">
                  <EmptyNotif />
                  <h3>No notifications yet</h3>
                  <p>All your notifications will appear here, check back later.</p>
                </div>
              ) : null}
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
};

export default NotificationsModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.4); */
  z-index: 30001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors.background};
    z-index: 30000;
    position: fixed;
    top: 80px;
    right: 20px;
    border-radius: 16px;
    width: 500px;
    height: calc(100vh - 90px);
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid ${(props) => props.theme.colors.line};

    @media ${device.phone} {
      width: 100vw;
      bottom: 0;
      transform: translate(0%, 0%);
      border-radius: 10px 10px 0 0;
      padding-top: 20px;
      right: 0;
      bottom: 0;
      min-height: calc(var(--vh, 1vh) * 100);
      min-height: -webkit-fill-available;
      height: 80vh;
    }

    header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 10px 20px;

      h2 {
        font-size: 1.2rem;
        font-weight: 500;
      }

      button {
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        width: 40px;
        height: 40px;
        transition: all 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        &:hover {
          background-color: ${(props) => props.theme.colors.grey_200};
        }

        img {
          width: 12px;
          height: 12px;
          object-fit: contain;
        }
      }
    }

    .title-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      h2 {
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors.secondary};
      }

      .read {
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 37px;
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;

        span {
          font-size: 0.8rem;
        }
      }
    }

    .loader-view {
      width: 100%;
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;

      .notifications {
        width: 100%;

        .notif {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 12px 0;
          border-top: 1px solid ${(props) => props.theme.colors.line};

          .notif-content {
            width: 100%;

            p {
              font-size: 0.9rem;
            }

            .cta {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                font-size: 0.7rem;
                color: ${(props) => props.theme.colors.info};
              }

              a {
                color: ${(props) => props.theme.colors.primary};
                font-size: 0.7rem;
                text-decoration: underline;
              }
            }
          }

          img {
            margin-right: 10px;
          }
        }
      }
    }

    .empty-notif {
      width: 100%;
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      svg {
        width: 150px;
        height: 150px;
      }

      h3 {
        font-size: 1rem;
        font-weight: 500;
        margin-top: 20px;
        color: ${(props) => props.theme.colors.secondary};
      }

      p {
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors.grey_400};
        text-align: center;
        margin-top: 10px;
      }
    }
  }
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const NotificationGroup = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  /* margin-bottom: 20px; */
`;

const NotificationDate = styled.h2`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.secondary};
`;

const ToastContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: ${(props) => props.theme.colors.secondary};
`;

const LoaderView = styled.div`
  width: 100%;
  min-height: 40vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;
