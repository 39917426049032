import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ReadIcon } from '@/assets/icons/read.icon.svg';
import { ReactComponent as MailOpenIcon } from '@/assets/icons/mail-opened.svg';
import { ReactComponent as MailClosedIcon } from '@/assets/icons/mail-closed.icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.icon.svg';
import { format } from 'date-fns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { deleteNotification, markNotificationAsRead } from '@/services/notificationsApi';

const CardView = ({ notification }) => {
  const queryClient = useQueryClient();
  const handleDeleteNotification = useMutation({
    mutationFn: ({ id }) => deleteNotification(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
      toast.success('Notification deleted successfully');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  const handleMarkAsRead = useMutation({
    mutationFn: ({ id }) => markNotificationAsRead(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications']);
      // toast.success('Notification marked as read');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  return (
    <CardWrapper isRead={notification.read}>
      <NotificationHeader isRead={notification.read}>
        <NotificationTitle isRead={notification.read}>{notification.title}</NotificationTitle>
        <NotificationActions isRead={notification.read}>
          <button
            className="read-btn"
            onClick={() => {
              if (!notification.read) {
                handleMarkAsRead.mutate({ id: notification.id });
              } else return;
            }}>
            {handleMarkAsRead.isLoading ? (
              <Oval
                color="#92c22c"
                secondaryColor="#ddd"
                height={20}
                width={20}
                strokeWidth={3}
                ariaLabel="loading"
              />
            ) : notification.read ? (
              <MailOpenIcon />
            ) : (
              <MailClosedIcon />
            )}
          </button>
          <button
            className="delete-btn"
            onClick={() => {
              handleDeleteNotification.mutate({ id: notification.id });
            }}>
            {handleDeleteNotification.isLoading ? (
              <Oval
                color="#B00020"
                secondaryColor="#ddd"
                height={20}
                width={20}
                strokeWidth={3}
                ariaLabel="loading"
              />
            ) : (
              <DeleteIcon />
            )}
          </button>
        </NotificationActions>
      </NotificationHeader>
      <NotificationDescription isRead={notification.read}>
        {notification.message}
      </NotificationDescription>

      <BottomView>
        <span>
          {notification?.createdAt
            ? format(new Date(notification.createdAt), 'do MMM, yyyy | h:mm a')
            : 'N/A'}
        </span>
      </BottomView>
    </CardWrapper>
  );
};

export default CardView;

const CardWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
  background-color: ${(props) =>
    props.isRead ? props.theme.colors.white : props.theme.colors.white};
`;

const NotificationTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
  color: ${(props) => (props.isRead ? props.theme.colors.info : props.theme.colors.secondary)};
`;

const NotificationDescription = styled.p`
  margin: 5px 0;
  color: ${(props) => (props.isRead ? props.theme.colors.info : props.theme.colors.secondary)};
  font-size: 0.9rem;
  padding: 15px;
  line-height: 19px;
  font-weight: 500;
`;

const NotificationHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isRead ? props.theme.colors.layer5 : props.theme.colors.layer6};
  height: 70px;
  border-radius: 10px 10px 0 0;
  padding: 0 15px;
`;

const NotificationActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .read-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    cursor: pointer;
    margin-right: 5px;

    span {
      font-size: 0.8rem;
      color: ${(props) => (props.isRead ? props.theme.colors.info : props.theme.colors.secondary)};
    }

    svg {
      path {
        fill: ${(props) => (props.isRead ? props.theme.colors.info : props.theme.colors.primary)};
      }
    }
  }

  .delete-btn {
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 37px;
    background-color: ${(props) => props.theme.colors.errorBackground};
    cursor: pointer;
  }
`;

const BottomView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.line};
  color: ${(props) => props.theme.colors.info};

  span {
    font-size: 0.75rem;
  }
`;
