import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import useOnClickOutside from '@/hooks/useClickOutside';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';

export default function BankListDropdown({ options, setValue, name, code, placeholder, value }) {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [resetOptions, setResetOptions] = useState([]);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    const filteredOptions = options?.filter((option) => option?.bankName !== 'PAPSS');
    setResetOptions(filteredOptions);
  }, [options]);

  const debouncedSetQuery = useCallback(
    debounce((newQuery) => {
      setQuery(newQuery);
    }, 300),
    []
  );

  const filter = (options, query) => {
    if (!query) return options; // return all options if query is empty

    const lowerCaseQuery = query.toLowerCase();
    return options.filter((option) => {
      const item = option[name] ? option[name].toLowerCase() : '';
      return item.includes(lowerCaseQuery);
    });
  };

  return (
    <View ref={ref} open={open}>
      <div className="value" onClick={() => setOpen(!open)}>
        {value ? (
          value[name] ? (
            <p className="val">{value[name]}</p>
          ) : (
            <span className="placeholder">{placeholder}</span>
          )
        ) : null}
        <button className="icon" type="button">
          <ArrowDownIcon />
        </button>
      </div>
      {open ? (
        <div className="dropdown">
          {options?.length > 5 ? (
            <input
              type="text"
              className="input"
              placeholder="Search..."
              onChange={(e) => {
                debouncedSetQuery(e.target.value);
              }}
            />
          ) : null}
          <div className="options">
            {filter(resetOptions, query)?.map((option) => (
              <div
                key={option[name]}
                className="option"
                onClick={() => {
                  setQuery('');
                  setOpen(false);
                  setValue(option);
                }}>
                {option[name]}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </View>
  );
}

BankListDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.any).isRequired
};

const View = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;

  border-radius: 4px;
  /* margin-top: 5px; */

  .value {
    width: 100%;
    height: 64px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-radius: 16px;
    outline: none;
    padding: 16px;
    margin-top: 5px;
    transition: all 0.25s ease-in-out;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      background-color: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 12px;
      cursor: pointer;

      svg {
        transition: all 0.3s ease-in-out;
        transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
        transform-origin: 50% 50%;
        width: 15px;
        height: 15px;
      }
    }

    p {
      color: ${({ theme }) => theme.colors?.activeTitle};
      text-transform: capitalize;
      font-weight: 400;
    }

    .placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
    }
  }

  .dropdown {
    width: 100%;
    z-index: 20;
    position: absolute;
    top: 70px;
    background-color: ${({ theme }) => theme.colors?.white};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .input {
      outline: none;
      margin-top: 0;
      padding: 10px;
      /* margin-bottom: 10px; */
      font-size: 0.8rem;
      font-weight: 300;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
      width: 100%;
      height: 50px;
      /* position: absolute;
      top: 0px;
      left: 0px; */
    }

    .options {
      width: 100%;
      max-height: 220px;
      padding-top: 10px;
      overflow-y: scroll;

      .option {
        padding: 12px 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: 100%;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors?.activeTitle};

        &:hover {
          background-color: ${({ theme }) => theme.colors?.layer};
          border-radius: 0;
        }

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          display: inline-block;
          border: 1px solid ${({ theme }) => theme.colors?.line};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
      }
    }
  }
`;
