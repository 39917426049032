/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import SearchDropdown from '@/components/SearchDropdown';
import Input from '@/components/Input/Input';
import TextArea from '@/components/TextArea/TextArea';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import FetchLoader from '@/components/FetchLoader';
import { toast } from 'react-hot-toast';
import { updateInvoice } from '@/services/receivablesApi';
import { fetchCustomers } from '@/services/customerApi';
import NewCustomerModal from './NewCustomerForm';
import { useNavigate } from 'react-router-dom';
import { device } from '@/constants/breakpoints';
import FilePicker2 from '@/components/FilePicker2';
import { fetchUser } from '@/services/userApi';
import FindCustomerModal from '../invoiceFinancing/FindCustomer';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 1, name: 'Daily', value: 'daily' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' },
  { id: 3, name: 'Quarterly', value: 'quarterly' },
  { id: 3, name: 'Yearly', value: 'yearly' }
];

function EditInvoiceForm({ showModal, setShowModal, selectedInvoice }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [showFindCustomerModal, setShowFindCustomerModal] = useState(false);
  const [customer, setCustomer] = useState(selectedInvoice?.customer);
  const [frequency, setFrequency] = useState(selectedInvoice?.frequency);
  const [file, setFile] = useState(null);
  const [expires, setExpires] = useState(false);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const schema = yup
    .object({
      recurringStartDate: (() => {
        let validation = yup.string();
        if (frequency?.value !== 'one-time') {
          validation = validation.required('Start date is required');
        }
        return validation;
      })(),
      recurringEndDate: (() => {
        let validation = yup.string();
        if (!expires && frequency?.value !== 'one-time') {
          validation = validation.required('End date is required');
        }
        return validation;
      })(),
      amount: yup.string().required('Invoice amount is required'),
      dueDate: yup.string().required('Due date is required'),
      description: yup.string().nullable(),
      billOrInvoiceNumber: yup.string().required('Invoice number is required'),
      requiresApproval: yup.boolean().required('Approval status is required')
    })
    .required();

  useEffect(() => {
    setCustomer(selectedInvoice?.customer);
    setFrequency(billFrequency.find((item) => item?.value === selectedInvoice?.frequency));
    setExpires(!selectedInvoice?.shouldExpireRecurring);
    if (selectedInvoice?.fileUrl) {
      setFile(selectedInvoice?.fileUrl);
    } else {
      setFile(null);
    }
    // if (selectedInvoice?.fileUrl) {
    //   const fileType = getMimeTypeFromUrl(selectedInvoice?.fileUrl);
    //   urlToFile(selectedInvoice?.fileUrl, `invoice.${fileType?.split('/')[1]}`, fileType)
    //     .then((filee) => {
    //       setFile(filee);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // } else {
    //   setFile(null);
    // }
  }, [selectedInvoice]);

  const customers = useQuery({
    queryKey: ['vendors', { page: 1, pageLimit: 1000 }],
    queryFn: fetchCustomers,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: selectedInvoice?.amount,
      billOrInvoiceNumber: selectedInvoice?.billOrInvoiceNumber,
      requiresApproval: selectedInvoice?.requiresApproval,
      dueDate: new Date(selectedInvoice?.dueDate || Date.now())?.toISOString()?.substring(0, 10),
      recurringStartDate: selectedInvoice?.recurringStartDate
        ? new Date(selectedInvoice?.recurringStartDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : '',
      recurringEndDate: selectedInvoice?.recurringEndDate
        ? new Date(selectedInvoice?.recurringEndDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      description: selectedInvoice?.description
    }
  });

  const requiresApproval = useWatch({
    control,
    name: 'requiresApproval'
  });

  useEffect(() => {
    reset({
      amount: selectedInvoice?.amount,
      billOrInvoiceNumber: selectedInvoice?.billOrInvoiceNumber,
      requiresApproval: selectedInvoice?.requiresApproval,
      dueDate: new Date(selectedInvoice?.dueDate || Date.now())?.toISOString()?.substring(0, 10),
      recurringStartDate: selectedInvoice?.recurringStartDate
        ? new Date(selectedInvoice?.recurringStartDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : '',
      recurringEndDate: selectedInvoice?.recurringEndDate
        ? new Date(selectedInvoice?.recurringEndDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      description: selectedInvoice?.description
    });
  }, [selectedInvoice, reset]);

  const handleUpdateInvoice = useMutation(({ id, data }) => updateInvoice(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      setShowModal(false);
      navigate(`/get-paid/invoices?tab=${selectedInvoice?.status}`);
      toast.success('Invoice updated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const invoiceData = {
      customerId: customer?.id,
      amount: data?.amount,
      frequency: frequency?.value,
      status: selectedInvoice?.status,
      billOrInvoiceNumber: data?.billOrInvoiceNumber,
      requiresApproval: data?.requiresApproval,
      description: data?.description,
      dueDate: new Date(data?.dueDate).toISOString(),
      recurringStartDate: data?.recurringStartDate
        ? new Date(data?.recurringStartDate).toISOString()
        : undefined,
      recurringEndDate: data?.recurringEndDate
        ? new Date(data?.recurringEndDate).toISOString()
        : undefined,
      shouldExpireRecurring: expires ? 'no' : 'yes',
      fileUrl: file
    };

    handleUpdateInvoice.mutate({ id: selectedInvoice?.id, data: invoiceData });
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 500 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======= Content here ====== */}
              <div className="header-view">
                <button type="button" className="back" onClick={() => setShowModal(false)}>
                  <BackIcon />
                </button>
              </div>

              {customers?.isLoading ? (
                <FetchLoader />
              ) : (
                <div className="form-view">
                  <form>
                    {/* Vendor */}
                    <div className="input-view vendor">
                      <label htmlFor="vendor">Customer</label>
                      <SearchDropdown
                        options={customers?.data?.data?.items}
                        id="id"
                        name="companyName"
                        setValue={setCustomer}
                        value={customer}
                        placeholder="Select customer"
                      />
                    </div>
                    <div className="add-view">
                      <button type="button" onClick={() => setShowFindCustomerModal(true)}>
                        <PlusIcon /> Add new customer
                      </button>
                    </div>

                    {/* Bill amount */}
                    <div className="input-view">
                      <label htmlFor="name">Bill amount</label>
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <CurrencyyInput
                            name={name}
                            placeholder="Enter bill amount"
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            prefix="₦"
                            onValueChange={onChange}
                            defaultValue={value || ''}
                            onBlur={onBlur}
                            error={!!errors?.amount?.message}
                            className={`currency-input ${
                              errors?.amount && dirtyFields?.amount
                                ? ' has-error'
                                : dirtyFields?.amount
                                ? 'valid'
                                : ''
                            }`}
                          />
                        )}
                      />

                      {errors?.billAmount && (
                        <span className="error-label">{errors?.billAmount?.message}</span>
                      )}
                    </div>

                    {/* Bill frequency */}
                    <div className="input-view">
                      <label htmlFor="vendor">Bill Frequency</label>
                      <SearchDropdown
                        options={billFrequency}
                        id="id"
                        name="name"
                        setValue={setFrequency}
                        value={frequency}
                        placeholder="Select frequency"
                      />
                    </div>

                    {frequency?.value !== 'one-time' && (
                      <>
                        {/* Frequency date */}
                        <div className="date-row">
                          <div className="input-view">
                            <label htmlFor="name">Start date</label>

                            <Input
                              type="date"
                              {...register('recurringStartDate')}
                              error={errors?.recurringStartDate?.message}
                            />

                            {errors?.recurringStartDate && (
                              <span className="error-label">
                                {errors?.recurringStartDate?.message}
                              </span>
                            )}
                          </div>
                          <div className="input-view">
                            <label htmlFor="name">End date</label>

                            <Input
                              type="date"
                              {...register('recurringEndDate')}
                              error={errors?.recurringEndDate?.message}
                              disabled={!!expires}
                              className={expires ? 'disabled' : ''}
                            />

                            {errors?.recurringEndDate && (
                              <span className="error-label">
                                {errors?.recurringEndDate?.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Checkbox */}
                        <div className="checkbox-view">
                          <input
                            type="checkbox"
                            name="expires"
                            id="expires"
                            value="false"
                            className="checkbox"
                            checked={expires}
                            onChange={() => setExpires(!expires)}
                          />
                          <label htmlFor="expires">Never expires</label>
                        </div>
                      </>
                    )}

                    <div className="input-view">
                      <label htmlFor="billOrInvoiceNumber">Invoice no.</label>
                      <Input type="text" {...register('billOrInvoiceNumber')} />
                      <div className="input-info-view">
                        <span className="info">*Invoice number is auto generated</span>
                      </div>
                      {errors?.billOrInvoiceNumber && (
                        <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                      )}
                    </div>

                    {/* Due date */}
                    <div className="input-view">
                      <label htmlFor="name">Due date</label>

                      <Input
                        type="date"
                        {...register('dueDate')}
                        error={errors?.dueDate?.message}
                      />

                      {errors?.dueDate && (
                        <span className="error-label">{errors?.dueDate?.message}</span>
                      )}
                    </div>

                    {/* Description */}
                    <div className="input-view">
                      <label htmlFor="description">Description</label>
                      <TextArea
                        type="number"
                        {...register('description')}
                        error={!!errors?.description?.message}
                        placeholder="Enter description"
                      />

                      {errors?.description && (
                        <span className="error-label">{errors?.description?.message}</span>
                      )}
                    </div>

                    {/* Document */}
                    <div className="input-view">
                      <label htmlFor="billOrInvoiceNumber">
                        Document <span>(optional)</span>
                      </label>

                      <FilePicker2 file={file} setFile={setFile} acceptsPDF />
                    </div>

                    {/* Approval */}
                    <div className="input-view">
                      <label htmlFor="vendor">Will the invoice require approval?</label>
                      <div className="options">
                        <div className="option">
                          <input
                            type="radio"
                            name="approval"
                            id="approval"
                            value={true}
                            defaultChecked={selectedInvoice?.requiresApproval}
                            {...register('requiresApproval')}
                            // onChange={() => setApproval(true)}
                          />
                          <span>Yes</span>
                        </div>

                        <div className="option">
                          <input
                            type="radio"
                            name="approval"
                            id="approval"
                            value={false}
                            defaultChecked={!selectedInvoice?.requiresApproval}
                            {...register('requiresApproval')}
                            // onChange={() => setApproval(false)}
                          />
                          <span>No</span>
                        </div>
                      </div>
                      {errors?.requiresApproval && (
                        <span className="error-label">{errors?.requiresApproval?.message}</span>
                      )}
                    </div>

                    {/* ===== CTA ====== */}
                    <div className="cta-view">
                      <button
                        type="button"
                        className="cta-view__save"
                        onClick={handleSubmit(onSubmit)}>
                        {handleUpdateInvoice.isLoading ? (
                          <Oval
                            color="#FFF"
                            secondaryColor="#ddd"
                            height={30}
                            width={30}
                            strokeWidth={4}
                            ariaLabel="loading"
                          />
                        ) : (
                          'Save changes'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
      <NewCustomerModal
        showModal={showCustomerForm}
        setShowModal={setShowCustomerForm}
        setCustomer={setCustomer}
      />

      <FindCustomerModal
        showModal={showFindCustomerModal}
        setShowModal={setShowFindCustomerModal}
        showAddNewCustomer={showCustomerForm}
        setShowAddNewCustomer={setShowCustomerForm}
        setCustomer={setCustomer}
      />
    </>
  );
}

export default EditInvoiceForm;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .form-view {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 20px;

      form {
        width: 100%;
        max-width: 460px;

        .input-view {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          input {
            width: 100%;
          }

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .error-label {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.error};
            margin-top: 5px;
          }

          .input-info-view {
            width: 100%;
            display: flex;
            margin-top: 8px;

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 14px;

            .option {
              width: 40%;
              display: flex;
              align-items: center;

              input {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 10px;
                accent-color: ${({ theme }) => theme.colors?.secondary};
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                cursor: pointer;
                color: ${({ theme }) => theme.colors?.primary};
              }

              span {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 0px;
              }
            }
          }
        }

        .checkbox-view {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 30px;
          margin-top: 10px;

          .checkbox {
            margin-right: 5px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
            width: 16px;
            height: 16px;
          }

          label {
            cursor: pointer;
          }
        }

        .date-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .input-view {
            width: 48%;
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            input {
              width: 100%;
            }

            .disabled {
              background-color: ${({ theme }) => theme.colors?.layer2};

              &::placeholder {
                color: ${({ theme }) => theme.colors?.info};
              }
            }
          }
        }

        .vendor {
          margin-bottom: 10px;
        }

        .add-view {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          button {
            color: ${({ theme }) => theme.colors?.secondary};
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 10px;
            }
          }
        }

        .select-view {
          width: 100%;
          margin-bottom: 20px;

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;

            .option {
              width: 33.333%;
              display: flex;
              align-items: center;
              cursor: pointer;

              .radio-button {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                margin-right: 10px;

                .dot {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colors?.primary};
                }
              }

              p {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.colors?.secondary};
              }
            }
          }
        }

        .cta-view {
          width: 100%;
          display: flex;
          margin-top: 60px;
          margin-bottom: 50px;
          justify-content: space-between;

          button {
            width: 60%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 16px;
          }

          .cta-view__save {
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            margin-bottom: 20px;
            width: 100%;
          }
        }
      }
    }
  }
`;
