import React from 'react';
import styled from 'styled-components';

function Pagination({ itemsPerPage, totalItems, currentPage, onPageChange }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i += 1) {
    pageNumbers.push(i);
  }

  let startIndex = Math.max(currentPage - 2, 0);
  const endIndex = Math.min(startIndex + 2, pageNumbers.length - 1);

  if (endIndex - startIndex < 2) {
    startIndex = Math.max(endIndex - 2, 0);
  }

  const displayedPages = pageNumbers.slice(startIndex, endIndex + 1);

  return (
    displayedPages.length > 1 && (
      <PaginationView>
        <div className="paginationn">
          {currentPage > 1 && (
            <button
              type="button"
              onClick={() => onPageChange(currentPage - 1)}
              className="prev-arrow">
              &laquo;
            </button>
          )}
          <div className="pages">
            {startIndex > 0 && (
              <button type="button" onClick={() => onPageChange(1)} className="page-number">
                ...
              </button>
            )}
            {displayedPages.map((number) => (
              <button
                type="button"
                className={`page-number ${currentPage === number ? 'active' : ''}`}
                onClick={() => onPageChange(number)}
                key={number}>
                {number}
              </button>
            ))}
            {endIndex < pageNumbers.length - 1 && (
              <button
                type="button"
                onClick={() => onPageChange(pageNumbers.length)}
                className="page-number">
                ...
              </button>
            )}
          </div>

          {currentPage < pageNumbers.length && (
            <button
              type="button"
              onClick={() => onPageChange(currentPage + 1)}
              className="next-arrow">
              &raquo;
            </button>
          )}
        </div>
      </PaginationView>
    )
  );
}

export default Pagination;

const PaginationView = styled.nav`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .paginationn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .prev-arrow,
    .next-arrow {
      border: none;
      outline: none;
      background-color: ${(props) => props.theme.colors?.secondary};
      border-radius: 50%;
      color: ${(props) => props.theme.colors?.white};
      font-size: 1rem;
      font-weight: 600;
      width: 40px;
      height: 40px;
    }

    .pages {
      background-color: ${(props) => props.theme.colors?.secondary};
      border-radius: 6px;
      margin: 0 10px;

      .page-number {
        color: ${(props) => props.theme.colors?.black};
        width: 40px;
        height: 40px;
        border: none;
        outline: none;
        border-radius: 4px;
        color: ${(props) => props.theme.colors?.white};
        font-weight: 600;
      }

      .active {
        border: none;
        background-color: ${(props) => props.theme.colors?.primary};

        button {
          color: ${(props) => props.theme.colors?.black};
        }
      }
    }
  }
`;
