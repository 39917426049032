/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import secureLocalStorage from 'react-secure-storage';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

import { ReactComponent as DashboardIcon } from '@/assets/icons/dashboard.icon.svg';
import { ReactComponent as InboxIcon } from '@/assets/icons/inbox.icon.svg';
import { ReactComponent as OutboxIcon } from '@/assets/icons/outbox.icon.svg';
import { ReactComponent as PaymentIcon } from '@/assets/icons/payment.icon.svg';
import { ReactComponent as PayIcon } from '@/assets/icons/pay.icon.svg';
import { ReactComponent as CardIcon } from '@/assets/icons/card.icon.svg';
import { ReactComponent as VendorsIcon } from '@/assets/icons/vendors.icon.svg';
import { ReactComponent as InflowIcon } from '@/assets/icons/inflows.icon.svg';
import { ReactComponent as CustomersIcon } from '@/assets/icons/customers.icon.svg';
import { ReactComponent as SettingsIcon } from '@/assets/icons/settings.icon.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/support.icon.svg';
import { ReactComponent as BankingIcon } from '@/assets/icons/banking.icon.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minus.icon.svg';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/icons/archive.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { useDispatch } from 'react-redux';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';
import { logoutUser } from '@/services/authApi';
import { resetTrackedUser, trackEvent } from '@/utils/mixpanel';
import useUser from '@/hooks/useUser';

function MobileSidebar({ openNav, setOpenNav }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
      trackEvent('User Logged Out', { userId: user?.id });
      resetTrackedUser();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <AnimatePresence mode="wait">
      {openNav && (
        <Backdrop
          onClick={(e) => {
            e.stopPropagation();
            setOpenNav(false);
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        />
      )}
      <AnimatePresence mode="wait">
        {openNav && (
          <SidebarView
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: -500 }}
            transition={{ duration: 0.2 }}>
            <LogoView>
              <Logo className="logo-icon" />
              <button type="button" className="back" onClick={() => setOpenNav(false)}>
                <BackIcon />
              </button>
            </LogoView>

            <ListView>
              <ListItem>
                <Link
                  to="/dashboard"
                  onClick={() => setOpenNav(false)}
                  className={location.pathname.includes('/dashboard') ? 'active' : ''}>
                  <DashboardIcon
                    className={location.pathname.includes('/dashboard') ? 'active-icon' : 'icon'}
                  />
                  Dashboard
                </Link>
              </ListItem>
              <ListItem>
                <button
                  type="button"
                  className={location.pathname.includes('/inboxx') ? 'active' : ''}>
                  <InboxIcon
                    className={location.pathname.includes('/inboxx') ? 'active-icon' : 'icon'}
                  />
                  Inboxx <span>Coming soon</span>
                </button>
              </ListItem>
              <ListItem>
                <button
                  type="button"
                  className={location.pathname.includes('/outboxx') ? 'active' : ''}>
                  <OutboxIcon
                    className={location.pathname.includes('/outboxx') ? 'active-icon' : 'icon'}
                  />
                  Outboxx <span>Coming soon</span>
                </button>
              </ListItem>
              <ListItem>
                <Link
                  to="/make-payments/bills?tab=draft&page=1"
                  onClick={() => setOpenNav(false)}
                  className={location.pathname.includes('/make-payments') ? 'active' : ''}>
                  <PaymentIcon
                    className={
                      location.pathname.includes('/make-payments') ? 'active-icon' : 'icon'
                    }
                  />
                  Make Payment{' '}
                  {location.pathname.includes('/make-payments') ? (
                    <MinusIcon className="opener" />
                  ) : (
                    <PlusIcon className="opener" />
                  )}
                </Link>
                {location.pathname.includes('/make-payments') && (
                  <SubMenu>
                    <SubMenuItem>
                      <Link
                        to="/make-payments/bills?tab=draft&page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/make-payments/bills') ? 'active' : ''
                        }>
                        <CardIcon
                          className={
                            location.pathname.includes('/make-payments/bills')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Bills
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/make-payments/vendors?page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/make-payments/vendors') ? 'active' : ''
                        }>
                        <VendorsIcon
                          className={
                            location.pathname.includes('/make-payments/vendors')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Vendors
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/make-payments/outflows?page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/make-payments/outflows') ? 'active' : ''
                        }>
                        <CardIcon
                          className={
                            location.pathname.includes('/make-payments/outflows')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Outflows
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/make-payments/archives?page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/make-payments/archives') ? 'active' : ''
                        }>
                        <ArchiveIcon
                          className={
                            location.pathname.includes('/make-payments/archives')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Archives
                      </Link>
                    </SubMenuItem>
                  </SubMenu>
                )}
              </ListItem>
              <ListItem>
                <Link
                  to="/get-paid/invoices?tab=draft&page=1"
                  onClick={() => setOpenNav(false)}
                  className={location.pathname.includes('/get-paid') ? 'active' : ''}>
                  <PayIcon
                    className={location.pathname.includes('/get-paid') ? 'active-icon' : 'icon'}
                  />
                  Get Paid
                  {location.pathname.includes('/get-paid') ? (
                    <MinusIcon className="opener" />
                  ) : (
                    <PlusIcon className="opener" />
                  )}
                </Link>
                {location.pathname.includes('/get-paid') && (
                  <SubMenu>
                    <SubMenuItem>
                      <Link
                        to="/get-paid/invoices?tab=draft&page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/get-paid/invoices') ? 'active' : ''
                        }>
                        <CardIcon
                          className={
                            location.pathname.includes('/get-paid/invoices')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Invoices
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/get-paid/customers?page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/get-paid/customers') ? 'active' : ''
                        }>
                        <CustomersIcon
                          className={
                            location.pathname.includes('/get-paid/customers')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Customers
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/get-paid/inflows?page=1"
                        onClick={() => setOpenNav(false)}
                        className={location.pathname.includes('/get-paid/inflows') ? 'active' : ''}>
                        <InflowIcon
                          className={
                            location.pathname.includes('/get-paid/inflows') ? 'active-icon' : 'icon'
                          }
                        />
                        Inflows
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/get-paid/archives?page=1"
                        onClick={() => setOpenNav(false)}
                        className={
                          location.pathname.includes('/get-paid/archives') ? 'active' : ''
                        }>
                        <ArchiveIcon
                          className={
                            location.pathname.includes('/get-paid/archives')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Archives
                      </Link>
                    </SubMenuItem>
                  </SubMenu>
                )}
              </ListItem>
              <ListItem>
                <Link
                  to="/financing/purchase-financing?tab=requests&page=1"
                  className={location.pathname.includes('/financing') ? 'active' : ''}>
                  <PayIcon
                    className={location.pathname.includes('/financing') ? 'active-icon' : 'icon'}
                  />
                  Financing
                  {location.pathname.includes('/financing') ? (
                    <MinusIcon className="opener" />
                  ) : (
                    <PlusIcon className="opener" />
                  )}
                </Link>
                {location.pathname.includes('/financing') && (
                  <SubMenu>
                    <SubMenuItem>
                      <Link
                        to="/financing/purchase-financing?tab=draft&page=1"
                        className={
                          location.pathname.includes('/financing/purchase-financing')
                            ? 'active'
                            : ''
                        }>
                        <CardIcon
                          className={
                            location.pathname.includes('/financing/purchase-financing')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Purchase Financing
                      </Link>
                    </SubMenuItem>
                    <SubMenuItem>
                      <Link
                        to="/financing/invoice-discounting?page=1"
                        className={
                          location.pathname.includes('/financing/invoice-discounting')
                            ? 'active'
                            : ''
                        }>
                        <CustomersIcon
                          className={
                            location.pathname.includes('/financing/invoice-discounting')
                              ? 'active-icon'
                              : 'icon'
                          }
                        />
                        Invoice Discounting
                      </Link>
                    </SubMenuItem>
                  </SubMenu>
                )}
              </ListItem>
              <ListItem>
                <Link
                  to="/banking?tab=manage-accounts"
                  onClick={() => setOpenNav(false)}
                  className={location.pathname.includes('/banking') ? 'active' : ''}>
                  <BankingIcon
                    className={location.pathname.includes('/banking') ? 'active-icon' : 'icon'}
                  />
                  Banking
                </Link>
              </ListItem>
              <FooterView>
                <FooterListItem>
                  <Link
                    to="/settings?tab=profile"
                    onClick={() => setOpenNav(false)}
                    className={location.pathname.includes('/settings') ? 'active' : ''}>
                    <SettingsIcon
                      className={location.pathname.includes('/settings') ? 'active-icon' : 'icon'}
                    />
                    Settings
                  </Link>
                </FooterListItem>
                <FooterListItem>
                  <a href="mailto:support@billboxx.com" target="_blank" rel="noopener noreferrer">
                    <SupportIcon
                      className={location.pathname.includes('/supports') ? 'active-icon' : 'icon'}
                    />
                    Support
                  </a>
                </FooterListItem>
                {/* ====== Logout Buttonn ====== */}
                <button
                  type="button"
                  className="logout"
                  onClick={() => handleLogoutUser.mutate({})}>
                  <LogoutIcon />
                  <span>{handleLogoutUser.isLoading ? 'Logging out...' : 'Logout'}</span>
                </button>
              </FooterView>
            </ListView>
          </SidebarView>
        )}
      </AnimatePresence>
    </AnimatePresence>
  );
}

export default MobileSidebar;

MobileSidebar.propTypes = {
  openNav: PropTypes.bool.isRequired,
  setOpenNav: PropTypes.func.isRequired
};

const SidebarView = styled(motion.nav)`
  min-height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: ${({ theme }) => theme.colors?.white};
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: 30px 20px;
`;

const LogoView = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-icon {
    width: 100px;
    height: 30px;
    padding: 0;
  }
`;

const ListView = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
`;

const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #eae4e4;
  position: relative;
  padding: 5px 0;

  a,
  button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 11.7px;
    color: ${({ theme }) => theme.colors?.secondary};
    padding: 24px 10px;
    transition: all 0.2s ease;
    position: relative;

    span {
      font-size: 0.675rem;
      top: 50%;
      right: 0;
      position: absolute;
      transform: translateY(-50%);
      background-color: ${({ theme }) => theme.colors?.label};
      padding: 2px 5px;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors?.white};
    }

    &:hover {
      background-color: #dce2eb;
      border-radius: 4px;
    }

    .icon {
      margin-right: 16px;

      path {
        stroke-width: 1.5;
      }
    }

    .active-icon {
      margin-right: 16px;
    }

    .active-icon path {
      /* stroke: ${({ theme }) => theme.colors?.primary_600}; */
      fill: ${({ theme }) => theme.colors?.white};
    }

    .active-icon ellipse {
      /* stroke: ${({ theme }) => theme.colors?.primary_600}; */
      fill: ${({ theme }) => theme.colors?.white};
    }

    .opener {
      position: absolute;
      right: 0;
      margin-right: 10px;
    }
  }
  .active {
    color: ${({ theme }) => theme.colors?.white};
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 6px;
    font-weight: 500;
    border: none;

    .icon {
      margin-right: 16px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors?.primary};
      border-radius: 4px;
    }
  }
`;

const SubMenu = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 40px;
  background-color: ${({ theme }) => theme.colors?.background};
`;

const SubMenuItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0px 0;

  a {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 11.7px;
    color: ${({ theme }) => theme.colors?.secondary};
    padding: 24px 10px;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.colors?.primary};
      background-color: transparent;

      .icon path {
        fill: ${({ theme }) => theme.colors?.primary};
      }
    }

    .icon {
      margin-right: 16px;

      path {
        stroke-width: 1.5;
      }
    }

    .active-icon {
      margin-right: 16px;
    }

    .active-icon path {
      /* stroke: ${({ theme }) => theme.colors?.primary_600}; */
      fill: ${({ theme }) => theme.colors?.primary};
    }
  }

  .active {
    color: ${({ theme }) => theme.colors?.primary};
    background-color: transparent;
    border-radius: 6px;
    font-weight: 500;
    border: none;

    .icon {
      margin-right: 16px;
    }

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }
`;

const FooterView = styled.div`
  width: 100%;
  margin-top: 150px;

  .logout {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${({ theme }) => theme.colors?.info};
    margin-bottom: 16px;
    margin-top: 0px;
    padding: 10px;
    transition: all 0.2s ease;
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 6px;

    &:hover {
      background-color: #dce2eb;
      border-radius: 4px;
    }

    span {
      margin-left: 16px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 11.7px;
    }
  }
`;

const FooterListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* border-bottom: 1px solid #eae4e4; */
  position: relative;
  padding: 5px 0;

  a {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 11.7px;
    color: ${({ theme }) => theme.colors?.secondary};
    padding: 24px 10px;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      background-color: #dce2eb;
      border-radius: 4px;
    }

    .icon {
      margin-right: 16px;

      path {
        stroke-width: 1.5;
      }
    }

    .active-icon {
      margin-right: 16px;
    }

    .active-icon path {
      /* stroke: ${({ theme }) => theme.colors?.primary_600}; */
      fill: ${({ theme }) => theme.colors?.white};
    }

    .active-icon ellipse {
      /* stroke: ${({ theme }) => theme.colors?.primary_600}; */
      fill: ${({ theme }) => theme.colors?.white};
    }

    .opener {
      position: absolute;
      right: 0;
      margin-right: 10px;
    }
  }
  .active {
    color: ${({ theme }) => theme.colors?.white};
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 6px;
    font-weight: 500;
    border: none;

    .icon {
      margin-right: 16px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors?.primary};
      border-radius: 4px;
    }
  }

  .switch-icon {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;

const Backdrop = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  /* backdrop-filter: blur(2px); */
  z-index: 20;
  top: 0;
  overflow: hidden;
`;
