import { useNavigate, useSearchParams } from 'react-router-dom';

import { AccountVerificationView } from './verifyEmail.styles';
import { useMutation } from '@tanstack/react-query';
import { verifyEmail } from '@/services/authApi';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { Oval } from 'react-loader-spinner';
import theme from '@/constants/theme';
import { identifyUser, setUser, trackEvent } from '@/utils/mixpanel';

const AccountVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const modifiedEmail = email.replace(/ /g, '+');
  const token = searchParams.get('token');

  const handleRegisterUser = useMutation(verifyEmail, {
    onSuccess: (data) => {
      secureLocalStorage.setItem('ut', data?.data?.accessToken);
      secureLocalStorage.setItem('rt', data?.data?.refreshToken);
      secureLocalStorage.setItem('auth', true);
      navigate(`/onboarding?page=user-info`, { replace: true });
      toast.success('Account verified');

      trackEvent('Account Created', {
        userId: data?.data?.user?.id
      });
      identifyUser(data?.data?.user?.id);
      setUser(data?.data?.user?.id, {
        userId: data?.data?.user?.id,
        $email: data?.data?.user?.email,
        $name: `${data?.data?.user?.firstName} ${data?.data?.user?.lastName}`,
        signupTime: data?.data?.user?.createdAt,
        firstActivityTime: new Date(),
        activationTime: (new Date() - new Date(data?.data?.user?.createdAt)) / (1000 * 60 * 60 * 24)
      });
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
        navigate(`/login`);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  useEffect(() => {
    if (handleRegisterUser.isIdle) {
      handleRegisterUser.mutate({ email: modifiedEmail, token });
    }
  }, []);

  if (handleRegisterUser.isLoading) {
    return (
      <AccountVerificationView>
        <Oval
          color={theme.colors.primary}
          secondaryColor={theme.colors.primaryFocus}
          height={40}
          width={40}
        />
        <h3>Verifying account</h3>
      </AccountVerificationView>
    );
  }

  return null;
};

export default AccountVerification;
