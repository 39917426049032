/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import ProfileDropdown from '@/components/ProfileDropdown';
import { fetchCompany, fetchUser } from '@/services/userApi';
import AccountPayableChart from './components/AccountPayableChart';
import AccountReceivableChart from './components/AccountReceivableChart';
import CashflowChart from './components/CashflowChart';
import TotalPayablesChart from './components/TotalPayablesChart';
import TotalReceivablesChart from './components/TotalReceivablesChart';
import { ActionButton, ActionButtonView, DashboardStyles } from './dashboard.styles';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as DSO1Icon } from '@/assets/icons/ds01.icon.svg';
import { ReactComponent as DSO2Icon } from '@/assets/icons/ds02.icon.svg';
import { ReactComponent as DSO3Icon } from '@/assets/icons/ds03.icon.svg';
import { ReactComponent as DSO4Icon } from '@/assets/icons/ds04.icon.svg';
import MobileSidebar from '@/components/MobileSidebar';
import WelcomeModal from './components/WelcomeModal';
import withAuthentication from '@/hooks/withAuthentication';
import FetchLoader from '@/components/FetchLoader';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchCompliance } from '@/services/complianceApi';
import BankAccountMetrics from './components/BankAccountMetrics';
import BVNWarnModal from '@/components/BVNWarnModal';
import { truncate } from '@/utils/helpers';

function Dashboard() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyId = secureLocalStorage.getItem('company');
  const [openNav, setOpenNav] = useState(false);
  const [isComplianceVerified, setIsComplianceVerified] = useState(false);
  const [showBVNWarning, setShowBVNWarning] = useState(false);

  const state = searchParams.get('state');

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    enabled: !!companyId,
    onError: () => {}
  });

  const user = useQuery({
    queryKey: ['dashboard-user'],
    queryFn: fetchUser,
    onSuccess: (data) => {
      if (data?.data?.complianceVerified && !data?.data?.hasComplianceBvn) {
        setShowBVNWarning(true);
      } else {
        setShowBVNWarning(false);
      }
    }
  });

  const compliance = useQuery({
    queryKey: ['compliance'],
    queryFn: fetchCompliance,
    onSuccess: (data) => {
      setIsComplianceVerified(data?.data?.isVerified);
    }
  });

  if (company?.isLoading || compliance?.isLoading || user?.isLoading) {
    return <FetchLoader />;
  }

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <DashboardStyles>
        <div className="dashboard-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{truncate(company?.data?.data?.name || '', 40, true)}</h3>
          </div>
          <ProfileDropdown companyy={company?.data?.data} />
        </div>
        <div className="dashboard-wrapper">
          <BankAccountMetrics />
          <ActionButtonView>
            <ActionButton type="button" onClick={() => navigate('/banking?tab=add-funds')}>
              Add Funds <DSO1Icon />
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => navigate('/banking?tab=transfer-funds&page=1&limit=10')}>
              Transfer Funds <DSO3Icon />
            </ActionButton>
            <ActionButton type="button" onClick={() => navigate('/make-payments/bills/new')}>
              New Bill <DSO2Icon />
            </ActionButton>
            <ActionButton type="button" onClick={() => navigate('/get-paid/invoices/new-invoice')}>
              New Invoice <DSO4Icon />
            </ActionButton>
          </ActionButtonView>
          <div className="line-chart-view">
            <TotalPayablesChart />
            <TotalReceivablesChart />
          </div>
          <CashflowChart />
          <AccountPayableChart />
          <AccountReceivableChart />
        </div>
      </DashboardStyles>

      <WelcomeModal showModal={state} />

      <BVNWarnModal showModal={showBVNWarning} setShowModal={setShowBVNWarning} />
    </>
  );
}

export default withAuthentication(Dashboard);
