/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';

export const PayablesStyles = styled.div`
  width: 80%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  .payables-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.phone} {
      padding: 20px 16px;
    }

    .title-view {
      .menu {
        display: none;
      }
      @media ${device.phone} {
        display: flex;
        align-items: center;

        .menu {
          display: block;
          margin-right: 10px;
        }
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 1rem;
          display: none;
        }
      }
    }
  }

  .payables-wrapper {
    width: 100%;
    background-color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 30px;
    border-radius: 30px;
    padding: 30px 40px;

    @media ${device.phone} {
      border-radius: 0;
      padding: 30px 16px;
    }

    .payables-wrapper__page-title {
      width: 100%;
      display: flex;
      align-items: center;

      @media ${device.phone} {
        display: none;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
        margin-left: 10px;
      }
    }

    .payables-wrapper__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      @media ${device.phone} {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .left-view {
        display: flex;
        align-items: center;

        @media ${device.phone} {
          width: 50%;
        }

        .new {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px 32px;
          border-radius: 8px;
          background-color: ${({ theme }) => theme.colors?.primary};
          color: ${({ theme }) => theme.colors?.white};
          font-size: 1rem;
          font-weight: 600;
          border: none;
          cursor: pointer;

          @media ${device.phone} {
            padding: 12px;
            font-size: 0.875rem;
            width: 100%;
            height: 50px;
            margin: 0;
          }

          svg {
            margin-right: 10px;

            path {
              fill: ${({ theme }) => theme.colors?.white};
            }
          }
        }

        .upload {
          width: 40px;
          height: 40px;
          color: ${(props) => props.theme.colors?.secondary};
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 10px;
          padding: 0;
          margin-left: 10px;

          svg {
          }

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer};
          }
        }
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }

    .payables-wrapper__tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      .tab {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 3;
        padding-bottom: 10px;
        cursor: pointer;
        border-bottom: 4px solid ${({ theme }) => theme.colors?.white};

        @media ${device.phone} {
          flex: 1;
          min-width: 150px;
          align-items: center;
        }

        h3 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 5px;

          @media ${device.phone} {
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .tab-values {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media ${device.phone} {
            display: none;
          }

          h4 {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors?.black};
          }

          p {
            font-size: 0.875rem;
            font-weight: 600;
          }
        }
      }

      .line {
        width: 1px;
        height: 35px;
        background-color: ${({ theme }) => theme.colors?.line};

        @media ${device.phone} {
          width: 2px;
        }
      }

      .active {
        border-bottom: 4px solid ${({ theme }) => theme.colors?.primary};
      }
    }

    .payables-wrapper__components {
      width: 100%;
      margin-top: 30px;
    }

    .payables-wrapper__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }
`;
export const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: 68%;
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
