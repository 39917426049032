import { device } from '@/constants/breakpoints';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchTransferBankList, resolveTransferBankAccount } from '@/services/bankingApi';
import BankListDropdown from './BankListDropdown';
import Input from '@/components/Input/Input';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import VerifyOtpModal from './VerifyOtpModal';

const AddBeneficiaryModal = ({ showModal, setShowModal }) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState({});
  const [accountData, setAccountData] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, dirtyFields }
  } = useForm();

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: fetchTransferBankList
  });

  const handleResolveBankAccount = useMutation(resolveTransferBankAccount, {
    onSuccess: (data) => {
      setAccountData({
        ...data?.data,
        bankName: selectedBank?.bankName
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      setAccountData(null);
      setSelectedBank({});
      setAccountNumber('');
    }
  });

  useEffect(() => {
    if (accountNumber.length === 10) {
      if (!selectedBank?.bankCode) {
        toast.error('Please select a bank');
        return;
      }
      handleResolveBankAccount.mutate({
        accountNumber,
        bankCode: selectedBank?.bankCode,
        provider: 'providus'
      });
    }
  }, [accountNumber, selectedBank]);

  const handleChangeInput = (event) => {
    const { value, maxLength } = event.target;
    const account = value.slice(0, maxLength);
    setAccountNumber(account);
  };

  const closeModal = () => {
    setShowModal(false);
    setAccountData(null);
    setSelectedBank({});
    setAccountNumber('');
  };

  const onSubmit = () => {
    setShowModal(false);
    setShowOtpModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <h3>Add Beneficiary</h3>
                    <button type="button" onClick={closeModal}>
                      <CloseIcon />
                    </button>
                  </div>

                  <FormView>
                    <InputView>
                      <div className="label-view">
                        <label htmlFor="">Select bank</label>
                      </div>
                      {banks.isFetching ? (
                        <div>
                          <span>Loading banks...</span>
                        </div>
                      ) : (
                        <BankListDropdown
                          options={banks?.data?.data}
                          code="bankCode"
                          name="bankName"
                          setValue={setSelectedBank}
                          value={selectedBank}
                          placeholder="Select bank"
                        />
                      )}
                    </InputView>
                    <InputView>
                      <div className="label-view">
                        <label htmlFor="">Account Number</label>
                      </div>
                      <Input
                        type="number"
                        maxLength="10"
                        placeholder="Enter account number"
                        value={accountNumber}
                        onChange={(e) => handleChangeInput(e)}
                      />
                    </InputView>

                    <div className="loader">
                      {handleResolveBankAccount.isLoading && (
                        <Oval
                          color="#92C22C"
                          secondaryColor="#EEFFC9"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      )}
                    </div>

                    {accountData && (
                      <div className="name">
                        <span>Account name</span>
                        <p>{accountData?.accountName}</p>
                      </div>
                    )}

                    {/* ===== CTA ====== */}
                    <div className="action-btn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={!accountData}
                        onClick={handleSubmit(onSubmit)}>
                        Save as beneficiary
                      </button>
                      <button type="button" className="btn btn-outline" onClick={closeModal}>
                        Cancel
                      </button>
                    </div>
                  </FormView>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <VerifyOtpModal
        showModal={showOtpModal}
        setShowModal={setShowOtpModal}
        selectedAccount={accountData}
        setSelectedAccount={setAccountData}
      />
    </>
  );
};

export default AddBeneficiaryModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 300px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  @media ${device.phone} {
    padding: 20px 16px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const FormView = styled.form`
  width: 100%;
  margin-top: 30px;

  .name {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 50px;

    button {
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      width: 40%;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      width: 60%;
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .fees {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.primary};
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;
