import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import {
  ButtonView,
  ContentView,
  FormView,
  HeadingView,
  InputView,
  RegisterView,
  ResetPasswordView,
  TitleView
} from './forgotPassword.styles';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import Input from '@/components/Input/Input';
import Button from '@/components/Button/Button';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '@/services/authApi';
import { Oval } from 'react-loader-spinner';
import theme from '@/constants/theme';
import toast from 'react-hot-toast';
import useAuthentication from '@/hooks/useAuthentication';

const schema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required')
  })
  .required();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { authenticated } = useAuthentication();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const handleForgotPassword = useMutation(forgotPassword, {
    onSuccess: () => {
      toast.success('Password reset link sent to your email');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const isValid = await trigger();
        if (isValid) {
          handleForgotPassword.mutate({ email: data.email });
        }
      } catch (error) {
        // Handle error and display appropriate error message to the user
      }
    },
    [trigger]
  );

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <ResetPasswordView data-testid="reset-view">
      <HeadingView>
        <button type="button" className="back" onClick={() => navigate('/login')}>
          <BackIcon />
        </button>
        <Logo data-testid="logo-icon" />
        <span />
      </HeadingView>

      <ContentView>
        <TitleView>
          <h3>Forgot your password?</h3>
          <p>Enter your company email address and we’ll send you a link to reset it.</p>
        </TitleView>

        <FormView onSubmit={handleSubmit(onSubmit)}>
          <InputView>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              placeholder="Enter email"
              {...register('email')}
              error={!!errors?.email?.message}
            />

            {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
          </InputView>

          <ButtonView>
            <Button width="100%" height="64px" type="submit">
              {handleForgotPassword.isLoading ? (
                <Oval
                  color={theme.colors.white}
                  secondaryColor={theme.colors.primaryFocus}
                  height={30}
                  width={30}
                  strokeWidth={4}
                />
              ) : (
                'Continue'
              )}
            </Button>
          </ButtonView>
        </FormView>

        <RegisterView>
          <span>Return to </span>
          <Link to="/login" aria-label="Login">
            Login
          </Link>
        </RegisterView>
      </ContentView>
    </ResetPasswordView>
  );
};

export default ForgotPassword;
