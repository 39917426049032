import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import {
  ButtonView,
  ContentView,
  FormView,
  HeadingView,
  InputView,
  PasswordInputView,
  RegisterView,
  ResetPasswordView,
  TitleView
} from './resetPassword.styles';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import { ReactComponent as EyeeIcon } from '@/assets/icons/eye.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off.icon.svg';
import Input from '@/components/Input/Input';
import Button from '@/components/Button/Button';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from '@/services/authApi';
import { Oval } from 'react-loader-spinner';
import theme from '@/constants/theme';
import toast from 'react-hot-toast';
import useAuthentication from '@/hooks/useAuthentication';
import { useDispatch } from 'react-redux';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';

const schema = yup
  .object({
    password: yup
      .string()
      .min(8, 'Password should not be less than 8 characters long')
      .required('Password is required')
  })
  .required();

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const { authenticated } = useAuthentication();

  const email = searchParams.get('email');
  const modifiedEmail = email.replace(/ /g, '+');
  const token = searchParams.get('token');

  useEffect(() => {
    if (!authenticated) {
      localStorage.clear();
      dispatch(resetAppStateOnLogout());
    }
  }, [authenticated]);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: ''
    }
  });

  const handleResetPassword = useMutation(resetPassword, {
    onSuccess: () => {
      toast.success('Password reset successful');
      navigate('/login');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        const isValid = await trigger();
        if (isValid) {
          handleResetPassword.mutate({ email: modifiedEmail, token, password: data.password });
        }
      } catch (error) {
        // Handle error and display appropriate error message to the user
      }
    },
    [trigger]
  );

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <ResetPasswordView data-testid="reset-view">
      <HeadingView>
        <button type="button" className="back" onClick={() => navigate('/login')}>
          <BackIcon />
        </button>
        <Logo data-testid="logo-icon" />
        <span />
      </HeadingView>

      <ContentView>
        <TitleView>
          <h3>
            Reset password for <span>{modifiedEmail}</span>
          </h3>
          <p>Enter your new password below.</p>
        </TitleView>

        <FormView onSubmit={handleSubmit(onSubmit)}>
          <PasswordInputView>
            <label htmlFor="password">Password</label>
            <div className="input">
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter password"
                {...register('password')}
                error={!!errors?.password?.message}
              />
              <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeOffIcon /> : <EyeeIcon />}
              </button>
            </div>
            {errors?.password && <span className="error-label">{errors?.password?.message}</span>}
            <div className="password-info">
              <span>
                *Password must be at least 8 characters long and contain at least one uppercase
                letter, one lowercase letter.
              </span>
            </div>
          </PasswordInputView>

          <ButtonView>
            <Button width="100%" height="64px" type="submit">
              {handleResetPassword.isLoading ? (
                <Oval
                  color={theme.colors.white}
                  secondaryColor={theme.colors.primaryFocus}
                  height={30}
                  width={30}
                  strokeWidth={4}
                />
              ) : (
                'Continue'
              )}
            </Button>
          </ButtonView>
        </FormView>

        <RegisterView>
          <span>Return to </span>
          <Link to="/login" aria-label="Login">
            Login
          </Link>
        </RegisterView>
      </ContentView>
    </ResetPasswordView>
  );
};

export default ResetPassword;
