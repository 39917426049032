import Pagination from '@/components/Pagination';
import { device } from '@/constants/breakpoints';
import { numberWithCommas } from '@/utils/helpers';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/fileUpload.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import useDebounce from '@/hooks/useDebounce';
import SortingComponent from '@/components/SortingComponent';
import FilterSort from '@/components/FilterSort';
import TableLoader from '@/components/TableLoader';
import { deleteRecurring, fetchRecurringInvoices } from '@/services/recurringApi';
import { useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from '@/hooks/useClickOutside';
import toast from 'react-hot-toast';
import ModalLoader from '@/components/ModalLoader';
import InvoiceDetails from './InvoiceDetails';
// import ExportModal from './ExportModal';

const statusClassMap = {
  successful: 'successful',
  failed: 'failed',
  pending: 'pending'
};

const RecurringInvoices = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedBill, setSelectedBill] = useState({});
  const [showExportModal, setShowExportModal] = useState(false);
  const PAGE_LIMIT = 10;
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowMenu(false));

  const handleFilterApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  const bills = useQuery({
    queryKey: [
      'recurringInvoices',
      {
        page: page,
        limit: PAGE_LIMIT,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchRecurringInvoices
  });

  const handleCancelRecurringBill = useMutation(({ id }) => deleteRecurring(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      queryClient.invalidateQueries({ queryKey: ['recurringInvoices'] });
      toast.success('Recurring invoice cancelled successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onPageChange = (page) => {
    setSearchParams(`tab=recurring&view=invoices&page=${page}`);
  };

  return (
    <>
      <RecurringInvoicesStyles>
        <h3>Recurring Bills</h3>
        <div className="filter-view">
          <SearchView>
            <input
              type="search"
              placeholder="Search invoices"
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className="search-icon" />
          </SearchView>
          <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
          <FilterSort onApply={handleFilterApply} />
          {/* <button type="button" className="upload" onClick={() => setShowExportModal(true)}>
            <FileIcon />
          </button> */}
        </div>
        {bills?.isLoading ? (
          <TableLoader />
        ) : (
          <>
            <TableView>
              <TableHeader>
                <div className={`item recipient`}>
                  <span>Customer </span>
                </div>
                {/* <div className={`item ref`}>
          <span>Reference </span>
        </div> */}
                <div className={`item amount`}>
                  <span>Amount </span>
                </div>
                <div className={`item type`}>
                  <span>Frequency </span>
                </div>
                <div className={`item date`}>
                  <span>Date </span>
                </div>
                <div className="item action">
                  <span></span>
                </div>
              </TableHeader>

              <TableBody>
                {bills?.data?.data?.data?.map((bill, i) => (
                  <TableRow key={bill?.id}>
                    <div className="item recipient">
                      <span>{bill?.customer?.companyName || bill?.customer?.name}</span>
                    </div>
                    {/* <div className="item ref">
              <span>{truncate(bill?.identifier || '', 25, false)}</span>
            </div> */}
                    <div className="item amount">
                      <span>₦{numberWithCommas(bill?.amount || '00.00')}</span>
                    </div>

                    <div className="item type">
                      <span>{bill?.frequency}</span>
                    </div>

                    <div className="item date">
                      <span>{format(new Date(bill?.createdAt), 'do MMM, yyyy')}</span>
                    </div>

                    <div className="item action">
                      <button
                        type="button"
                        className="action-btn"
                        onClick={() => {
                          setShowMenu(true);
                          setCurrentIndex(i);
                          setSelectedBill(bill);
                        }}>
                        <MoreIcon className="more-icon" />
                      </button>

                      <AnimatePresence>
                        {showMenu && currentIndex === i ? (
                          <motion.div
                            className="menu-actions"
                            ref={ref}
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}>
                            <button
                              type="button"
                              onClick={() => {
                                setShowViewModal(true);
                              }}>
                              View bill
                            </button>
                            <button
                              type="button"
                              onClick={() => handleCancelRecurringBill.mutate({ id: bill?.id })}>
                              Cancel recurring bill
                            </button>
                          </motion.div>
                        ) : null}
                      </AnimatePresence>
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </TableView>

            <div className="listing">
              {bills?.data?.data?.data?.map((bill, i) => (
                <div className={`item`} key={bill?.id}>
                  <div className="col1">
                    <span className="name">
                      {bill?.customer?.companyName || bill?.customer?.name}
                    </span>
                    <span className="type">{bill?.frequency}</span>
                    <span className="date">
                      {format(new Date(bill?.createdAt), 'do MMM, yyyy, h:mm a')}
                    </span>
                    <span className="id">{bill?.reference}</span>
                  </div>
                  <button
                    type="button"
                    className="btn more"
                    onClick={() => {
                      setShowMenu(true);
                      setCurrentIndex(i);
                      setSelectedBill(bill);
                    }}>
                    <MoreIcon className="more-icon" />
                  </button>

                  <AnimatePresence>
                    {showMenu && currentIndex === i ? (
                      <motion.div
                        className="menu-actions"
                        ref={ref}
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        <button
                          type="button"
                          onClick={() => {
                            setShowViewModal(true);
                          }}>
                          View invoice
                        </button>
                        <button
                          type="button"
                          onClick={() => handleCancelRecurringBill.mutate({ id: bill?.id })}>
                          Cancel recurring invoice
                        </button>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                  <span className="amount">₦{numberWithCommas(bill?.amount || '00.00')}</span>
                </div>
              ))}
            </div>

            <div className="pagination">
              <Pagination
                itemsPerPage={PAGE_LIMIT}
                totalItems={bills?.data?.data?.meta?.totalItems}
                currentPage={parseInt(page)}
                onPageChange={onPageChange}
              />
            </div>
          </>
        )}
      </RecurringInvoicesStyles>

      <InvoiceDetails
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        billId={selectedBill?.id}
      />

      {handleCancelRecurringBill.isLoading && (
        <ModalLoader message="Cancelling recurring invoice..." />
      )}

      {/* <ExportModal showModal={showExportModal} setShowModal={setShowExportModal} /> */}
    </>
  );
};

export default RecurringInvoices;

const RecurringInvoicesStyles = styled.div`
  width: 100%;

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
    margin-bottom: 20px;
  }

  .filter-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .upload {
      width: 40px;
      height: 40px;
      color: ${(props) => props.theme.colors?.secondary};
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 10px;
      padding: 0;

      svg {
      }

      &:hover {
        background-color: ${(props) => props.theme.colors?.layer};
      }
    }
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .name {
          font-size: 0.9rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .type {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
          text-transform: capitalize;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }

        .account {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .action-btn {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        border: none;
        background-color: ${(props) => props.theme.colors?.layer2};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }

        .more-icon {
          width: 20px;
          height: 20px;
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 220px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const TableView = styled.div`
  width: 100%;
  margin-top: 20px;

  @media ${device.phone} {
    display: none;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};

  .item {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &.recipient {
      flex: 1.5;
    }

    &.ref {
      flex: 1.2;
    }

    &.amount {
      flex: 0.8;
    }

    &.type {
      flex: 0.7;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }

    &.action {
      flex: 0.5;
    }
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
      text-transform: capitalize;
    }

    &.recipient {
      flex: 1.5;

      span {
        word-wrap: break-word;
      }
    }

    &.ref {
      flex: 1.2;
      flex-wrap: wrap;
      word-wrap: break-word;
    }

    &.amount {
      flex: 0.8;
    }

    &.type {
      flex: 0.7;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    &.successful {
      span {
        color: ${(props) => props.theme.colors?.success};
      }
    }

    &.failed {
      span {
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    &.pending {
      span {
        color: ${(props) => props.theme.colors?.warning};
      }
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }

    &.action {
      flex: 0.5;
      justify-content: flex-end;
    }
  }

  .date {
    flex: 0.6;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @media ${device.phone} {
      margin-bottom: 20px;
    }

    .action-btn {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      cursor: pointer;

      .more-icon {
        width: 20px;
        height: 20px;
      }
    }

    .menu-actions {
      position: absolute;
      top: 10px;
      right: 0px;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 220px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: transparent;
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }
  }
`;

const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: 68%;
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
