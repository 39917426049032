import React from 'react';
import { useLottie } from 'lottie-react';
import loadAnimation from '../assets/lottie-logo.json';
import styled from 'styled-components';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import secureLocalStorage from 'react-secure-storage';
import { fetchUserToken } from '@/services/authApi';
import { fetchUser } from '@/services/userApi';

const Home = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const uid = searchParams.get('uid');
  const queryClient = useQueryClient();

  const options = {
    animationData: loadAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  const userToken = useQuery({
    queryKey: ['user-token', { userId: uid }],
    queryFn: fetchUserToken,
    enabled: !!uid
  });

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    enabled: !!uid && userToken.isSuccess
  });

  if (user?.isSuccess) {
    console.log(user?.data?.data?.company?.id);

    secureLocalStorage.setItem('company', user?.data?.data?.company?.id);
  }

  const getUser = async () => {
    const user = await queryClient.fetchQuery({
      queryKey: ['user'],
      queryFn: fetchUser
    });

    if (user) {
      const company = user?.data?.company?.id;
      if (company) {
        secureLocalStorage.setItem('company', company);
        secureLocalStorage.setItem('auth', true);
        navigate('/dashboard', { replace: true });
      } else {
        secureLocalStorage.setItem('auth', true);
        navigate('/onboarding?page=company-name', { replace: true });
      }
    }
  };

  if (userToken?.isSuccess) {
    secureLocalStorage.setItem('ut', userToken?.data?.data?.accessToken);
    secureLocalStorage.setItem('rt', userToken?.data?.data?.refreshToken);
    secureLocalStorage.setItem('auth', true);
    getUser();

    navigate('/dashboard', { replace: true });
  }

  if (userToken?.error) {
    toast.error('Your session has expired, please login again');
    return <Navigate to="/login" />;
  }

  if (!uid) {
    return <Navigate to="/login" />;
  }

  return (
    <LoadingView>
      {userToken.isLoading ? <LoadingContent>{View}</LoadingContent> : null}
    </LoadingView>
  );
};

export default Home;

const LoadingView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme?.colors?.background};
`;

const LoadingContent = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
