import api from './api';

/**
 * * Submits the compliance data
 * @param {*} data - Compliance data
 * @returns {Promise} - Returns the created compliance
 */
export const submitCompliance = async (data) => {
  const response = await api.put(`/profile/compliance/submit`, data);
  return response.data;
};

/**
 * * Fetches the compliance data
 * @returns {Promise} - Returns the compliance data
 */
export const fetchCompliance = async () => {
  const response = await api.get(`/profile/compliance`);
  return response.data;
};
