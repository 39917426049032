import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { device } from '@/constants/breakpoints';
import { fetchUserInfo } from '@/services/billFinancingApi';
import FetchLoader from '@/components/FetchLoader';
import UserInfo from './UserInfo';
import { useState } from 'react';
import BusinessInfo from './BusinessInfo';
import ConfirmLoanModal from './ConfirmLoanModal';
import SupportingDocs from './SupportingDocs';

function ReviewDetailsModal({ showModal, setShowModal }) {
  const [currentTab, setCurrentTab] = useState(0);
  const [gender, setGender] = useState(null);

  const userInfo = useQuery({
    queryKey: ['userInfo'],
    queryFn: fetchUserInfo,
    onSuccess: () => {},
    onError: () => {}
  });

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======= Content here ====== */}
              <div className="header-view">
                <button type="button" className="back" onClick={() => setShowModal(false)}>
                  <BackIcon />
                </button>
              </div>

              {userInfo.isLoading ? (
                <FetchLoader />
              ) : (
                <WrapperView>
                  <TitleView>
                    <h1>Purchase Financing</h1>
                    <p>Make payment via loan</p>
                  </TitleView>

                  <TabsView>
                    <div className="tabs">
                      <button type="button" className={currentTab === 0 ? 'active' : 'tab'}>
                        Contact details
                      </button>
                      <button type="button" className={currentTab === 1 ? 'active' : 'tab'}>
                        Business details
                      </button>
                      <button type="button" className={currentTab === 2 ? 'active' : 'tab'}>
                        Supporting Doc.
                      </button>
                    </div>

                    <div className="tab-content">
                      {currentTab === 0 && (
                        <UserInfo
                          userInfo={userInfo.data?.data}
                          gender={gender}
                          setGender={setGender}
                          setCurrentTab={setCurrentTab}
                        />
                      )}
                      {currentTab === 1 && (
                        <BusinessInfo
                          userInfo={userInfo.data?.data}
                          setCurrentTab={setCurrentTab}
                        />
                      )}
                      {currentTab === 2 && <SupportingDocs setCurrentTab={setCurrentTab} />}

                      {/* <div className="continue">
                        {currentTab > 0 && (
                          <button
                            type="button"
                            className="prev"
                            onClick={() => setCurrentTab((prev) => prev - 1)}>
                            Previous
                          </button>
                        )}
                        {currentTab < 2 && (
                          <button
                            type="button"
                            onClick={() => {
                              if (currentTab === 0 && gender === null) {
                                toast.error('Please select your gender');
                              } else {
                                setCurrentTab((prev) => prev + 1);
                              }
                            }}>
                            Next
                          </button>
                        )}
                        {currentTab === 2 && (
                          <button type="button" onClick={() => setShowAgreeModal(true)}>
                            Continue
                          </button>
                        )}
                      </div> */}
                    </div>
                  </TabsView>
                </WrapperView>
              )}
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default ReviewDetailsModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
`;

const WrapperView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;

  @media ${device.phone} {
    flex-direction: column;
  }
`;

const TitleView = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.secondary};
    line-height: 19px;
  }
`;

const TabsView = styled.div`
  width: 45%;

  @media ${device.phone} {
    width: 100%;
  }

  .tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid ${({ theme }) => theme.colors?.line};

    button {
      width: 48%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      border: none;
      cursor: pointer;
      transition: 0.3s ease;
    }

    .active {
      border-bottom: 2px solid ${({ theme }) => theme.colors?.primary};
      transition: 0.3s ease;
    }
  }

  .tab-content {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;

    .continue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 70px;

      button {
        width: 180px;
        height: 56px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: 0.3s ease;
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        border-radius: 16px;

        &:hover {
          background-color: ${({ theme }) => theme.colors?.primaryDark};
        }
      }

      .prev {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.primary};
        margin-right: 20px;
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }
    }
  }
`;
