/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import { createSecurityPin } from '@/services/securityApi';
import Button from '@/components/Button/Button';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

export default function CreatePinModal({ showModal, setShowModal, billId }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [confirmedPin, setConfirmedPin] = useState('');
  const [showConfirmPin, setShowConfirmPin] = useState(false);

  const handleCreatePin = useMutation(createSecurityPin, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      toast.success('Transaction PIN created successfully');
      setShowModal(false);
      navigate(`/make-payments/bills/pay?id=${billId}`);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const formData = {
      type: 't-pin',
      value: confirmedPin
    };

    if (pin === confirmedPin) {
      handleCreatePin.mutate(formData);
    } else {
      toast.error('Transaction PINs do not match');
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <span></span>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <TransactionPinView>
                  <TitleView>
                    <h3>Create transaction PIN</h3>
                    <p>
                      To continue, please create a transaction PIN. This PIN will be used to
                      authorize all transactions on your account.
                    </p>
                  </TitleView>

                  {!showConfirmPin && (
                    <FormView>
                      {/* Email */}
                      <div className="input-view">
                        <div className="label-view">
                          <label htmlFor="email">Enter transaction PIN</label>
                        </div>
                        <OTPInput
                          value={pin}
                          onChange={setPin}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          containerStyle="otp-input"
                          inputType="number"
                          skipDefaultStyles
                          shouldAutoFocus
                        />
                      </div>

                      {/* Button view */}
                      <div className="button-view">
                        <Button
                          type="button"
                          styleType="primary"
                          width="100%"
                          onClick={() => setShowConfirmPin(true)}
                          disabled={pin.length < 6}>
                          Continue
                        </Button>
                      </div>
                    </FormView>
                  )}

                  {/* Re-enter transaction PIN */}
                  {showConfirmPin && (
                    <FormView>
                      {/* Email */}
                      <div className="input-view">
                        <div className="label-view">
                          <label htmlFor="email">Confirm transaction PIN</label>
                        </div>
                        <OTPInput
                          value={confirmedPin}
                          onChange={setConfirmedPin}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          containerStyle="otp-input"
                          inputType="number"
                          skipDefaultStyles
                          shouldAutoFocus
                        />
                      </div>

                      {/* Button view */}
                      <div className="button-view">
                        <buttton
                          className="back-button"
                          type="button"
                          onClick={() => setShowConfirmPin(false)}>
                          Back
                        </buttton>
                        <Button
                          type="button"
                          styleType="primary"
                          width="70%"
                          onClick={onSubmit}
                          disabled={confirmedPin.length < 6}>
                          {handleCreatePin.isLoading ? (
                            <Oval
                              color="#FFF"
                              secondaryColor="#ddd"
                              height={30}
                              width={30}
                              strokeWidth={4}
                              ariaLabel="loading"
                            />
                          ) : (
                            'Create transaction PIN'
                          )}
                        </Button>
                      </div>
                    </FormView>
                  )}
                </TransactionPinView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const TransactionPinView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }
`;

const FormView = styled.form`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
  }

  .input-view {
    width: 100%;
    margin-bottom: 26px;

    .label-view {
      width: 100%;
      display: flex;
      margin-bottom: 8px;

      label {
        font-size: 1rem;
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 8px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }
  }

  .otp-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    input {
      width: calc(100% / 6 - 10px);
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      text-align: center;
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      border: none;
      -webkit-text-security: disc;

      &:focus {
        outline: 1px solid ${({ theme }) => theme.colors?.primary};
        border: none;
      }
    }
  }

  .button-view {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;

    @media ${device.phone} {
      width: 100%;
    }

    button {
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .back-button {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
