/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';

export const SettingsStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  .settings-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.phone} {
      padding: 20px 16px;
    }

    .title-view {
      .menu {
        display: none;
      }
      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 0.875rem;
          display: none;
        }
      }

      @media ${device.phone} {
        display: flex;
        align-items: center;

        .menu {
          display: block;
          margin-right: 10px;
        }
      }
    }
  }

  .settings-wrapper {
    width: 100%;
    min-height: 80vh;
    background-color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 30px;
    border-radius: 30px;
    overflow: hidden;

    @media ${device.phone} {
      border-radius: 0;
    }
  }
`;

export const ProfileHeader = styled.div`
  width: 100%;
  height: 200px;
  background-color: ${({ theme }) => theme.colors?.secondary};
  position: relative;
  overflow: hidden;
  z-index: 10;

  @media ${device.phone} {
    height: 150px;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ProfilePhotoView = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  padding: 0 70px;
  position: relative;
  z-index: 10;
  display: none;

  @media ${device.phone} {
    padding: 0px 16px;
    width: 100%;
    height: 100%;
  }

  .profile-photo {
    width: 20%;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;

    @media ${device.phone} {
      width: 60px;
      height: 60px;
      top: -30px;
    }

    img {
      width: 100px;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;

      @media ${device.phone} {
        width: 60px;
        height: 60px;
      }
    }
  }

  .gap {
    width: 20%;
  }

  .profile-name {
    width: 60%;
    margin-top: 40px;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;

export const ProfileContentView = styled.div`
  width: 100%;
  padding: 0px 70px;
  padding-bottom: 30px;
  display: flex;
  margin-top: 30px;

  @media ${device.phone} {
    padding: 20px 16px;
    padding-top: 40px;
    flex-direction: column;
  }
`;

export const TabsView = styled.div`
  width: 20%;
  border-right: 1px solid ${({ theme }) => theme.colors?.line};
  padding-right: 30px;

  @media ${device.phone} {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    border: none;
  }

  .tab-item {
    width: 100%;
    height: 44px;
    margin-bottom: 30px;

    @media ${device.phone} {
      margin-bottom: 0;
      margin-right: 10px;
      width: 200px;
    }

    button {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.info};
      transition: all 0.3s ease-in-out;
      padding: 12px;
      border-radius: 8px;
      text-align: left;

      @media ${device.phone} {
        padding: 0;
        width: 250px;
        justify-content: center;
      }

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: ${({ theme }) => theme.colors?.primaryFocus};
    }
  }
`;

export const InfoView = styled.div`
  width: 80%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
  }
`;
