import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilePicker2 from '@/components/FilePicker2';
import { ReactComponent as FileIcon } from '@/assets/icons/file2.icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { appSelector, setPayLaterData } from '@/redux/features/app.slice';
import ConfirmLoanModal from './ConfirmLoanModal';
import { toast } from 'react-hot-toast';
import SearchDropdown from '@/components/SearchDropdown';
import { documentTypes } from '@/utils/data.utils';
import Input from '@/components/Input/Input';

const schema = yup
  .object({
    companyName: yup.string().required('Related company name is required')
  })
  .required();

const SupportingDocs = ({ setCurrentTab }) => {
  const { loanBill } = useSelector(appSelector);
  const [documents, setDocuments] = useState([
    {
      documentType: 'domiciliation',
      url: ''
    }
  ]);
  const [optionalDocuments, setOptionalDocuments] = useState([]);
  const [docType, setDocType] = useState('');
  const [optionalDocument, setOptionalDocument] = useState({
    documentType: '',
    url: ''
  });

  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const dispatch = useDispatch();
  const { payLaterData } = useSelector(appSelector);




  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: loanBill?.customer?.companyName
    }
  });

  useEffect(() => {
    reset({
      companyName: loanBill?.customer?.companyName
    });
  }, [reset, loanBill]);

  const handleFileChange = (url, index) => {
    setDocuments((prevDocuments) => {
      const updatedDocuments = [...prevDocuments];
      updatedDocuments[index].url = url;
      return updatedDocuments;
    });
  };

  const handleOptionalFileChange = (url, docType) => {
    setOptionalDocument({
      documentType: docType?.value,
      url: url
    });
    setOptionalDocuments((prevOptionalDocuments) => [
      ...prevOptionalDocuments,
      {
        documentType: docType?.value,
        url: url
      }
    ]);
  };

  const handleAddOptionalDocument = () => {
    setOptionalDocument({ documentType: '', url: '' });
    setDocType('');
  };

  const handleDeleteOptionalDocument = (index) => {
    setOptionalDocuments((prevOptionalDocuments) =>
      prevOptionalDocuments.filter((_, i) => i !== index)
    );
  };

  const handleTemplateDownload = (url, filename) => {
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      });
  };

  const handleContinue = (data) => {
    if (documents[0].url) {
      dispatch(
        setPayLaterData({
          ...payLaterData,
          documents: [...documents, ...optionalDocuments],
          companyName: data?.companyName
        })
      );
      setShowAgreeModal(true);
    } else toast.error('Please upload the domiciliation letter');
  };

  return (
    <FormView>
      <form>
        <div className="template-view">
          <div className="label-view">
            <label htmlFor="domLetter">Download Domiciliation Letter Template</label>
          </div>
          <div className="template">
            <div className="left">
              <FileIcon />
              <span>
                Domiciliation Letter <br /> <strong>14.9KB</strong>
              </span>
            </div>
            <div className="right">
              <button
                type="button"
                onClick={() => {
                  handleTemplateDownload(
                    'https://res.cloudinary.com/billboxx-dev/raw/upload/v1711025353/Irrevocable_Domiciliation_Letter_grrwsd.docx',
                    'Domiciliation_Letter_Template.docx'
                  );
                }}>
                Download
              </button>
            </div>
          </div>
        </div>

        {/* Render the default document picker */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor={`doc-${0}`}>Upload Domiciliation Letter</label>
          </div>
          <FilePicker2
            file={documents[0].url}
            setFile={(url) => handleFileChange(url, 0)}
            acceptsPDF
          />
        </div>

        <div className="input-view">
          <div className="label-view">
            <label htmlFor="companyName">Related company</label>
          </div>
          <Input
            type="text"
            placeholder="Enter related company name"
            {...register('companyName')}
            error={errors?.companyName?.message}
          />
          {errors?.companyName && (
            <span className="error-label">{errors?.companyName?.message}</span>
          )}
        </div>

        <div className="input-view">
          <div className="label-view">
            <label htmlFor="website">Supporting Document type</label>
          </div>
          <SearchDropdown
            options={documentTypes}
            id="id"
            name="title"
            setValue={setDocType}
            value={docType}
            placeholder="Select document type"
          />
        </div>

        {docType?.value && (
          <div className="input-view">
            <div className="label-view">
              <label htmlFor={`optional-doc`}>Upload {docType?.title}</label>
            </div>
            <FilePicker2
              file={optionalDocument.url}
              setFile={(url) => handleOptionalFileChange(url, docType)}
              acceptsPDF
            />
            <button type="button" className="add" onClick={handleAddOptionalDocument}>
              Add another document
            </button>
          </div>
        )}

        {optionalDocuments.length > 0 && (
          <div className="optional-documents">
            <h3>Supporting Documents:</h3>
            <ul>
              {optionalDocuments.map((doc, index) => (
                <li key={index}>
                  <div className="icon">
                    <FileIcon />
                  </div>
                  <a href={doc?.url} target="_blank" rel="noopener noreferrer">
                    {doc.documentType}-document
                  </a>
                  <button
                    type="button"
                    className="delete"
                    onClick={() => handleDeleteOptionalDocument(index)}>
                    <DeleteIcon />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="continue">
          <button type="button" className="prev" onClick={() => setCurrentTab((prev) => prev - 1)}>
            Previous
          </button>
          <button type="button" onClick={handleSubmit(handleContinue)}>
            Continue
          </button>
        </div>
      </form>

      {/* Agree to terms modal */}
      <ConfirmLoanModal showModal={showAgreeModal} setShowModal={setShowAgreeModal} />
    </FormView>
  );
};

export default SupportingDocs;

const FormView = styled.div`
  width: 100%;

  form {
    width: 100%;

    .add {
      background-color: transparent;
      color: ${({ theme }) => theme.colors?.primary};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      text-decoration: underline;
      margin-top: 10px;
    }

    .input-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      input {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .delete {
        position: absolute;
        bottom: -20px;
        right: 0;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 5px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .template-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;

      label {
        font-size: 0.875rem;
        font-weight: 400;
      }

      .template {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: ${({ theme }) => theme.colors?.layer3};
        border-radius: 16px;
        margin-top: 10px;

        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }

          span {
            font-size: 0.875rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
            line-height: 18px;
          }
        }

        .right {
          button {
            width: 100px;
            height: 36px;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.875rem;
            font-weight: 500;
            border: none;
            cursor: pointer;
            transition: 0.3s ease;
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            border-radius: 8px;

            &:hover {
              background-color: ${({ theme }) => theme.colors?.primaryDark};
            }
          }
        }
      }
    }

    .optional-documents {
      width: 100%;
      margin-bottom: 30px;

      ul {
        width: 100%;
        margin-top: 10px;

        li {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme.colors?.layer3};
            margin-right: 10px;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          a {
            font-size: 1.2rem;
            font-weight: 500;
            color: ${({ theme }) => theme.colors?.secondary};
            text-decoration: underline;
          }

          .delete {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme.colors?.layer3};
            margin-left: 10px;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s ease;

            svg {
              width: 20px;
              height: 20px;
            }

            &:hover {
              background-color: ${({ theme }) => theme.colors?.layer2};
            }
          }
        }
      }

      .continue {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 70px;

        button {
          width: 180px;
          height: 56px;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-weight: 500;
          border: none;
          cursor: pointer;
          transition: 0.3s ease;
          background-color: ${({ theme }) => theme.colors?.primary};
          color: ${({ theme }) => theme.colors?.white};
          border-radius: 16px;

          &:hover {
            background-color: ${({ theme }) => theme.colors?.primaryDark};
          }
        }

        .prev {
          background-color: transparent;
          color: ${({ theme }) => theme.colors?.primary};
          margin-right: 20px;
          border: 1px solid ${({ theme }) => theme.colors?.primary};
        }
      }
    }
  }
`;
