/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';

function PreviewInvoice({ showModal, setShowModal, company, user, customer, previewData }) {
  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={() => setShowModal(false)}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Invoice</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <InvoiceBody>
                {/* PDF HEADER */}
                <InvoiceHeader>
                  <div className="logo-view">
                    {/* Company logo */}
                    {company?.logoUrl ? (
                      <div className="logo-image">
                        <img src={company?.logoUrl} alt="Company logo" />
                      </div>
                    ) : (
                      <div className="logo">
                        <span>
                          {user?.firstName?.charAt(0) || ''}
                          {user?.lastName?.charAt(0) || ''}
                        </span>
                      </div>
                    )}

                    {/* Company address */}
                    <div className="address-view">
                      <h3>{company?.name}</h3>
                      <p>{company?.address || ''}</p>
                      <p>{user?.phoneNumber || ''}</p>
                      <p>{user?.email}</p>
                    </div>
                  </div>

                  <div className="title-view">
                    <h2>Invoice</h2>
                  </div>
                </InvoiceHeader>

                {/* Divider */}
                <Divider>
                  <div className="line"></div>
                </Divider>

                {/* PDF CONTENT */}
                <InvoiceContent>
                  <div className="row-view">
                    {/* <!-- Bill to */}
                    <div className="column-view">
                      <span>Bill to:</span>
                      <p>{customer?.companyName}</p>
                    </div>

                    {/* Invoice number */}
                    <div className="column-view-right">
                      <span>Invoice no:</span>
                      <p>{previewData?.billOrInvoiceNumber}</p>
                    </div>
                  </div>
                  <div className="row-view">
                    {/* <!-- Bill to */}
                    <div className="column-view">
                      {previewData?.subject && (
                        <>
                          <span>Subject:</span>
                          <p>{parse(previewData?.subject) || ''}</p>
                        </>
                      )}
                    </div>

                    {/* Invoice number */}
                    <div className="column-view-right">
                      <span>Due date:</span>
                      <p>{format(new Date(previewData?.dueDate), 'do MMM, yyyy')}</p>
                    </div>
                  </div>

                  {/* Table */}
                  <Table>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Rate(NGN)</th>
                      <th>Amount(NGN)</th>
                    </tr>
                    {previewData?.items?.map((item) => (
                      <tr className="table-row" key={item?.name}>
                        <td>{item?.name}</td>
                        <td>{numberWithCommas2(item?.quantity || '0')}</td>
                        <td>{numberWithCommas(item?.rate || '00.00')}</td>
                        <td>{numberWithCommas(item?.amount || '00.00')}</td>
                      </tr>
                    ))}
                  </Table>

                  {/* Total summary */}
                  <TotalSummary>
                    <div className="total">
                      <span>Sub Total:</span>
                      <p>NGN {numberWithCommas(previewData?.subtotal || '00.00')}</p>
                    </div>
                    <div className="total">
                      <span>
                        Discount{previewData?.discountType === 'percentage' ? '(%)' : '(NGN)'}:
                      </span>
                      {previewData?.discountType === 'percentage' ? (
                        <p>NGN {numberWithCommas(previewData?.discount || '0')}</p>
                      ) : (
                        <p>NGN {numberWithCommas(previewData?.discount || '00.00')}</p>
                      )}
                    </div>
                    <div className="total">
                      <span>VAT{previewData?.taxType === 'percentage' ? '(%)' : '(NGN)'}:</span>
                      {previewData?.taxType === 'percentage' ? (
                        <p>NGN {numberWithCommas(previewData?.tax || '0')}</p>
                      ) : (
                        <p>NGN {numberWithCommas(previewData?.tax || '00.00')}</p>
                      )}
                    </div>
                    <div className="total-total">
                      <span>Total:</span>
                      <p>NGN {numberWithCommas(previewData?.total || '00.00')}</p>
                    </div>
                  </TotalSummary>
                </InvoiceContent>

                {/* FOOTER */}
                <InvoiceFooter>
                  {/* DIVIDER */}
                  <Divider>
                    <div className="line"></div>
                  </Divider>

                  {previewData?.note && (
                    <div className="note-view">
                      <span>Note</span>
                      <p>{parse(previewData?.note) || ''}</p>
                    </div>
                  )}
                </InvoiceFooter>

                <FooterBase>
                  <div className="left"></div>
                  <div className="right"></div>
                </FooterBase>
              </InvoiceBody>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default PreviewInvoice;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding-top: 0;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;

  @media ${device.phone} {
    width: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const InvoiceBody = styled.div`
  width: 100%;
  height: 100%;
`;

const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .logo-view {
    width: 70%;
    display: flex;
    align-items: flex-start;

    .logo-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      border: 1px solid #e5e5e5;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .logo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #174078;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      span {
        font-size: 1.5rem;
        color: #fff;
        font-weight: 600;
      }
    }

    .address-view {
      width: calc(100% - 120px);

      h3 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 8px;
      }

      p {
        font-size: 0.75rem;
        margin: 0;
        margin-bottom: 5px;
      }
    }
  }

  .title-view {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h2 {
      margin: 0;
      padding: 0;
      color: #174078;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  padding: 0 20px;

  .line {
    width: 100%;
    height: 1px;
    background-color: #92c22c;
  }
`;

const InvoiceContent = styled.div`
  width: 100%;
  padding: 20px;

  .row-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    .column-view {
      width: 70%;

      span {
        font-size: 0.875rem;
      }

      p {
        font-size: 1rem;
        margin: 0;
        margin: 5px 0;
        font-weight: 500;
      }
    }

    .column-view-right {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        font-size: 0.875rem;
      }

      p {
        font-size: 1rem;
        margin: 0;
        margin: 5px 0;
        font-weight: 500;
      }
    }
  }
`;

const Table = styled.table`
  width: 100%;
  margin-top: 60px;

  tr {
    width: 100%;
    background-color: #92c22c;

    th {
      text-align: left;
      padding: 12px 20px;
      font-size: 0.5rem;
      font-weight: 600;
    }
  }

  .table-row {
    width: 100%;
    background-color: #d3e6ff;
    border-bottom: 1px solid #fff;

    td {
      text-align: left;
      padding: 12px 20px;
      font-size: 0.5rem;
      font-weight: 400;
    }
  }
`;

const TotalSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;

  .total {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media ${device.phone} {
      width: 100%;
    }

    span {
      font-size: 0.875rem;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
      margin: 5px 0;
      font-weight: 600;
      text-align: right;
    }
  }

  .total-total {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d3e6ff;
    margin-top: 20px;
    padding: 10px;

    @media ${device.phone} {
      width: 100%;
    }

    span {
      font-size: 0.875rem;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
      margin: 5px 0;
      font-weight: 600;
      text-align: right;
    }
  }
`;

const InvoiceFooter = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 40px;

  .note-view {
    width: 100%;
    padding-top: 20px;

    span {
      font-size: 0.75rem;
    }

    p {
      font-size: 0.875rem;
      margin: 0;
      margin: 5px 0;
      font-weight: 400;
      text-align: left;
    }
  }
`;

const FooterBase = styled.div`
  width: 100%;
  height: 30px;
  display: flex;

  .left {
    width: 70%;
    height: 30px;
    background-color: #174078;
  }

  .right {
    width: 30%;
    height: 30px;
    background-color: #92c22c;
  }
`;
