import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useState, useMemo } from 'react';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { device } from '@/constants/breakpoints';
import { createInvoicePDF } from '@/services/receivablesApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { trackEvent } from '@/utils/mixpanel';
import DynamicForm from './DynamicForm';

const taxOptions = [{ value: 'percentage', label: '%' }];

const discountOptions = [
  { value: 'percentage', label: '%' },
  { value: 'fixed', label: 'NGN' }
];

function InvoiceItems({
  showModal,
  setShowModal,
  setInvoiceItems,
  invoiceForm,
  setShowPreview,
  setPreviewData,
  user
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [rows, setRows] = useState([{ item: '', quantity: 1, rate: '', amount: '' }]);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxType] = useState(taxOptions[0]);
  const [discountType, setDiscountType] = useState(discountOptions[0]);
  const [showTaxOptions, setShowTaxOptions] = useState(false);
  const [showDiscountOptions, setShowDiscountOptions] = useState(false);
  const [saveStatus, setSaveStatus] = useState('draft');

  const calculateSubtotal = useMemo(() => {
    return rows.reduce((subtotal, row) => {
      const amount = parseFloat(row.amount);
      return isNaN(amount) ? subtotal : subtotal + amount;
    }, 0);
  }, [rows]);

  const calculateVAT = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const taxValue = parseFloat(tax) || 0;

    if (taxType.value === 'percentage') {
      return ((subtotal * taxValue) / 100).toFixed(2);
    } else {
      return taxValue.toFixed(2);
    }
  }, [calculateSubtotal, tax, taxType]);

  const calculateDiscount = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const discountValue = parseFloat(discount) || 0;

    if (discountType.value === 'percentage') {
      return ((subtotal * discountValue) / 100).toFixed(2);
    } else {
      return discountValue.toFixed(2);
    }
  }, [calculateSubtotal, discount, discountType]);

  const calculateTotal = useMemo(() => {
    return (calculateSubtotal + parseFloat(calculateVAT) - parseFloat(calculateDiscount)).toFixed(
      2
    );
  }, [calculateSubtotal, calculateVAT, calculateDiscount]);

  const handleCreateInvoice = useMutation(createInvoicePDF, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (saveStatus === 'sent') {
        if (user?.hasBankAccount) {
          if (data?.data?.requiresApproval) {
            navigate(`/get-paid/invoices/request-approval?id=${data?.data?.id}`);
          } else {
            navigate(`/get-paid/invoices/request-payment?id=${data?.data?.id}`);
          }
          toast.success('Invoice created successfully');
        } else {
          navigate('/settings?tab=bank-account');
          toast.success(
            'Invoice created successfully, add bank account to start receiving payments'
          );
        }
      } else {
        navigate(`/get-paid/invoices?tab=${saveStatus}`);
        toast.success('Invoice created successfully.');
      }

      trackEvent('Create Invoice', {
        userId: user?.id
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleSubmit = (saveType) => {
    setSaveStatus(saveType);
    const newRows = rows.map((row) => {
      return {
        name: row.item,
        amount: parseFloat(row.amount || 0).toFixed(2),
        quantity: parseFloat(row.quantity || 0),
        rate: parseFloat(row.rate || 0)
      };
    });
    const formData = {
      ...invoiceForm,
      status: 'draft',
      tax: parseFloat(tax || 0),
      discount: parseFloat(discount || 0),
      taxType: taxType.value,
      discountType: discountType.value,
      items: newRows
    };
    setInvoiceItems(newRows);
    formData.items = formData.items.filter((item) => item.name && item.amount);
    handleCreateInvoice.mutate(formData);
  };

  const handlePreview = () => {
    const newRows = rows.map((row) => {
      return {
        name: row.item,
        amount: parseFloat(row.amount || 0).toFixed(2),
        quantity: parseFloat(row.quantity || 0),
        rate: parseFloat(row.rate || 0)
      };
    });
    const formData = {
      ...invoiceForm,
      status: 'draft',
      tax: parseFloat(tax || 0),
      discount: parseFloat(discount || 0),
      taxType: taxType.value,
      discountType: discountType.value,
      items: newRows
    };
    formData.items = formData.items.filter((item) => item.name && item.amount);
    setPreviewData({
      ...formData,
      subtotal: calculateSubtotal,
      discount: calculateDiscount,
      tax: calculateVAT,
      total: calculateTotal,
      discountType: discountType.value,
      taxType: taxType.value
    });
    setShowPreview(true);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 500 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.2 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>
            <div className="form-view">
              <div className="title-view">
                <h3>Add invoice items</h3>
              </div>
              <div className="preview-view">
                <button type="button" onClick={handlePreview}>
                  Preview Invoice
                </button>
              </div>
              {/* Form */}
              <DynamicForm
                rows={rows}
                setRows={setRows}
                tax={tax}
                setTax={setTax}
                discount={discount}
                setDiscount={setDiscount}
                taxType={taxType}
                discountType={discountType}
                setDiscountType={setDiscountType}
                showTaxOptions={showTaxOptions}
                setShowTaxOptions={setShowTaxOptions}
                showDiscountOptions={showDiscountOptions}
                setShowDiscountOptions={setShowDiscountOptions}
                handleSubmit={handleSubmit}
                handleCreateInvoice={handleCreateInvoice}
                saveStatus={saveStatus}
                setSaveStatus={setSaveStatus}
              />
            </div>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default InvoiceItems;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .form-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      flex-direction: column;

      .title-view {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        h3 {
          font-size: 1.5rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .preview-view {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 30px;

        button {
          background-color: transparent;
          border: none;
          outline: none;
          color: ${({ theme }) => theme.colors?.primary};
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          text-transform: uppercase;
        }
      }
    }
  }
`;
