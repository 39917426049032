import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { persistStore } from 'redux-persist';
import toast, { Toaster } from 'react-hot-toast';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import 'react-tooltip/dist/react-tooltip.css';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import './assets/styles/fonts.css';
import store from './redux/store';
import BasedStyles from './assets/styles/GlobalStyle';
import theme from './constants/theme';
import { BrowserRouter } from 'react-router-dom';
import App2 from './App2';
import { TimeoutProvider } from './components/TimeoutProvider';
import 'react-tooltip/dist/react-tooltip.css';

const productionHost = 'app.billboxx.com';
const devToken = import.meta.env.VITE_MIXPANEL_DEV_TOKEN;
const prodToken = import.meta.env.VITE_MIXPANEL_PROD_TOKEN;

Sentry.init({
  dsn: 'https://bb24f9e12407c059891a6b05468e792d@o4507741669621760.ingest.us.sentry.io/4508063928483840',
  integrations: []
});

if (window.location.hostname.toLowerCase().search(productionHost) < 0) {
  mixpanel.init(devToken, {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage',
    ignore_dnt: true,
    api_host: 'https://mixpanel-api-proxy-qbzjj.ondigitalocean.app/'
  });
} else {
  mixpanel.init(prodToken, {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage',
    ignore_dnt: true,
    api_host: 'https://mixpanel-api-proxy-qbzjj.ondigitalocean.app/'
  });
}

let persistor = persistStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: false
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message?.[0]);
      }

      if (error?.response?.status === 500) {
        toast.error(`Something went wrong: ${error?.response?.data?.message}`);
      }
    }
  })
});

ReactPixel.init('313648681492114');

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster
          containerStyle={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: 0,
            padding: 0,
            borderRadius: '10px',
            zIndex: 100000
          }}
          toastOptions={{
            className: 'toastt',
            duration: 6000,
            style: {
              padding: '10px',
              color: '#000',
              fontSize: '14px',
              fontWeight: '600',
              borderRadius: '10px',
              margin: 0,
              zIndex: 100000
            },
            success: {
              style: {
                background: '#174078',
                color: '#FAFAFA',
                textAlign: 'left',
                justifyContent: 'flex-start'
              }
            },
            error: {
              style: {
                background: '#174078',
                color: '#FAFAFA',
                textAlign: 'left',
                justifyContent: 'flex-start'
              }
            }
          }}
          position="top-center"
        />
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <BasedStyles />
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <TimeoutProvider>
                  <App2 />
                </TimeoutProvider>
              </BrowserRouter>

              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ThemeProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </>
);
