import React, { useState } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/'
};

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <PDFView>
      <Document
        options={options}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Loading...">
        <Page pageNumber={pageNumber} className="pdf" />
      </Document>
    </PDFView>
  );
};

export default PDFViewer;

const PDFView = styled.div`
  width: 100%;
  height: 100%;

  .react-pdf__Document {
    width: 100%;
    height: 100%;
  }

  .pdf {
    width: 100%;
    height: 100%;
  }
`;
