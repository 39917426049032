/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import Button from '@/components/Button/Button';
import PinModal from './PINModal';
import { numberWithCommas } from '@/utils/helpers';
import { subscribeToPlan } from '@/services/subscriptionApi';
import { Oval } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';

export default function UpgradeModal({ showModal, setShowModal, plan }) {
  const queryClient = useQueryClient();
  const [showPinModal, setShowPinModal] = useState(false);
  const [selectedCost, setSelectedCost] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('third-party');
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('state');
  const DurationCost = [
    {
      duration: 'Monthly',
      cost: plan?.monthlyCost,
      value: 'month',
      id: plan?.id
    },
    {
      duration: 'Quarterly',
      cost: plan?.quarterlyCost,
      value: 'quarter',
      id: plan?.id
    },
    {
      duration: 'Annually',
      cost: plan?.annualCost,
      value: 'year',
      id: plan?.id
    }
  ];

  useEffect(() => {
    if (tab === 'monthly') {
      setSelectedCost(DurationCost[0]);
    } else if (tab === 'quaterly') {
      setSelectedCost(DurationCost[1]);
    } else if (tab === 'annual') {
      setSelectedCost(DurationCost[2]);
    }
  }, [plan, tab]);

  const handleSubscription = useMutation(subscribeToPlan, {
    onSuccess: (data) => {
      if (plan?.name === 'Free') {
        setShowModal(false);
        queryClient.invalidateQueries(['subscriptionPlans']);
        queryClient.invalidateQueries(['userSubscription']);
        queryClient.invalidateQueries(['userSubscriptionUsage']);
        queryClient.invalidateQueries(['billingHistory']);
        toast.success('Downgraded to Free plan successfully');
        return;
      } else {
        setShowModal(false);
        queryClient.invalidateQueries(['subscriptionPlans']);
        queryClient.invalidateQueries(['userSubscription']);
        queryClient.invalidateQueries(['userSubscriptionUsage']);
        queryClient.invalidateQueries(['billingHistory']);
        window.location.href = data?.data?.authorization_url;
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const formData = {
      subscriptionId: plan?.id,
      plan: selectedCost?.duration?.toLowerCase(),
      channel: 'third-party'
    };

    if (paymentMethod === 'third-party') {
      handleSubscription.mutate(formData);
    } else {
      setShowPinModal(true);
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  {plan?.name === 'Free' ? (
                    <h3>Downgrade to {plan?.name}</h3>
                  ) : (
                    <h3>Subscribe to {plan?.name}</h3>
                  )}
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <ContentView>
                  {plan?.name === 'Free' ? (
                    <></>
                  ) : (
                    <>
                      <h3>Select subscription duration</h3>
                      <div className="select-view">
                        {DurationCost.map((item, index) => (
                          <div className="select-item" key={item?.duration}>
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                name={item?.duration}
                                id={item?.duration}
                                checked={selectedCost?.cost === item?.cost}
                                onChange={() => setSelectedCost(item)}
                              />
                              <label htmlFor={item?.duration}>
                                {item?.duration} - ₦{numberWithCommas(item?.cost || '00.00')}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <div className="summary">
                    <div className="summary-item">
                      <p>{plan?.name}</p>
                      <p>
                        ₦{numberWithCommas(selectedCost?.cost || '00.00')} / {selectedCost?.value}
                      </p>
                    </div>
                    <div className="summary-item total">
                      <p>Total</p>
                      <p>₦{numberWithCommas(selectedCost?.cost || '00.00')}</p>
                    </div>
                  </div>

                  <div className="warn">
                    <p>
                      You will be charged ₦{numberWithCommas(selectedCost?.cost || '00.00')} for{' '}
                      {plan?.name} plan.
                    </p>
                  </div>

                  <div className="button-view">
                    <button
                      type="button"
                      className="btn btn-cancel"
                      onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <Button
                      type="button"
                      width="40%"
                      className="btn btn-primary"
                      onClick={onSubmit}>
                      {handleSubscription.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PinModal showModal={showPinModal} setShowModal={setShowPinModal} plan={selectedCost} />
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 580px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px 0;
  padding-bottom: 0;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 15px 30px;
    background-color: ${(props) => props.theme.colors?.layer4};
    text-align: center;
  }

  .select-view {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0 30px;

    .select-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      .checkbox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          cursor: pointer;
          accent-color: ${(props) => props.theme.colors?.primary};
          color: ${(props) => props.theme.colors?.primary};
        }

        label {
          font-size: 1rem;
          font-weight: 500;
          color: ${(props) => props.theme.colors?.secondary};
          cursor: pointer;
        }
      }
    }
  }

  .summary {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors?.layer4};
    padding: 30px;

    .summary-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid ${(props) => props.theme.colors?.line};

      p {
        font-size: 1rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .total {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .warn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors?.primaryFocus};
    padding: 20px 30px;

    p {
      font-size: 1rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
      line-height: 19px;
      text-align: center;
    }
  }

  .button-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    padding-bottom: 0;
    margin-top: 20px;
    background-color: ${(props) => props.theme.colors?.white};

    .btn {
      width: 100%;
      height: 64px;
      max-width: 200px;
      font-size: 1rem;
      font-weight: 500;
      padding: 10px 0;
      border-radius: 16px;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.btn-cancel {
        background-color: transparent;
        color: ${(props) => props.theme.colors?.secondary};
        border: 1px solid ${(props) => props.theme.colors?.secondary};
        width: 120px;
      }

      &.btn-primary {
        background-color: ${(props) => props.theme.colors?.primary};
        color: ${(props) => props.theme.colors?.white};
      }
    }
  }
`;
