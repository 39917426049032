import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as InactiveImage } from '@/assets/icons/inactive.icon.svg';
import secureLocalStorage from 'react-secure-storage';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from '@/services/authApi';
import toast from 'react-hot-toast';
import { device } from '@/constants/breakpoints';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';

const TimeoutModal = ({ isVisible, setModalVisible, timeLeft, onDismiss }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      // toast.error(error?.response?.data?.message);
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
      setModalVisible(false);
    }
  });

  if (!isVisible) return null;

  return (
    <Backdrop initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Modal initial={{ scale: 0.8 }} animate={{ scale: 1 }} exit={{ scale: 0.8 }}>
        <InactiveImage />
        <h3>Still here?</h3>
        <p>
          Privacy is important to us. You will be logged out in <strong>{timeLeft}s</strong> due to
          inactivity unless you confirm you're still active.
        </p>
        <Button onClick={onDismiss}>Stay Logged In</Button>
        <LogoutButton
          onClick={() => {
            handleLogoutUser.mutate({});
            secureLocalStorage.clear();
            secureLocalStorage.removeItem('company');
            dispatch(resetAppStateOnLogout());
            navigate('/login', { replace: true });
            setModalVisible(false);
          }}>
          Logout now
        </LogoutButton>
      </Modal>
    </Backdrop>
  );
};

export default TimeoutModal;

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90000;
`;

const Modal = styled(motion.div)`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.phone} {
    width: calc(100% - 32px);
  }

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 50px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors?.secondary};

    strong {
      font-weight: 600;
    }
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: ${({ theme }) => theme.colors?.primary};
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.colors?.white};
  font-weight: 600;
  font-size: 1rem;
`;

const LogoutButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: transparent;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors?.error_200};
  font-weight: 600;
  font-size: 1rem;
`;
