import { fetchAllSubscriptionPlans } from '@/services/subscriptionApi';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MarkIcon } from '@/assets/icons/mark.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-arrow-right.icon.svg';
import { ReactComponent as CrossIcon } from '@/assets/icons/cross-x.svg';
import { device } from '@/constants/breakpoints';
import UpgradeModal from './UpgradeModal';
import useSubscriptionFeatures from '@/hooks/useSubscriptionFeatures';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SupportModal from './SupportModal';

const tabs = [
  {
    name: 'Monthly',
    path: 'tab=subscriptions&view=plans&state=monthly',
    value: 'monthly'
  },
  {
    name: 'Quarterly',
    path: 'tab=subscriptions&view=plans&state=quaterly',
    value: 'quaterly'
  },
  {
    name: 'Annual',
    path: 'tab=subscriptions&view=plans&state=annual',
    value: 'annual'
  }
];

const hostname = window.location.hostname.split('.')[0];

const Subscriptions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('state');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const { userSubscription } = useSubscriptionFeatures();

  const subscriptionPlans = useQuery({
    queryKey: ['subscriptionPlans'],
    queryFn: fetchAllSubscriptionPlans,
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const formatKey = (key) => {
    // Replace underscores with spaces and capitalize first letter
    return key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
  };

  const renderBooleanIcon = (value) => {
    if (typeof value === 'boolean') {
      return value ? <MarkIcon /> : <CrossIcon style={{'width': '17px'}} />;
    }
    return value;
  };

  const renderUsersValue = (feature, plan) => {
    const value = subscriptionPlans.data.data.find((item) => item.name === plan)?.data[feature];

    if (feature.toLowerCase() === 'users' && typeof value === 'number') {
      return `${new Intl.NumberFormat('en-US').format(value)} ${value === 1 ? 'user' : 'users'}`;
    }
    
    if (feature.toLowerCase() === 'file_storage' && typeof value === 'number') {
      return `${new Intl.NumberFormat('en-US').format(value)} GB`;
    }
    
    if (typeof value === 'number') {
      // Use Intl.NumberFormat for general numeric formatting
      return new Intl.NumberFormat('en-US').format(value);
    }

    if (typeof value === 'string' && value.toLowerCase() === 'unlimited') {
      switch (feature.toLowerCase()) {
        case 'file_storage':
          return 'unlimited GB';
        case 'users':
          return 'unlimited users';
        default:
          return 'unlimited';
      }
    }

    return value;
  };

  //Custom Previous arrow
  const PrevArrow = (props) => <NextIcon {...props} style={{ transform: 'rotate(180deg)' }} />;

  //For responsiveness
  const size = {
    phone: 600,
    tablet: 768,
    laptop: 992,
    desktop: 1450,
    tv: 1400
  };

  //React slick settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextIcon />,
    responsive: [
      {
        breakpoint: size.phone,
        settings: {
          dots: true,
          arrows: false,
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <SubscriptionsView>
        {subscriptionPlans?.isLoading ? (
          <div className="loader-view">
            <div className="loader">
              <Oval
                color="#92C22C"
                secondaryColor="#ddd"
                height={50}
                width={50}
                strokeWidth={4}
                ariaLabel="loading"
              />
            </div>
          </div>
        ) : (
          <UpgradePlanView>
            <h3>Upgrade your plan</h3>
            <p>Get more out of your Billboxx account</p>

            <UpgradeTabsView>
              {tabs.map((item, index) => (
                <button
                  key={index}
                  className={`tab ${tab === item.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(item.path)}>
                  {item.name}
                </button>
              ))}
            </UpgradeTabsView>

            <PlansView>
              <Slider {...settings}>
                {subscriptionPlans?.data?.data?.map((plan) => (
                  <PlanCardView key={plan.id}>
                    <div className="costs">
                      <p>{plan.name}</p>
                      {tab === 'monthly' ? (
                        <h2>{plan.data.is_unlimited === false ? `₦${numberWithCommas(plan.monthlyCost || '00.00')}` : 'Contact us' }</h2>
                      ) : tab === 'quaterly' ? (
                        <h2>{plan.data.is_unlimited === false ? `₦${numberWithCommas(plan.quarterlyCost || '00.00')}` : 'Contact us' }</h2>
                      ) : (
                        <h2>{plan.data.is_unlimited === false ? `₦${numberWithCommas(plan.annualCost || '00.00')}` : 'Contact us' }</h2>
                      )}
                    </div>
                    <ul>
                      {Object.keys(subscriptionPlans.data.data[0]?.data || {}).map((feature) => (
                        <div key={feature} className='flex'>
                          <li>{formatKey(feature)}</li>
                          <li className='center'>{renderBooleanIcon(renderUsersValue(feature, plan.name))}</li>
                        </div>
                      ))}
                    </ul>

                    {plan?.name !== 'Free' ? (
                      <button
                        type="button"
                        disabled={
                          userSubscription?.subscriptionPlan?.name === plan?.name &&
                          userSubscription?.duration === tab
                        }
                        onClick={() => {
                          if(plan.data.is_unlimited) {
                            setSelectedPlan(plan);
                            setShowSupportModal(true);
                          } else {
                            setSelectedPlan(plan);
                            setShowUpgradeModal(true);
                          }
                        }}>
                        {userSubscription?.subscriptionPlan?.name === plan?.name &&
                        userSubscription?.duration === tab
                          ? 'Current Plan'
                          : 'Subscribe'}
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled={userSubscription?.subscriptionPlan?.name === plan?.name}
                        onClick={() => {
                          setSelectedPlan(plan);
                          setShowUpgradeModal(true);
                        }}>
                        {userSubscription?.subscriptionPlan?.name === 'Free'
                          ? 'Current Plan'
                          : 'Downgrade'}
                      </button>
                    )}
                  </PlanCardView>
                ))}
              </Slider>
            </PlansView>
          </UpgradePlanView>
        )}
      </SubscriptionsView>

      {/* Upgrade Modal */}
      <UpgradeModal
        showModal={showUpgradeModal}
        setShowModal={setShowUpgradeModal}
        plan={selectedPlan}
      />

      {/* Contact Support Modal */}
      <SupportModal 
        showModal={showSupportModal} 
        setShowModal={setShowSupportModal}
      />
    </>
  );
};

export default Subscriptions;

const SubscriptionsView = styled.div`
  width: 100%;

  @media ${device.phone} {
    padding: 0;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const UpgradePlanView = styled.div`
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 0px;

  @media ${device.phone} {
    margin-top: 30px;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const UpgradeTabsView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;

  .tab {
    width: 33.33%;
    padding: 20px 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    color: ${(props) => props.theme.colors.info};
    height: 56px;

    &:hover {
      border-bottom: 4px solid ${(props) => props.theme.colors.primary};
    }
  }

  .active {
    border-bottom: 4px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    z-index: 2;
    font-weight: 600;
  }
`;

const PlansView = styled.div`
  width: 100%;

  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
`;

const PlanCardView = styled.div`
  background-color: ${(props) => props.theme.colors.layer3};
  height: 900px;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 0.9rem;
  position: relative;

  .costs {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.info};

    h2 {
      font-size: 1.4rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.secondary};
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.secondary};
      margin-bottom: 10px;
    }
  }

  ul {
    width: 100%;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    li {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors.secondary};
      margin-bottom: 10px;
      list-style: none;
      text-transform: capitalize;
      /* word-spacing: 2px; */

      .center {
        text-align: end;
      }

      span {
      }

      svg {
        width: 12px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  button {
    width: 90%;
    height: 56px;
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
    border-radius: 0.6rem;
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      background-color: ${(props) => props.theme.colors.info};
    }
  }
`;
