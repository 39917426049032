import {
  isValidAmount,
  isValidCompanyName,
  isValidDueDate,
  isValidEmail,
  isValidInvoiceNumber,
} from './validators';

export const config = {
  headers: [
    {
      name: 'Company name',
      inputName: 'companyName',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (companyName) => {
        return isValidCompanyName(companyName);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      },
    },
    {
      name: 'Description',
      inputName: 'description',
      required: false,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
    },
    {
      name: 'Amount',
      inputName: 'amount',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (amount) => {
        return isValidAmount(amount);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}, ${headerName} should be a number`;
      },
    },
    {
      name: 'Invoice number',
      inputName: 'invoiceNumber',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (invoiceNumber) => {
        return isValidInvoiceNumber(invoiceNumber);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      },
    },
    {
      name: 'Due date',
      inputName: 'dueDate',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (dueDate) => {
        return isValidDueDate(dueDate);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      },
    },
    {
      name: 'Email (Optional)',
      inputName: 'email',
      required: false,
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (email) => {
        return isValidEmail(email);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      },
    },
  ],
};
