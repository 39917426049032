/* eslint-disable import/no-unresolved */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { ReactComponent as UploadIcon } from '@/assets/icons/upload.icon.svg';

function FilePicker({ file, setFile }) {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setLoading(true);
    fileRejections.forEach((data) => {
      data.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`File is larger than 5MB`);
        }

        if (err.code === 'file-invalid-type') {
          toast.error(`Error: ${err.message}`);
        }

        setLoading(false);
      });
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      setFile(acceptedFile);
      setLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'image/*': [],
      'image/heic': []
    },
    maxFiles: 1,
    noClick: false,
    noKeyboard: false,
    maxSize: 5242880
  });

  // const removeFile = (img) => {
  //   if (multiple) {
  //     const filtered = file?.filter((n) => n !== img)
  //     if (filtered?.length > 0) {
  //       setFile(filtered)
  //     } else {
  //       setFile(null)
  //     }
  //   } else {
  //     setFile(null)
  //   }
  // }

  return (
    <PickerView file={file}>
      <Picker {...getRootProps()}>
        <input type="file" accept="image/*, application/pdf" {...getInputProps()} />
        <div className="title">
          {file ? (
            <span className="name">{file?.name}</span>
          ) : (
            <span className="placeholder">Click to upload document(.pdf, .png, .jpg,)</span>
          )}
        </div>
        <div className="icon">
          {loading ? (
            <Oval
              color="#174078"
              secondaryColor="#D9DBE9"
              ariaLabel="loading"
              height={20}
              width={20}
            />
          ) : (
            <UploadIcon />
          )}
        </div>
      </Picker>
    </PickerView>
  );
}

export default FilePicker;

FilePicker.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};

const PickerView = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`;

const Picker = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors?.inputBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    .placeholder {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.placeholder};
    }

    .name {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.activeTitle};
    }
  }

  .icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
