import socketIO from 'socket.io-client';

const hostname = window.location.hostname.split('.')[0];

const URL =
  hostname === 'app' ? import.meta.env.VITE_PROD_BASE_URL : import.meta.env.VITE_DEV_BASE_URL;

export const socket = socketIO(URL, {
  autoConnect: false
});

// Listen for socket events when the user is authenticated
export const subscribeToNotifications = (userId) => {
  if (socket && userId) {
    socket.connect();
  }
};

export const unsubscribeFromNotifications = (userId) => {
  if (socket && userId) {
    socket.disconnect();
  }
};

export const listenForNotifications = (userId, callback) => {
  socket.on(userId, (latestNotification) => {
    callback(latestNotification);
  });

  // socket.on(`${userId}_bulk`, (allNotifications) => {
  //   callback(allNotifications);
  // });
};
