import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device } from '@/constants/breakpoints';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { Oval } from 'react-loader-spinner';
import OtpInput from 'react-otp-input';
import { useMutation, useQuery } from '@tanstack/react-query';
import { verifySecurityPin } from '@/services/securityApi';
import { fetchPaymentAdvicePDF, initiatePayment } from '@/services/billsApi';
import toast from 'react-hot-toast';
import PaymentAdvice from '@/components/PaymentAdvice';
import { trackEvent } from '@/utils/mixpanel';

function TransactionPinModal({ showModal, setShowModal, bill, amount, remark }) {
  const [pin, setPin] = useState('');
  const [showPaymentAdvice, setShowPaymentAdvice] = useState(false);
  const [billData, setBillData] = useState(null);
  const [payAdvicePDF, setPayAdvicePDF] = useState(null);

  const handleVerifyPin = useMutation(verifySecurityPin, {
    onSuccess: async (data) => {
      const paymentData = {
        amount,
        billId: bill?.id,
        name: bill?.vendor?.companyName || bill?.vendor?.name,
        channel: 'wallet',
        pin,
        remark
      };

      await handleInitiatePayment.mutate(paymentData);
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const handleInitiatePayment = useMutation(initiatePayment, {
    onSuccess: async (data) => {
      setBillData(data?.data);
      setPayAdvicePDF(data?.data?.reference);
      trackEvent('Bill Payment', {});
      // Fetch payment query here
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const fetchPaymentAdvice = useQuery({
    queryKey: ['paymentAdvice', payAdvicePDF],
    queryFn: fetchPaymentAdvicePDF,
    enabled: payAdvicePDF !== null,
    onSuccess: (data) => {
      setPin('');
      setShowModal(false);
      setShowPaymentAdvice(true);
    }
  });

  const handleMakePayment = (data) => {
    const pinData = {
      type: 't-pin',
      value: pin
    };
    handleVerifyPin.mutate(pinData);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <h3>Transaction PIN</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        setPin('');
                      }}>
                      <CloseIcon />
                    </button>
                  </div>

                  <div className="content">
                    <TitleView>
                      <p>Enter your transaction PIN below</p>
                    </TitleView>

                    <FormView>
                      <div className="input-view">
                        <OtpInput
                          value={pin}
                          onChange={setPin}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          containerStyle="otp-input"
                          inputType="number"
                          skipDefaultStyles
                          shouldAutoFocus
                        />
                      </div>
                      <div className="action-btn">
                        <button
                          type="button"
                          className="btn btn-outline"
                          onClick={() => {
                            setShowModal(false);
                            setPin('');
                          }}>
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={pin.length < 6}
                          onClick={handleMakePayment}>
                          {handleVerifyPin.isLoading ||
                          handleInitiatePayment.isLoading ||
                          fetchPaymentAdvice?.isFetching ? (
                            <Oval color="#fff" width={30} height={30} />
                          ) : (
                            'Make payment'
                          )}
                        </button>
                      </div>
                    </FormView>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PaymentAdvice
        showModal={showPaymentAdvice}
        setShowModal={setShowPaymentAdvice}
        billData={billData}
        paymentAdvice={fetchPaymentAdvice?.data?.data?.url}
      />

      {/* <PaymentSuccessModal
        showModal={showPayementSuccessModal}
        setShowModal={setShowPaymentSuccessModal}
        amount={amount}
        billStatus={newBillStatus}
        recipient={bill?.vendor?.companyName || bill?.vendor?.name}
      /> */}
    </>
  );
}

export default TransactionPinModal;

TransactionPinModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 16px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 300px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const TitleView = styled.div`
  width: 100%;

  h3 {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 400;
    margin-bottom: 20px;
  }
`;

const FormView = styled.form`
  width: 100%;

  .input-view {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    input {
      width: 100%;
    }

    label {
      font-size: 0.875rem;
      font-weight: 400;

      span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 5px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .otp-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      input {
        width: calc(100% / 6 - 10px);
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors?.info};
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
        text-align: center;
        -webkit-text-security: disc;

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors?.primary};
          border: none;
        }
      }
    }
  }

  .resend-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.secondary};
      font-weight: 400;
    }

    .resend {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.primary};
      font-weight: 600;
      cursor: pointer;
      background-color: transparent;
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 100px;

    button {
      margin-left: 10px;

      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
