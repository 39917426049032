import styled from 'styled-components';

export const SupportStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  .support-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-view {
      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  .support-wrapper {
    width: 100%;
    background-color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 30px;
    border-radius: 30px;
    padding: 30px;
  }
`;
