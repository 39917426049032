import api from './api';

export const createSecurityPin = async (data) => {
  const response = await api.post(`/user-security/create`, data);
  return response.data;
};

export const verifySecurityPin = async (data) => {
  const response = await api.post(`/user-security/verify`, data);
  return response.data;
};

export const changeSecurityPin = async (data) => {
  const response = await api.post(`/user-security/reset`, data);
  return response.data;
};

export const initiateResetPin = async (data) => {
  const response = await api.post(`/user-security/reset/initiate`, data);
  return response.data;
};

export const completeResetPin = async (data) => {
  const response = await api.post(`/user-security/reset/complete`, data);
  return response.data;
};

export const deactivateSecurity = async (data) => {
  const response = await api.post(`/user-security/deactivate`, data);
  return response.data;
};

export const reactivateSecurity = async (data) => {
  const response = await api.post(`/user-security/reactivate`, data);
  return response.data;
};
