import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as RefreshIcon } from '@/assets/icons/refresh.icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { numberWithCommas } from '@/utils/helpers';
import PDFViewer from '@/components/PDFViewer';
import { device } from '@/constants/breakpoints';
import { fetchInvoiceDiscountDetails, getOfferLetter } from '@/services/financingApi';
import PaymentMethodModal from './PaymentMethod';
import PaymentTypeModal from './PaymentType';
import ConfirmationModal from './ConfirmationModal';
import TransactionPinModal from './TransactionPinModal';
import { format } from 'date-fns';
import AccountInfoModal from './AccountInfoModal';

function RequestDetails({ showModal, setShowModal, requestId, fullAmount }) {
  const [showActions, setShowActions] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAuthorisationModal, setShowAuthorisationModal] = useState(false);
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);
  const [transferAccountInfo, setTransferAccountInfo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('wallet');
  const ref = useRef();
  useOnClickOutside(ref, () => setShowActions(false));

  const request = useQuery({
    queryKey: ['request-details-disbursed', { id: requestId }],
    queryFn: fetchInvoiceDiscountDetails
  });

  const offerLetter = useQuery({
    queryKey: [
      'offer-letter',
      {
        id: requestId
      }
    ],
    queryFn: getOfferLetter,
    enabled: !!requestId && showModal
  });

  const handleFileDownload = (url, filename) => {
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      });
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}

              {request?.isLoading || offerLetter?.isLoading ? (
                <LoaderView>
                  <Oval color="#64748B" height={50} width={50} />
                </LoaderView>
              ) : (
                <div className="modal-content">
                  <div className="header">
                    <span></span>
                    <button type="button" onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="modal-content__title-view">
                    <h3>Facility Details</h3>
                  </div>
                  <div className="modal-content__values">
                    <div className="modal-content__values-item">
                      <span>Lender</span>
                      <h3>{request?.data?.data?.loan?.loanDetails[0]?.businessName || 'N/A'}</h3>
                    </div>
                    <div className="modal-content__values-item">
                      <span>Facility</span>
                      <h3>₦{numberWithCommas(request?.data?.data?.loan?.amount || '00.00')}</h3>
                    </div>
                    <div className="modal-content__values-item">
                      <span>Due date</span>
                      <h3>
                        {format(new Date(request?.data?.data?.loan?.dueDate), 'do MMM, yyyy')}
                      </h3>
                    </div>
                    <div className="modal-content__values-item">
                      <span>Repayment</span>
                      <h3>₦{numberWithCommas(request?.data?.data?.loan?.repayment || '00.00')}</h3>
                    </div>
                  </div>
                  <div className="modal-content__row">
                    <div className="file-view">
                      {offerLetter?.data?.data?.url?.includes('.pdf') ? (
                        <div className="pdf-view">
                          <PDFViewer file={offerLetter?.data?.data?.url} />
                        </div>
                      ) : (
                        <div className="image-view">
                          {offerLetter?.data?.data?.url ? (
                            <img src={offerLetter?.data?.data?.url} alt="Invoice" />
                          ) : request?.data?.data?.items?.length > 0 ? (
                            <div className="refresh">
                              <p>Please hold on, generating invoice...</p>
                              <button
                                onClick={() => {
                                  // refetch();
                                }}>
                                <RefreshIcon /> Refresh
                              </button>
                            </div>
                          ) : (
                            <p>No File to display</p>
                          )}
                        </div>
                      )}

                      {offerLetter?.data?.data?.url && (
                        <div className="download-view">
                          <button
                            onClick={() => {
                              handleFileDownload(
                                offerLetter?.data?.data?.url,
                                offerLetter?.data?.data?.url
                              );
                            }}>
                            Download file <DownloadIcon />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="action-view">
                      <div className="balance">
                        <span>Balance due</span>
                        <h3>₦{numberWithCommas(request?.data?.data?.loan?.balance || '00.00')}</h3>
                      </div>
                      <div className="action">
                        <button
                          type="button"
                          onClick={(e) => {
                            setShowActions(true);
                            e.stopPropagation();
                          }}>
                          Action <DownIcon />
                        </button>
                        <AnimatePresence>
                          {showActions ? (
                            <motion.div
                              className="menu-actions"
                              ref={ref}
                              initial={{ y: -20 }}
                              animate={{ y: 0 }}
                              exit={{ y: -10, opacity: 0 }}
                              transition={{ duration: 0.2 }}>
                              <button type="button" onClick={() => setShowPaymentMethodModal(true)}>
                                Make Repayment
                              </button>
                              <button type="button" onClick={() => setShowModal(false)}>
                                Cancel
                              </button>
                            </motion.div>
                          ) : null}
                        </AnimatePresence>
                      </div>
                      <div className="request">
                        <button onClick={() => setShowPaymentMethodModal(true)}>
                          Make Repayment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      {showPaymentMethodModal && (
        <PaymentMethodModal
          setShowModal={setShowPaymentMethodModal}
          showModal={showPaymentMethodModal}
          setShowPaymentTypeModal={setShowPaymentTypeModal}
          fullAmount={request?.data?.data?.loan?.repayment}
          requestId={requestId}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
      )}

      {showPaymentTypeModal && (
        <PaymentTypeModal
          showModal={showPaymentTypeModal}
          setShowModal={setShowPaymentTypeModal}
          setShowConfirmationModal={setShowConfirmationModal}
          paymentMethod={paymentMethod}
          fullAmount={fullAmount}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          showModal={setShowConfirmationModal}
          setShowModal={setShowConfirmationModal}
          setShowAuthorisationModal={setShowAuthorisationModal}
          setShowAccountInfoModal={setShowAccountInfoModal}
          paymentMethod={paymentMethod}
          setPaymentData={setTransferAccountInfo}
        />
      )}

      {showAuthorisationModal && (
        <TransactionPinModal
          showModal={showAuthorisationModal}
          setShowModal={setShowAuthorisationModal}
          paymentMethod={paymentMethod}
          paymentData={transferAccountInfo}
          setPaymentData={setTransferAccountInfo}
          setShowAccountInfoModal={setShowAccountInfoModal}
        />
      )}

      {showAccountInfoModal && (
        <AccountInfoModal
          showModal={showAccountInfoModal}
          setShowModal={setShowAccountInfoModal}
          paymentData={transferAccountInfo}
        />
      )}
    </>
  );
}

export default RequestDetails;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 90000;
    position: fixed;
    width: 60%;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 16px;
    min-height: 60%;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
      border-radius: 16px;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px;

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          width: 100%;
        }
      }

      .modal-content__title-view {
        width: 100%;
        margin-top: 20px;

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .modal-content__values {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column;
        }

        .modal-content__values-item {
          width: 100%;

          @media ${device.phone} {
            width: 100%;
            margin-bottom: 20px;
          }

          span {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
          }

          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
            margin-top: 8px;
          }
        }
      }

      .modal-content__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column-reverse;
        }

        .file-view {
          width: 55%;
          height: 100%;

          @media ${device.phone} {
            width: 100%;
          }

          .download-view {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 500;
            }
          }

          .pdf-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;
            overflow: scroll;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            .react-pdf__Page {
              max-width: calc(100% - 1em);
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .react-pdf__Page canvas {
              max-width: 100%;
              height: auto !important;
            }

            .react-pdf__message {
              padding: 1em;
              color: white;
            }
          }

          .image-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            p {
              padding: 20px;
              text-align: center;
              color: ${(props) => props.theme.colors?.secondary};
            }

            .refresh {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              p {
                padding: 20px;
                text-align: center;
                color: ${(props) => props.theme.colors?.secondary};
              }

              button {
                color: ${(props) => props.theme.colors?.secondary};
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  margin-right: 5px;

                  path {
                    stroke: ${(props) => props.theme.colors?.secondary};
                  }
                }
              }
            }
          }
        }

        .action-view {
          width: 40%;
          height: 450px;
          position: relative;

          @media ${device.phone} {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            position: initial;
          }

          .balance {
            width: 100%;

            span {
              font-size: 0.875rem;
              font-weight: 400;
              color: ${(props) => props.theme.colors?.secondary};
            }

            h3 {
              font-size: 1.25rem;
              font-weight: 600;
              color: ${(props) => props.theme.colors?.secondary};
              margin-top: 8px;
            }
          }

          .action {
            width: 100%;
            margin-top: 30px;
            position: relative;

            button {
              width: 120px;
              height: 40px;
              background: ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.white};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                margin-left: 10px;

                path {
                  stroke: ${(props) => props.theme.colors?.white};
                }
              }
            }

            .menu-actions {
              position: absolute;
              top: 45px;
              left: 0;
              background-color: ${(props) => props.theme.colors?.white};
              border-radius: 6px;
              box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
              width: 180px;
              z-index: 100;
              overflow: hidden;

              button {
                width: 100%;
                padding: 16px 24px;
                border: none;
                background-color: ${(props) => props.theme.colors?.white};
                font-size: 0.875rem;
                color: ${(props) => props.theme.colors?.secondary};
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                  background-color: ${(props) => props.theme.colors?.layer2};
                }
              }
            }
          }

          .request {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media ${device.phone} {
              width: 100%;
              justify-content: flex-end;
              position: initial;
              margin-top: 20px;
            }

            button {
              width: 180px;
              height: 50px;
              background: ${(props) => props.theme.colors?.white};
              border: 1px solid ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.primary};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              @media ${device.phone} {
                width: 48%;
              }
            }

            .download {
              width: 180px;
              height: 50px;
              background: ${(props) => props.theme.colors?.white};
              border: 1px solid ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.primary};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              @media ${device.phone} {
                width: 48%;
                font-size: 0.8rem;
              }
            }

            .request-btn {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.white};
              font-weight: 500;

              @media ${device.phone} {
                width: 48%;
              }
            }

            .menu-actionss {
              position: absolute;
              top: -80px;
              left: 0;
              background-color: ${(props) => props.theme.colors?.white};
              border-radius: 6px;
              box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
              width: 180px;
              z-index: 100;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              .action {
                width: 100%;
                padding: 0 20px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 0;
                height: 40px;
                background-color: ${(props) => props.theme.colors?.white};
                border: none;
                color: ${(props) => props.theme.colors?.secondary};
                font-weight: 500;

                &:hover {
                  background-color: ${(props) => props.theme.colors?.layer};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
`;
