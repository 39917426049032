/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';

export const VendorsStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.phone} {
    padding: 20px 16px;
  }

  .title-view {
    .menu {
      display: none;
    }
    h3 {
      font-size: 1.3rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};

      @media ${device.phone} {
        font-size: 1rem;
        display: none;
      }
    }

    @media ${device.phone} {
      display: flex;
      align-items: center;

      .menu {
        display: block;
        margin-right: 10px;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  margin-bottom: 30px;
  border-radius: 30px;
  padding: 30px 40px;

  @media ${device.phone} {
    border-radius: 0;
    padding: 30px 16px;
  }

  .vendors-wrapper__page-title {
    width: 100%;
    display: flex;
    align-items: center;

    @media ${device.phone} {
      display: none;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-left: 10px;
    }
  }

  .vendors-wrapper__header {
    width: 100%;
    margin-top: 30px;

    @media ${device.phone} {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .row1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        font-size: 1rem;
        font-weight: 600;
        border: none;
        cursor: pointer;

        @media ${device.phone} {
          padding: 12px;
          font-size: 0.875rem;
          width: 40%;
          height: 50px;
          margin: 0;
        }

        svg {
          margin-right: 10px;

          @media ${device.phone} {
            display: none;
          }

          path {
            fill: ${({ theme }) => theme.colors?.white};
          }
        }
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }

    .row2 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
    }
  }
`;

export const ComponentsView = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const PaginationView = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;

  @media ${device.phone} {
    width: calc(100% - 80px);
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
