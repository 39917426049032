/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/profile.icon.svg';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import { searchUserByEmail } from '@/services/invoiceFinancingApi';
import { createCustomer } from '@/services/customerApi';
import theme from '@/constants/theme';
import { useDispatch } from 'react-redux';
import { setSearchedUserEmail } from '@/redux/features/app.slice';

function FindCustomerModal({
  showModal,
  setShowModal,
  setShowAddNewCustomer,
  setCustomer,
  setCustomerName
}) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [foundCustomer, setFoundCustomer] = useState(null);

  const schema = yup
    .object({
      email: yup.string().email('Invalid email format').required('Email is required')
    })
    .required();

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const email = useWatch({
    control,
    name: 'email'
  });

  const handleCreateCustomer = useMutation(createCustomer, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      const customer = {
        ...data?.data
      };
      setCustomer(customer);
      if (setCustomerName) {
        setCustomerName(data?.data?.companyName);
      }
      setShowModal(false);
      reset();
      setFoundCustomer(null);
    },
    onError: (error) => {
      if (error?.response?.data?.statusCode === 302) {
        const customer = {
          ...error?.response?.data?.data
        };
        setCustomer(customer);
        if (setCustomerName) {
          setCustomerName(foundCustomer?.companyName);
        }
        setShowModal(false);
        reset();
        setFoundCustomer(null);
        return;
      }
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmitCreateCustomer = (data) => {
    const newData = {
      phoneNumber: foundCustomer?.phoneNumber ?? null,
      companyName: foundCustomer?.company
        ? foundCustomer?.company?.name
        : foundCustomer?.firstName
          ? `${foundCustomer?.firstName || ''} ${foundCustomer?.lastName || ''}`
          : (foundCustomer?.company?.name ?? null),
      email: foundCustomer?.email ?? null,
      name: foundCustomer?.firstName
        ? `${foundCustomer?.firstName || ''} ${foundCustomer?.lastName || ''}`
        : (foundCustomer?.company?.name ?? null),
      type: foundCustomer?.company ? 'business' : 'individual'
    };
    if (foundCustomer?.isLocalScopedCustomer && foundCustomer?.customerId) {
      const customer = {
        ...foundCustomer,
        companyName: foundCustomer?.company,
        id: foundCustomer?.customerId
      };
      setCustomer(customer);
      if (setCustomerName) {
        setCustomerName(foundCustomer?.company || foundCustomer?.firstName);
      }
      setShowModal(false);
      reset();
    } else {
      handleCreateCustomer.mutate(newData);
    }
  };

  const handleSearchUserByEmail = useMutation(searchUserByEmail, {
    onSuccess: (data) => {
      setFoundCustomer(data?.data);
      reset();
    },
    onError: (error) => {
      if (error?.response?.data?.message[0] === 'You can not make yourself a customer') {
        setFoundCustomer(null);
        toast.error(error?.response?.data?.message);
        return;
      } else {
        setShowModal(false);
        setShowAddNewCustomer(true);
        reset();
      }

      // toast.error(error?.response?.data?.message);
    }
  });

  const handleSearch = (data) => {
    const formData = {
      email: data?.email,
      billType: 'customer'
    };
    dispatch(setSearchedUserEmail(data?.email));
    handleSearchUserByEmail.mutate(formData);
  };

  const handleReset = () => {
    setFoundCustomer(null);
    reset({
      email: ''
    });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Add Customer</h3>
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    reset();
                    setFoundCustomer(null);
                  }}>
                  <CloseIcon />
                </button>
              </div>

              <p className="info">Enter customer email address to search for customer</p>

              <FormView>
                <div className="input-view">
                  <label htmlFor="name">Enter customer email address</label>

                  <Input
                    type="email"
                    {...register('email')}
                    error={errors?.email?.message}
                    placeholder="Enter email address"
                  />

                  {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                </div>

                <div className="action-btn">
                  {foundCustomer && (
                    <button className="reset" onClick={handleReset}>
                      Reset
                    </button>
                  )}
                  {email && (
                    <button className="search" onClick={handleSubmit(handleSearch)}>
                      {handleSearchUserByEmail.isLoading ? (
                        <Oval
                          color={theme.colors?.primary}
                          secondaryColor="#A0A3BD"
                          height={15}
                          width={15}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Search'
                      )}
                    </button>
                  )}
                </div>

                <div className="customer">
                  <>
                    {foundCustomer && (
                      <h3>{handleSearchUserByEmail?.data?.message || 'Customer retrieved'} </h3>
                    )}
                    {foundCustomer && (
                      <div className="customer-info">
                        <ProfileIcon />
                        {foundCustomer?.isLocalScopedCustomer ? (
                          <div className="info">
                            {foundCustomer?.company ? (
                              <p>{foundCustomer?.company}</p>
                            ) : (
                              <p>
                                {foundCustomer?.firstName} {foundCustomer?.lastName}
                              </p>
                            )}
                            <span>
                              {foundCustomer?.email} | {foundCustomer?.phoneNumber}
                            </span>
                          </div>
                        ) : (
                          <div className="info">
                            {foundCustomer?.company ? (
                              <p>{foundCustomer?.company?.name}</p>
                            ) : (
                              <p>
                                {foundCustomer?.firstName} {foundCustomer?.lastName}
                              </p>
                            )}
                            <span>
                              {foundCustomer?.email} | {foundCustomer?.phoneNumber}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>

                <div className="cta">
                  <button type="button" onClick={onSubmitCreateCustomer} disabled={!foundCustomer}>
                    {handleCreateCustomer.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Continue'
                    )}
                  </button>
                </div>
              </FormView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default FindCustomerModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 90009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .info {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.info};
    margin-top: 20px;
  }
`;

const FormView = styled.div`
  width: 100%;
  margin-top: 30px;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.activeTitle};
    margin-top: 10px;
  }

  .input-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .reset {
      width: 100px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      color: ${({ theme }) => theme.colors?.secondary};
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    .search {
      width: 100px;
      height: 40px;
      background-color: ${({ theme }) => theme.colors?.secondary};
      color: ${({ theme }) => theme.colors?.white};
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }

  .customer {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 30px;

    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.info};
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .customer-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        path {
          fill: ${({ theme }) => theme.colors?.info};
        }
      }

      .info {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin: 0;

        p {
          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.activeTitle};
          margin: 0;
          margin-bottom: 5px;
        }

        span {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.activeTitle};
        }
      }
    }
  }

  .cta {
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      width: 220px;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
