import axios from 'axios';
import api from './api';

const hostname = window.location.hostname.split('.')[0];

const domain =
  hostname === 'app'
    ? import.meta.env.VITE_PROD_AUTH0_DOMAIN
    : import.meta.env.VITE_DEV_AUTH0_DOMAIN;

/**
 * * Fetches the user data from the API
 * @returns {Promise} - Returns the user data
 */
export const fetchUser = async () => {
  const response = await api.get(`/me`);
  return response.data;
};

/**
 * * Updates the user data
 * @param {*} data
 * @returns {Promise} - Returns the updated user data
 */
export const updateUser = async (data) => {
  const response = await api.put(`/me/update`, data);
  return response.data;
};

/**
 * * Create a company for the user
 * @param {*} data
 * @returns {Promise} - Returns the created company
 */
export const setupUpCompany = async (data) => {
  const response = await api.post(`/company/setup`, data);
  return response.data;
};

/**
 * * Fetches the company data
 * @param {Array} queryKey
 * @returns {Promise} - Returns the company data
 */
export const fetchCompany = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/company/${id}`);
    return response.data;
  } else {
    throw new Error('No company ID provided');
  }
};

/**
 * * Updates the company data
 * @param {*} id - Company ID
 * @param {*} data  - Company data
 * @returns {Promise} - Returns the updated company
 */
export const updateCompany = async (id, data) => {
  const response = await api.patch(`/company/${id}`, data);
  return response.data;
};

/**
 * * Fetches the user types
 * @returns {Promise} - Returns account types
 */
export const fetchAccountTypes = async () => {
  const response = await api.get(`/misc/account-types`);
  return response.data;
};

/**
 * * Fetches all industries
 * @returns {Promise} - Returns industries
 */
export const fetchIndustries = async () => {
  const response = await api.get(`/misc/industries`);
  return response.data;
};

/**
 * * Fetches all accounting softwares
 * @returns {Promise} - Returns accounting softwares
 */
export const fetchAccountingSoftwares = async () => {
  const response = await api.get(`/misc/acct-software`);
  return response.data;
};

/**
 * * Changes the user password
 * @param {*} data - Password data
 * @returns {Promise} - Returns the response data
 */
export const changePassword = async (data) => {
  const response = await axios.post(`https://${domain}/dbconnections/change_password`, data);
  return response.data;
};
