import api from './api';

export const fetchBankList = async () => {
  const response = await api.get(`/banks/list`);
  return response.data;
};

export const resolveBankAccount = async (data) => {
  const response = await api.post(`/banks/resolve-name`, data);
  return response.data;
};

export const addBankAccount = async (data) => {
  const response = await api.post(`/profile/bank/accounts/add`, data);
  return response.data;
};

export const fetchUserBankAccounts = async () => {
  const response = await api.get(`/profile/bank/accounts`);
  return response.data;
};

export const setDefaultBankAccount = async (id) => {
  const response = await api.put(`/profile/bank/accounts/${id}/set-default`)
  return response.data
}

export const deleteBankAccount = async (id) => {
  const response = await api.delete(`/profile/bank/accounts/${id}`)
  return response.data
}