import ProfileDropdown from '@/components/ProfileDropdown';
import React from 'react';
import { SupportStyles } from './support.styles';
import withAuthentication from '@/hooks/withAuthentication';

const Support = () => {
  return (
    <SupportStyles>
      <div className="support-header">
        <div className="title-view">{/* <h3>ABC Ventures</h3> */}</div>
        <ProfileDropdown />
      </div>
      <div className="support-wrapper">Support</div>
    </SupportStyles>
  );
};

export default withAuthentication(Support);
