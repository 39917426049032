import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';
import { useSelector } from 'react-redux';
import { appSelector } from '@/redux/features/app.slice';
import { numberWithCommas } from '@/utils/helpers';
import { useMutation } from '@tanstack/react-query';
import { makePayment } from '@/services/financingApi';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';

function ConfirmationModal({
  showModal,
  setShowModal,
  setShowAuthorisationModal,
  setShowAccountInfoModal,
  setPaymentData
}) {
  const { disbursedPaymentData } = useSelector(appSelector);

  const handleMakePayment = useMutation(({ id, data }) => makePayment(id, data), {
    onSuccess: (data) => {
      setShowAccountInfoModal(true);
      setPaymentData(data?.data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleConfirm = () => {
    if (disbursedPaymentData?.channel === 'wallet') {
      setShowAuthorisationModal(true);
    } else {
      const data = {
        amount: parseFloat(disbursedPaymentData?.amount),
        requestId: disbursedPaymentData?.requestId,
        channel: disbursedPaymentData?.channel
      };
      handleMakePayment.mutate({ id: disbursedPaymentData?.requestId, data });
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <Modal
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ModalHeader>
                  <span></span>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <ReactSVG src={icons.closeIcon} />
                  </button>
                </ModalHeader>

                <ContentView>
                  <div className="icon">
                    <span>?</span>
                  </div>
                  {disbursedPaymentData?.channel === 'wallet' ? (
                    <p>
                      Are you sure that you want to pay the amount of ₦
                      {numberWithCommas(parseFloat(disbursedPaymentData?.amount, 10) || '00.00')}{' '}
                      from your Billboxx balance?
                    </p>
                  ) : (
                    <p>
                      Are you sure that you want to pay the amount of ₦
                      {numberWithCommas(parseFloat(disbursedPaymentData?.amount, 10) || '00.00')}?
                    </p>
                  )}
                  <button type="button" className="withdraw" onClick={handleConfirm}>
                    {handleMakePayment.isLoading ? (
                      <Oval color="#fff" height={20} width={20} />
                    ) : (
                      "Yes, I'm sure"
                    )}
                  </button>
                </ContentView>
              </ModalContent>
            </Modal>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default ConfirmationModal;

export const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9010;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled(motion.div)`
  background-color: ${(props) => props.theme?.colors?.white};
  z-index: 30000;
  position: fixed;
  border-radius: 4px;
  max-width: 500px;
  padding: 30px 0px;
  padding-top: 0;
  padding-bottom: 20px;
  border-radius: 30px;
  max-height: 90%;
  overflow-y: scroll;

  @media ${device.mobile} {
    width: calc(100% - 32px);
    border-radius: 16px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    width: 100%;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${(props) => props.theme?.colors?.secondary};
  }
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @media ${device.mobile} {
    width: 100%;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${(props) => props.theme?.colors?.secondary};
    margin-top: 20px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme?.colors?.secondary};
    margin-top: 10px;
    font-weight: 400;
    max-width: 80%;
    text-align: center;
    line-height: 19px;
  }

  .icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${(props) => props.theme?.colors?.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    span {
      font-size: 2rem;
      font-weight: 600;
      color: ${(props) => props.theme?.colors?.white};
    }
  }

  button.withdraw {
    width: 100%;
    height: 64px;
    background: ${(props) => props.theme?.colors?.primary};
    color: ${(props) => props.theme?.colors?.white};
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;
  }
`;
