/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { device } from '@/constants/breakpoints';
import { paymentMethods } from '@/utils/data.utils';
import Button from '@/components/Button/Button';
import PaymentTypeModal from './PaymentTypeModal';
import TransferTypeModal from '@/pages/Bills/components/bankTransfer/TransferTypeModal';

function PayNowModal({ showModal, setShowModal, invoice }) {
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods[0]?.value);
  const [showTransferTypeModal, setShowTransferTypeModal] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);

  const handleContinue = () => {
    setShowModal(false);
    if (selectedMethod === 'card') {
      setShowPromptModal(true);
    }

    if (selectedMethod === 'bank-transfer') {
      setShowTransferTypeModal(true);
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Select payment type</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="form-view">
                  {paymentMethods?.map((method) => {
                    const Icon = method?.icon;
                    return (
                      method?.value !== 'billboxx-balance' && (
                        <div
                          className={`option ${
                            selectedMethod === method?.value && method?.available ? 'selected' : ''
                          }`}
                          key={method?.value}
                          onClick={() =>
                            setSelectedMethod(method?.available ? method?.value : 'card')
                          }>
                          {selectedMethod === method?.value && method?.available && (
                            <CheckedIcon className="checked" />
                          )}
                          <div className="icon">
                            <Icon />
                          </div>
                          <div className={`info ${method?.available ? '' : 'fade'}`}>
                            <h3>{method?.title}</h3>
                            <p>{method?.description}</p>
                            <span>{method?.subText}</span>
                            {method?.value === 'billboxx-balance' && null}
                            <br />
                            {method?.available ? null : <h4 className="soon">Coming soon</h4>}
                          </div>
                        </div>
                      )
                    );
                  })}

                  {/* Button view */}
                  <div className="button-view">
                    <Button type="button" styleType="primary" width="100%" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PaymentTypeModal
        showModal={showPromptModal}
        setShowModal={setShowPromptModal}
        invoice={invoice}
        selectedMethod={selectedMethod}
      />

      <TransferTypeModal
        showModal={showTransferTypeModal}
        setShowModal={setShowTransferTypeModal}
        bill={invoice}
        selectedMethod={selectedMethod}
      />
    </>
  );
}

export default PayNowModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 94%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .form-view {
    width: 100%;
    margin-top: 30px;

    @media ${device.phone} {
      width: 100%;
      padding-left: 0px;
    }

    .option {
      width: 100%;
      display: flex;
      align-items: flex-start;
      border-radius: 16px;
      padding: 12px 10px;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: all 0.1s ease-in-out;
      margin-bottom: 10px;

      .checked {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors?.layer};
        margin-right: 24px;
      }

      .info {
        width: calc(100% - 64px);

        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};
          margin-bottom: 8px;
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 400;
          margin-bottom: 4px;
        }

        span {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
        }

        ul {
          list-style: disc;
          margin: 0;
          padding: 0;

          li {
            font-size: 0.875rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.secondary};
            font-weight: 400;
            margin-bottom: 4px;
          }
        }

        .soon {
          font-size: 0.875rem;
          line-height: 19px;

          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
          margin-top: 10px;
          background-color: ${({ theme }) => theme.colors?.inputBackground};
          opacity: 1;
          padding: 4px 8px;
          text-align: center;
        }

        .balance-view {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 10px;

          span {
            font-size: 0.875rem;
            line-height: 19px;
            background-color: ${({ theme }) => theme.colors?.background};
            border-radius: 1.62rem;
            padding: 0.18rem 0.45rem;
            color: ${({ theme }) => theme.colors?.black};
            font-weight: 300;
            margin-right: 10px;
          }

          a {
            font-size: 0.875rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.primary};
            font-weight: 500;
            text-decoration: underline;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      .fade {
        opacity: 0.4;
      }
    }

    .selected {
      border: 1px solid ${({ theme }) => theme.colors?.primary};
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }
`;
