/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { device } from '@/constants/breakpoints';
import { changeSecurityPin } from '@/services/securityApi';
import Button from '@/components/Button/Button';
import OTPInput from 'react-otp-input';
import SuccessPopup from '@/components/SuccessPopup';

export default function ChangePinModal({ showModal, setShowModal }) {
  const [currentPin, setCurrentPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmedPin, setConfirmedPin] = useState('');
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmedPin, setShowConfirmedPin] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCreatePin = useMutation(changeSecurityPin, {
    onSuccess: () => {
      // toast.success('Transaction PIN reset successfully');
      setCurrentPin('');
      setNewPin('');
      setConfirmedPin('');
      setShowModal(false);
      setShowSuccessModal(true);
      setShowConfirmedPin(false);
      setShowNewPin(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const formData = {
      type: 't-pin',
      oldValue: currentPin,
      newValue: confirmedPin
    };

    if (!currentPin) {
      toast.error('Current PIN is required');
    } else {
      if (newPin === confirmedPin) {
        handleCreatePin.mutate(formData);
      } else {
        toast.error('New transaction PINs do not match');
      }
    }
  };
  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Change Transaction PIN</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>
                {!showNewPin && !showConfirmedPin && (
                  <FormVieww>
                    <div className="input-vieww">
                      <div className="label-view">
                        <label htmlFor="email">Enter current PIN</label>
                      </div>
                      <OTPInput
                        value={currentPin}
                        onChange={setCurrentPin}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otp-inputt"
                        inputType="number"
                        skipDefaultStyles
                        shouldAutoFocus
                        inputStyle="otp"
                        isInputNum
                      />
                    </div>

                    {/* Button view */}
                    <div className="button-view">
                      <Button
                        type="button"
                        styleType="primary"
                        width="100%"
                        onClick={
                          currentPin.length === 6
                            ? () => {
                                setShowNewPin(true);
                                setShowConfirmedPin(false);
                              }
                            : null
                        }
                        disabled={currentPin.length < 6}>
                        Continue
                      </Button>
                    </div>
                  </FormVieww>
                )}

                {showNewPin && (
                  <FormVieww>
                    <button
                      className="back"
                      onClick={() => {
                        setShowConfirmedPin(false);
                        setShowNewPin(false);
                      }}>
                      <BackIcon />
                    </button>
                    <div className="input-vieww">
                      <div className="label-view">
                        <label htmlFor="email">Enter new PIN</label>
                      </div>
                      <OTPInput
                        value={newPin}
                        onChange={setNewPin}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otp-inputt"
                        inputType="number"
                        skipDefaultStyles
                        shouldAutoFocus
                        inputStyle="otp"
                      />
                    </div>
                    {/* Button view */}
                    <div className="button-view">
                      <Button
                        type="button"
                        styleType="primary"
                        width="100%"
                        onClick={
                          newPin.length === 6
                            ? () => {
                                setShowNewPin(false);
                                setShowConfirmedPin(true);
                              }
                            : null
                        }
                        disabled={newPin.length < 6}>
                        Continue
                      </Button>
                    </div>
                  </FormVieww>
                )}

                {showConfirmedPin && (
                  <FormVieww>
                    <button
                      className="back"
                      onClick={() => {
                        setShowConfirmedPin(false);
                        setShowNewPin(true);
                      }}>
                      <BackIcon />
                    </button>
                    <div className="input-vieww">
                      <div className="label-view">
                        <label htmlFor="email">Confirm new PIN</label>
                      </div>
                      <OTPInput
                        value={confirmedPin}
                        onChange={setConfirmedPin}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle="otp-inputt"
                        inputType="number"
                        skipDefaultStyles
                        shouldAutoFocus
                        inputStyle="otp"
                        isInputNum
                      />
                    </div>

                    <div className="button-view">
                      <Button
                        type="button"
                        styleType="primary"
                        width="100%"
                        onClick={onSubmit}
                        disabled={
                          confirmedPin.length < 6 || newPin.length < 6 || currentPin.length < 6
                        }>
                        {handleCreatePin.isLoading ? (
                          <Oval
                            color="#FFF"
                            secondaryColor="#ddd"
                            height={30}
                            width={30}
                            strokeWidth={4}
                            ariaLabel="loading"
                          />
                        ) : (
                          'Change transaction PIN'
                        )}
                      </Button>
                    </div>
                  </FormVieww>
                )}
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <SuccessPopup
        showModal={showSuccessModal}
        setShowModal={setShowSuccessModal}
        mainText="Your PIN is set"
        subText="You can now use your new PIN to make transactions"
      />
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FormVieww = styled.form`
  width: 100%;
  margin-top: 30px;

  @media ${device.phone} {
    width: 100%;
  }

  .back {
    margin-bottom: 30px;
  }

  .input-vieww {
    width: 100%;
    margin-bottom: 26px;

    .label-view {
      width: 100%;
      display: flex;
      margin-bottom: 8px;

      label {
        font-size: 1rem;
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 8px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .otp-inputt {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .otp {
        width: calc(100% / 6 - 10px);
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
        text-align: center;
        background-color: ${({ theme }) => theme.colors?.inputBackground};
        border: none;
        -webkit-text-security: disc;

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors?.primary};
          border: none;
        }
      }
    }
  }

  .button-view {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;

    @media ${device.phone} {
      width: 100%;
    }

    button {
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .back-button {
      width: 140px;
      height: 64px;
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: transparent;
      border: none;
      text-align: left;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
      border: 1px solid ${({ theme }) => theme.colors?.secondary};
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
