import { numberWithCommas } from '@/utils/helpers';
import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

const DoughnutChart = ({ data }) => {
  const renderLegend = () => {
    return (
      <CustomLegend
      >
        {data.map((entry, index) => (
          <div
            key={`legend-${index}`}
            className="legend-item"
          >
            <div
              className="legend-color"
              style={{
                backgroundColor: entry.color
              }}
            ></div>
            <div className="legend-label">
              <span>{entry.label === "notDue" ? "Not Due" : `${entry.label} Days`}</span>
            </div>
          </div>
        ))}
      </CustomLegend>
    );
  };

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
      const { label, value } = payload[0].payload;
      return (
        <CustomedTooltip className="custom-tooltip">
          <p className="desc">Age: {label === "notDue" ? "Not Due" : `${label} Days`}</p>
          <p className="label">{`₦${numberWithCommas(value || '00.00')}`}</p>
        </CustomedTooltip>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          dataKey="value"
          innerRadius={50}
          outerRadius={80}
          paddingAngle={0}
          startAngle={100}
          endAngle={500}
          spacing={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: 'none' }} offset={5} />
        <Legend content={renderLegend} align="center" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutChart;

const CustomedTooltip = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 12px;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 4.7644px 4.7644px rgba(50, 50, 71, 0.08),
    0px 4.7644px 9.5288px rgba(50, 50, 71, 0.06);
  padding: 12px;

  .desc {
    font-size: 0.8rem;
    margin-bottom: 4px;
  }

  .label {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    font-weight: 600;
    margin-top: 3px;
  }
`;

const CustomLegend = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
flex-wrap: wrap;
margin-top: 0px;
top: 0px;
margin-bottom: 20px;

.legend-item {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}


.legend-color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-label {
  font-size: 0.8rem;    
  color: ${({ theme }) => theme.colors?.secondary};      
}
`
