import { device } from '@/constants/breakpoints';
import { appSelector } from '@/redux/features/app.slice';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const ProgressIndicator = () => {
  const { onboardingProgress } = useSelector(appSelector);

  return (
    <IndicatorStyles>
      <div className={`indicator ${onboardingProgress >= 10 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 20 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 30 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 40 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 50 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 60 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 70 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 80 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 90 ? 'done' : ''}`}></div>
      <div className={`indicator ${onboardingProgress >= 100 ? 'done' : ''}`}></div>
    </IndicatorStyles>
  );
};

export default ProgressIndicator;

const IndicatorStyles = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    width: 100%;
    flex-direction: row;
  }

  .indicator {
    width: 1px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors?.white};
    border: 1px solid ${({ theme }) => theme.colors?.line};

    @media ${device.phone} {
      width: calc(100% / 10);
      height: 1px;
      margin-bottom: 20px;
    }
  }

  .done {
    border-color: ${({ theme }) => theme.colors?.primary};
  }
`;
