import React from 'react';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import { Container, LogoView, OnboardingStyles } from './onboarding.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserInfo from './components/UserInfo';
import UserType from './components/UserType';
import CompanyName from './components/CompanyName';
import CompanyLocation from './components/CompanyLocation';
import Industry from './components/Industry';
import BillVolume from './components/BillVolume';
import BillPayments from './components/BillPayments';
import InvoiceVolume from './components/InvoiceVolume';
import CoorperateBank from './components/CooperateBank';
import AccountingSoftware from './components/AccountingSoftware';
import withAuthentication from '@/hooks/withAuthentication';
import { useMutation } from '@tanstack/react-query';
import { logoutUser } from '@/services/authApi';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';
import toast from 'react-hot-toast';
import ReactPixel from 'react-facebook-pixel';
const hostname = window.location.hostname.split('.')[0];

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page = searchParams.get('page');

  const isProduction = hostname === 'app';

  if (isProduction) {
    ReactPixel.track('CompleteRegistration');
  }

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      dispatch(resetAppStateOnLogout());
      secureLocalStorage.removeItem('company');
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <OnboardingStyles>
      <Container>
        <LogoView>
          <span></span>
          <Logo />
          <button onClick={() => handleLogoutUser.mutate({})}>
            {handleLogoutUser.isLoading ? 'Logging out...' : 'Logout'}
          </button>
        </LogoView>

        {page === 'user-info' && <UserInfo />}
        {page === 'user-type' && <UserType />}
        {page === 'company-name' && <CompanyName />}
        {page === 'company-location' && <CompanyLocation />}
        {page === 'industry' && <Industry />}
        {page === 'bill-volume' && <BillVolume />}
        {page === 'payment-volume' && <BillPayments />}
        {page === 'invoice-volume' && <InvoiceVolume />}
        {page === 'bank' && <CoorperateBank />}
        {page === 'software' && <AccountingSoftware />}
      </Container>
    </OnboardingStyles>
  );
};

export default withAuthentication(Onboarding);

// export default Onboarding;
