import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { device } from '@/constants/breakpoints';
import { applyForLoan, calculateLoan, fetchUserInfo } from '@/services/billFinancingApi';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appSelector,
  setLoanAmount,
  setLoanBill,
  setPayLaterData
} from '@/redux/features/app.slice';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import FetchLoader from '@/components/FetchLoader';
import TermsAgreementModal from './TermsAgreementModal';
import { fetchTerms } from '@/services/termsApi';

const loanTenures = [
  { value: 'THIRTY_DAYS', label: '30', percentage: '5' },
  { value: 'FORTY_FIVE_DAYS', label: '45', percentage: '7.5' },
  { value: 'SIXTY_DAYS', label: '60', percentage: '10' }
];

function ConfirmLoanModal({ showModal, setShowModal }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loanBill, loanAmount, payLaterData } = useSelector(appSelector);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedTenure, setSelectedTenure] = useState(null);
  const [calculatedLoan, setCalculatedLoan] = useState(null);
  const [billTerms, setBillTerms] = useState(null);

  const userInfo = useQuery({
    queryKey: ['userInfo'],
    queryFn: fetchUserInfo,
    onSuccess: () => {},
    onError: () => {}
  });

  const terms = useQuery({
    queryKey: ['terms'],
    queryFn: fetchTerms,
    onSuccess: (data) => {
      setBillTerms(data?.data[0]?.content);
    },
    onError: () => {}
  });

  const handleCalculateLoan = useMutation(calculateLoan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      setCalculatedLoan(data?.data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    dispatch(
      setPayLaterData({
        ...payLaterData,
        billId: loanBill?.id,
        amount: parseInt(calculatedLoan?.loanAmount, 10),
        loanTenure: calculatedLoan?.tenure
      })
    );
    setShowTermsModal(true);
  };

  if (userInfo.isLoading || terms.isLoading) {
    return <FetchLoader />;
  }

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======= Content here ====== */}
              <div className="header-view">
                <button type="button" className="back" onClick={() => setShowModal(false)}>
                  <BackIcon />
                </button>
              </div>

              <WrapperView>
                <TitleView>
                  <h1>Purchase Financing</h1>
                  <p>Information about the bill</p>
                </TitleView>

                <DetailsView>
                  <div className="title-view">
                    <h1>Facility details</h1>
                    <p>Kindly confirm all the details are correct</p>
                  </div>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Invoice no.:</span>
                      <p className="details-value">{loanBill?.billOrInvoiceNumber}</p>
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Request:</span>
                      <p className="details-value">₦{numberWithCommas(loanAmount || '00.00')}</p>
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Interest:</span>
                      {calculatedLoan ? (
                        <p className="details-value">
                          ₦{numberWithCommas(calculatedLoan?.interest || '00.00')}
                        </p>
                      ) : (
                        <p className="details-value">₦0.00</p>
                      )}
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Repayment:</span>
                      {calculatedLoan ? (
                        <p className="details-value">
                          ₦{numberWithCommas(calculatedLoan?.repaymentAmount || '00.00')}
                        </p>
                      ) : (
                        <p className="details-value">₦0.00</p>
                      )}
                    </div>
                  </div>
                  <div className="details-row">
                    <div className="details-item">
                      <span className="details-label">Start date:</span>
                      {calculatedLoan ? (
                        <p className="details-value">
                          {format(new Date(calculatedLoan?.proposedStartDate), 'do MMM, yyyy')}
                        </p>
                      ) : (
                        <p className="details-value">N/A</p>
                      )}
                    </div>
                    <div className="details-item left">
                      <span className="details-label">Due date:</span>
                      {calculatedLoan ? (
                        <p className="details-value">
                          {format(new Date(calculatedLoan?.proposedDueDate), 'do MMM, yyyy')}
                        </p>
                      ) : (
                        <p className="details-value">N/A</p>
                      )}
                    </div>
                  </div>

                  {calculatedLoan && (
                    <div className="info">
                      <p>
                        The <strong>start and due date</strong> of this loan may change depending on
                        the time the application is approved
                      </p>
                    </div>
                  )}

                  <div className="tenures">
                    <h3>Loan tenure (No of days)</h3>
                    <p>Select the best repayment tenure for you</p>
                    <div className="tenure-row">
                      {loanTenures.map((tenure, index) => (
                        <div
                          key={tenure?.value}
                          className={
                            selectedTenure?.value === tenure?.value
                              ? 'tenure-item selected'
                              : 'tenure-item'
                          }
                          onClick={() => {
                            setSelectedTenure(tenure);
                            handleCalculateLoan.mutate({
                              amount: parseFloat(loanAmount, 10),
                              loanTenure: tenure.value
                            });
                          }}>
                          {selectedTenure?.value === tenure?.value &&
                            !handleCalculateLoan.isLoading && <CheckedIcon />}
                          {handleCalculateLoan.isLoading &&
                            selectedTenure?.value === tenure?.value && (
                              <Oval color="#0d3c61" height={15} width={15} />
                            )}
                          <div className="tenure-circle">
                            <p className="day">{tenure.label}</p>
                            <p className="percent">{tenure.percentage}%</p>
                            <p className="interest">Interest</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="btn-view">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!selectedTenure}
                      onClick={onSubmit}>
                      Agree to continue
                    </button>
                  </div>
                </DetailsView>
              </WrapperView>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <TermsAgreementModal
        showModal={showTermsModal}
        setShowModal={setShowTermsModal}
        content={billTerms}
      />
    </>
  );
}

export default ConfirmLoanModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
`;

const WrapperView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }
`;

const TitleView = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.secondary};
    line-height: 19px;
  }
`;

const DetailsView = styled.div`
  width: 45%;
  margin-top: 20px;

  @media ${device.phone} {
    width: 100%;
  }

  .title-view {
    width: 100%;

    h1 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
    }

    p {
      font-size: 1rem;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 10px;
    }
  }

  .details-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .details-item {
      width: 100%;
      display: flex;
      flex-direction: column;

      .details-label {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .details-value {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 500;
        margin-top: 5px;
      }
    }

    .left {
      .details-label {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        text-align: right;
      }

      .details-value {
        font-size: 1rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 500;
        margin-top: 5px;
        text-align: right;
      }
    }
  }

  .info {
    width: 100%;
    margin-top: 20px;
    background-color: ${(props) => props.theme.colors?.inputBackground};
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1rem;
      color: ${(props) => props.theme.colors?.secondary};
      text-align: center;
      line-height: 18px;

      strong {
        font-weight: 500;
      }
    }
  }

  .tenures {
    width: 100%;
    margin-top: 40px;

    h3 {
      font-size: 1rem;
      color: ${(props) => props.theme.colors?.secondary};
    }

    p {
      font-size: 1rem;
      color: ${(props) => props.theme.colors?.secondary};
      margin-top: 10px;
    }

    .tenure-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;

      .tenure-item {
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
        margin-right: 20px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        svg {
          position: absolute;
          top: 5px;
          right: 5px;
        }

        .tenure-circle {
          width: 70px;
          height: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0px;
          border-radius: 50%;
          background-color: ${(props) => props.theme.colors?.info};

          .day {
            font-size: 1.2rem;
            color: ${(props) => props.theme.colors?.white};
            font-weight: 500;
            border-bottom: 1px solid ${(props) => props.theme.colors?.white};
            padding-bottom: 2px;
            margin-top: 0;
          }

          .percent {
            font-size: 1rem;
            color: ${(props) => props.theme.colors?.white};
            margin-top: 5px;
          }

          .interest {
            font-size: 0.6rem;
            color: ${(props) => props.theme.colors?.white};
            margin-top: 0;
          }
        }
      }

      .selected {
        border: 1px solid ${(props) => props.theme.colors?.primary};
      }
    }
  }

  .btn-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 70px;

    button {
      width: 200px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
