import React, { useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import useOnClickOutside from '@/hooks/useClickOutside';
import FilterSort from '@/components/FilterSort';
import useDebounce from '@/hooks/useDebounce';
import TableLoader from '@/components/TableLoader';
import Pagination from '@/components/Pagination';
import SortingComponent from '@/components/SortingComponent';
import { fetchInvoiceDiscounts } from '@/services/financingApi';
import {
  ContentHeader,
  ContentView,
  EmptyView,
  HeaderActions,
  SearchView,
  Table,
  TableMobileView
} from './styles';
import RequestDetails from './RequestDetails';
import AcceptOfferModal from './AcceptOfferModal';

function Offers({ enabledFetch, metrics }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;
  const currentPage = searchParams.get('page');
  const [showMenu, setShowMenu] = useState(false);
  const [, setShowFilterMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showOfferDetailsModal, setShowOfferDetailsModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(false);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);
  const ref = useRef();
  const filterRef = useRef();

  const handleFilterSortApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  useOnClickOutside(ref, () => setShowMenu(false));
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const billFinanceRequests = useQuery({
    queryKey: [
      'invoice-discount-offers',
      {
        status: 'OFFER',
        page: currentPage,
        pageLimit,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchInvoiceDiscounts,
    enabled: enabledFetch
  });

  const onPageChange = (page) => {
    setSearchParams(`tab=offers&page=${page}`);
  };

  return (
    <ContentView>
      <ContentHeader>
        <div className="title-view">
          <h3>
            Total: ₦{numberWithCommas(metrics?.sum || '00.00')} (
            {numberWithCommas2(metrics?.count || '00.00')})
          </h3>
        </div>

        <HeaderActions>
          <SearchView>
            <input
              type="search"
              placeholder="Search requests"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className="search-icon" />
          </SearchView>
          <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
          <FilterSort onApply={handleFilterSortApply} />
        </HeaderActions>
      </ContentHeader>

      {billFinanceRequests?.isLoading ? (
        <TableLoader />
      ) : billFinanceRequests?.data?.data?.data?.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th className="name">Lender</th>
                <th className="amount">Offer ID</th>
                <th className="value">Offer</th>
                <th className="tenure">Tenure</th>
                <th className="amount">Interest</th>
                <th className="amount">Created</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {billFinanceRequests?.data?.data?.data?.map((request, index) => (
                <tr key={index}>
                  <td className="name">
                    {request?.loan?.loanRequest[0]?.lender?.companies[0]?.name || 'N/A'}
                  </td>
                  <td className="amount">{request?.requestId || 'N/A'}</td>
                  <td className="value">₦{numberWithCommas(request?.loan?.amount || '00.00')}</td>
                  <td className="tenure">{request?.loan?.tenure} Days</td>
                  <td className="amount">₦{numberWithCommas(request.loan?.interest || '00.00')}</td>
                  <td className="amount">{format(new Date(request?.createdAt), 'do MMM, yyyy')}</td>
                  <td className="actions">
                    <button
                      type="button"
                      className="btn view"
                      onClick={() => {
                        setShowOfferDetailsModal(true);
                        setSelectedRequest(request?.id);
                      }}>
                      <ViewIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMenu(true);
                        setCurrentIndex(index);
                        setSelectedRequest(request?.id);
                      }}
                      className="btn more">
                      <MoreIcon />
                    </button>

                    <AnimatePresence>
                      {showMenu && currentIndex === index && (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          <button
                            type="button"
                            onClick={() => {
                              setShowOfferDetailsModal(true);
                              setSelectedRequest(request?.id);
                            }}>
                            Review offer
                          </button>
                          <button type="button" onClick={() => setShowAcceptOfferModal(true)}>
                            Accept offer
                          </button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <TableMobileView>
            {billFinanceRequests?.data?.data?.data?.map((request, index) => (
              <div className="item-view" key={request?.id}>
                <div className="row">
                  <div className="item">
                    <p>Lender</p>
                    <p className="value">
                      {request?.loan?.loanRequest[0]?.lender?.companies[0]?.name || 'N/A'}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setShowMenu(true);
                      setCurrentIndex(index);
                      setSelectedRequest(request?.id);
                    }}
                    className="btn more">
                    <MoreIcon />
                  </button>
                </div>

                <div className="row end">
                  <div className="item">
                    <p>Offer ID</p>
                    <p className="value">{request?.requestId || 'N/A'}</p>
                  </div>
                  <div className="item">
                    <p>Offer</p>
                    <p className="value">₦{numberWithCommas(request?.loan?.amount || '00.00')}</p>
                  </div>
                </div>

                <AnimatePresence>
                  {showMenu && currentIndex === index && (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        type="button"
                        onClick={() => {
                          setShowOfferDetailsModal(true);
                          setSelectedRequest(request?.id);
                        }}>
                        Review offer
                      </button>
                      <button type="button" onClick={() => setShowAcceptOfferModal(true)}>
                        Accept offer
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </TableMobileView>
        </>
      ) : (
        <EmptyView>
          <EmptyIcon />
          <h3>Nothing to see here!</h3>
        </EmptyView>
      )}

      <div className="pagination">
        <Pagination
          itemsPerPage={pageLimit}
          totalItems={billFinanceRequests?.data?.data?.meta?.totalItems}
          currentPage={Number(currentPage)}
          onPageChange={onPageChange}
        />
      </div>

      {showOfferDetailsModal && (
        <RequestDetails
          showModal={showOfferDetailsModal}
          setShowModal={setShowOfferDetailsModal}
          requestId={selectedRequest}
        />
      )}

      {showAcceptOfferModal && (
        <AcceptOfferModal
          showModal={showAcceptOfferModal}
          setShowModal={setShowAcceptOfferModal}
          requestId={selectedRequest}
        />
      )}
    </ContentView>
  );
}

export default Offers;
