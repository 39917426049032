import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { useNavigate } from 'react-router-dom';

const BeneficiarySuccessModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <section className="modal-content">
              <div className="logo">
                <p onClick={() => setShowModal(false)}>Back</p>
                <img
                  src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695118944/Billboxx/Billboxx-web/BillboxxLogo_aedtrv.png"
                  alt="Billboxx Logo"
                />
              </div>

              <div className="content-info">
                <div className="success-info">
                  <img
                    src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695119243/Billboxx/Billboxx-web/success_yyljk6.png"
                    alt="success-img"
                  />
                  <p className="bold-text">Beneficiary added Successfully</p>
                  <p>
                    Kindly refer to your beneficiary list for update
                  </p>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      setShowModal(false);
                      navigate(`/banking?tab=manage-beneficiaries`);
                    }}
                  >
                    View beneficiaries
                  </button>
                </div>

                {/* <div className="banner">
                  <div className="text">
                    <p>
                      Your payment has been successfully processed. You can continue to view your
                      bills or make another payment.
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      navigate('/make-payments/bills?tab=draft');
                    }}>
                    View Bills
                  </button>
                  <img
                    className="banner-image"
                    src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695119010/Billboxx/Billboxx-web/payment-image_r672i6.png"
                    alt=""
                  />
                </div> */}
              </div>
            </section>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default BeneficiarySuccessModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: flex;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    width: 100vw;
    height: 100vh;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
    }

    .modal-content {
      margin: 1.75rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        width: 12%;

        img {
          width: 100%;
        }

        @media ${device.phone} {
          width: 30%;
        }
      }

      @media ${device.phone} {
        margin: 2rem 0;
        gap: 7rem;
      }

      .content-info {
        width: 50%;
        margin-top: 12rem;

        .success-info {
          text-align: center;

          img {
            width: 20%;
          }

          .bold-text {
            font-size: 32px;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
          }

          p {
            margin-top: 1rem;
            /* margin-bottom: 2rem; */
            color: ${(props) => props.theme.colors?.secondary};
          }

          .btn {
            margin-top: 2rem;
            font-weight: 600;
            padding: 14px 26px;
            border-radius: 12px;
            text-decoration: none;
            color: ${(props) => props.theme.colors?.white};
            background-color: ${(props) => props.theme.colors?.primary};
          }

          @media ${device.phone} {
            img {
              width: 30%;
              margin-bottom: 0;
            }

            .bold-text {
              font-size: 26px;
            }

            p {
              font-size: 15px;
            }
          }
        }

        .banner {
          position: relative;
          margin: 2.75rem 0;
          padding: 1.75rem 2rem;
          border-radius: 0.75rem;
          background-color: ${(props) => props.theme.colors?.primaryFocus};

          .text {
            margin-bottom: 2.45rem;
            color: ${(props) => props.theme.colors?.secondary};

            p {
              margin-bottom: 1rem;
              max-width: 70%;
              line-height: 19px;

              @media ${device.phone} {
                font-size: 14px;
              }
            }
          }

          .btn {
            font-weight: 600;
            padding: 14px 26px;
            border-radius: 12px;
            text-decoration: none;
            color: ${(props) => props.theme.colors?.white};
            background-color: ${(props) => props.theme.colors?.primary};
          }

          .banner-image {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50%;
          }
        }

        @media ${device.phone} {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;
