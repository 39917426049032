/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import SearchDropdown from '@/components/SearchDropdown';
import Input from '@/components/Input/Input';
import TextArea from '@/components/TextArea/TextArea';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchVendors } from '@/services/vendorApi';
import FetchLoader from '@/components/FetchLoader';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { updateBill } from '@/services/billsApi';
import NewVendorModal from '@/pages/Vendors/components/NewVendorModal';
import { getMimeTypeFromUrl, urlToFile } from '@/utils/helpers';
import { device } from '@/constants/breakpoints';
import FilePicker2 from '@/components/FilePicker2';
import useUser from '@/hooks/useUser';
import { fetchUser } from '@/services/userApi';
import FindVendorModal from './billFinancing/FindVendor';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 1, name: 'Daily', value: 'daily' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' },
  { id: 3, name: 'Quarterly', value: 'quarterly' },
  { id: 3, name: 'Yearly', value: 'yearly' }
];

function EditBillForm({ showModal, setShowModal, selectedBill }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showVendorForm, setShowVendorForm] = useState(false);
  const [showFindVendorModal, setShowFindVendorModal] = useState(false);
  const [vendor, setVendor] = useState(selectedBill?.vendor);
  const [frequency, setFrequency] = useState(selectedBill?.frequency);
  const [file, setFile] = useState(null);
  const [expires, setExpires] = useState(false);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const schema = yup
    .object({
      recurringStartDate: (() => {
        let validation = yup.string();
        if (frequency?.value !== 'one-time') {
          validation = validation.required('Start date is required');
        }
        return validation;
      })(),
      recurringEndDate: (() => {
        let validation = yup.string();
        if (!expires && frequency?.value !== 'one-time') {
          validation = validation.required('End date is required');
        }
        return validation;
      })(),
      amount: yup.string().required('Bill amount is required'),
      dueDate: yup.string().required('Due date is required'),
      description: yup.string().nullable(),
      billOrInvoiceNumber: yup.string().required('Bill number is required')
    })
    .required();

  useEffect(() => {
    setVendor(selectedBill?.vendor);
    setFrequency(billFrequency.find((item) => item.value === selectedBill?.frequency));
    setExpires(!selectedBill?.shouldExpireRecurring);
    if (selectedBill?.fileUrl) {
      // const fileType = getMimeTypeFromUrl(selectedBill?.fileUrl);
      // urlToFile(selectedBill?.fileUrl, `bill.${fileType?.split('/')[1]}`, fileType)
      //   .then((filee) => {
      //     setFile(filee);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
      setFile(selectedBill?.fileUrl);
    } else {
      setFile(null);
    }
  }, [selectedBill]);

  const vendors = useQuery({
    queryKey: ['vendors', { page: 1, pageLimit: 1000 }],
    queryFn: fetchVendors,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: selectedBill?.amount || '',
      billOrInvoiceNumber: selectedBill?.billOrInvoiceNumber,
      dueDate: new Date(selectedBill?.dueDate || Date.now())?.toISOString()?.substring(0, 10),
      recurringStartDate: selectedBill?.recurringStartDate
        ? new Date(selectedBill?.recurringStartDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      recurringEndDate: selectedBill?.recurringEndDate
        ? new Date(selectedBill?.recurringEndDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      description: selectedBill?.description
    }
  });

  useEffect(() => {
    reset({
      amount: selectedBill?.amount || '',
      billOrInvoiceNumber: selectedBill?.billOrInvoiceNumber,
      dueDate: new Date(selectedBill?.dueDate || Date.now())?.toISOString()?.substring(0, 10),
      recurringStartDate: selectedBill?.recurringStartDate
        ? new Date(selectedBill?.recurringStartDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      recurringEndDate: selectedBill?.recurringEndDate
        ? new Date(selectedBill?.recurringEndDate || Date.now())?.toISOString()?.substring(0, 10)
        : '',
      description: selectedBill?.description
    });
  }, [selectedBill, reset]);

  const handleUpdatetBill = useMutation(({ id, data }) => updateBill(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      navigate(
        `${
          selectedBill?.status === 'pending-approval'
            ? '/make-payments/bills?tab=approval'
            : `/make-payments/bills?tab=${selectedBill?.status}`
        }`
      );
      toast.success('Bill updated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onRequestSubmit = (data) => {
    const billData = {
      vendorId: vendor?.id,
      amount: data?.amount,
      frequency: frequency?.value,
      status: selectedBill?.status,
      billOrInvoiceNumber: data?.billOrInvoiceNumber,
      description: data?.description,
      dueDate: new Date(data?.dueDate).toISOString(),
      recurringStartDate: data?.recurringStartDate
        ? new Date(data?.recurringStartDate).toISOString()
        : undefined,
      recurringEndDate: data?.recurringEndDate
        ? new Date(data?.recurringEndDate).toISOString()
        : undefined,
      shouldExpireRecurring: expires ? 'no' : 'yes',
      fileUrl: file
    };

    const filteredBillData = Object.fromEntries(
      Object.entries(billData).filter(([_, v]) => v != null && v !== '')
    );

    handleUpdatetBill.mutate({ id: selectedBill?.id, data: filteredBillData });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>

            {vendors?.isLoading ? (
              <FetchLoader />
            ) : (
              <div className="form-view">
                <form>
                  {/* Vendor */}
                  <div className="input-view vendor">
                    <label htmlFor="vendor">Vendor</label>
                    <SearchDropdown
                      options={vendors?.data?.data?.items}
                      id="id"
                      name="companyName"
                      setValue={setVendor}
                      value={vendor}
                      placeholder="Select vendor"
                    />
                  </div>
                  <div className="add-view">
                    <button type="button" onClick={() => setShowFindVendorModal(true)}>
                      <PlusIcon /> Add new vendor
                    </button>
                  </div>

                  {/* Bill amount */}
                  <div className="input-view">
                    <label htmlFor="name">Bill amount</label>
                    <Controller
                      name="amount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter bill amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          defaultValue={value || ''}
                          onBlur={onBlur}
                          error={!!errors?.amount?.message}
                          className={`currency-input ${
                            errors?.amount && dirtyFields?.amount
                              ? ' has-error'
                              : dirtyFields?.amount
                              ? 'valid'
                              : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.billAmount && (
                      <span className="error-label">{errors?.billAmount?.message}</span>
                    )}
                  </div>

                  {/* Bill frequency */}
                  <div className="input-view">
                    <label htmlFor="vendor">Bill Frequency</label>
                    <SearchDropdown
                      options={billFrequency}
                      id="id"
                      name="name"
                      setValue={setFrequency}
                      value={frequency}
                      placeholder="Select frequency"
                    />
                  </div>

                  {frequency?.value !== 'one-time' && (
                    <>
                      {/* Frequency date */}
                      <div className="date-row">
                        <div className="input-view">
                          <label htmlFor="name">Start date</label>

                          <Input
                            type="date"
                            {...register('recurringStartDate')}
                            error={errors?.recurringStartDate?.message}
                          />

                          {errors?.recurringStartDate && (
                            <span className="error-label">
                              {errors?.recurringStartDate?.message}
                            </span>
                          )}
                        </div>
                        <div className="input-view">
                          <label htmlFor="name">End date</label>

                          <Input
                            type="date"
                            {...register('recurringEndDate')}
                            error={errors?.recurringEndDate?.message}
                            disabled={!!expires}
                            className={expires ? 'disabled' : ''}
                          />

                          {errors?.recurringEndDate && (
                            <span className="error-label">{errors?.recurringEndDate?.message}</span>
                          )}
                        </div>
                      </div>

                      {/* Checkbox */}
                      <div className="checkbox-view">
                        <input
                          type="checkbox"
                          name="expires"
                          id="expires"
                          value="false"
                          className="checkbox"
                          checked={expires}
                          onChange={() => setExpires(!expires)}
                        />
                        <label htmlFor="expires">Never expires</label>
                      </div>
                    </>
                  )}

                  <div className="input-view">
                    <label htmlFor="billOrInvoiceNumber">Bill (or Invoice) no.</label>
                    <Input type="text" {...register('billOrInvoiceNumber')} />
                    <div className="input-info-view">
                      <span className="info">*Bill or invoice number is auto generated</span>
                    </div>
                    {errors?.billOrInvoiceNumber && (
                      <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                    )}
                  </div>

                  {/* Due date */}
                  <div className="input-view">
                    <label htmlFor="name">Due date</label>

                    <Input type="date" {...register('dueDate')} error={errors?.dueDate?.message} />

                    {errors?.dueDate && (
                      <span className="error-label">{errors?.dueDate?.message}</span>
                    )}
                  </div>

                  {/* Description */}
                  <div className="input-view">
                    <label htmlFor="description">Description</label>
                    <TextArea
                      type="number"
                      {...register('description')}
                      error={!!errors?.description?.message}
                      placeholder="Enter description"
                    />

                    {errors?.description && (
                      <span className="error-label">{errors?.description?.message}</span>
                    )}
                  </div>

                  {/* Document */}
                  <FilePicker2 file={file} setFile={setFile} acceptsPDF />

                  {/* ===== CTA ====== */}
                  <div className="cta-view">
                    <button
                      type="button"
                      className="cta-view__save"
                      onClick={handleSubmit(onRequestSubmit)}>
                      {handleUpdatetBill.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Save changes'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </motion.div>
        </ModalView>
      )}
      <NewVendorModal
        showModal={showVendorForm}
        setShowModal={setShowVendorForm}
        setVendor={setVendor}
      />

      <FindVendorModal
        showModal={showFindVendorModal}
        setShowModal={setShowFindVendorModal}
        showAddNewVendor={showVendorForm}
        setShowAddNewVendor={setShowVendorForm}
        setVendor={setVendor}
      />
    </AnimatePresence>
  );
}

export default EditBillForm;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }

    .form-view {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 20px;

      form {
        width: 100%;
        max-width: 460px;

        .input-view {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          input {
            width: 100%;
          }

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .error-label {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.error};
            margin-top: 5px;
          }

          .input-info-view {
            width: 100%;
            display: flex;
            margin-top: 8px;

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }
        }

        .checkbox-view {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 30px;
          margin-top: 10px;

          .checkbox {
            margin-right: 5px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
            width: 16px;
            height: 16px;
          }

          label {
            cursor: pointer;
          }
        }

        .date-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .input-view {
            width: 48%;
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            input {
              width: 100%;
            }

            .disabled {
              background-color: ${({ theme }) => theme.colors?.layer2};

              &::placeholder {
                color: ${({ theme }) => theme.colors?.info};
              }
            }
          }
        }

        .vendor {
          margin-bottom: 10px;
        }

        .add-view {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          button {
            color: ${({ theme }) => theme.colors?.secondary};
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 10px;
            }
          }
        }

        .select-view {
          width: 100%;
          margin-bottom: 20px;

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;

            .option {
              width: 33.333%;
              display: flex;
              align-items: center;
              cursor: pointer;

              .radio-button {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                margin-right: 10px;

                .dot {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colors?.primary};
                }
              }

              p {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.colors?.secondary};
              }
            }
          }
        }

        .cta-view {
          width: 100%;
          display: flex;
          margin-top: 60px;
          margin-bottom: 50px;
          justify-content: space-between;

          button {
            width: 60%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 16px;
          }

          .cta-view__save {
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            margin-bottom: 20px;
            width: 100%;
          }
        }
      }
    }
  }
`;
