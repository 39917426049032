import { ReactComponent as CircleCheckedIcon } from '@/assets/icons/circle-checked2.icon.svg';
import { ReactComponent as CircleIcon } from '@/assets/icons/circle2.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import PaymentSuccessfulModal from '@/components/PaymentSuccessful';
import { device } from '@/constants/breakpoints';
import useTimer from '@/hooks/useTimer';
import { appSelector } from '@/redux/features/app.slice';
import { verifyBankTransfer } from '@/services/financingApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Watch } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

function TransferPolling({
  showPollingModal,
  setShowPollingModal,
  paymentData,
  setShowAccountInfoModal
}) {
  const { disbursedPaymentData } = useSelector(appSelector);
  const { minutes, seconds, setMinutes, setSeconds } = useTimer(10, 0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (showPollingModal && paymentData?.transaction?.reference) {
      setMinutes(10);
      setSeconds(0);
    }
  }, [paymentData, showPollingModal]);

  const handleVerifyPayment = useQuery({
    queryKey: ['disbursed-payment', { trxRef: paymentData?.reference }],
    queryFn: verifyBankTransfer,
    refetchInterval: 20000,
    staleTime: 25000,
    refetchIntervalInBackground: false,
    enabled: !!paymentData?.reference,
    onSuccess: handleSuccess,
    onError: handleError
  });

  function handleSuccess(data) {
    if (data?.data?.status === 'successful') {
      queryClient.invalidateQueries({ queryKey: ['bill-finance-disbursed'] });
      queryClient.invalidateQueries({ queryKey: ['bill-finance-closed'] });
      queryClient.invalidateQueries({ queryKey: ['bill-finance-metrics'] });
      if (disbursedPaymentData?.paymentType === 'full') {
        navigate('/financing/purchase-financing/?tab=closed&page=1');
      } else {
        navigate('/financing/purchase-financing/?tab=disbursed&page=1');
      }
    } else if (data?.data?.status === 'declined') {
      setShowPollingModal(false);
      setShowAccountInfoModal(true);
      toast.error('Payment declined, please try again later');
    } else {
      setShowPollingModal(true);
    }
  }

  function handleError(error) {
    toast.error(error?.response?.data?.message || 'An error occurred');
  }

  const formattedMinutes = useMemo(() => (minutes < 10 ? `0${minutes}` : minutes), [minutes]);
  const formattedSeconds = useMemo(() => (seconds < 10 ? `0${seconds}` : seconds), [seconds]);

  useEffect(() => {
    if (minutes === 0 && seconds === 0 && showPollingModal) {
      setShowPollingModal(false);
      setShowAccountInfoModal(true);
      toast.error('Payment verification failed, please try again later');
    }
  }, [minutes, seconds, showPollingModal]);

  return (
    <>
      <AnimatePresence>
        {showPollingModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <button type="button" onClick={() => setShowPollingModal(false)}>
                      <CloseIcon />
                    </button>
                  </div>

                  <Content>
                    <p>We're waiting to confirm your transfer. This can take a few minutes.</p>

                    <div className="payment-state">
                      <div className="sent">
                        <CircleCheckedIcon />
                        <span>Sent</span>
                      </div>
                      <div className="indeterminate-progress-bar">
                        <div className="indeterminate-progress-bar__progress"></div>
                      </div>

                      <div className="received">
                        <CircleIcon />
                        <span>Received</span>
                      </div>
                    </div>

                    <div className="time-wait">
                      <Watch
                        visible={true}
                        height="20"
                        width="20"
                        radius="48"
                        color="#4fa94d"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <span>
                        Please wait for {formattedMinutes}:{formattedSeconds} minutes
                      </span>
                    </div>
                  </Content>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PaymentSuccessfulModal
        showModal={showSuccessModal}
        amount={handleVerifyPayment?.data?.data?.amount ?? ''}
        recipient={handleVerifyPayment?.data?.data?.user?.companies[0]?.name ?? ''}
      />
    </>
  );
}

export default TransferPolling;

const rotate = keyframes`
   from {
        left: -50%;
      }
      to {
        left: 100%;
      }
`;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9012;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 500px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    background-color: ${(props) => props.theme.colors?.inputBackground};
    padding: 20px 20px;
    border-radius: 6px;
    color: ${(props) => props.theme.colors?.secondary};
    line-height: 19px;
  }

  .payment-state {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin: 40px;

    .sent,
    .received {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};

      svg {
        margin-bottom: 10px;
      }
    }

    .indeterminate-progress-bar {
      background-color: #d1d5db;
      border-radius: 9999px;
      height: 0.5rem;
      position: relative;
      overflow: hidden;
      width: 100%;

      .indeterminate-progress-bar__progress {
        background-color: ${(props) => props.theme.colors?.primary};
        border-radius: 9999px;
        position: absolute;
        bottom: 0;
        top: 0;
        width: 60%;

        /* Move the bar infinitely */
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: ${rotate};
      }
    }
  }

  .time-wait {
    width: 70%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors?.line};
    border-radius: 4px;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
      margin-left: 10px;
    }
  }
`;
