import PaymentSuccessfulModal from '@/components/PaymentSuccessful';
import { verifyPayment } from '@/services/billsApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SubscriptionSuccessfulModal from './Settings/components/subscriptions/SuccessModal';

function VerifySubscription() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const search = location.search;
  const trxref = new URLSearchParams(search).get('trxref');
  const [showModal, setShowModal] = useState(false);

  const handleVerifyPayment = useQuery({
    queryKey: ['bills', trxref],
    queryFn: verifyPayment,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['subscriptionPlans']);
      queryClient.invalidateQueries(['userSubscription']);
      queryClient.invalidateQueries(['userSubscriptionUsage']);
      queryClient.invalidateQueries(['user']);
      queryClient.invalidateQueries(['billingHistory']);
      setShowModal(true);
      toast.success('Subscription payment verified');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  });

  return (
    <VerifyView>
      {handleVerifyPayment.isLoading ? (
        <>
          <Oval color="#92c22c" width={50} height={50} strokeWidth={5} />
          <p>Verifying subscription payment...</p>
        </>
      ) : null}

      <SubscriptionSuccessfulModal showModal={showModal} />
    </VerifyView>
  );
}

export default VerifySubscription;

const VerifyView = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 10px;
  }
`;
