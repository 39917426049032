import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];

function WYSIWYG({ value, setValue, placeholder }) {
  return (
    <QuillView>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        style={{ height: '200px', border: 'none', borderRadius: '16px' }}
        className="quill"
      />
    </QuillView>
  );
}

export default WYSIWYG;

const QuillView = styled.div`
  width: 100%;
  margin-top: 5px;
  margin-bottom: 30px;

  .ql-editor {
    border: none;
    border-radius: 0 0 16px 16px;
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }

  .ql-toolbar {
    border: none;
    border-radius: 16px 16px 0 0;
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  }

  .ql-container {
    border: none;
    height: 150px;
    margin-bottom: 30px;
  }
`;
