/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Button from '@/components/Button/Button';
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { paymentMethods3 } from '@/utils/data.utils';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as NotFoundIcon } from '@/assets/icons/no-bill.icon.svg';
import { useQuery } from '@tanstack/react-query';
import { numberWithCommas } from '@/utils/helpers';
import PageLoader from '@/components/PageLoader';
import { device } from '@/constants/breakpoints';
import toast from 'react-hot-toast';
import withAuthentication from '@/hooks/withAuthentication';
import PreviewModal from './components/PreviewModal';
import PayNowModal from './components/PayNowModal';
import PaymentTypeModal from './components/billFinancing/payLater/PaymentTypeModal';
import { useDispatch } from 'react-redux';
import { setLoanBill } from '@/redux/features/app.slice';
import { fetchPublicInvoice } from '@/services/receivablesApi';

function MakePayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [showPayNowModal, setShowPayNowModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods3[0]?.value);
  const billId = searchParams.get('id');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false);

  const bill = useQuery({
    queryKey: ['bill', billId],
    queryFn: fetchPublicInvoice,
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleContinue = () => {
    if (selectedMethod === 'pay-now') {
      setShowPayNowModal(true);
      dispatch(setLoanBill(null));
    }

    if (selectedMethod === 'pay-later') {
      if (bill?.data?.data?.hasActiveLoan) {
        toast.error('You already have an active loan for this bill');
      } else {
        dispatch(setLoanBill(bill?.data?.data));
        setShowPaymentTypeModal(true);
      }
    }

    if (selectedMethod === 'pay-small') {
      dispatch(setLoanBill(null));
      toast.error('This payment method is not available yet');
      // setShowCreditPaymentModal(true);
    }
  };

  if (bill.isFetching) {
    return <PageLoader />;
  }

  return (
    <MakePaymentStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </div>
      {!bill?.data?.data ? (
        <NoBillView>
          <NotFoundIcon />
          <h3>Bill does not exist</h3>
          <p>The bill you're trying to pay for does not exist</p>
          <button
            type="button"
            className="back"
            onClick={() => {
              navigate('/');
            }}>
            Go back home
          </button>
        </NoBillView>
      ) : (
        <div className="row">
          <div className="heading-view">
            <div className="title-view">
              <h1>How would you like to pay?</h1>
              <p>
                You’re paying <span>₦{numberWithCommas(bill?.data?.data?.balance || '00.00')}</span>{' '}
                to{' '}
                {bill?.data?.data?.type === 'vendor'
                  ? bill?.data?.data?.vendor?.companyName || bill?.data?.data?.vendor?.name
                  : bill?.data?.data?.user?.companies?.length > 0 && (
                      <span>{bill?.data?.data?.user?.companies[0]?.name}</span>
                    )}
              </p>
            </div>
            {bill?.data?.data?.fileUrl && (
              <div className="preview">
                <button
                  className="open-modal"
                  type="button"
                  onClick={() => setShowPreviewModal(true)}>
                  <span>Preview Bill</span>
                </button>
              </div>
            )}
          </div>

          <div className="form-view">
            <div className="options">
              {paymentMethods3?.map((method) => {
                const Icon = method?.icon;
                return (
                  <div
                    className={`option ${
                      selectedMethod === method?.value && method?.available ? 'selected' : ''
                    }`}
                    key={method?.value}
                    onClick={() => {
                      if (method?.available) {
                        setSelectedMethod(method?.available ? method?.value : 'transfer');
                      } else return;
                    }}>
                    {selectedMethod === method?.value && method?.available && (
                      <CheckedIcon className="checked" />
                    )}
                    <div className="icon">
                      <Icon />
                    </div>
                    <div className={`info ${method?.available ? '' : 'fade'}`}>
                      <h3>{method?.title}</h3>
                      <p>{method?.description}</p>
                      {method?.list && (
                        <ul>
                          {method?.list.map((item) => (
                            <li key={item}>{item}</li>
                          ))}
                        </ul>
                      )}
                      {method?.text && <span className="text">{method?.text}</span>} <br />
                      {method?.subText && <span>{method?.subText}</span>}
                      <br />
                      {method?.available ? null : <h4 className="soon">Coming soon</h4>}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="button-view">
              <Button type="button" styleType="primary" width="100%" onClick={handleContinue}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      )}

      <PayNowModal
        showModal={showPayNowModal}
        setShowModal={setShowPayNowModal}
        bill={bill?.data?.data}
      />

      <PaymentTypeModal
        showModal={showPaymentTypeModal}
        setShowModal={setShowPaymentTypeModal}
        bill={bill?.data?.data}
        // selectedMethod={selectedMethod}
      />

      <PreviewModal
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        bill={bill?.data?.data}
      />
    </MakePaymentStyles>
  );
}

export default withAuthentication(MakePayment);

const MakePaymentStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 100px;

  @media ${device.phone} {
    margin-top: 50px;
    width: 100%;
    padding: 0px 16px;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      left: 16px;
      padding-bottom: 20px;
    }
  }

  .row {
    width: 65%;
    height: auto;
    margin-top: 60px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      margin-top: 60px;
    }

    .heading-view {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0px;
        margin-bottom: 40px;
      }

      .title-view {
        width: 50%;

        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            text-align: center;
          }
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          margin-top: 20px;

          @media ${device.phone} {
            text-align: center;
          }

          span {
            font-weight: 600;
          }
        }
      }

      .preview {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0px;

        @media ${device.phone} {
          margin-top: 20px;
        }

        .open-modal {
          font-size: 1rem;
          width: 180px;
          height: 50px;
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
          color: ${({ theme }) => theme.colors?.primary};
          text-transform: uppercase;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 90px;
          padding: 0;
        }
      }
    }

    .indicator-view {
    }

    .form-view {
      width: 100%;
      margin-top: 60px;

      @media ${device.phone} {
        width: 100%;
        padding-left: 0px;
      }

      .options {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media ${device.phone} {
          flex-direction: column;
        }

        .option {
          width: 32%;
          height: 230px;
          border-radius: 16px;
          padding: 12px 20px;
          cursor: pointer;
          position: relative;
          border: 1px solid transparent;
          transition: all 0.1s ease-in-out;
          margin-bottom: 10px;
          background-color: ${({ theme }) => theme.colors?.layer3};

          @media ${device.phone} {
            width: 100%;
          }

          .checked {
            position: absolute;
            top: 10px;
            right: 10px;
          }

          .icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.colors?.white};
            margin-right: 24px;
            margin-bottom: 20px;
          }

          .info {
            width: calc(100% - 64px);

            h3 {
              font-size: 1.125rem;
              font-weight: 600;
              color: ${({ theme }) => theme.colors?.secondary};
              margin-bottom: 8px;
            }

            p {
              font-size: 1rem;
              line-height: 19px;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 500;
              margin-bottom: 10px;
            }

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 400;
            }

            .text {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 600;
            }

            ul {
              list-style-type: disc;
              margin: 0;
              padding-left: 20px;

              li {
                font-size: 0.875rem;
                line-height: 19px;
                color: ${({ theme }) => theme.colors?.secondary};
                font-weight: 400;
                margin-bottom: 4px;
              }
            }

            .soon {
              font-size: 0.875rem;
              line-height: 19px;
              color: ${({ theme }) => theme.colors?.primary};
              font-weight: 500;
              margin-top: 10px;
              background-color: ${({ theme }) => theme.colors?.primaryFocus};
              padding: 4px 8px;
              text-align: center;
              position: absolute;
              top: 0px;
              right: 0px;
              cursor: not-allowed;
            }

            .balance-view {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 10px;

              span {
                font-size: 0.875rem;
                line-height: 19px;
                background-color: ${({ theme }) => theme.colors?.background};
                border-radius: 1.62rem;
                padding: 0.18rem 0.45rem;
                color: ${({ theme }) => theme.colors?.black};
                font-weight: 300;
                margin-right: 10px;
              }

              a {
                font-size: 0.875rem;
                line-height: 19px;
                color: ${({ theme }) => theme.colors?.primary};
                font-weight: 500;
                text-decoration: underline;
                transition: all 0.1s ease-in-out;
              }
            }
          }

          .fade {
            cursor: not-allowed;
          }
        }

        .selected {
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;

const NoBillView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;

  svg {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;

    @media ${device.phone} {
      width: 200px;
      height: 200px;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    line-height: 19px;
    color: ${({ theme }) => theme.colors?.secondary};
    font-weight: 400;
    margin-bottom: 4px;
  }

  button {
    font-size: 1rem;
    line-height: 19px;
    color: ${({ theme }) => theme.colors?.white};
    font-weight: 500;
    margin-top: 10px;
    background-color: ${({ theme }) => theme.colors?.primary};
    opacity: 1;
    padding: 8px 24px;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
`;
