import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SideNavigation from './Sidebar2';
import withAuthentication from '@/hooks/withAuthentication';
import secureLocalStorage from 'react-secure-storage';

function Layout() {
  const company = secureLocalStorage.getItem('company');

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  if (!company) {
    return <Navigate to="/onboarding?page=company-name" />;
  }

  return (
    <View>
      <SideNavigation />
      <Outlet />
    </View>
  );
}

export default withAuthentication(Layout);

const View = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.colors?.background};

  .nav {
    width: 20%;
  }
`;
