import { device } from '@/constants/breakpoints';
import styled from 'styled-components';

export const ResetPasswordView = styled.div`
  width: 1300px;
  margin: 0 auto;
  height: 100%;
  background-color: ${(props) => props.theme.colors?.white};
  padding: 4rem 0;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
`;
export const HeadingView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.phone} {
    width: 100%;
    margin-top: 3rem;
  }
`;

export const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 3.4rem;
  align-items: center;
`;
export const TitleView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3.4rem;

  h3 {
    color: ${(props) => props.theme.colors?.secondary};
    font-size: 2.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media ${device.phone} {
      width: 100%;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.7rem;
    max-width: 24rem;
    text-align: center;
  }
`;

export const FormView = styled.form`
  width: 30rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    width: 100%;
  }
`;

export const InputView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  .error-label {
    color: ${(props) => props.theme.colors?.error};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

export const ButtonView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
`;

export const RegisterView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4.75rem;

  a {
    color: ${(props) => props.theme.colors?.primary};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
  }
`;
