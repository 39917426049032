import api from './api';

export const fetchUserInfo = async () => {
  const response = await api.get(`/invoice-discount/user`);
  return response.data;
};

export const fetchDiscountDetails = async ({ queryKey }) => {
  const [, discountId] = queryKey;
  const response = await api.get(`/invoice-discount/discounts/${discountId}/detail`);
  return response.data;
};

export const calculateInvoiceDiscount = async (data) => {
  const response = await api.post(`/invoice-discount/calculate`, data);
  return response.data;
};

export const applyForDiscount = async (data) => {
  const response = await api.post(`/invoice-discount/discount/apply`, data);
  return response.data;
};

export const fetchTerms = async () => {
  const response = await api.get(`/bill-finance/terms/all`);
  return response.data;
};

export const fetchTerm = async ({ queryKey }) => {
  const [_key, { type }] = queryKey;
  const response = await api.get(`/bill-finance/terms/${type}`);
  return response.data;
};
