import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import ProfileDropdown from '@/components/ProfileDropdown';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/fileUpload.icon.svg';
import { ReceivablesStyles, SearchView } from './invoices.styles';
import Drafts from './components/Drafts';
import { fetchReceivables, fetchReceivablesMetric } from '@/services/receivablesApi';
import TableLoader from '@/components/TableLoader';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import UnsentInvoices from './components/Receivables';
import SentInvoices from './components/SentInvoices';
import PaidInvoices from './components/PaidInvoices';
import Pagination from '@/components/Pagination';
import PartiallyPaidInvoices from './components/PartiallyPaidInvoices';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';
import AddBankAccountModal from '../Settings/components/AddBankAccountModal';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';
import ComplianceWarnModal from '@/components/ComplianceWarnModal';
import { fetchCompany, fetchUser } from '@/services/userApi';
import useDebounce from '@/hooks/useDebounce';
import ExportInvoiceModal from './components/ExportInvoiceModal';

function Receivables() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = secureLocalStorage.getItem('company');
  const [search, setSearch] = useState('');
  const debouncedsearch = useDebounce(search, 500);
  const tab = searchParams.get('tab');
  const [currentPage, setCurrentPage] = useState(1);
  const [openNav, setOpenNav] = useState(false);
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);
  const [showComplianceWarning, setShowComplianceWarning] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const invoicesMetrics = useQuery({
    queryKey: ['invoicesMetrics'],
    queryFn: fetchReceivablesMetric,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClickNewInvoice = () => {
    navigate('/get-paid/invoices/new-invoice');
  };

  useEffect(() => {
    if (!user?.isLoading && user?.data?.data) {
      if (user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(false);
      }
      if (!user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(true);
      }
    } else {
      setShowComplianceWarning(false);
    }
  }, [user?.data?.data]);

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <ReceivablesStyles>
        <div className="receivables-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{company?.data?.data?.name}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <div className="receivables-wrapper">
          <div className="receivables-wrapper__page-title">
            <button type="button">
              <BackIcon />
            </button>
            <h3>Get Paid</h3>
          </div>

          <div className="receivables-wrapper__header">
            <h3>Invoices</h3>

            <div className="left-view">
              {user?.data?.data?.complianceVerified && (
                <button type="button" className="new" onClick={handleClickNewInvoice}>
                  <PlusIcon /> New Invoice
                </button>
              )}

              <button type="button" className="upload" onClick={() => setShowExportModal(true)}>
                <FileIcon />
              </button>
            </div>
          </div>

          <div className="receivables-wrapper__tabs">
            <div
              className={`tab drafts ${tab === 'draft' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=draft&page=1')}>
              <h3>Drafts</h3>
              <div className="tab-values">{/* <h4>15 Items</h4> */}</div>
            </div>
            <div className="line"></div>
            <div
              className={`tab ready ${tab === 'sent' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=sent&page=1')}>
              <h3>Sent</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(invoicesMetrics?.data?.data?.sent?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(invoicesMetrics?.data?.data?.sent?.totalAmount || '0')}</p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab approval ${tab === 'receivables' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=receivables&page=1')}>
              <h3>Receivables</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(invoicesMetrics?.data?.data?.approved?.count || '0')} Items
                </h4>
                <p>
                  ₦{numberWithCommas(invoicesMetrics?.data?.data?.approved?.totalAmount || '0')}
                </p>
              </div>
            </div>

            <div className="line"></div>
            <div
              className={`tab ready ${tab === 'partially-paid' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=partially-paid&page=1')}>
              <h3>Partially paid</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(invoicesMetrics?.data?.data?.partiallyPaid?.count || '0')}{' '}
                  Items
                </h4>
                <p>
                  ₦
                  {numberWithCommas(
                    invoicesMetrics?.data?.data?.partiallyPaid?.totalAmount || '00.00'
                  )}
                </p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab paid ${tab === 'paid' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=paid&page=1')}>
              <h3>Fully paid</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(invoicesMetrics?.data?.data?.paid?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(invoicesMetrics?.data?.data?.paid?.totalAmount || '0')}</p>
              </div>
            </div>
          </div>

          <div className="receivables-wrapper__components">
            {invoicesMetrics?.isFetching ? (
              <TableLoader />
            ) : tab === 'draft' ? (
              <Drafts enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'receivables' ? (
              <UnsentInvoices enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'sent' ? (
              <SentInvoices enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : tab === 'partially-paid' ? (
              <PartiallyPaidInvoices enabledFetch={user?.data?.data?.complianceVerified || false} />
            ) : (
              <PaidInvoices enabledFetch={user?.data?.data?.complianceVerified || false} />
            )}
          </div>

          {/* <div className="payables-wrapper__pagination">
            <Pagination
              itemsPerPage={pageLimit}
              totalItems={invoices?.data?.data?.meta?.totalCount}
              currentPage={invoices?.data?.data?.meta?.page}
              onPageChange={onPageChange}
            />
          </div> */}
        </div>
      </ReceivablesStyles>

      <AddBankAccountModal
        showModal={showAddBankAccountModal}
        setShowModal={setShowAddBankAccountModal}
        shouldNavigate={true}
        url="/get-paid/invoices/new-invoice"
      />

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="get-paid/invoices?tab=draft"
        text="You have reached your invoice limit. Upgrade to a higher plan to continue."
      />

      <ComplianceWarnModal
        showModal={showComplianceWarning}
        setShowModal={setShowComplianceWarning}
      />

      <ExportInvoiceModal showModal={showExportModal} setShowModal={setShowExportModal} />
    </>
  );
}

export default withAuthentication(Receivables);
