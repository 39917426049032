/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as NotFoundIcon } from '@/assets/icons/no-bill.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import Button from '@/components/Button/Button';
import PageLoader from '@/components/PageLoader';
import { device } from '@/constants/breakpoints';
import { fetchPublicInvoice } from '@/services/receivablesApi';
import { paymentMethods3 } from '@/utils/data.utils';
import { numberWithCommas } from '@/utils/helpers';
import PaymentTypeModal from './components/PaymentTypeModal';
import PreviewModal from './components/PreviewModal';
import useAuthentication from '@/hooks/useAuthentication';
import PayNowModal from './components/PayNowModal';
import { useDispatch } from 'react-redux';

function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods3[0]?.value);
  const [searchParams] = useSearchParams();
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showCreditPaymentModal, setShowCreditPaymentModal] = useState(false);
  const invoiceId = searchParams.get('id');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPayNowModal, setShowPayNowModal] = useState(false);
  const { authenticated } = useAuthentication();

  const invoice = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: fetchPublicInvoice,
    onSuccess: () => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleContinue = () => {
    if (selectedMethod === 'pay-now') {
      setShowPayNowModal(true);
    }

    if (selectedMethod === 'pay-later') {
      navigate(`/signup`);
    }

    if (selectedMethod === 'pay-small') {
      toast.error('This payment method is not available yet');
    }
  };

  useEffect(() => {
    if (invoiceId && authenticated) {
      navigate(`/make-payments/bills/pay?id=${invoiceId}`);
    } else return;
  }, [invoiceId, authenticated]);

  if (invoice?.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <MakePaymentStyles>
        <ContentView>
          {/* <div className="back-view">
            <button
              type="button"
              className="back"
              onClick={() => {
                navigate(-1);
              }}>
              <BackIcon />
            </button>
          </div> */}
          {!invoice?.data?.data ? (
            <NoBillView>
              <NotFoundIcon />
              <h3>Bill does not exist</h3>
              <p>The bill you're trying to pay for does not exist</p>
              <button
                type="button"
                className="back"
                onClick={() => {
                  navigate('/');
                }}>
                Go back home
              </button>
            </NoBillView>
          ) : (
            <div className="row">
              <div className="heading-view">
                <div className="title-view">
                  <h1>How would you like to pay?</h1>
                  <p>
                    You’re paying{' '}
                    <span>₦{numberWithCommas(invoice?.data?.data?.balance || '00.00')}</span> to{' '}
                    {invoice?.data?.data?.user?.companies?.length > 0 && (
                      <span>{invoice?.data?.data?.user?.companies[0]?.name}</span>
                    )}
                  </p>
                </div>
                {invoice?.data?.data?.fileUrl && (
                  <div className="preview">
                    <button
                      className="open-modal"
                      type="button"
                      onClick={() => setShowPreviewModal(true)}>
                      <span>Preview Bill</span>
                    </button>
                  </div>
                )}
              </div>

              <div className="form-view">
                <div className="options">
                  {paymentMethods3?.map((method) => {
                    const Icon = method?.icon;
                    return (
                      <div
                        className={`option ${
                          selectedMethod === method?.value && method?.available ? 'selected' : ''
                        }`}
                        key={method?.value}
                        onClick={() => {
                          if (method?.available) {
                            setSelectedMethod(method?.available ? method?.value : 'transfer');
                          } else return;
                        }}>
                        {selectedMethod === method?.value && method?.available && (
                          <CheckedIcon className="checked" />
                        )}
                        <div className="icon">
                          <Icon />
                        </div>
                        <div className={`info ${method?.available ? '' : 'fade'}`}>
                          <h3>{method?.title}</h3>
                          <p>{method?.description}</p>
                          {method?.list && (
                            <ul>
                              {method?.list.map((item) => (
                                <li key={item}>{item}</li>
                              ))}
                            </ul>
                          )}
                          {method?.text && <span className="text">{method?.text}</span>} <br />
                          {method?.subText && <span>{method?.subText}</span>}
                          <br />
                          {method?.available ? null : <h4 className="soon">Coming soon</h4>}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="button-view">
                  <Button type="button" styleType="primary" width="100%" onClick={handleContinue}>
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ContentView>

        <IllustrationView></IllustrationView>
      </MakePaymentStyles>

      <PayNowModal
        showModal={showPayNowModal}
        setShowModal={setShowPayNowModal}
        invoice={invoice?.data?.data}
      />
      <PaymentTypeModal
        showModal={showPromptModal}
        setShowModal={setShowPromptModal}
        invoice={invoice?.data?.data}
      />
      <PreviewModal
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        invoice={invoice?.data?.data}
      />
    </>
  );
}

export default Payment;

const MakePaymentStyles = styled.div`
  width: 1350px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;

  @media ${device.phone} {
    margin-top: 50px;
    width: 100%;
    padding: 0px 16px;
  }
`;

const ContentView = styled.div`
  width: 65%;
  height: 100%;
  margin-top: 100px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      left: 16px;
      padding-bottom: 20px;
    }
  }

  .row {
    width: 100%;
    height: auto;
    margin-top: 60px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      margin-top: 0px;
    }

    .heading-view {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0px;
        margin-bottom: 40px;
      }

      .title-view {
        width: 50%;

        @media ${device.phone} {
          width: 100%;
        }

        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            text-align: center;
          }
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          margin-top: 20px;

          @media ${device.phone} {
            text-align: center;
          }

          span {
            font-weight: 600;
          }
        }
      }

      .preview {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0px;

        @media ${device.phone} {
          margin-top: 20px;
        }

        .open-modal {
          font-size: 1rem;
          width: 180px;
          height: 50px;
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
          color: ${({ theme }) => theme.colors?.primary};
          text-transform: uppercase;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 90px;
          padding: 0;
        }
      }
    }

    .indicator-view {
    }

    .form-view {
      width: 100%;
      margin-top: 60px;

      @media ${device.phone} {
        width: 100%;
        padding-left: 0px;
      }

      .options {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media ${device.phone} {
          flex-direction: column;
        }

        .option {
          width: 32%;
          height: 230px;
          border-radius: 16px;
          padding: 12px 20px;
          cursor: pointer;
          position: relative;
          border: 1px solid transparent;
          transition: all 0.1s ease-in-out;
          margin-bottom: 10px;
          background-color: ${({ theme }) => theme.colors?.layer3};

          @media ${device.phone} {
            width: 100%;
          }

          .checked {
            position: absolute;
            top: 10px;
            right: 10px;
          }

          .icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.colors?.white};
            margin-right: 24px;
            margin-bottom: 20px;
          }

          .info {
            width: calc(100% - 64px);

            h3 {
              font-size: 1.125rem;
              font-weight: 600;
              color: ${({ theme }) => theme.colors?.secondary};
              margin-bottom: 8px;
            }

            p {
              font-size: 1rem;
              line-height: 19px;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 500;
              margin-bottom: 10px;
            }

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 400;
            }

            .text {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 600;
            }

            ul {
              list-style-type: disc;
              margin: 0;
              padding-left: 20px;

              li {
                font-size: 0.875rem;
                line-height: 19px;
                color: ${({ theme }) => theme.colors?.secondary};
                font-weight: 400;
                margin-bottom: 4px;
              }
            }

            .soon {
              font-size: 0.875rem;
              line-height: 19px;
              color: ${({ theme }) => theme.colors?.primary};
              font-weight: 500;
              margin-top: 10px;
              background-color: ${({ theme }) => theme.colors?.primaryFocus};
              padding: 4px 8px;
              text-align: center;
              position: absolute;
              top: 0px;
              right: 0px;
              cursor: not-allowed;
            }

            .balance-view {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 10px;

              span {
                font-size: 0.875rem;
                line-height: 19px;
                background-color: ${({ theme }) => theme.colors?.background};
                border-radius: 1.62rem;
                padding: 0.18rem 0.45rem;
                color: ${({ theme }) => theme.colors?.black};
                font-weight: 300;
                margin-right: 10px;
              }

              a {
                font-size: 0.875rem;
                line-height: 19px;
                color: ${({ theme }) => theme.colors?.primary};
                font-weight: 500;
                text-decoration: underline;
                transition: all 0.1s ease-in-out;
              }
            }
          }

          .fade {
            cursor: not-allowed;
          }
        }

        .selected {
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;

const NoBillView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;

  svg {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;

    @media ${device.phone} {
      width: 200px;
      height: 200px;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    line-height: 19px;
    color: ${({ theme }) => theme.colors?.secondary};
    font-weight: 400;
    margin-bottom: 4px;
  }

  button {
    font-size: 1rem;
    line-height: 19px;
    color: ${({ theme }) => theme.colors?.white};
    font-weight: 500;
    margin-top: 10px;
    background-color: ${({ theme }) => theme.colors?.primary};
    opacity: 1;
    padding: 8px 24px;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const IllustrationView = styled.div`
  width: 35%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0px;
  top: 0px;
  background-image: url('https://res.cloudinary.com/dcd1lhe7x/image/upload/v1709229524/Billboxx/v5lxbw4pat9hi6fiuymw.svg');
  background-size: cover;

  @media ${device.phone} {
    display: none;
  }
`;
