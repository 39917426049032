/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import Button from '@/components/Button/Button';
import { exportOutflows } from '@/services/billsApi';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import SearchDropdown from '@/components/SearchDropdown';

const transactionTypes = [
  {
    id: 'all',
    title: 'All transactions',
    value: 'ALL'
  },
  {
    id: 'credit',
    title: 'Credit transactions',
    value: 'CREDIT'
  },
  {
    id: 'debit',
    title: 'Debit transactions',
    value: 'DEBIT'
  }
];

const schema = yup
  .object({
    startDate: yup.string().required('Start date is required'),
    endDate: yup.string().required('End date is required')
  })
  .required();

function ExportModal({ showModal, setShowModal }) {
  const [includeProgress, setIncludeProgress] = useState(false);
  const [eventType, setEventType] = useState(transactionTypes[0]);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleExportOutflows = useMutation(exportOutflows, {
    onSuccess: (data) => {
      toast.success(data?.message || 'Transactions history sent to your email');
      setShowModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      startDate: new Date(data?.startDate).toISOString(),
      endDate: new Date(data?.endDate).toISOString(),
      type: 'transaction',
      includeInProgress: includeProgress,
      event: eventType?.value
    };

    handleExportOutflows.mutate(formData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="back-view">
              <button
                type="button"
                className="back"
                onClick={() => {
                  setShowModal(false);
                }}>
                <BackIcon />
              </button>
            </div>
            <div className="containerr">
              <div className="row">
                <div className="title-view">
                  <h1>Export transaction history</h1>
                </div>
                <div className="form-view">
                  {/* Due date */}
                  <div className="input-view">
                    <label htmlFor="name">Start date</label>

                    <Input
                      type="date"
                      {...register('startDate')}
                      error={errors?.startDate?.message}
                    />

                    {errors?.startDate && (
                      <span className="error-label">{errors?.startDate?.message}</span>
                    )}
                  </div>

                  {/* End date */}
                  <div className="input-view">
                    <label htmlFor="name">End date</label>

                    <Input type="date" {...register('endDate')} error={errors?.endDate?.message} />

                    {errors?.endDate && (
                      <span className="error-label">{errors?.endDate?.message}</span>
                    )}
                  </div>

                  {/* Type */}
                  <div className="input-view">
                    <label htmlFor="name">Transaction type</label>

                    <SearchDropdown
                      options={transactionTypes}
                      id="id"
                      name="title"
                      setValue={setEventType}
                      value={eventType}
                      placeholder="Select business registration type"
                    />
                  </div>

                  {/* In progress payment */}
                  <div className="select-view">
                    <div className="options">
                      <div className="option" onClick={() => setIncludeProgress(!includeProgress)}>
                        <div className="radio-button">
                          {includeProgress ? <span className="dot"></span> : null}
                        </div>
                        <p>Include in-progress transactions</p>
                      </div>
                    </div>
                  </div>
                  {/* Button view */}
                  <div className="button-view">
                    <Button
                      type="button"
                      styleType="primary"
                      width="100%"
                      onClick={handleSubmit(onSubmit)}>
                      {handleExportOutflows.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Export'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default ExportModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 200000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 30px 16px;
    }

    .containerr {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;

      @media ${device.phone} {
        flex-direction: column;
      }

      .row {
        width: 70%;
        display: flex;
        justify-content: center;
        margin-top: 40px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column;
          margin-top: 0;
        }

        .title-view {
          width: 40%;
          padding-right: 80px;

          @media ${device.phone} {
            width: 100%;
            padding-right: 0;
          }

          h1 {
            font-size: 2rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
          }

          p {
            font-size: 1rem;
            line-height: 19px;
            margin-top: 20px;
          }
        }

        .form-view {
          width: 60%;
          padding-left: 80px;

          @media ${device.phone} {
            width: 100%;
            padding-left: 0;
            margin-top: 30px;
          }

          .input-view {
            width: 100%;
            margin-bottom: 30px;

            .label-view {
              width: 100%;
              display: flex;
              margin-bottom: 8px;

              label {
                font-size: 1rem;
              }

              span {
                font-size: 0.875rem;
                color: ${({ theme }) => theme.colors?.info};
              }
            }

            .error-label {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.error};
              margin-top: 5px;
            }

            .input-info-view {
              width: 100%;
              display: flex;
              margin-top: 8px;

              span {
                font-size: 0.875rem;
                color: ${({ theme }) => theme.colors?.info};
              }
            }
          }
          .select-view {
            width: 100%;
            margin-bottom: 20px;

            label {
              font-size: 0.875rem;
              font-weight: 400;

              span {
                font-size: 0.75rem;
                color: ${({ theme }) => theme.colors?.info};
              }
            }

            .options {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 14px;

              .option {
                display: flex;
                align-items: center;
                cursor: pointer;

                .radio-button {
                  width: 30px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colors?.primaryFocus};
                  margin-right: 10px;

                  .dot {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: ${({ theme }) => theme.colors?.primary};
                  }
                }

                p {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: ${({ theme }) => theme.colors?.secondary};
                }
              }
            }
          }
          .button-view {
            width: 230px;
            margin-top: 50px;

            @media ${device.phone} {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
