const size = {
  phone: '768px',
  mobile: '768px',
  tablet: '1024px',
  laptop: '1440px',
  desktop: '1440px',
  tv: '1440px'
};

export const device = {
  phone: `(max-width: ${size.phone})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  tv: `(min-width: ${size.tv})`
};
