/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ReactComponent as UncheckIcon } from '@/assets/icons/uncheck.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { ReactComponent as AscIcon } from '@/assets/icons/asc.icon.svg';
import { ReactComponent as DescIcon } from '@/assets/icons/desc.icon.svg';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useSortableData } from '@/hooks/useSortableData';
import EditVendor from './EditVendor';
import { deleteVendor } from '@/services/vendorApi';
import PromptModal from '@/components/PromptModal';
import { device } from '@/constants/breakpoints';

function VendorsTables({ vendors }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showMenu, setShowMenu] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [, setShowFilterMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedVendor, setSelectedVendor] = useState({});
  const ref = useRef();
  const filterRef = useRef();

  useOnClickOutside(ref, () => setShowMenu(false));
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const { items, requestSort, sortConfig } = useSortableData(vendors?.items || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleDeleteVendor = useMutation(deleteVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      toast.success('Vendor deleted successfully');
      setShowPromptModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <VendorsTableStyles>
      <div className="vendors-header">
        <div className="title-view">{/* <h3>Total: ₦23,424.90 (14 Items)</h3> */}</div>

        <div className="vendors-header__actions">
          <button type="button">{/* <FilterIcon /> */}</button>
        </div>
      </div>

      {items?.length > 0 ? (
        <>
          <div className="vendors-table">
            <div className="vendors-table__header">
              {/* <div className="vendors-table__header__item icon">
                <button type="button">
                  <UncheckIcon />
                </button>
              </div> */}
              <div
                className={`vendors-table__header__item vendor ${getClassNamesFor('companyName')}`}
                onClick={() => requestSort('name')}>
                <span>
                  Vendor{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`vendors-table__header__item bill ${getClassNamesFor('numberOfBills')}`}
                onClick={() => requestSort('numberOfBills')}>
                <span>
                  No. of Bills{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`vendors-table__header__item amount ${getClassNamesFor(
                  'totalPayables'
                )}`}
                onClick={() => requestSort('totalPayables')}>
                <span>
                  Total amount{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`vendors-table__header__item amount ${getClassNamesFor('totalPaid')}`}
                onClick={() => requestSort('totalPaid')}>
                <span>
                  Payments made{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`vendors-table__header__item amount ${getClassNamesFor('openBalance')}`}
                onClick={() => requestSort('openBalance')}>
                <span>
                  Open Balance{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div className="vendors-table__header__item action">
                <span></span>
              </div>
            </div>

            {/* ====== Table body ====== */}
            <div className="vendors-table__body">
              {items.map((vendor, i) => (
                <div className="vendors-table__body-row" key={vendor.id}>
                  {/* <div className="vendors-table__body-row__item icon">
                    <button type="button">
                      <UncheckIcon />
                    </button>
                  </div> */}
                  <div className="vendors-table__body-row__item vendor">
                    <span>{vendor?.companyName || 'N/A'}</span>
                  </div>
                  <div className="vendors-table__body-row__item bill">
                    <span>{vendor?.numberOfBills}</span>
                  </div>
                  <div className="vendors-table__body-row__item amount">
                    <span>₦{numberWithCommas(vendor?.totalPayables || '00.00')}</span>
                  </div>
                  <div className="vendors-table__body-row__item amount">
                    <span>₦{numberWithCommas(vendor?.totalPaid || '00.00')}</span>
                  </div>
                  <div className="vendors-table__body-row__item amount">
                    <span>₦{numberWithCommas(vendor?.openBalance || '00.00')}</span>
                  </div>
                  <div className="vendors-table__body-row__item action">
                    <button
                      type="button"
                      className="btn view"
                      onClick={() => {
                        navigate(`/make-payments/vendors/${vendor?.id}`);
                      }}>
                      <ViewIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMenu(true);
                        setCurrentIndex(i);
                        setSelectedVendor(vendor);
                      }}
                      className="btn more">
                      <MoreIcon />
                    </button>

                    {/* ===== Dropdown Menu ====== */}
                    <AnimatePresence>
                      {showMenu && currentIndex === i ? (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          <button
                            type="button"
                            onClick={() => {
                              navigate(`/make-payments/vendors/${vendor?.id}`);
                            }}>
                            View vendor
                          </button>
                          <button type="button" onClick={() => setShowEditModal(true)}>
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setShowPromptModal(true);
                              setSelectedVendor(vendor);
                            }}>
                            Delete
                          </button>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="listing">
            {items?.map((item, i) => (
              <div className="item" key={item?.id}>
                <div className="col1">
                  <span className="name">{item?.companyName}</span>
                </div>
                <div className="col2">
                  <p className="label">No. of Bills</p>
                  <span className="value">{numberWithCommas2(item?.numberOfBills || '0')}</span>
                </div>
                <div className="col2">
                  <p className="label">Open balance</p>
                  <span className="value">₦{numberWithCommas(item?.openBalance || '00.00')}</span>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                    setSelectedVendor(item);
                  }}
                  className="btn more">
                  <MoreIcon />
                </button>
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        type="button"
                        onClick={() => {
                          navigate(`/make-payments/vendors/${item?.id}`);
                        }}>
                        View vendor
                      </button>
                      <button type="button" onClick={() => setShowEditModal(true)}>
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setShowPromptModal(true);
                          setSelectedVendor(item);
                        }}>
                        Delete
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyView>
          <EmptyIcon />
          <h3>Nothing to see here!</h3>
        </EmptyView>
      )}

      <EditVendor
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedVendor={selectedVendor}
      />

      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete vendor</h3>
          <p>When you delete a vendor, you lose all related data. This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteVendor.mutate(selectedVendor?.id)}>
            {handleDeleteVendor.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </VendorsTableStyles>
  );
}

export default VendorsTables;

const VendorsTableStyles = styled.div`
  width: 100%;
  min-height: 50vh;

  .vendors-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-view {
      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }

  .vendors-table {
    width: 100%;
    margin-top: 20px;

    @media ${device.phone} {
      display: none;
    }

    .vendors-table__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-radius: 6px;
      background: ${(props) => props.theme.colors?.layer2};

      .vendors-table__header__item {
        flex: 1;
        display: flex;
        align-items: center;

        span {
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 600;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 3px;
          }
        }
      }

      .ascending {
        color: ${({ theme }) => theme.colors?.secondary};

        .up path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .descending {
        color: ${({ theme }) => theme.colors?.secondary};

        .down path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .icon {
        flex: 0.3;
      }

      .vendor {
        flex: 2.5;
      }

      .bill {
        flex: 1;
      }

      .date {
        flex: 1.3;
      }

      .amount {
        flex: 1.2;
      }

      .action {
        flex: 0.5;
      }
    }

    .vendors-table__body {
      width: 100%;
      margin-top: 20px;

      .vendors-table__body-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors?.white};

        &:nth-child(odd) {
          background-color: ${(props) => props.theme.colors?.layer3};
        }

        .vendors-table__body-row__item {
          flex: 1;
          display: flex;
          align-items: center;

          span {
            font-size: 0.875rem;
            color: ${(props) => props.theme.colors?.secondary};
            font-weight: 400;
          }
        }

        .icon {
          flex: 0.3;
        }

        .vendor {
          flex: 2.5;
        }

        .bill {
          flex: 1;
        }

        .date {
          flex: 1.3;
        }

        .amount {
          flex: 1.2;
        }

        .action {
          flex: 0.5;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;

          .btn {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .view {
            margin-right: 20px;
          }

          .menu-actions {
            position: absolute;
            top: 0px;
            right: 0;
            background-color: ${(props) => props.theme.colors?.white};
            border-radius: 6px;
            box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
            width: 180px;
            z-index: 100;
            overflow: hidden;

            button {
              width: 100%;
              padding: 16px 24px;
              border: none;
              background-color: ${(props) => props.theme.colors?.white};
              font-size: 0.875rem;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &:hover {
                background-color: ${(props) => props.theme.colors?.layer2};
              }
            }
          }
        }
      }
    }

    .vendors-table__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }
      }

      .col2 {
        display: flex;
        flex-direction: column;

        .value {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .label {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
          margin-bottom: 5px;
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }
`;
