/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';

import {
  ComponentsView,
  ContactView,
  ContainerHeaderView,
  ContainerView,
  CustomerView,
  HeaderView,
  ListItem,
  PageTitleView,
  PaymentView
} from './customer.styles';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as ContactIcon } from '@/assets/icons/contact.icon.svg';
import { ReactComponent as PaymentIcon } from '@/assets/icons/payments.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import ProfileDropdown from '@/components/ProfileDropdown';
import FetchLoader from '@/components/FetchLoader';
import PromptModal from '@/components/PromptModal';
import EditCustomer from './components/EditCustomer';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { setCustomerToBill } from '@/redux/features/app.slice';
import { deleteCustomer, fetchCustomer } from '@/services/customerApi';
import BillCustomerForm from './components/BillCustomerForm';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';

function Customer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showActions, setShowActions] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowActions(false));
  const { id } = useParams();

  const handleDeleteCustomer = useMutation(deleteCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      toast.success('Customer deleted successfully');
      setShowPromptModal(false);
      navigate(-1);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const customer = useQuery({
    queryKey: ['customer', id],
    queryFn: fetchCustomer,
    onSuccess: () => {},
    onError: () => {}
  });

  if (customer?.isFetching) {
    return <FetchLoader />;
  }

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <CustomerView>
        <HeaderView>
          <div className="title-view">
            {' '}
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            {/* <h3>ABC Ventures</h3> */}
          </div>
          <ProfileDropdown />
        </HeaderView>
        <ContainerView>
          <PageTitleView>
            <button type="button" onClick={() => navigate(-1)}>
              <BackIcon />
            </button>
            <h3>Get Paid</h3>
          </PageTitleView>

          <ContainerHeaderView>
            <h3>Customer</h3>
            <div className="action">
              <button type="button" onClick={() => setShowActions(true)}>
                Action <DownIcon />
              </button>
              <AnimatePresence>
                {showActions ? (
                  <motion.div
                    className="menu-actions"
                    ref={ref}
                    initial={{ y: -20 }}
                    animate={{ y: 0 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(setCustomerToBill(customer?.data?.data));
                        setShowInvoiceModal(true);
                      }}>
                      New invoice
                    </button>
                    <button type="button" onClick={() => setShowUpdateModal(true)}>
                      Edit
                    </button>
                    <button type="button" onClick={() => setShowPromptModal(true)}>
                      Delete
                    </button>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
          </ContainerHeaderView>

          <ComponentsView>
            <ContactView>
              <div className="icon-view">
                <div className="icon">
                  <ContactIcon />
                </div>
              </div>
              <h3>Contact</h3>
              <ListItem>
                <span>Customer’s company name</span>
                <h4>{customer?.data?.data?.companyName}</h4>
              </ListItem>
              <ListItem>
                <span>Contact’s full name</span>
                <h4>{customer?.data?.data?.name || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Email address</span>
                <h4>{customer?.data?.data?.email || 'Nil'}</h4>
              </ListItem>
              <ListItem>
                <span>Phone number</span>
                <h4>{customer?.data?.data?.phoneNumber || 'Nil'}</h4>
              </ListItem>
            </ContactView>
            <PaymentView>
              <div className="icon-view">
                <div className="icon">
                  <PaymentIcon />
                </div>
              </div>
              <h3>Payments</h3>
              <ListItem>
                <span>Open balance</span>
                <h4>₦{numberWithCommas(customer?.data?.data?.openBalance || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>Total amount</span>
                <h4>₦{numberWithCommas(customer?.data?.data?.totalPayables || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>Payments made</span>
                <h4>₦{numberWithCommas(customer?.data?.data?.totalPaid || '00.00')}</h4>
              </ListItem>
              <ListItem>
                <span>No. of Invoices</span>
                <h4>{numberWithCommas2(customer?.data?.data?.numberOfBills || '00.00')}</h4>
              </ListItem>
            </PaymentView>
          </ComponentsView>
        </ContainerView>
      </CustomerView>

      <EditCustomer
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        selectedcustomer={customer?.data?.data}
      />

      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete customer</h3>
          <p>When you delete a customer, you lose all related data. This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteCustomer.mutate(customer?.data?.data?.id)}>
            {handleDeleteCustomer.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>

      <BillCustomerForm showModal={showInvoiceModal} setShowModal={setShowInvoiceModal} />
    </>
  );
}

export default withAuthentication(Customer);
