import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import welcomeImg from 'assets/images/welcome.image.png';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WaveIcon } from '@/assets/icons/wave.icon.svg';
import { ReactComponent as EllipseIcon } from '@/assets/icons/ellipse.icon.svg';
import { useDispatch } from 'react-redux';
import { device } from '@/constants/breakpoints';
import { useSearchParams } from 'react-router-dom';

const WelcomeModal = ({ showModal }) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <ImageView>
                <img src={welcomeImg} alt="welcome" />
                <button onClick={() => setSearchParams('')}>
                  <CloseIcon />
                </button>

                <div className="wave">
                  <WaveIcon />
                </div>
              </ImageView>

              <InfoView>
                <h2>Welcome!</h2>
                <p>
                  You’re about to{' '}
                  <span>
                    <EllipseIcon className="ellipse" /> supercharge
                  </span>{' '}
                  your business cashflow.
                </p>
              </InfoView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default WelcomeModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
  }

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    border-radius: 38px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
      border-radius: 16px;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 520px;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;

  @media ${device.phone} {
    height: 420px;
  }
`;

const ImageView = styled.div`
  width: 100%;
  height: 350px;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    height: 250px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      path {
        stroke: ${(props) => props.theme.colors?.black};
        fill: ${(props) => props.theme.colors?.black};
      }
    }
  }

  .wave {
    position: absolute;
    bottom: -36px;
    left: 50%;
    transform: translateX(-50%);
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors?.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const InfoView = styled.div`
  width: 100%;
  height: calc(550px - 350px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;

  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors?.secondary};

    @media ${device.phone} {
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 1.3rem;
    line-height: 32px;
    text-align: center;
    max-width: 60%;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};

    @media ${device.phone} {
      font-size: 1rem;
      max-width: 90%;
    }

    span {
      color: ${(props) => props.theme.colors?.primary};
      font-weight: 600;
      position: relative;

      .ellipse {
        position: absolute;
        top: -20px;
        left: -5px;

        @media ${device.phone} {
          display: none;
        }
      }
    }
  }
`;
