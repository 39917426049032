import api from './api';

export const fetchBillFinanceRequests = async ({ queryKey }) => {
  const [, { page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/bill-finance/requests?${queryParams}`);
  return response.data;
};

export const fetchBillFinance = async ({ queryKey }) => {
  const [, { status, page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `status=${status}&page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/bill-finance/requests?${queryParams}`);
  return response.data;
};

export const fetchRequestMetrics = async ({ queryKey }) => {
  const response = await api.get(`/bill-finance/metrics/stat/`);
  return response.data;
};

export const fetchRequestDetails = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  if (id) {
    const response = await api.get(`/bill-finance/requests/${id}`);
    return response.data;
  }
};

export const withdrawRequest = async (requestId) => {
  const response = await api.delete(`/bill-finance/requests/${requestId}`);
  return response.data;
};

export const acceptOffer = async (requestId) => {
  const response = await api.patch(`/bill-finance/requests/${requestId}/accept`);
  return response.data;
};

export const makePayment = async (requestId, data) => {
  const response = await api.post(`/bill-finance/requests/${requestId}/payment`, data);
  return response.data;
};

export const verifyBankTransfer = async ({ queryKey }) => {
  const [, { trxRef }] = queryKey;
  if (trxRef) {
    const response = await api.get(`/transactions/${trxRef}/loan-verify`);
    return response.data;
  }
};

// Invoice Discounts

export const fetchInvoiceDiscountRequests = async ({ queryKey }) => {
  const [, { page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/invoice-discount/requests?${queryParams}`);
  return response.data;
};

export const fetchInvoiceDiscounts = async ({ queryKey }) => {
  const [, { status, page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `status=${status}&page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/invoice-discount/requests?${queryParams}`);
  return response.data;
};

export const fetchInvoiceDiscountMetrics = async ({ queryKey }) => {
  const response = await api.get(`/invoice-discount/metrics/stat`);
  return response.data;
};

export const fetchInvoiceDiscountDetails = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  if (id) {
    const response = await api.get(`/invoice-discount/requests/${id}`);
    return response.data;
  }
};

export const invoiceDiscountWithdrawRequest = async (requestId) => {
  const response = await api.delete(`/invoice-discount/requests/${requestId}`);
  return response.data;
};

export const invoiceDiscountAcceptOffer = async (requestId) => {
  const response = await api.patch(`/invoice-discount/requests/${requestId}/accept`);
  return response.data;
};

export const InvoiceDiscountMakePayment = async (requestId, data) => {
  const response = await api.post(`/invoice-discount/requests/${requestId}/payment`, data);
  return response.data;
};

export const getOfferLetter = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  if (id) {
    const response = await api.get(`/lender/request/${id}/offer-letter`);
    return response.data;
  }
};
