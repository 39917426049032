/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import ProfileDropdown from '@/components/ProfileDropdown';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import TableLoader from '@/components/TableLoader';
import Pagination from '@/components/Pagination';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';
import ComplianceWarnModal from '@/components/ComplianceWarnModal';
import { fetchCompany, fetchUser } from '@/services/userApi';
import useDebounce from '@/hooks/useDebounce';
import { ArchivesStyles, SearchView } from './archives.styles';
import ArchivesTable from './ArchivesTable';
import { fetchReceivables } from '@/services/receivablesApi';
import SortingComponent from '@/components/SortingComponent';
import FilterSort from '@/components/FilterSort';

function InvoiceArchives() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const pageLimit = 10;
  const [openNav, setOpenNav] = useState(false);
  const companyId = secureLocalStorage.getItem('company');
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);
  const [showComplianceWarning, setShowComplianceWarning] = useState(false);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);

  const handleFilterApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const invoices = useQuery({
    queryKey: [
      'invoices',
      {
        status: 'archived',
        page: currentPage,
        pageLimit,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchReceivables,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const onPageChange = (page) => {
    setSearchParams(`page=${page}`);
  };

  useEffect(() => {
    if (!user?.isLoading && user?.data?.data) {
      if (user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(false);
      }
      if (!user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(true);
      }
    } else {
      setShowComplianceWarning(false);
    }
  }, [user?.data?.data]);

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <ArchivesStyles>
        <div className="payables-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{company?.data?.data?.name}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <div className="payables-wrapper">
          <div className="payables-wrapper__page-title">
            <button type="button">
              <BackIcon />
            </button>
            <h3>Get Paid</h3>
          </div>

          <div className="payables-wrapper__header">
            <h3>Archived Invoices</h3>
            <div className="left-view">
              <SearchView>
                <input
                  type="search"
                  placeholder="Search invoices"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <SearchIcon className="search-icon" />
              </SearchView>
              <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
              <FilterSort onApply={handleFilterApply} />
            </div>
          </div>

          <div className="payables-wrapper__components">
            {invoices?.isLoading ? <TableLoader /> : <ArchivesTable bills={invoices?.data?.data} />}
          </div>

          <div className="payables-wrapper__pagination">
            <Pagination
              itemsPerPage={pageLimit}
              totalItems={invoices?.data?.data?.meta?.totalCount}
              currentPage={invoices?.data?.data?.meta?.page}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </ArchivesStyles>

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="make-payments/payables?tab=draft"
        text="You have reached your invoice limit for your current plan. Upgrade your plan to continue."
      />

      <ComplianceWarnModal
        showModal={showComplianceWarning}
        setShowModal={setShowComplianceWarning}
      />
    </>
  );
}

export default withAuthentication(InvoiceArchives);
