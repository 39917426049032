import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as EnterIcon } from '@/assets/icons/nav-arrow-right.icon.svg';
import { ReactComponent as SendIcon } from '@/assets/icons/send2.icon.svg';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';
import useUser from '@/hooks/useUser';
import useWallet from '@/hooks/useWallet';
import { numberWithCommas } from '@/utils/helpers';
import AccountChoiceModal from './AccountChoiceModal';
import AccountTransferModal from './AccountTransferModal';
import PinWarnModal from './PinWarnModal';
import TransferHistoryTable from './TransferHistoryTable';
import { device } from '@/constants/breakpoints';

const TransferFunds = () => {
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [showOthersModal, setShowOthersModal] = useState(false);
  const [showWarnModal, setShowWarnModal] = useState(false);
  const { user } = useUser();
  const { userWallet } = useWallet();
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);

  const handleTransferToOwnAccount = () => {
    if (user?.hasTransactionPin) {
      setShowAccountsModal(true);
      setShowOthersModal(false);
    } else {
      setShowWarnModal(true);
    }
  };

  const handleTransferToOtherAccount = () => {
    if (user?.hasTransactionPin) {
      setShowAccountsModal(false);
      setShowOthersModal(true);
    } else {
      setShowWarnModal(true);
    }
  };

  return (
    <>
      <TransferFundsStyles>
        <TitleView>
          <h3>Transfer Funds</h3>
          <span>
            {userWallet?.currentBalance
              ? `Balance: ₦${numberWithCommas(userWallet?.currentBalance || '00.00')}`
              : 'Balance: ₦0.00'}
          </span>
        </TitleView>

        <OptionsView>
          <OptionView>
            <div className="icon">
              <SendIcon />
            </div>

            <div className="account-info">
              <h4>Transfer to own account</h4>
              <p className="description">Send money to your saved bank account</p>
            </div>

            <div className="proceed-view">
              <button className="icon-copy" onClick={handleTransferToOwnAccount}>
                Proceed <EnterIcon />
              </button>
            </div>
          </OptionView>

          <OptionView>
            <div className="icon">
              <SendIcon />
            </div>

            <div className="account-info">
              <h4>Transfer to other bank account</h4>
              <p className="description">Send money to third-party recipient</p>
            </div>

            <div className="proceed-view">
              <button className="icon-copy" onClick={handleTransferToOtherAccount}>
                Proceed <EnterIcon />
              </button>
            </div>
          </OptionView>
        </OptionsView>

        <TransferHistoryTable />
      </TransferFundsStyles>

      <AccountChoiceModal showModal={showAccountsModal} setShowModal={setShowAccountsModal} />
      <AccountTransferModal showModal={showOthersModal} setShowModal={setShowOthersModal} />
      <PinWarnModal
        showModal={showWarnModal}
        setShowModal={setShowWarnModal}
        state="transfer-funds"
      />

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="banking?tab=transfer-funds"
        text="You cannot transfer funds with your current plan. Upgrade your plan to continue."
      />
    </>
  );
};

export default TransferFunds;

const TransferFundsStyles = styled.div`
  width: 100%;
  height: 100%;
`;

const TitleView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.layer4};
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
`;

const OptionsView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .fade {
    opacity: 0.5;
    pointer-events: none;
    position: relative;

    .coming-soon {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${({ theme }) => theme.colors.info};
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};

      p {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

const OptionView = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.layer4};
  padding: 1.5rem;
  border-radius: 0.5rem;

  @media ${device.phone} {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    svg {
      width: 4rem;
      height: 4rem;

      @media ${device.phone} {
        width: 40px;
        height: 40px;
      }
    }
  }

  .account-info {
    width: 50%;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 100%;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
    }
  }

  .proceed-view {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;

    @media ${device.phone} {
      position: absolute;
      bottom: 20px;
      right: 20px;
      top: auto;
    }

    button {
      font-size: 0.875rem;
    }

    .icon-copy {
      border: none;
      outline: none;
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;

      &:hover {
        text-decoration: underline;
        svg {
          transform: translateX(0.5rem);
        }
      }

      svg {
        margin-left: 0.3rem;
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;
