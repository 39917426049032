/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { requestPaymentMethods } from '@/utils/data.utils';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';
import { useQuery } from '@tanstack/react-query';
import { fetchPublicInvoice } from '@/services/receivablesApi';
import PageLoader from '@/components/PageLoader';
import PreviewModal from './components/PreviewModal';
import PercentageDiscountModal from './components/invoiceDiscounting/payNow/PercentageDiscountModal';
import { useDispatch } from 'react-redux';
import { setDiscountInvoice } from '@/redux/features/app.slice';
import toast from 'react-hot-toast';

function RequestPaymentType() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState(requestPaymentMethods[0]?.value);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPercentageDiscountModal, setShowPercentageDiscountModal] = useState(false);
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);

  const invoice = useQuery({
    queryKey: ['invoice', id],
    queryFn: fetchPublicInvoice
  });

  const handleContinue = () => {
    if (selectedMethod === 'request-payment') {
      navigate(`/get-paid/invoices/request-payment?id=${id}`);
    }
    if (selectedMethod === 'invoice-discounting') {
      if (invoice?.data?.data?.requiresApproval) {
        setShowPercentageDiscountModal(true);
        dispatch(setDiscountInvoice(invoice?.data?.data));
      } else {
        toast.error('This invoice does not require approval and cannot be discounted.');
      }
    }
  };

  if (invoice.isFetching) {
    return <PageLoader />;
  }

  return (
    <RequestPaymentTypeStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="heading-view">
          <div className="title-view">
            <h1>How do you want this invoice to be paid?</h1>
            <p>Choose your preferred way to get paid for this invoice</p>
          </div>
          {invoice?.data?.data?.fileUrl && (
            <div className="preview">
              <button
                className="open-modal"
                type="button"
                onClick={() => setShowPreviewModal(true)}>
                <span>Preview Invoice</span>
              </button>
            </div>
          )}
        </div>
        <div className="form-view">
          {requestPaymentMethods?.map((method) => {
            const Icon = method?.icon;
            return (
              <div
                className={`option ${selectedMethod === method?.value ? 'selected' : ''}`}
                key={method?.value}
                onClick={() => setSelectedMethod(method?.value)}>
                {selectedMethod === method?.value && <CheckedIcon className="checked" />}
                <div className="icon">
                  <Icon />
                </div>
                <div className="info">
                  <h3>{method?.title}</h3>
                  <p>{method?.description}</p>
                </div>
              </div>
            );
          })}

          {/* Button view */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleContinue}>
              Continue
            </Button>
          </div>
        </div>
      </div>

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="get-paid/invoices/new-invoice"
        text="You cannot continue on this feature because it is not included in your current plan. Please upgrade to continue."
      />

      <PreviewModal
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        invoice={invoice?.data?.data}
      />

      <PercentageDiscountModal
        showModal={showPercentageDiscountModal}
        setShowModal={setShowPercentageDiscountModal}
        invoice={invoice?.data?.data}
      />
    </RequestPaymentTypeStyles>
  );
}

export default withAuthentication(RequestPaymentType);

const RequestPaymentTypeStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 100px;

  @media ${device.phone} {
    margin-top: 30px;
    width: 100%;
    padding: 0px 16px;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      left: 16px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 60px;
    }

    .heading-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0px;
        margin-bottom: 40px;
      }

      .title-view {
        width: 100%;

        h1 {
          font-size: 1.5rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            text-align: center;
          }
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          margin-top: 20px;

          @media ${device.phone} {
            text-align: center;
          }

          span {
            font-weight: 600;
          }
        }
      }

      .preview {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        @media ${device.phone} {
          margin-top: 20px;
        }

        .open-modal {
          font-size: 1rem;
          width: 180px;
          height: 50px;
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
          color: ${({ theme }) => theme.colors?.primary};
          text-transform: uppercase;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.1s ease-in-out;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 90px;
          padding: 0;
        }
      }
    }

    .indicator-view {
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding-left: 0px;
        margin-top: 30px;
      }

      .option {
        width: 380px;
        display: flex;
        align-items: flex-start;
        border-radius: 16px;
        padding: 12px 10px;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.1s ease-in-out;
        margin-bottom: 10px;

        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.colors?.layer};
          margin-right: 24px;
        }

        .info {
          width: calc(100% - 64px);

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
            margin-bottom: 8px;
          }

          p {
            font-size: 1rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.secondary};
            font-weight: 300;
          }
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
