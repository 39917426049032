import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applyForLoan } from '@/services/billFinancingApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import parse from 'html-react-parser';
import {
  appSelector,
  setLoanAmount,
  setLoanBill,
  setPayLaterData
} from '@/redux/features/app.slice';
import { Oval } from 'react-loader-spinner';
import ApplicationSentModal from './ApplicationSentModal';

function TermsAgreementModal({ showModal, setShowModal, content }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { payLaterData } = useSelector(appSelector);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showApplicationSentModal, setShowApplicationSentModal] = useState(false);

  const handleLoanApplication = useMutation(applyForLoan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
      dispatch(setLoanBill(null));
      dispatch(setLoanAmount(null));
      dispatch(setPayLaterData({}));
      setShowModal(false);
      setShowApplicationSentModal(true);
      // navigate('/make-payments/bills?tab=approved');
      // toast.success(data?.data?.message || 'Loan request successful');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleApplyForLoan = () => {
    handleLoanApplication.mutate(payLaterData);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <span></span>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setAgreedToTerms(false);
                    }}>
                    <CloseIcon />
                  </button>
                </div>

                {/* <p className="info">
                  Terms and conditions of <strong>Purchase Financing</strong>
                </p> */}

                <TermsView>{parse(content)}</TermsView>

                <ButtonView>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="terms"
                      name="terms"
                      value={agreedToTerms}
                      onChange={() => setAgreedToTerms(!agreedToTerms)}
                    />
                    <label htmlFor="terms">
                      I agree to the terms and conditions of the purchase financing
                    </label>
                  </div>

                  <div className="buttons">
                    <button
                      type="button"
                      className="cancel"
                      onClick={() => {
                        setShowModal(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="confirm"
                      disabled={!agreedToTerms}
                      onClick={handleApplyForLoan}>
                      {handleLoanApplication?.isLoading ? (
                        <Oval color="#FFF" height={30} width={30} />
                      ) : (
                        'Confirm'
                      )}
                    </button>
                  </div>
                </ButtonView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <ApplicationSentModal
        showModal={showApplicationSentModal}
        setShowModal={setShowApplicationSentModal}
        returnTo="/financing/purchase-financing?tab=requests&page=1"
      />
    </>
  );
}

export default TermsAgreementModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: relative;
    border-radius: 4px;
    max-width: 55%;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;

    overflow-y: scroll;

    @media ${device.phone} {
      max-width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;

  @media ${device.phone} {
    width: 100%;
    padding: 0px 0px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .info {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors?.secondary};
    margin-top: 20px;

    strong {
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const TermsView = styled.div`
  width: 100%;
  padding: 0 30px;
  color: ${(props) => props.theme.colors?.secondary};

  p {
    strong {
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      list-style-type: decimal;
      font-weight: 400;
      line-height: 19px;
    }
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      list-style-type: disc;
      font-weight: 400;
      line-height: 19px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    line-height: 19px;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 30px;
  padding-bottom: 50px;
  background-color: ${(props) => props.theme.colors?.layer3};

  @media ${device.phone} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .buttons {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
    }

    .cancel {
      background-color: transparent;
      color: ${(props) => props.theme.colors?.secondary};
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
    }

    .confirm {
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      width: 150px;
      height: 50px;
      padding: 10px 20px;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 500;
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .checkbox {
    width: 60%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    @media ${device.phone} {
      width: 96%;
    }

    input {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.colors?.secondary};
      cursor: pointer;
      accent-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.primary};
    }

    label {
      font-size: 1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 500;
    }
  }
`;
