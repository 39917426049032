/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { resolveBankAccount } from '@/services/bankAccountApi';
import { device } from '@/constants/breakpoints';
import CurrencyyInput from '@/components/CurrencyInput';
import { usePaystackPayment } from 'react-paystack';

const hostname = window.location.hostname.split('.')[0];

const paystackKey =
  import.meta.env.MODE === 'production'
    ? import.meta.env.VITE_PAYSTACK_PUBLIC_KEY
    : import.meta.env.VITE_PAYSTACK_PUBLIC_TEST_KEY;

function FundsAmountModal({ showModal, setShowModal, email }) {
  const [amount, setAmount] = useState('');
  const schema = yup
    .object({
      amount: yup.string().required('Amount is required')
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100,
    publicKey:
      hostname === 'billboxx-staging' ? import.meta.env.VITE_PAYSTACK_PUBLIC_TEST_KEY : paystackKey
  };

  const initializePayment = usePaystackPayment(config);

  const handleAddFunds = useMutation(resolveBankAccount, {
    onSuccess: (data) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onPaymentSuccess = (reference) => {
    handleAddFunds.mutate({});
  };

  const onPaymentClose = () => {
    toast.error('Transaction failed');
  };

  const onSubmit = (data) => {
    setAmount(data.amount);
    initializePayment(onPaymentSuccess, onPaymentClose);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Add funds</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="heading-view">
                  <h3>Enter the amount you want to add to your wallet</h3>
                </div>

                <FormView>
                  <InputView>
                    <label htmlFor="name">Amount</label>
                    <Controller
                      name="amount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          error={!!errors?.amount?.message}
                          className={`currency-input ${
                            errors?.amount && dirtyFields?.amount
                              ? ' has-error'
                              : dirtyFields?.amount
                              ? 'valid'
                              : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.amount && (
                      <span className="error-label">{errors?.amount?.message}</span>
                    )}
                  </InputView>

                  {/* ===== CTA ====== */}
                  <div className="action-btn">
                    <button type="button" className="btn btn-outline">
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit(onSubmit)();
                      }}>
                      Add funds
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default FundsAmountModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 14px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .heading-view {
    width: 100%;
    margin-top: 30px;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      line-height: 19px;
    }
  }
`;

const FormView = styled.form`
  width: 100%;
  margin-top: 30px;

  .name {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;

    button {
      margin-left: 10px;
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;
