/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input/Input';
import { addBankAccount, fetchBankList, resolveBankAccount } from '@/services/bankAccountApi';
import SearchDropdown from '@/components/SearchDropdown';
import { device } from '@/constants/breakpoints';
import { fetchTransferBankList } from '@/services/bankingApi';
import BankListDropdown from '@/pages/banking/components/BankListDropdown';

function AddBankAccountModal({ showModal, setShowModal, shouldNavigate, url }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [accountNumber, setAccountNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState({});
  const [accountData, setAccountData] = useState(null);

  const handleChangeInput = (event) => {
    const { value, maxLength } = event.target;
    const account = value.slice(0, maxLength);
    setAccountNumber(account);
  };

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: fetchTransferBankList
  });

  const handleResolveBankAccount = useMutation(resolveBankAccount, {
    onSuccess: (data) => {
      setAccountData({
        ...data?.data,
        bankName: selectedBank?.bankName
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      setAccountData(null);
    }
  });

  const handleAddBankAccount = useMutation(addBankAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bankAccounts'] });
      toast.success('Bank account added successfully!');
      setAccountNumber('');
      setSelectedBank({});
      setAccountData(null);

      if (shouldNavigate) {
        setShowModal(false);
        navigate(url);
      } else {
        setShowModal(false);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (accountNumber.length === 10) {
      if (!selectedBank?.bankCode) {
        toast.error('Please select a bank');
        return;
      }
      handleResolveBankAccount.mutate({
        accountNumber,
        bankCode: selectedBank?.bankCode
      });
    }
  }, [accountNumber, selectedBank]);

  const onSubmit = () => {
    if (!accountData) {
      toast.error('Please add a valid account number');
    } else {
      const formData = {
        ...accountData,
        bankName: selectedBank?.bankName,
        isDefault: true
      };
      handleAddBankAccount.mutate(formData);
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Add bank account</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <div className="heading-view">
                <h3>
                  Add your bank account details to receive payments from your customers on the app
                  or via bank transfer.
                </h3>
              </div>

              <FormView>
                <Form>
                  {/* First name */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="firstName">Select bank</label>
                    </div>
                    <BankListDropdown
                      options={banks?.data?.data}
                      code="bankCode"
                      name="bankName"
                      setValue={setSelectedBank}
                      value={selectedBank}
                      placeholder="Select bank"
                    />
                  </InputView>
                  {/* Last name */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="accountNumber">Account Number</label>
                    </div>
                    <Input
                      type="number"
                      maxLength="10"
                      placeholder="Enter account number"
                      value={accountNumber}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </InputView>
                  <div className="loader">
                    {handleResolveBankAccount.isLoading && (
                      <Oval
                        color="#92C22C"
                        secondaryColor="#EEFFC9"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    )}
                  </div>
                  {accountData && (
                    <div className="name">
                      <span>Account name</span>
                      <p>{accountData?.accountName}</p>
                    </div>
                  )}
                  {/* ===== CTA ====== */}
                  <ButtonView>
                    <button type="button" className="cta-view__save" onClick={onSubmit}>
                      {handleAddBankAccount.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Add bank account'
                      )}
                    </button>
                  </ButtonView>
                </Form>
              </FormView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default AddBankAccountModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 14px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .heading-view {
    width: 100%;
    margin-top: 30px;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.info};
      line-height: 19px;
    }
  }
`;

const FormView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
`;

const Form = styled.form`
  width: 100%;

  .name {
    width: 100%;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;

  button {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
