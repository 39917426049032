import Button from '@/components/Button/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { setOnboardingProgress } from '@/redux/features/app.slice';
import { useEffect } from 'react';
import ProgressIndicator from './ProgressIndicator';
import { billPayments } from '@/utils/data.utils';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCompany, updateCompany } from '@/services/userApi';
import { toast } from 'react-hot-toast';
import FetchLoader from '@/components/FetchLoader';
import { Oval } from 'react-loader-spinner';
import { device } from '@/constants/breakpoints';
import secureLocalStorage from 'react-secure-storage';

const BillPayments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const companyId = secureLocalStorage.getItem('company');
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [selectedVolume, setSelectedVolume] = useState(billPayments[0]?.volume);

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    onSuccess: (data) => {
      setSelectedVolume(data?.data?.monthlyBillPaymentVolume || billPayments[0]?.volume);
    },
    onError: (error) => {}
  });

  const handleUpdateCompany = useMutation(({ id, data }) => updateCompany(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      navigate('/onboarding?page=invoice-volume');
      dispatch(setOnboardingProgress(80));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (page === 'payment-volume') {
      dispatch(setOnboardingProgress(70));
    }
  }, [page, dispatch]);

  if (company.isFetching) {
    return <FetchLoader />;
  }

  return (
    <BillPaymentStyles>
      <div className="back-view">
        <button
          className="back"
          onClick={() => {
            navigate('/onboarding?page=bill-volume');
            dispatch(setOnboardingProgress(60));
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>

        <div className="title-view">
          <h1>What is {company?.data?.data?.name} estimated monthly bill payment value?</h1>
          <p>
            How much in your local currency does the company pays out to vendors and suppliers on a
            monthly basis?
          </p>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>
        <div className="form-view">
          {billPayments?.map((volume, index) => {
            const Icon = volume?.icon;
            return (
              <div
                className={`option ${selectedVolume === volume?.volume ? 'selected' : ''}`}
                key={volume?.volume}
                onClick={() => setSelectedVolume(volume?.volume)}>
                {selectedVolume === volume?.volume && <CheckedIcon className="checked" />}
                <div className="icon">
                  <Icon />
                </div>
                <div className="info">
                  <h3>{volume?.volume}</h3>
                </div>
              </div>
            );
          })}

          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={() => {
                handleUpdateCompany.mutate({
                  id: companyId,
                  data: {
                    monthlyBillPaymentVolume: selectedVolume
                  }
                });
              }}>
              {handleUpdateCompany.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="skip-view">
        <button
          type="button"
          onClick={() => {
            navigate('/onboarding?page=invoice-volume');
            dispatch(setOnboardingProgress(80));
          }}>
          Skip
        </button>
      </div>
    </BillPaymentStyles>
  );
};

export default BillPayments;

const BillPaymentStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      margin-bottom: 20px;
    }
  }

  .skip-view {
    width: 100%;
    position: absolute;
    bottom: -100px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors?.black};
      background: ${({ theme }) => theme.colors?.primaryFocus};
      padding: 5px 12px;
      border-radius: 10px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          line-height: 29px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          font-size: 1rem;
        }
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .option {
        width: 260px;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 16px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.1s ease-in-out;

        @media ${device.phone} {
          width: 100%;
        }

        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.colors?.layer};
          margin-right: 24px;
        }

        .info {
          width: calc(100% - 64px);

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
            margin-bottom: 8px;
          }

          p {
            font-size: 1rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.secondary};
            font-weight: 300;
          }
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
