import api from './api';

export const fetchRecurringBills = async ({ queryKey }) => {
  const [, { page, limit, search, sortBy, fetch }] = queryKey;

  let queryParams = `page=${page}&perPage=${limit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/recurring/bill?${queryParams}`);
  return response.data;
};

export const fetchRecurringInvoices = async ({ queryKey }) => {
  const [, { page, limit, search, sortBy, fetch }] = queryKey;

  let queryParams = `page=${page}&perPage=${limit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/recurring/invoice?${queryParams}`);
  return response.data;
};

export const fetchRecurringInvoicesOrBill = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  const response = await api.get(`/recurring/${id}/detail`);
  return response?.data;
};

export const deleteRecurring = async (id) => {
  const response = await api.delete(`/recurring/${id}/detail`);
  return response.data;
};
