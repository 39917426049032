/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import styled from 'styled-components';
import secureLocalStorage from 'react-secure-storage';
import { ReactComponent as EyeeIcon } from '@/assets/icons/eye.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off.icon.svg';

import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import { changePassword, logoutUser } from '@/services/authApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';

const schema = yup
  .object({
    oldPassword: yup.string().required('Current password is required'),
    password: yup
      .string()
      .min(8, 'Password should not be less than 8 characters long')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[\d\W]/, 'Password must contain at least one number or special character')
      .required('Password is required'),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required')
  })
  .required();

function ManagePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false
  });

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleChangePassword = useMutation(changePassword, {
    onSuccess: () => {
      toast.success('Password changed successfully, please login with your new password');
      reset();
      handleLogoutUser.mutate({});
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      authType: 'password',
      ...data
    };
    handleChangePassword.mutate(formData);
  };

  return (
    <ManagePasswordView>
      <TitleView>
        <h3>Change password</h3>
      </TitleView>

      <FormView>
        <PasswordInputView>
          <label htmlFor="password">Current Password</label>
          <div className="input">
            <Input
              type={showPassword?.oldPassword ? 'text' : 'password'}
              placeholder="Enter current password"
              {...register('oldPassword')}
              error={!!errors?.oldPassword?.message}
            />
            <button
              type="button"
              onClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  oldPassword: !prevState.oldPassword
                }))
              }>
              {showPassword?.oldPassword ? <EyeOffIcon /> : <EyeeIcon />}
            </button>
          </div>
          {errors?.oldPassword && (
            <span className="error-label">{errors?.oldPassword?.message}</span>
          )}
        </PasswordInputView>

        <PasswordInputView>
          <label htmlFor="password">New Password</label>
          <div className="input">
            <Input
              type={showPassword?.password ? 'text' : 'password'}
              placeholder="Enter new password"
              {...register('password')}
              error={!!errors?.password?.message}
            />
            <button
              type="button"
              onClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  password: !prevState.password
                }))
              }>
              {showPassword?.password ? <EyeOffIcon /> : <EyeeIcon />}
            </button>
          </div>
          {errors?.password && <span className="error-label">{errors?.password?.message}</span>}
        </PasswordInputView>

        <PasswordInputView>
          <label htmlFor="password">Confirm New Password</label>
          <div className="input">
            <Input
              type={showPassword?.confirmPassword ? 'text' : 'password'}
              placeholder="Enter new password"
              {...register('passwordConfirm')}
              error={!!errors?.password?.message}
            />
            <button
              type="button"
              onClick={() =>
                setShowPassword((prevState) => ({
                  ...prevState,
                  confirmPassword: !prevState.confirmPassword
                }))
              }>
              {showPassword?.confirmPassword ? <EyeOffIcon /> : <EyeeIcon />}
            </button>
          </div>
          {errors?.passwordConfirm && (
            <span className="error-label">{errors?.passwordConfirm?.message}</span>
          )}
        </PasswordInputView>

        {/* Button view */}
        <div className="button-view">
          <Button type="button" styleType="primary" width="100%" onClick={handleSubmit(onSubmit)}>
            {handleChangePassword.isLoading ? (
              <Oval
                color="#FFF"
                secondaryColor="#ddd"
                height={30}
                width={30}
                strokeWidth={4}
                ariaLabel="loading"
              />
            ) : (
              'Change password'
            )}
          </Button>
        </div>
      </FormView>
    </ManagePasswordView>
  );
}

export default ManagePassword;

const ManagePasswordView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
const TitleView = styled.div`
  width: 100%;
  padding: 0 0px;
  margin-bottom: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 70%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FormView = styled.form`
  width: 60%;
  padding: 0 0px;

  @media ${device.phone} {
    width: 100%;
  }

  .button-view {
    width: 230px;
    margin-top: 50px;

    @media ${device.phone} {
      width: 100%;
    }
  }
`;

const PasswordInputView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    color: ${(props) => props.theme.colors?.error};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }

  .password-info {
    color: ${(props) => props.theme.colors?.info};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;
