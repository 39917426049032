import api, { publicApi } from './api';

export const loginUser = async (data) => {
  const response = await publicApi.post(`/auth/login`, data);
  return response.data;
};

export const registerUser = async (data) => {
  const response = await publicApi.post(`/auth/register`, data);
  return response.data;
};

export const verifyEmail = async (data) => {
  const response = await publicApi.post(`/auth/verify-email`, data);
  return response.data;
};

export const verify2fa = async (data) => {
  const response = await publicApi.post(`/auth/verify-2fa`, data);
  return response.data;
};

export const forgotPassword = async (data) => {
  const response = await publicApi.post(`/auth/forgot-password`, data);
  return response.data;
};

export const resetPassword = async (data) => {
  const response = await publicApi.patch(`/auth/reset-password`, data);
  return response.data;
};

export const changePassword = async (data) => {
  const response = await api.patch(`/profile/update-password`, data);
  return response.data;
};

export const logoutUser = async () => {
  const response = await api.get(`/auth/logout`);
  return response.data;
};

export const fetchUserToken = async ({ queryKey }) => {
  const [, { userId }] = queryKey;
  const response = await publicApi.get(`/auth/token`, {
    headers: {
      'x-user-id': userId
    }
  });
  return response.data;
};
