import React, { useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import { ReactComponent as BankIcon } from '@/assets/icons/bank2.icon.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { fetchAccount } from '@/services/bankingApi';
import { fetchUser } from '@/services/userApi';
import { useQuery } from '@tanstack/react-query';
import FundsAmountModal from './FundsAmountModal';
import { device } from '@/constants/breakpoints';

const AddFunds = () => {
  const [showAmountModal, setShowAmountModal] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Account number copied to clipboard');
  };

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const bankingAccount = useQuery({
    queryKey: ['bankingAccount'],
    queryFn: fetchAccount
  });

  return (
    <>
      <AddFundsStyles>
        <TitleView>
          <h3>Add Funds</h3>
          <p>
            Top up your bank wallet with funds to enable you make payments for your bills and other
            services.
          </p>
        </TitleView>

        <TransferOptionView>
          <div className="icon">
            <BankIcon />
          </div>

          <div className="account-info">
            <h4>{bankingAccount?.data?.data?.provider} Bank</h4>
            <p className="name">{bankingAccount?.data?.data?.accountName}</p>
            <p className="description">
              To add funds, simply transfer or deposit money into this account number
            </p>
          </div>

          <div className="account-number">
            <h4>
              {bankingAccount?.data?.data?.accountNumber
                ? bankingAccount?.data?.data?.accountNumber
                : 'XXXXXXXXXX'}
            </h4>
            <button
              className="icon-copy"
              onClick={() => {
                copyToClipboard(
                  bankingAccount?.data?.data?.accountNumber
                    ? bankingAccount?.data?.data?.accountNumber
                    : 'XXXXXXXXXX'
                );
              }}>
              <CopyIcon />
            </button>
          </div>
        </TransferOptionView>

        {/* <CardOptionView>
          <div className="icon">
            <CardIcon />
          </div>

          <div className="account-info">
            <h4>Use a debit card</h4>
            <p className="description">Add funds through Paystack channel</p>
          </div>

          <div className="proceed-view">
            <button className="icon-copy" onClick={() => setShowAmountModal(true)}>
              Proceed <EnterIcon />
            </button>
          </div>
        </CardOptionView> */}

        {/* <FundHistoryTable /> */}
      </AddFundsStyles>

      <FundsAmountModal
        showModal={showAmountModal}
        setShowModal={setShowAmountModal}
        email={user?.data?.data?.email}
      />
    </>
  );
};

export default AddFunds;

const AddFundsStyles = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const TitleView = styled.div`
  width: 100%;

  h3 {
    font-size: 1.3rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 19px;
    margin-bottom: 20px;
    max-width: 70%;

    @media ${device.phone} {
      max-width: 100%;
    }
  }
`;

const TransferOptionView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.layer4};
  padding: 1.5rem;
  border-radius: 0.5rem;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .account-info {
    width: 50%;
    margin-right: 1rem;

    @media ${device.phone} {
      width: 100%;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
      text-transform: capitalize;

      @media ${device.phone} {
        width: 100%;
        margin-top: 10px;
      }
    }

    .name {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
    }
  }

  .account-number {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    h4 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};
    }

    .icon-copy {
      border: none;
      outline: none;
      margin-left: 0.5rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;

const CardOptionView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.layer4};
  padding: 1.5rem;
  border-radius: 0.5rem;

  .icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .account-info {
    width: 50%;
    margin-right: 1rem;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0.5rem;
    }

    .description {
      font-size: 0.8rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
    }
  }

  .proceed-view {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;

    .icon-copy {
      border: none;
      outline: none;
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;

      &:hover {
        text-decoration: underline;
        svg {
          transform: translateX(0.5rem);
        }
      }

      svg {
        margin-left: 0.3rem;
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;
