import api from './api';

export const fetchAllNotifications = async ({ queryKey }) => {
  const [, { limit, read, channel }] = queryKey;

  let queryParams = `limit=${limit}`;

  if (read) {
    queryParams += `&read=${read}`;
  }

  if (channel && channel !== 'ALL') {
    queryParams += `&channel=${channel}`;
  }

  const response = await api.get(`/notification?${queryParams}`);
  return response.data;
};

export const markAllNotificationAsRead = async () => {
  const response = await api.patch(`/notification/mark-as-read`);
  return response?.data;
};

export const markNotificationAsRead = async (id) => {
  const response = await api.patch(`/notification/${id}/mark-as-read`);
  return response?.data;
};

export const deleteNotification = async (id) => {
  const response = await api.delete(`/notification/${id}/delete-notification`);
  return response.data;
};
