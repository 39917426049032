import api from './api'

/**
 * * Fetches all reminders
 * @returns {Promise<*>} response
 */
export const fetchReminders = async () => {
  const response = await api.get(`/profile/reminders`)
  return response.data
}

/**
 * * Creates a reminder
 * @param {*} data - reminder data
 * @returns {Promise<*>} response
 */
export const createReminders = async (data) => {
  const response = await api.post(`/profile/reminders/create`, data)
  return response.data
}

/**
 * * Toggles a reminder
 * @param {*} data - reminder data
 * @returns {Promise<*>} response
 */
export const toggleReminder = async (id, data) => {
  const response = await api.put(`/profile/reminders/${id}/toggle`, data)
  return response.data
}

/**
 * * Deletes a reminder
 * @param {*} data - reminder data
 * @returns {Promise<*>} response
 */
export const deleteReminder = async (id) => {
  const response = await api.delete(`/profile/reminders/${id}`)
  return response.data
}
