/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import useOnClickOutside from '@/hooks/useClickOutside';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';

export default function SearchDropdown2({
  options,
  id,
  name,
  setVendorName,
  setVendorId,
  vendorName,
  setVendorEmail
}) {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));

  const filter = (optionss) =>
    optionss?.filter((option) => option[name]?.toLowerCase()?.indexOf(query.toLowerCase()) > -1);

  return (
    <View ref={ref} open={open}>
      <div className="value" onClick={() => setOpen(!open)}>
        <input
          type="text"
          onChange={(e) => {
            setVendorName(e.target.value);
            setQuery(e.target.value);
          }}
          value={vendorName}
        />
        <button className="icon" type="button">
          <ArrowDownIcon />
        </button>
      </div>
      {open ? (
        <div className="dropdown">
          <div className="opts">
            {options?.length > 0 ? (
              filter(options).map((option) => (
                <div
                  key={option[id]}
                  className="opt"
                  onClick={() => {
                    setOpen(false);
                    setVendorId(option?.id);
                    setVendorName(option[name]);
                    setVendorEmail(option?.email || '');
                  }}>
                  {option[name]}
                </div>
              ))
            ) : (
              <div className="option">No option</div>
            )}
          </div>
        </div>
      ) : null}
    </View>
  );
}

const View = styled.div`
  width: 100%;
  position: relative;
  margin-top: 8px;

  border-radius: 4px;
  /* margin-top: 5px; */

  .value {
    width: 100%;
    height: 64px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-radius: 16px;
    outline: none;
    padding: 16px;
    margin-top: 5px;
    transition: all 0.25s ease-in-out;
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    /* cursor: pointer; */

    input {
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
    }

    .icon {
      background-color: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: 12px;
      cursor: pointer;

      svg {
        transition: all 0.3s ease-in-out;
        transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
        transform-origin: 50% 50%;
        width: 15px;
        height: 15px;
      }
    }

    .val {
      color: ${({ theme }) => theme.colors?.black};
      text-transform: capitalize;
    }

    .placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
    }
  }

  .dropdown {
    width: 100%;
    z-index: 20;
    position: absolute;
    top: 70px;
    background-color: ${({ theme }) => theme.colors?.white};
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .input {
      outline: none;
      margin-top: 0;
      padding: 10px;
      /* margin-bottom: 10px; */
      font-size: 0.8rem;
      font-weight: 300;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
      width: 100%;
      height: 50px;
      /* position: absolute;
      top: 0px;
      left: 0px; */
    }

    .opts {
      width: 100%;
      max-height: 220px;
      padding-top: 10px;
      overflow-y: scroll;

      .opt {
        padding: 12px 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.8rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: 100%;
        text-transform: capitalize;

        &:hover {
          background-color: ${({ theme }) => theme.colors?.layer};
          border-radius: 0;
        }

        .logo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          display: inline-block;
          border: 1px solid ${({ theme }) => theme.colors?.line};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
      }
    }
  }
`;
