import Button from '@/components/Button/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { setOnboardingProgress } from '@/redux/features/app.slice';
import { useEffect } from 'react';
import ProgressIndicator from './ProgressIndicator';
import { listedBanks } from '@/utils/data.utils';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchNigeriaBanks } from '@/services/utilsApi';
import BankDropdown from './BankDropdown';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { fetchCompany, updateCompany } from '@/services/userApi';
import FetchLoader from '@/components/FetchLoader';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { device } from '@/constants/breakpoints';
import secureLocalStorage from 'react-secure-storage';

const CoorperateBank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const companyId = secureLocalStorage.getItem('company');
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [selectedBank, setSelectedBank] = useState();
  const [banks, setBanks] = useState([]);

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    onSuccess: async (data) => {
      const banks = await queryClient.fetchQuery({
        queryKey: ['banks'],
        queryFn: fetchNigeriaBanks
      });

      setBanks(banks);

      const bank = banks?.find((bank) => bank.name === data?.data?.corporateBank);
      setSelectedBank(bank || listedBanks[0]);
    },
    onError: (error) => {}
  });

  const handleUpdateCompany = useMutation(({ id, data }) => updateCompany(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      navigate('/onboarding?page=software');
      dispatch(setOnboardingProgress(100));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (page === 'bank') {
      dispatch(setOnboardingProgress(90));
    }
  }, [page, dispatch]);

  if (company.isFetching) {
    return <FetchLoader />;
  }

  return (
    <CoorperateBankStyles>
      <div className="back-view">
        <button
          className="back"
          onClick={() => {
            navigate('/onboarding?page=invoice-volume');
            dispatch(setOnboardingProgress(80));
          }}>
          <BackIcon />
        </button>
      </div>

      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>
        <div className="title-view">
          <h1>What is {company?.data?.data?.name} corporate bank?</h1>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>
        <div className="form-view">
          <div className="optionss">
            {listedBanks?.map((bank, index) => {
              return (
                <div
                  className={`option ${selectedBank?.name === bank?.name ? 'selected' : ''}`}
                  key={bank?.code}
                  onClick={() => setSelectedBank(bank)}>
                  {selectedBank?.name === bank?.name && <CheckedIcon className="checked" />}
                  <div className="icon">
                    <img src={bank?.logo} alt="" />
                  </div>
                  <div className="info">
                    <h3>{bank?.name}</h3>
                  </div>
                </div>
              );
            })}
            <div
              className={`option ${selectedBank === null ? 'selected' : ''}`}
              onClick={() => setSelectedBank(null)}>
              {selectedBank === null && <CheckedIcon className="checked" />}
              <div className="info">
                <h3>Don’t have any</h3>
              </div>
            </div>
          </div>
          <div className="input-view">
            <label htmlFor="bank">Other</label>

            <BankDropdown
              options={banks}
              code="code"
              name="name"
              setValue={setSelectedBank}
              value={selectedBank}
              placeholder="Select bank"
            />
          </div>
          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={() => {
                handleUpdateCompany.mutate({
                  id: companyId,
                  data: { corporateBank: selectedBank?.name || null }
                });
              }}>
              {handleUpdateCompany.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className="skip-view">
        <button
          type="button"
          onClick={() => {
            navigate('/onboarding?page=software');
            dispatch(setOnboardingProgress(100));
          }}>
          Skip
        </button>
      </div>
    </CoorperateBankStyles>
  );
};

export default CoorperateBank;

const CoorperateBankStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      margin-bottom: 20px;
    }
  }

  .skip-view {
    width: 100%;
    position: absolute;
    bottom: -100px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors?.black};
      background: ${({ theme }) => theme.colors?.primaryFocus};
      padding: 5px 12px;
      border-radius: 10px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          line-height: 29px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          font-size: 1rem;
        }
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .optionss {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .option {
          width: 48%;
          height: 60px;
          display: flex;
          align-items: center;
          border-radius: 16px;
          padding: 0 10px;
          cursor: pointer;
          position: relative;
          border: 1px solid transparent;
          transition: all 0.1s ease-in-out;

          @media ${device.phone} {
            width: 100%;
          }

          .checked {
            position: absolute;
            top: 10px;
            right: 10px;
          }

          .icon {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.colors?.layer};
            margin-right: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          .info {
            width: calc(100% - 64px);

            h3 {
              font-size: 0.9rem;
              font-weight: 600;
              color: ${({ theme }) => theme.colors?.secondary};
              margin-bottom: 8px;
            }

            p {
              font-size: 1rem;
              line-height: 19px;
              color: ${({ theme }) => theme.colors?.secondary};
              font-weight: 300;
            }
          }
        }

        .selected {
          border: 1px solid ${({ theme }) => theme.colors?.primary};
        }
      }

      .input-view {
        width: 100%;
        margin-bottom: 26px;

        .label-view {
          width: 100%;
          display: flex;
          margin-bottom: 8px;

          label {
            font-size: 1rem;
          }
        }

        .input-info-view {
          width: 100%;
          display: flex;
          margin-top: 8px;

          span {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
