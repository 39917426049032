import api from './api';

/**
 * * Create a vendor ✅
 * @param {Object} data - Vendor data
 * @returns {Promise} - Returns the created vendor
 */
export const createVendor = async (data) => {
  const response = await api.post(`/vendors/create`, data);
  return response.data;
};

/**
 * * Fetches all vendors ✅
 * @returns {Promise} - Returns the vendors
 */
export const fetchVendors = async ({ queryKey }) => {
  const [, { page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/vendors?${queryParams}`);
  return response.data;
};

/**
 * * Fetches a vendor
 * @param {Object} queryKey - Query key
 * @returns {Promise} - Returns the vendor
 */
export const fetchVendor = async ({ queryKey }) => {
  const [_, vendorId] = queryKey;
  const response = await api.get(`/vendors/${vendorId}`);
  return response.data;
};

/**
 * * Deletes a vendor
 * @param {String} vendorId - Vendor ID
 * @returns {Promise} - Returns the deleted vendor
 */
export const deleteVendor = async (vendorId) => {
  const response = await api.delete(`/vendors/${vendorId}`);
  return response.data;
};

/**
 * * Updates a vendor
 * @param {String} id - Vendor id
 * @param {Object} data - Vendor data
 * @returns {Promise} - Returns the updated Vendor
 */
export const updateVendor = async (id, data) => {
  const response = await api.put(`/vendors/${id}`, data);
  return response.data;
};

/**
 * * Fetches a vendor's bills
 * @param {Onject} queryKey - Query key
 * @returns {Promise} - Returns the vendor bills
 */
export const fetchVendorBills = async ({ queryKey }) => {
  const [_, vendorId] = queryKey;
  const response = await api.get(`/vendors/${vendorId}/bills`);
  return response.data;
};

export const addVendorBankAccount = async (data) => {
  const response = await api.post(`/vendors/banks/add-account`, data);
  return response.data;
};

export const fetchVendorBankAccounts = async ({ queryKey }) => {
  const [_, vendorId] = queryKey;
  const response = await api.get(`/vendors/${vendorId}/banks`);
  return response.data;
};
