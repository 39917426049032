/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import AllTransactions from './AllTrasactions';
import DebitTransactions from './DebitTransactions';
import CreditTransactions from './CreditTransactions';

const tabs = [
  {
    name: 'All Transactions',
    path: 'view=all&page=1&limit=10',
    value: 'all'
  },
  {
    name: 'Debits',
    path: 'view=debits&page=1&limit=10',
    value: 'debits'
  },
  {
    name: 'Credits',
    path: 'view=credits&page=1&limit=10',
    value: 'credits'
  }
];

function TransactionHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('view');

  return (
    <>
      <TransactionHistoryStyles>
        <ContentView>
          <TabsView>
            {tabs.map((tabItem) => (
              <div key={tabItem.path} className="tab-item">
                <button
                  type="button"
                  className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=transaction-history&${tabItem?.path}`)}>
                  {tabItem.name}
                </button>
              </div>
            ))}
          </TabsView>
          <InfoView>
            {tab === 'all' && <AllTransactions />}
            {tab === 'debits' && <DebitTransactions />}
            {tab === 'credits' && <CreditTransactions />}
          </InfoView>
        </ContentView>
      </TransactionHistoryStyles>
    </>
  );
}

export default TransactionHistory;

export const TransactionHistoryStyles = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }
`;

export const ContentView = styled.div`
  width: 100%;
  padding: 0px 0px 0 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    padding: 20px 0px;
    padding-top: 40px;
    flex-direction: column;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-bottom: 20px;

    @media ${device.phone} {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const TabsView = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow-x: scroll;
  border-bottom: 2px solid ${({ theme }) => theme.colors?.layer3};

  @media ${device.phone} {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    border: none;
  }

  .tab-item {
    margin-right: 10px;
    display: flex;

    @media ${device.phone} {
      margin-bottom: 0;
      margin-right: 10px;
      width: 200px;
    }

    button {
      width: fit-content;
      height: 44px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
      transition: all 0.3s ease-in-out;
      padding: 12px 32px;
      word-break: normal;
      white-space: nowrap;

      @media ${device.phone} {
        padding: 0;
        width: 150px;
        justify-content: center;
      }

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        border-bottom: 2px solid ${({ theme }) => theme.colors?.primary};
        border-radius: 0;
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      border-bottom: 2px solid ${({ theme }) => theme.colors?.primary};
      border-radius: 0;
    }
  }
`;

export const InfoView = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
  }
`;
