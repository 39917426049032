import React from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

function FetchLoader() {
  return (
    <LoaderView>
      <Oval color="#92c22c" width={50} height={50} strokeWidth={5} />
    </LoaderView>
  );
}

export default FetchLoader;

const LoaderView = styled.div`
  width: 82%;
  height: 70vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;
