import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import useOnClickOutside from '@/hooks/useClickOutside';
import { numberWithCommas } from '@/utils/helpers';

function PaymentAdvice({ showModal, setShowModal, billData, paymentAdvice }) {
  const navigate = useNavigate();
  const shareRef = useRef();
  const [saveAs, setSaveAs] = useState('pdf');
  useOnClickOutside(shareRef, () => setShowShareOptions(false));
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [loadingPaymentAdvice, setLoadingPaymentAdvice] = useState(false);

  const handleFileDownload = (url, filename) => {
    setLoadingPaymentAdvice(true);
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        setLoadingPaymentAdvice(false);
        fileDownload(res.data, newFileName);
      })
      .catch(() => {
        setLoadingPaymentAdvice(false);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (billData?.bill?.type === 'vendor') {
      if (billData?.bill?.status === 'paid') {
        navigate('/make-payments/bills?tab=paid&page=1');
      } else {
        navigate('/make-payments/bills?tab=partially-paid&page=1');
      }
    } else {
      if (billData?.bill?.status === 'paid') {
        navigate('/get-paid/invoices?tab=paid&page=1');
      } else {
        navigate('/get-paid/invoices?tab=partially-paid&page=1');
      }
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}

            <div className="close-view">
              <button className="close" type="button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            </div>

            <ContentView>
              <div className="logo">
                <img
                  src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695118944/Billboxx/Billboxx-web/BillboxxLogo_aedtrv.png"
                  alt="Billboxx Logo"
                />
              </div>

              <div className="content-info">
                <div className="success-info">
                  <img
                    src="https://res.cloudinary.com/doi40g1ct/image/upload/v1695119243/Billboxx/Billboxx-web/success_yyljk6.png"
                    alt="success-img"
                  />
                  <p className="bold-text">Payment successful!</p>
                  <p>
                    You've successfully paid{' '}
                    <strong>₦{numberWithCommas(billData?.amount || '00.00')}</strong> to{' '}
                    <strong>
                      {billData?.bill?.vendor?.companyName || billData?.bill?.vendor?.name || '-'}
                    </strong>
                    .
                  </p>
                </div>

                <div className="receipt-area">
                  {paymentAdvice && (
                    <img
                      src={paymentAdvice.replace('.pdf', '.png') || ''}
                      alt="receipt"
                      className="receipt"
                    />
                  )}
                </div>

                <div className="cta">
                  {/* <button className="add">Add as beneficiary</button> */}
                  <button className="share" onClick={() => setShowShareOptions(true)}>
                    Share Advice
                  </button>
                  <AnimatePresence>
                    {showShareOptions && (
                      <motion.div
                        className="menu-actions"
                        ref={shareRef}
                        initial={{ y: 20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -20, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        <button
                          onClick={() => {
                            setSaveAs('png');
                            handleFileDownload(
                              paymentAdvice.replace('.pdf', '.png'),
                              `payment-advice.png`
                            );
                          }}>
                          {loadingPaymentAdvice && saveAs === 'png'
                            ? 'Downloading...'
                            : ' Download Image'}
                        </button>
                        <button
                          onClick={() => {
                            setSaveAs('pdf');
                            handleFileDownload(paymentAdvice, `payment-advice.pdf`);
                          }}>
                          {loadingPaymentAdvice && saveAs === 'pdf'
                            ? 'Downloading...'
                            : ' Download PDF'}
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </ContentView>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default PaymentAdvice;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
      padding-top: 30px;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 200px;

    img {
      width: 100%;
    }

    @media ${device.phone} {
      width: 30%;
    }
  }

  .content-info {
    width: 100%;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .success-info {
      text-align: center;

      img {
        width: 100px;
      }

      .bold-text {
        font-size: 32px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }

      p {
        margin-top: 1rem;
        color: ${(props) => props.theme.colors?.secondary};

        strong {
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 600;
        }
      }

      @media ${device.phone} {
        img {
          width: 100px;
          margin-bottom: 0;
        }

        .bold-text {
          font-size: 26px;
        }

        p {
          font-size: 15px;
        }
      }
    }
  }

  .receipt-area {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    @media ${device.phone} {
      width: 100%;
    }

    .receipt {
      width: 100%;
      height: auto;
    }
  }

  .cta {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 50px;
    position: relative;

    @media ${device.phone} {
      width: 100%;
    }

    button {
      width: 48%;
      height: 56px;
      padding: 15px 0;
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease;

      &.share {
        background-color: ${(props) => props.theme.colors?.primary};
        color: ${(props) => props.theme.colors?.white};
        margin-right: 10px;

        &:hover {
          background-color: ${(props) => props.theme.colors?.primary};
        }
      }
    }

    .menu-actions {
      position: absolute;
      top: -60px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button {
        width: 100%;
        padding: 0 20px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer};
        }
      }
    }
  }
`;

const ReceiptView = styled.div`
  min-width: 100%;
  max-width: 100%;
  height: 800px;
  background-color: #f8f8f8;
  border-radius: 30px 30px 0 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;

  .logo-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    left: 0;

    p {
      font-size: 0.7rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
      margin-bottom: 5px;
    }

    .logo {
      width: 100px;

      img {
        width: 100%;
      }
    }
  }

  .watermark {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    opacity: 0.1;

    img {
      width: 400px;
      height: 400px;

      @media ${device.phone} {
        width: 300px;
        height: 300px;
      }
    }
  }

  .receipt-header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px;
    background-color: #efefef;
    overflow: hidden;
    border-radius: 30px 30px 0 0;

    @media ${device.phone} {
      width: 100%;
      padding: 20px;
    }

    .left {
      .company-view {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-text {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${(props) => props.theme.colors?.secondary};
          margin-right: 10px;
          border-radius: 50%;

          @media ${device.phone} {
            width: 40px;
            height: 40px;
          }

          span {
            font-size: 1rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.white};
          }
        }

        .company-name {
          p {
            font-size: 1.3rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
          }
        }
      }

      .address-view {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 0px;

        .email {
          width: 60%;
          display: flex;
          flex-direction: column;

          p {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
            margin-bottom: 5px;
          }
        }
      }
    }

    .right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      @media ${device.phone} {
        margin-top: 20px;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 700;
        color: ${(props) => props.theme.colors?.secondary};
        text-transform: uppercase;
        text-align: right;

        @media ${device.phone} {
          font-size: 1rem;
        }
      }
    }
  }

  .amount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px;
    background-color: ${(props) => props.theme.colors?.secondary};

    @media ${device.phone} {
      width: 100%;
      padding: 14px 20px;
    }

    span {
      font-size: 1rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.white};

      @media ${device.phone} {
        font-size: 0.875rem;
      }
    }

    h2 {
      font-size: 1.3rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.white};
      text-transform: uppercase;

      @media ${device.phone} {
        font-size: 1.1rem;
      }
    }
  }

  .listing {
    width: 100%;
    padding: 0 30px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom: 1px solid #e5e5e5;
      padding: 20px 0;

      span {
        font-size: 1rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 0.875rem;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
        text-transform: capitalize;

        @media ${device.phone} {
          font-size: 0.875rem;
        }
      }
    }

    .last {
      border-bottom: none;
    }
  }
`;
