/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as ProfileBg } from '@/assets/icons/profile-bg.icon.svg';
import MobileSidebar from '@/components/MobileSidebar';
import ProfileDropdown from '@/components/ProfileDropdown';
import withAuthentication from '@/hooks/withAuthentication';
import { appSelector } from '@/redux/features/app.slice';
import { fetchCompany } from '@/services/userApi';
import secureLocalStorage from 'react-secure-storage';
import CompanyProfile from './components/CompanyProfile';
import Compliance from './components/Compliance';
import EditProfile from './components/EditProfile';
import Reminders from './components/Reminders';
import ManagePassword from './components/security/ManagePassword';
import Security from './components/security/Security';
import SubscriptionAndBilling from './components/subscriptions/SubscriptionAndBilling';
import {
  InfoView,
  ProfileContentView,
  ProfileHeader,
  ProfilePhotoView,
  SettingsStyles,
  TabsView
} from './settings.styles';
import Recurring from './components/recurring/Recurring';

const tabs = [
  {
    name: 'User Profile',
    path: 'tab=profile',
    value: 'profile'
  },
  {
    name: 'Company Profile',
    path: 'tab=company-profile',
    value: 'company-profile'
  },
  {
    name: 'Compliance',
    path: 'tab=compliance',
    value: 'compliance'
  },
  {
    name: 'Recurring Bills/Invoices',
    path: 'tab=recurring&view=bills&page=1',
    value: 'recurring'
  },
  {
    name: 'Reminders',
    path: 'tab=reminders',
    value: 'reminders'
  },
  // {
  //   name: 'Subscriptions',
  //   path: 'tab=subscriptions&view=billing',
  //   value: 'subscriptions'
  // },
  {
    name: 'Security',
    path: 'tab=security&view=change-password',
    value: 'security'
  }
];

function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { user } = useSelector(appSelector);
  const companyId = secureLocalStorage.getItem('company');
  const [openNav, setOpenNav] = useState(false);

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    onSuccess: () => {},
    onError: () => {}
  });

  // if (company?.isFetching) {
  //   return <FetchLoader />
  // }

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <SettingsStyles>
        <div className="settings-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{company?.data?.data?.name}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <div className="settings-wrapper">
          <ProfileHeader>
            <div className="icon">
              <ProfileBg />
            </div>
          </ProfileHeader>

          <ProfilePhotoView>
            <div className="gap"></div>
            <div className="profile-photo">{/* <img src={user?.picture} alt="profile" /> */}</div>
          </ProfilePhotoView>

          <ProfileContentView>
            <TabsView>
              {tabs.map((tabItem) => (
                <div key={tabItem.path} className="tab-item">
                  <button
                    type="button"
                    className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                    onClick={() => setSearchParams(tabItem?.path)}>
                    {tabItem.name}
                  </button>
                </div>
              ))}
              {/* <div className="tab-item">
                <button
                  type="button"
                  className={`tab-btn ${tab === 'password' ? 'active' : ''}`}
                  onClick={() => setSearchParams('tab=password')}>
                  Manage Password
                </button>
              </div> */}
            </TabsView>
            <InfoView>
              {tab === 'profile' && <EditProfile />}
              {tab === 'compliance' && <Compliance />}
              {tab === 'password' && <ManagePassword />}
              {tab === 'company-profile' && <CompanyProfile />}
              {tab === 'reminders' && <Reminders />}
              {tab === 'recurring' && <Recurring />}
              {/* {tab === 'subscriptions' && <SubscriptionAndBilling />} */}
              {tab === 'security' && <Security />}
            </InfoView>
          </ProfileContentView>
        </div>
      </SettingsStyles>
    </>
  );
}

export default withAuthentication(Settings);
