/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';

import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { setOnboardingProgress } from '@/redux/features/app.slice';
import FetchLoader from '@/components/FetchLoader';
import ProgressIndicator from './ProgressIndicator';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { fetchUser, setupUpCompany } from '@/services/userApi';
import { device } from '@/constants/breakpoints';
import secureLocalStorage from 'react-secure-storage';

const schema = yup
  .object({
    name: yup.string().required('Company name is required')
  })
  .required();

function CompanyName() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const handleSetupCompany = useMutation(setupUpCompany, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      queryClient.invalidateQueries({ queryKey: ['user'] });
      secureLocalStorage.setItem('company', data?.data?.id);
      navigate('/onboarding?page=company-location');
      dispatch(setOnboardingProgress(40));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.data?.data?.company?.name || ''
    }
  });

  const onSubmit = (data) => {
    handleSetupCompany.mutate(data);
  };

  useEffect(() => {
    reset({
      name: user?.data?.data?.company?.name || ''
    });
  }, [user?.data, reset]);

  useEffect(() => {
    if (page === 'company-name') {
      dispatch(setOnboardingProgress(30));
    }
  }, [page, dispatch]);

  if (user.isFetching) {
    return <FetchLoader />;
  }

  return (
    <CompanyNameStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate('/onboarding?page=user-type');
            dispatch(setOnboardingProgress(20));
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>
        <div className="title-view">
          <h1>We&apos;ll also like to know about your company.</h1>
          <p>What is your company&apos;s registered name?</p>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>
        <div className="form-view">
          {/* Full name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="companyName">Company name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter company name."
              {...register('name')}
              error={!!errors?.name?.message}
            />
            {errors.name && <span className="error-label">{errors.name.message}</span>}
          </div>

          {/* Button view */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleSubmit(onSubmit)}>
              {handleSetupCompany.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>
    </CompanyNameStyles>
  );
}

export default CompanyName;

const CompanyNameStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      margin-bottom: 20px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          line-height: 29px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          font-size: 1rem;
        }
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .input-view {
        width: 100%;
        margin-bottom: 26px;

        .label-view {
          width: 100%;
          display: flex;
          margin-bottom: 8px;

          label {
            font-size: 1rem;
          }
        }

        .error-label {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.error};
          margin-top: 5px;
        }

        .input-info-view {
          width: 100%;
          display: flex;
          margin-top: 8px;

          span {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
