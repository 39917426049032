import { device } from '@/constants/breakpoints';
import styled from 'styled-components';

export const OnboardingStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  height: 100%;
  background-color: ${({ theme }) => theme.colors?.white};

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const LogoView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media ${device.phone} {
    width: 100%;

    span {
      display: none;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors?.secondary};
  }
`;
