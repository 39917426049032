import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as RefreshIcon } from '@/assets/icons/refresh.icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.icon.svg';
import PDFViewer from '@/components/PDFViewer';
import { device } from '@/constants/breakpoints';
import { getOfferLetter } from '@/services/financingApi';
import { useQuery } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';

function FileViewModal({ showModal, setShowModal, requestId }) {
  const offerLetter = useQuery({
    queryKey: [
      'offer-letter',
      {
        id: requestId
      }
    ],
    queryFn: getOfferLetter,
    enabled: !!requestId && showModal
  });

  const handleFileDownload = (url, filename) => {
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      });
  };


  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}

            {offerLetter.isLoading ? (
              <LoaderView>
                <Oval color="#64748B" height={50} width={50} />
              </LoaderView>
            ) : (
              <div className="modal-content">
                <div className="header">
                  <span></span>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="modal-content__row">
                  <div className="file-view">
                    {offerLetter?.data?.data?.url && (
                      <div className="pdf-view">
                        <PDFViewer file={offerLetter?.data?.data?.url} />
                      </div>
                    )}
                    {!offerLetter?.data?.data?.url && (
                      <div className="image-view">
                        <p>No File to display</p>
                      </div>
                    )}

                    {offerLetter?.data?.data?.url && (
                      <div className="download-view">
                        <button
                          onClick={() => {
                            handleFileDownload(
                              offerLetter?.data?.data?.url,
                              offerLetter?.data?.data?.url
                            );
                          }}>
                          Download offer letter <DownloadIcon />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default FileViewModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9011;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 90000;
    position: fixed;
    width: 700px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 16px;
    height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
      border-radius: 16px;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px;

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          width: 100%;
        }
      }

      .modal-content__title-view {
        width: 100%;
        margin-top: 20px;

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .modal-content__row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column-reverse;
        }

        .file-view {
          width: 100%;
          height: 90%;

          .download-view {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 500;
            }
          }

          .pdf-view {
            width: 100%;
            height: 100%;
            border: 1px solid #e0e0e0;
            overflow: scroll;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            .react-pdf__Page {
              max-width: calc(100% - 1em);
              display: flex;
              flex-direction: column;
              align-items: center;
              /* margin: 1em; */
            }

            .react-pdf__Page canvas {
              max-width: 100%;
              height: 100%;
            }

            .react-pdf__message {
              padding: 1em;
              color: white;
            }
          }

          .image-view {
            width: 100%;
            height: 100%;
            border: 1px solid #e0e0e0;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            p {
              padding: 20px;
              text-align: center;
              color: ${(props) => props.theme.colors?.secondary};
            }

            .refresh {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              p {
                padding: 20px;
                text-align: center;
                color: ${(props) => props.theme.colors?.secondary};
              }

              button {
                color: ${(props) => props.theme.colors?.secondary};
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  margin-right: 5px;

                  path {
                    stroke: ${(props) => props.theme.colors?.secondary};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
`;
