/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import api from './api';

/**
 * * Creates a bill ✅
 * @param {*} data - File data
 * @returns {Promise} - Returns the created bill
 */
export const createInvoice = async (data) => {
  const response = await api.post(`/receivables/create`, data);
  return response.data;
};

/**
 * * Request payment ✅
 * @param {*} data - File data
 * @returns {Promise} - Returns a request payment
 */
export const requestPayment = async (data) => {
  const response = await api.post(`/receivables/request-payment`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};

export const requestApproval = async (data) => {
  const response = await api.post(`/receivables/invoice/request-approval`, data);
  return response.data;
};

/**
 * * Fetches all bills ✅
 * @param {*} param0 - Query object
 * @returns {Promise} - Returns the bills
 */
export const fetchReceivables = async ({ queryKey }) => {
  const [, { status, page, pageLimit, search, sortBy, fetch }] = queryKey;

  let queryParams = `status=${status}&page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/receivables?${queryParams}`);
  return response.data;
};

/**
 * * Fetches invoice ✅
 * @param {Array} param0 - Query key
 * @returns {Promise} - Returns the bill
 */
export const fetchInvoice = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/receivables/${id}`);
    return response.data;
  }
};

export const updateManualInvoice = async (id, data) => {
  if (id) {
    const response = await api.put(`/invoices/${id}`, data);
    return response.data;
  }
};

export const fetchPublicInvoice = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/bills/no-auth/${id}`);
    return response.data;
  }
};

/**
 * * Update a bill ✅
 * @param {String} id - Bill id
 * @param {Object} data - Bill data
 * @returns {Promise} - Returns the updated bill
 */
export const updateInvoice = async (id, data) => {
  const response = await api.put(`/receivables/${id}`, data);
  return response.data;
};

/**
 * * Extract invoice or bill details from a file.Supported file formats:[PNG, JPEG, PDF]. Maximum file size is 1MB ✅
 * @param {Object} data - File data
 * @returns {Promise} - Returns the extracted bill
 */
export const extractInvoice = async (data) => {
  const response = await api.post(`/receivables/extract`, data);
  return response.data;
};

/**
 * * Approve a bill ✅
 * @param {*} billId - Bill id
 * @param {*} data - Approval data
 * @returns {Promise} - Returns the approved bill
 */
export const approveInvoice = async (billId, data) => {
  const response = await api.post(`/receivables/${billId}/approval`, data);
  return response.data;
};

export const markAsSent = async (invoiceId) => {
  const response = await api.put(`/receivables/${invoiceId}/mark-as-sent`);
  return response.data;
};

/**
 * * Fetches all bills metrics ✅
 * @returns {Promise} - Returns the bills metrics
 */
export const fetchReceivablesMetric = async () => {
  const response = await api.get(`/receivables/metrics/`);
  return response.data;
};

/**
 * * Fetches all inflows ✅
 * @param {*} param0 - Query object
 * @returns {Promise} - Returns the outflows
 */
export const fetchInflows = async ({ queryKey }) => {
  const [, { page, limit, search, sortBy, fetch }] = queryKey;

  let queryParams = `page=${page}&perPage=${limit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/transactions/inflows?${queryParams}`);
  return response.data;
};

/**
 * * Exports all inflows ✅
 * @returns {Promise} - Returns the exported outflows
 */
export const exportInflows = async (data) => {
  const response = await api.post(`/transactions/exports/`, data);
  return response.data;
};

/**
 * * Creates bulk bills ✅
 * @param {*} data - File data
 * @returns {Promise} - Returns the created bills
 */
export const createBulkUploadInvoice = async (data) => {
  const response = await api.post(`/receivables/create/bulk`, data);
  return response.data;
};

export const markBillAsPaid = async (data) => {
  const response = await api.post(`/transactions/mark-as-paid`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};

/**
 * * Deletes an invoice ✅
 * @param {*} invoiceId - Invoice id
 * @returns {Promise} - Returns rest of the invoices
 */
export const deleteInvoice = async (invoiceId, type) => {
  const response = await api.delete(`/receivables/${invoiceId}/${type}`);
  return response.data;
};

export const restoreDeletedInvoice = async (billId) => {
  const response = await api.patch(`/receivables/${billId}/restore`);
  return response.data;
};

export const createInvoicePDF = async (data) => {
  const response = await api.post(`/invoices/create`, data);
  return response.data;
};

export const duplicateInvoice = async (id) => {
  const response = await api.post(`/bills/${id}/duplicate`);
  return response.data;
};

export const checkboxActionsInvoices = async (data) => {
  const response = await api.post(`/bills/approve-draft-invoice`, data);
  return response.data;
};
