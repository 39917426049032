import { device } from '@/constants/breakpoints';
import styled from 'styled-components';

export const VerifyEmailView = styled.div`
  width: 1300px;
  margin: 0 auto;
  height: 100%;
  background-color: ${(props) => props.theme.colors?.white};
  padding: 4rem 0;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    padding-top: 3rem;
  }
`;
export const HeadingView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 3.4rem;
  align-items: center;
`;
export const TitleView = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;

  h3 {
    color: ${(props) => props.theme.colors?.secondary};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.7rem;
    text-align: left;
    word-break: break-word;

    span {
      font-weight: 600;
      word-break: break-word;
    }
  }
`;

export const InfoView = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors?.primaryFocus};
  padding: 30px;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${device.phone} {
    width: 100%;
    padding: 20px;
  }

  svg {
    width: 30px;
    height: 30px;
    margin: 0;
    margin-right: 10px;
  }
`;

export const RegisterView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4.75rem;

  a {
    color: ${(props) => props.theme.colors?.primary};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
  }
`;

export const AccountVerificationView = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    color: ${(props) => props.theme.colors?.secondary};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
`;
