/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import styled from 'styled-components';
import { device } from '@/constants/breakpoints';

export const CustomerView = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }
`;

export const HeaderView = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.phone} {
    padding: 20px 16px;
  }

  .title-view {
    .menu {
      display: none;
    }

    h3 {
      font-size: 1.3rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }

    @media ${device.phone} {
      display: flex;
      align-items: center;

      .menu {
        display: block;
        margin-right: 10px;
      }
    }
  }
`;

export const ContainerView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  margin-bottom: 30px;
  border-radius: 30px;
  padding: 30px 40px;

  @media ${device.phone} {
    border-radius: 0;
    padding: 30px 16px;
  }
`;

export const PageTitleView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${device.phone} {
    display: none;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-left: 10px;
  }
`;

export const ContainerHeaderView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media ${device.phone} {
    margin-top: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  .action {
    position: relative;

    button {
      width: 120px;
      height: 40px;
      background: ${(props) => props.theme.colors?.primary};
      border-radius: 8px;
      border: none;
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.875rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 10px;

        path {
          stroke: ${(props) => props.theme.colors?.white};
        }
      }
    }

    .menu-actions {
      position: absolute;
      top: 45px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: ${(props) => props.theme.colors?.white};
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }
  }
`;

export const ComponentsView = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  display: flex;
  padding-bottom: 150px;
  justify-content: flex-start;

  @media ${device.phone} {
    flex-direction: column;
  }
`;

export const ContactView = styled.div`
  width: 30%;
  height: 450px;
  padding-right: 60px;
  background-color: ${({ theme }) => theme.colors?.layer4};
  padding: 20px;
  border-radius: 10px;
  margin-right: 40px;

  @media ${device.phone} {
    width: 100%;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  .icon-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .icon {
      width: 60px;
      height: 60px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const PaymentView = styled.div`
  width: 30%;
  height: 450px;
  padding-right: 60px;
  background-color: ${({ theme }) => theme.colors?.layer4};
  padding: 20px;
  border-radius: 10px;

  @media ${device.phone} {
    width: 100%;
    margin-bottom: 30px;
  }

  .icon-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .icon {
      width: 60px;
      height: 60px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }
`;
export const BankAccountView = styled.div`
  width: 30%;
  height: 450px;
  padding-right: 60px;
  background-color: ${({ theme }) => theme.colors?.layer4};
  padding: 20px;
  border-radius: 10px;

  .icon-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .icon {
      width: 60px;
      height: 60px;
      background-color: ${({ theme }) => theme.colors?.white};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }
`;

export const ListItem = styled.div`
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors?.layer};

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-top: 8px;
    word-wrap: break-word;
    max-width: 90%;
  }
`;
