import api from './api';

export const fetchAccount = async () => {
  const response = await api.get(`/virtual-account/providus`);
  return response.data;
};

export const fetchWallet = async () => {
  const response = await api.get(`/wallet`);
  return response.data;
};

export const fetchWalletTransactions = async ({ queryKey }) => {
  const [_key, { channel, page, limit, search, sortBy, fetch, event }] = queryKey;

  let queryParams = `status=${status}&page=${page}&perPage=${limit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  if (channel) {
    queryParams += `&channel=${channel}`;
  }

  if (event) {
    queryParams += `&event=${event}`;
  }

  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/wallet/transactions?${queryParams}`);
  return response.data;
};

export const fetchTransferBankList = async () => {
  const response = await api.get(`/transfer/banks?provider=providus`);
  return response.data;
};

export const resolveTransferBankAccount = async (data) => {
  const response = await api.post(`/transfer/resolve-account`, data);
  return response.data;
};

export const initiateTransfer = async (data) => {
  const response = await api.post(`/transfer/initiate`, data);
  return response.data;
};

export const fetchTransferHistory = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;

  if (search) {
    const response = await api.get(`/transfer/all?page=${page}&perPage=${limit}&search=${search}`);
    return response.data;
  } else {
    const response = await api.get(`/transfer/all?page=${page}&perPage=${limit}`);
    return response.data;
  }
};

export const calculateTransferCharge = async (data) => {
  const response = await api.post(`/transactions/calculate-charge`, data);
  return response.data;
};

export const fetchBeneficiaries = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;

  if (search) {
    const response = await api.get(`/beneficiary?page=${page}&perPage=${limit}&search=${search}`);
    return response.data;
  } else {
    const response = await api.get(`/beneficiary?page=${page}&perPage=${limit}`);
    return response.data;
  }
};

export const addBeneficiary = async (data) => {
  const response = await api.post(`/beneficiary`, data);
  return response.data;
};

export const deleteBeneficiary = async (id) => {
  const response = await api.delete(`beneficiary/${id}`);
  return response.data;
};

export const fetchTransferPaymentAdvice = async ({ queryKey }) => {
  const [, trxRef] = queryKey;
  const response = await api.get(`/transfer/payment-advice/${trxRef}`);
  return response.data;
};

export const fetchTransactionPaymentAdvice = async ({ queryKey }) => {
  const [, trxRef] = queryKey;
  const response = await api.get(`/wallet/payment-advice/${trxRef}`);
  return response.data;
};

export const fetchTransactionReceipt = async ({ queryKey }) => {
  const [, trxRef] = queryKey;
  const response = await api.get(`/wallet/transactions/receipt/${trxRef}`);
  return response.data;
};
