/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import Subscriptions from './Subscriptions';
import Billing from './Billing';

const tabs = [
  {
    name: 'Billing',
    path: 'view=billing',
    value: 'billing'
  },
  {
    name: 'Subscription Plans',
    path: 'view=plans&state=monthly',
    value: 'plans'
  }
];

function SubscriptionAndBilling() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('view');

  return (
    <>
      <SubscriptionAndBillingStyles>
        <ContentView>
          <TabsView>
            {tabs.map((tabItem) => (
              <div key={tabItem.path} className="tab-item">
                <button
                  type="button"
                  className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=subscriptions&${tabItem?.path}`)}>
                  {tabItem.name}
                </button>
              </div>
            ))}
          </TabsView>
          <InfoView>
            {tab === 'billing' && <Billing />}
            {tab === 'plans' && <Subscriptions />}
          </InfoView>
        </ContentView>
      </SubscriptionAndBillingStyles>
    </>
  );
}

export default SubscriptionAndBilling;

export const SubscriptionAndBillingStyles = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 0 40px 0 0;
  overflow-y: scroll; */

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }
`;

export const ContentView = styled.div`
  width: 100%;
  padding: 0px 0px 0 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    padding: 20px 0px;
    padding-top: 40px;
    flex-direction: column;
  }
`;

export const TabsView = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow-x: scroll;

  @media ${device.phone} {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    border: none;
  }

  .tab-item {
    margin-right: 10px;
    display: flex;

    @media ${device.phone} {
      margin-bottom: 0;
      margin-right: 10px;
      width: 200px;
    }

    button {
      width: fit-content;
      height: 44px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
      transition: all 0.3s ease-in-out;
      padding: 12px 32px;
      border-radius: 8px;
      word-break: normal;
      white-space: nowrap;

      @media ${device.phone} {
        padding: 0;
        width: 150px;
        justify-content: center;
      }

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: ${({ theme }) => theme.colors?.primaryFocus};
    }
  }
`;

export const InfoView = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
  }
`;
