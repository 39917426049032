import { Link, Navigate, useSearchParams } from 'react-router-dom';

import {
  ContentView,
  HeadingView,
  RegisterView,
  VerifyEmailView,
  TitleView,
  InfoView
} from './verifyEmail.styles';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import useAuthentication from '@/hooks/useAuthentication';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const { authenticated } = useAuthentication();

  const email = searchParams.get('email');

  const modifiedEmail = email.replace(/ /g, '+');

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <VerifyEmailView data-testid="reset-view">
      <HeadingView>
        <span />
        <Logo data-testid="logo-icon" />
        <span />
      </HeadingView>

      <ContentView>
        <InfoView>
          <InfoIcon />
          <TitleView>
            <h3>Welcome to Billboxx</h3>
            <p>
              A link to verify your account has been sent to your email <span>{modifiedEmail}</span>
            </p>
          </TitleView>
        </InfoView>

        <RegisterView>
          <span>Return to </span>
          <Link to="/login" aria-label="Login">
            Login
          </Link>
        </RegisterView>
      </ContentView>
    </VerifyEmailView>
  );
};

export default VerifyEmail;
