import ProfileDropdown from '@/components/ProfileDropdown';
import React from 'react';
import { OutboxxStyles } from './outboxx.styles';
import { ReactComponent as SoonIcon } from '@/assets/icons/soon.svg';
import withAuthentication from '@/hooks/withAuthentication';

const Outboxx = () => {
  return (
    <OutboxxStyles>
      <div className="outboxx-header">
        <div className="title-view">{/* <h3>ABC Ventures</h3> */}</div>
        <ProfileDropdown />
      </div>
      <div className="outboxx-wrapper">
        <div className="soon">
          <SoonIcon />
          <h3>This feature is currently unavailable!</h3>
        </div>
      </div>
    </OutboxxStyles>
  );
};

export default withAuthentication(Outboxx);
