/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CSVFileValidator from 'csv-file-validator';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/file.icon.svg';
import InvoiceTestModal from './components/InvoiceTestModal';
import ConfirmCSVModal from './components/ConfirmCSVModal';
import { config } from './config';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';

function BulkUpload() {
  const navigate = useNavigate();
  const [parsedCSV, setParsedCSV] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showInvoiceTestModal, setShowInvoiceTestModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`File is larger than 5MB`);
        }

        if (err.code === 'file-invalid-type') {
          toast.error(`Error: ${err.message}`);
        }
      });
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      CSVFileValidator(acceptedFile, config)
        .then((data) => {
          setErrors(data.inValidData?.map((d) => d.message));
          setParsedCSV(data.data);
          if (data?.data?.length < 2) {
            toast.error('CSV file is empty');
            return;
          }

          if (data.inValidData?.length === 0) {
            setShowConfirmModal(true);
          } else {
            setShowInvoiceTestModal(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': []
    },
    maxFiles: 1,
    noClick: false,
    noKeyboard: false
  });
  return (
    <>
      <BulkUploadView>
        <HeaderView>
          <button type="button" onClick={() => navigate(-1)}>
            <BackIcon />
          </button>
        </HeaderView>

        <ContentView>
          <div className="drop-zone">
            <h3>Upload CSV file</h3>
            <div className="drop-container" {...getRootProps()}>
              <input {...getInputProps()} />
              <FileIcon />
              <p>Browse your CSV file or Drag and drop here</p>
            </div>
          </div>
        </ContentView>
      </BulkUploadView>

      <InvoiceTestModal
        showModal={showInvoiceTestModal}
        setShowModal={setShowInvoiceTestModal}
        errors={errors}
      />

      <ConfirmCSVModal
        showModal={showConfirmModal}
        setShowModal={setShowConfirmModal}
        parsedCSV={parsedCSV}
      />
    </>
  );
}

export default withAuthentication(BulkUpload);

const BulkUploadView = styled.div`
  width: 1200px;
  margin: 0 auto;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
  }
`;

const HeaderView = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  .drop-zone {
    width: 50%;
    height: 400px;
    background-color: #f9f9fa;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;

    @media ${device.phone} {
      width: 100%;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-bottom: 25px;
    }

    .drop-container {
      width: 100%;
      height: 170px;
      border: 1px dashed #174078;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      svg {
        width: 40px;
        height: 50px;
      }

      p {
        margin-top: 30px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          max-width: 90%;
          text-align: center;
        }
      }
    }
  }

  button {
    width: 250px;
    height: 64px;
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 16px;
    margin-top: 40px;
  }
`;
