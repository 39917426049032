/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import FetchLoader from '@/components/FetchLoader';
import ProfileDropdown from '@/components/ProfileDropdown';
import {
  ContentHeader,
  ContentWrapper,
  PaginationView,
  SearchView,
  VendorsStyles
} from './vendors.styles';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import VendorsTables from './components/VendorsTable';
import { fetchVendors } from '@/services/vendorApi';
import Pagination from '@/components/Pagination';
import { ComponentsView } from './vendor.styles';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';
import ComplianceWarnModal from '@/components/ComplianceWarnModal';
import { fetchCompany, fetchUser } from '@/services/userApi';
import useDebounce from '@/hooks/useDebounce';
import SortingComponent from '@/components/SortingComponent';
import FilterSort from '@/components/FilterSort';
import { truncate } from '@/utils/helpers';
import secureLocalStorage from 'react-secure-storage';

function Vendors() {
  const navigate = useNavigate();
  const companyId = secureLocalStorage.getItem('company');
  const [openNav, setOpenNav] = useState(false);
  const [showComplianceWarning, setShowComplianceWarning] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;
  const currentPage = searchParams.get('page');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);

  const handleFilterApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const vendors = useQuery({
    queryKey: [
      'vendors',
      {
        page: currentPage,
        pageLimit,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchVendors,
    enabled: user?.data?.data?.complianceVerified || false
  });

  const onPageChange = (page) => {
    setSearchParams(`page=${page}`);
  };

  useEffect(() => {
    if (!user?.isLoading && user?.data?.data) {
      if (user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(false);
      }
      if (!user?.data?.data?.complianceVerified) {
        setShowComplianceWarning(true);
      }
    } else {
      setShowComplianceWarning(false);
    }
  }, [user?.data?.data]);

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <VendorsStyles>
        <ContentHeader>
          <div className="title-view">
            {' '}
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{truncate(company?.data?.data?.name || '', 40, true)}</h3>
          </div>
          <ProfileDropdown />
        </ContentHeader>

        <ContentWrapper>
          <div className="vendors-wrapper__page-title">
            <button type="button">
              <BackIcon />
            </button>
            <h3>Make Payments</h3>
          </div>

          <div className="vendors-wrapper__header">
            <div className="row1">
              <h3>Vendors</h3>
              {user?.data?.data?.complianceVerified && (
                <button type="button" onClick={() => navigate('/make-payment/vendors/create')}>
                  <PlusIcon /> New Vendor
                </button>
              )}
            </div>
            <div className="row2">
              <SearchView>
                <input
                  type="search"
                  placeholder="Search vendors"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <SearchIcon className="search-icon" />
              </SearchView>
              <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
              <FilterSort onApply={handleFilterApply} />
            </div>
          </div>

          <ComponentsView>
            {vendors?.isFetching ? (
              <FetchLoader />
            ) : (
              <VendorsTables vendors={vendors?.data?.data} />
            )}
          </ComponentsView>

          <PaginationView>
            <Pagination
              itemsPerPage={pageLimit}
              totalItems={vendors?.data?.data?.meta?.total}
              currentPage={vendors?.data?.data?.meta?.page}
              onPageChange={onPageChange}
            />
          </PaginationView>
        </ContentWrapper>
      </VendorsStyles>

      <ComplianceWarnModal
        showModal={showComplianceWarning}
        setShowModal={setShowComplianceWarning}
      />
    </>
  );
}

export default withAuthentication(Vendors);
