import Pagination from '@/components/Pagination';
import { device } from '@/constants/breakpoints';
import { fetchWalletTransactions } from '@/services/bankingApi';
import { numberWithCommas } from '@/utils/helpers';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/fileUpload.icon.svg';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import useDebounce from '@/hooks/useDebounce';
import SortingComponent from '@/components/SortingComponent';
import FilterSort from '@/components/FilterSort';
import TableLoader from '@/components/TableLoader';
import TransactionDetails2 from '../TransactionDetails2';
import ExportModal from '../ExportModal';
import TableRowsSelect from '@/components/TableRowSelect';
import { useSearchParams } from 'react-router-dom';
import AccountStatementModal from './AccountStatementModal';

const AllTransactions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get('limit');
  const currentPage = searchParams.get('page');
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [showExportModal, setShowExportModal] = useState(false);
  const [showAccountStatementModal, setShowAccountStatementModal] = useState(false);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);

  const handleFilterApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  const handleRowsPerPageChange = (value) => {
    setSearchParams(`tab=transaction-history&view=all&page=1&limit=${value}`);
  };

  const transactions = useQuery({
    queryKey: [
      'walletTransactions',
      {
        channel: '',
        page: currentPage ? currentPage : 1,
        limit: limit ? limit : 10,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchWalletTransactions
  });

  const onPageChange = (page) => {
    setSearchParams(`tab=transaction-history&view=all&page=${page}&limit=${limit ? limit : 10}`);
  };

  return (
    <>
      <AllTransactionsStyles>
        {/* <h3>All transactions</h3> */}
        <div className="header-view">
          <button className="get-statement" onClick={() => setShowAccountStatementModal(true)}>
            Get Account Statement
          </button>
          <div className="filter-view">
            <SearchView>
              <input
                type="search"
                placeholder="Search transactions"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon className="search-icon" />
            </SearchView>
            <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
            <FilterSort onApply={handleFilterApply} />
            <button type="button" className="upload" onClick={() => setShowExportModal(true)}>
              <FileIcon />
            </button>
          </div>
        </div>
        {transactions?.isLoading ? (
          <TableLoader />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th className="value">Party</th>
                  <th className="amount">Amount</th>
                  <th className="value">Reference</th>
                  <th className="value">Channel</th>
                  <th className="amount">Status</th>
                  <th className="value">Time stamp</th>
                  <th className="actions"></th>
                </tr>
              </thead>
              <tbody>
                {transactions?.data?.data?.items?.map((history, index) => (
                  <tr key={index}>
                    <td className="value">
                      {history?.event === 'CREDIT' && history?.senderName
                        ? history?.senderName
                        : history?.event === 'DEBIT' && history?.receiverName
                          ? history?.receiverName
                          : 'N/A'}
                    </td>

                    <td className="amount">₦{numberWithCommas(history?.amount || '00.00')}</td>
                    <td className="value">{history?.identifier}</td>
                    <td className="value">{history?.channel}</td>
                    <td className={`amount ${history?.status?.toLowerCase()}`}>
                      {history?.status}
                    </td>
                    <td className="value">
                      {format(new Date(history?.createdAt), 'do MMM, yyyy hh:mm, a')}
                    </td>

                    <td className="actions">
                      <button
                        type="button"
                        className="btn view"
                        onClick={() => {
                          setSelectedTransaction(history);
                          setShowViewModal(true);
                        }}>
                        <ViewIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="listing">
              {transactions?.data?.data?.items?.map((history) => (
                <div
                  className={`item ${history?.event === 'CREDIT' ? 'credit' : 'debit'}`}
                  key={history?.id}>
                  <div className="col1">
                    <span className="name">{history?.remark}</span>
                    <span className="date">
                      {format(new Date(history?.createdAt), 'do MMM, yyyy, h:mm a')}
                    </span>
                    <span className="id">{history?.reference}</span>
                  </div>
                  <button
                    type="button"
                    className="btn more"
                    onClick={() => {
                      setSelectedTransaction(history);
                      setShowViewModal(true);
                    }}>
                    <ViewIcon />
                  </button>
                  <span className="amount">₦{numberWithCommas(history?.amount || '00.00')}</span>
                </div>
              ))}
            </div>

            <div className="pagination">
              <Pagination
                itemsPerPage={limit ? parseInt(limit) : 10}
                totalItems={transactions?.data?.data?.meta?.totalCount}
                currentPage={parseInt(transactions?.data?.data?.meta?.page)}
                onPageChange={onPageChange}
              />
              <TableRowsSelect onChange={handleRowsPerPageChange} />
            </div>
          </>
        )}
      </AllTransactionsStyles>

      <ExportModal showModal={showExportModal} setShowModal={setShowExportModal} />
      <AccountStatementModal
        showModal={showAccountStatementModal}
        setShowModal={setShowAccountStatementModal}
      />

      <TransactionDetails2
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        transaction={selectedTransaction}
      />
    </>
  );
};

export default AllTransactions;

const AllTransactionsStyles = styled.div`
  width: 100%;

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
    margin-bottom: 20px;
  }

  .header-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media ${device.phone} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .get-statement {
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      height: 40px;
      width: 200px;
      border: none;
      border-radius: 8px;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;

      @media ${device.phone} {
        margin-bottom: 20px;
      }
    }

    .filter-view {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media ${device.phone} {
        width: 100%;
      }

      .upload {
        width: 40px;
        height: 40px;
        color: ${(props) => props.theme.colors?.secondary};
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 10px;
        padding: 0;

        svg {
        }

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer};
        }
      }
    }
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .name {
          font-size: 0.9rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }

        .account {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .credit {
      border-left: 4px solid ${(props) => props.theme.colors?.primary};
    }

    .debit {
      border-left: 4px solid ${(props) => props.theme.colors?.error};
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  margin-top: 20px;

  @media ${device.tablet} {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media ${device.phone} {
    display: none;
  }

  thead {
    tr {
      background-color: ${({ theme }) => theme?.colors.layer2};
      border-radius: 4px;
    }
  }

  tr {
    color: ${({ theme }) => theme?.colors.secondary};
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;

    position: relative;

    @media ${device.mobile} {
      display: table-row;
    }
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme?.colors.background};
    border-radius: 4px;
  }

  th,
  td {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;
    text-transform: capitalize;

    &.amount,
    &.value,
    &.actions {
      flex-basis: 0;

      &.successful {
        color: ${(props) => props.theme.colors?.success};
        font-weight: 600;
      }
      &.pending {
        color: ${(props) => props.theme.colors?.warning};
        font-weight: 600;
      }
      &.declined {
        color: ${(props) => props.theme.colors?.error_200};
        font-weight: 600;
      }
    }

    &.green {
      color: ${({ theme }) => theme?.colors.primary};
      text-transform: capitalize;
    }

    &.value {
      flex: 2; /* Increase the flex value here */
      min-width: 200px;
    }

    &.actions {
      .view {
        margin-right: 10px;
      }
    }

    @media ${device.mobile} {
      display: table-cell;
    }
  }

  th {
    color: ${({ theme }) => theme?.colors.secondary};
    font-weight: 600;
    font-size: 0.875rem;
    text-align: left;
    outline: none;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    padding: 16px 12px;
  }

  td {
    align-items: center;
    margin: 0;
    padding: 16px 12px;
    font-weight: 500;

    &.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      svg {
        margin: 0;
        margin-left: 10px;
      }

      button {
        padding: 0;
        display: block;

        svg {
        }
      }

      .menu-actions {
        position: absolute;
        top: 50px;
        right: 0;
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors.layer2};
          }
        }
      }
    }
  }
`;

const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: 68%;
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
