import * as yup from 'yup';

export function isValidEmail(email = '') {
  const validator = yup.string().email('invalid email');

  try {
    validator.validateSync(email);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidDueDate(dueDate = '') {
  const validator = yup.string().min(8, 'Invalid date entered').required('Due date is required');

  try {
    validator.validateSync(dueDate);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidAmount(amount = '') {
  const validator = yup
    .string()
    .min(3, 'Amount is missing or invalid')
    .required('Amount is required');

  try {
    validator.validateSync(amount);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidCompanyName(companyName = '') {
  const validator = yup
    .string()
    .min(3, 'Company name is missing')
    .required('Company name is required');

  try {
    validator.validateSync(companyName);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidDescription(description = '') {
  const validator = yup.string();

  try {
    validator.validateSync(description);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidInvoiceNumber(invoiceNumber = '') {
  const validator = yup.string().required('Invoice number is required');

  try {
    validator.validateSync(invoiceNumber);

    return true;
  } catch (error) {
    return false;
  }
}
