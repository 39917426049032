/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg'
import { ReactComponent as UncheckIcon } from '@/assets/icons/uncheck.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { ReactComponent as AscIcon } from '@/assets/icons/asc.icon.svg';
import { ReactComponent as DescIcon } from '@/assets/icons/desc.icon.svg';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useSortableData } from '@/hooks/useSortableData';
import { deleteCustomer } from '@/services/customerApi';
import EditCustomer from './EditCustomer';
import PromptModal from '@/components/PromptModal';
import { device } from '@/constants/breakpoints';

export default function CustomersTables({ customers }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showMenu, setShowMenu] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [selectedcustomer, setSelectedCustomer] = useState(null);
  const [, setShowFilterMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const ref = useRef();
  const filterRef = useRef();

  useOnClickOutside(ref, () => setShowMenu(false));
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const { items, requestSort, sortConfig } = useSortableData(customers?.items || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return undefined;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleDeleteCustomer = useMutation(deleteCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      toast.success('Customer deleted successfully');
      setShowPromptModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <CustomerTableView>
      <ViewHeader>
        <div className="title">{/* <h3>Total: ₦23,424.90 (14 Items)</h3> */}</div>

        <div className="actions">
          <button type="button">{/* <FilterIcon /> */}</button>
        </div>
      </ViewHeader>

      {items?.length > 0 ? (
        <>
          <TableView>
            <TableHeader>
              {/* <div className="item icon">
                <button type="button">
                  <UncheckIcon />
                </button>
              </div> */}
              <div
                className={`item customer ${getClassNamesFor('customerName')}`}
                onClick={() => requestSort('customerName')}>
                <span>
                  Customer{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item bill ${getClassNamesFor('numberOfBills')}`}
                onClick={() => requestSort('numberOfInvoice')}>
                <span>
                  No. of Invoice{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item amount ${getClassNamesFor('invoices')}`}
                onClick={() => requestSort('invoices')}>
                <span>
                  Invoices{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item amount ${getClassNamesFor('paymentReceived')}`}
                onClick={() => requestSort('paymentReceived')}>
                <span>
                  Payment received{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`item amount ${getClassNamesFor('balance')}`}
                onClick={() => requestSort('balance')}>
                <span>
                  Open balance{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div className="item action">
                <span></span>
              </div>
            </TableHeader>

            {/* ====== Table body ====== */}
            <TableBody>
              {items.map((customer, i) => (
                <TableRow key={customer.id}>
                  {/* <div className="customers-table__body-row__item icon">
                    <butto type="button">
                      <UncheckIcon />
                    </butto>
                  </div> */}
                  <div className="customers-table__body-row__item customer">
                    {customer?.type === 'individual' && (
                      <span>{customer?.companyName || customer?.name}</span>
                    )}
                    {customer?.type === 'business' && <span>{customer?.companyName}</span>}
                  </div>
                  <div className="customers-table__body-row__item bill">
                    <span>{customer?.numberOfBills}</span>
                  </div>
                  <div className="customers-table__body-row__item amount">
                    <span>₦{numberWithCommas(customer?.totalPayables || '00.00')}</span>
                  </div>
                  <div className="customers-table__body-row__item amount">
                    <span>₦{numberWithCommas(customer?.totalPaid || '00.00')}</span>
                  </div>
                  <div className="customers-table__body-row__item amount">
                    <span>₦{numberWithCommas(customer?.openBalance || '00.00')}</span>
                  </div>
                  <div className="customers-table__body-row__item action">
                    <button
                      type="button"
                      className="btn view"
                      onClick={() => {
                        navigate(`/get-paid/customers/${customer?.id}`);
                      }}>
                      <ViewIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMenu(true);
                        setCurrentIndex(i);
                        setSelectedCustomer(customer);
                      }}
                      className="btn more">
                      <MoreIcon />
                    </button>

                    {/* ===== Dropdown Menu ====== */}
                    <AnimatePresence>
                      {showMenu && currentIndex === i ? (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          <button
                            type="button"
                            onClick={() => {
                              navigate(`/get-paid/customers/${customer?.id}`);
                            }}>
                            View customer
                          </button>
                          <button type="button" onClick={() => setShowUpdateModal(true)}>
                            Edit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setShowPromptModal(true);
                              setSelectedCustomer(customer);
                            }}>
                            Delete
                          </button>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </TableRow>
              ))}
            </TableBody>
          </TableView>

          <div className="listing">
            {items?.map((item, i) => (
              <div className="item" key={item?.id}>
                <div className="col1">
                  <span className="name">{item?.companyName || customer?.name}</span>
                </div>
                <div className="col2">
                  <p className="label">No. of Invoices</p>
                  <span className="value">{numberWithCommas2(item?.numberOfBills || '0')}</span>
                </div>
                <div className="col2">
                  <p className="label">Open balance</p>
                  <span className="value">₦{numberWithCommas(item?.openBalance || '00.00')}</span>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                    setSelectedCustomer(item);
                  }}
                  className="btn more">
                  <MoreIcon />
                </button>
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        type="button"
                        onClick={() => {
                          navigate(`/get-paid/customers/${item?.id}`);
                        }}>
                        View customer
                      </button>
                      <button type="button" onClick={() => setShowUpdateModal(true)}>
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setShowPromptModal(true);
                          setSelectedCustomer(item);
                        }}>
                        Delete
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyView>
          <EmptyIcon />
          <h3>Nothing to see here!</h3>
        </EmptyView>
      )}

      <EditCustomer
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        selectedcustomer={selectedcustomer}
      />
      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete customer</h3>
          <p>When you delete a customer, you lose all related data. This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteCustomer.mutate(selectedcustomer?.id)}>
            {handleDeleteCustomer.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </CustomerTableView>
  );
}

CustomersTables.propTypes = {
  customers: PropTypes.object.isRequired
};

const CustomerTableView = styled.div`
  width: 100%;

  .customers-table {
    @media ${device.phone} {
      display: none;
    }

    .customers-table__header {
      .ascending {
        color: ${({ theme }) => theme.colors?.secondary};

        .up path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .descending {
        color: ${({ theme }) => theme.colors?.secondary};

        .down path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .icon {
        flex: 0.3;
      }

      .customer {
        flex: 2.5;
      }

      .bill {
        flex: 1;
      }

      .date {
        flex: 1.3;
      }

      .amount {
        flex: 1.2;
      }

      .action {
        flex: 0.5;
      }
    }

    .customers-table__body {
      width: 100%;
      margin-top: 20px;

      .customers-table__body-row {
      }
    }

    .customers-table__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }
      }

      .col2 {
        display: flex;
        flex-direction: column;

        .value {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .label {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
          margin-bottom: 5px;
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const ViewHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`;

const TableView = styled.div`
  width: 100%;
  margin-top: 20px;

  @media ${device.phone} {
    display: none;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
      }
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors?.secondary};

    .up path {
      stroke: ${({ theme }) => theme.colors?.secondary};
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors?.secondary};

    .down path {
      stroke: ${({ theme }) => theme.colors?.secondary};
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .icon {
    flex: 0.3;
  }

  .customer {
    flex: 2.5;
  }

  .bill {
    flex: 1;
  }

  .date {
    flex: 1.3;
  }

  .amount {
    flex: 1.2;
  }

  .action {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .customers-table__body-row__item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
    }
  }

  .icon {
    flex: 0.3;
  }

  .customer {
    flex: 2.5;
  }

  .bill {
    flex: 1;
  }

  .date {
    flex: 1.3;
  }

  .amount {
    flex: 1.2;
  }

  .action {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view {
      margin-right: 20px;
    }

    .menu-actions {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: ${(props) => props.theme.colors?.white};
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }
`;
