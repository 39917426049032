import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import React from 'react';
import styled from 'styled-components';

function PhoneNumberInput({ ...props }) {
  return (
    <PhoneInputView error={props?.error}>
      <PhoneInput {...props} country="NG" className="phone-input" />
    </PhoneInputView>
  );
}

export default PhoneNumberInput;

const PhoneInputView = styled.div`
  width: 100%;
  margin-top: 5px;

  .phone-input {
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '64px'};
    padding: ${({ icon }) => (icon ? '0 16px 0 48px' : '0 16px')};
    background-color: ${({ theme, error }) =>
      error ? theme.colors?.errorBackground : theme.colors?.inputBackground};
    border: 1px solid
      ${({ theme, error }) => (error ? theme.colors?.error : theme.colors?.inputBackground)};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors?.activeTitle};
    outline: none;
    font-size: 1rem;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
    padding-left: 60px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px;

    &:focus {
      background-color: ${({ theme, error }) =>
        error ? theme.colors?.errorBackground : theme.colors?.white};
      border: 1px solid
        ${({ theme, error }) => (error ? theme.colors?.error : theme.colors?.activeTitle)};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
      font-weight: 300;
    }

    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .PhoneInputInput {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      outline: none;
    }

    .PhoneInputCountryIcon {
    }
  }

  .phone-btn {
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 16px;
    background-color: transparent !important;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 12px;
    border-radius: 20px;

    button {
      border-radius: 20px;
      &:hover {
        outline: none;
        background-color: transparent !important;
      }

      &:active {
        outline: none;
        background-color: transparent !important;
      }

      &:focus {
        outline: none;
        background-color: transparent !important;
      }
    }

    &:hover {
      outline: none;
      background-color: transparent !important;
    }

    &:active {
      outline: none;
      background-color: transparent !important;
    }

    &:focus {
      outline: none;
      background-color: transparent !important;
    }
  }

  .phone-search {
    width: 100%;
    height: 100%;
    border: none;
    background-color: white;
    margin: 0;
    left: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    input {
      width: 92%;
      border: none !important;
      outline: none;
      border-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors?.placeholder};
        font-size: 0.875rem;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }

  .phone-dropdown {
    border: none;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
  }
`;
