import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import { ReactSVG } from 'react-svg';
import { fetchInvoiceDiscountDetails, invoiceDiscountAcceptOffer } from '@/services/financingApi';
import theme from '@/constants/theme';
import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import FileViewModal from '@/components/FileViewModal';

const schema = yup.object({
  terms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions')
});

function AcceptOfferModal({ showModal, setShowModal, requestId }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showFileModal, setShowFileModal] = useState(false);

  const request = useQuery({
    queryKey: [
      'request-details-offer',
      {
        id: requestId
      }
    ],
    queryFn: fetchInvoiceDiscountDetails
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      terms: false
    }
  });

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name: 'terms',
    control,
    rules: { required: true }
  });

  const handleAcceptOffer = useMutation({
    mutationFn: (requestId) => invoiceDiscountAcceptOffer(requestId),
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries({ queryKey: ['invoice-discount-offers'] });
      queryClient.invalidateQueries({ queryKey: ['invoice-discount-metrics'] });
      toast.success('Offer accepted successfully');
      navigate('/financing/invoice-discounting?tab=accepted&page=1');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  });

  const onSubmit = () => {
    handleAcceptOffer.mutate(requestId);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <Modal
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                {request?.isLoading ? (
                  <LoaderView>
                    <Oval
                      color={theme?.colors?.primary}
                      secondaryColor={theme?.colors?.layer2}
                      height={40}
                      width={40}
                      strokeWidth={5}
                    />
                  </LoaderView>
                ) : (
                  <>
                    <ModalHeader>
                      <h3>Accept offer</h3>
                      <button type="button" onClick={() => setShowModal(false)}>
                        <ReactSVG src={icons.closeIcon} />
                      </button>
                    </ModalHeader>

                    <InfoView>
                      <h4>Kindly confirm all the details are correct before proceeding</h4>
                      <button
                        type="button"
                        onClick={() => {
                          setShowFileModal(true);
                        }}>
                        Preview offer letter
                      </button>
                    </InfoView>

                    <RowView>
                      <RowItem>
                        <h4>Offer from</h4>
                        <p>
                          {request?.data?.data?.loan?.loanRequest[0]?.lender?.companies[0]?.name ||
                            'N/A'}
                        </p>
                      </RowItem>

                      <RowItem className="end">
                        <h4>Offer ID</h4>
                        <p>{request?.data?.data?.requestId || 'N/A'}</p>
                      </RowItem>
                    </RowView>
                    <RowView>
                      <RowItem>
                        <h4>Offer</h4>
                        <p>₦{numberWithCommas(request?.data?.data?.loan?.amount || '00.00')}</p>
                      </RowItem>

                      <RowItem className="end">
                        <h4>Tenure</h4>
                        <p>{request?.data?.data?.loan?.tenure} Days</p>
                      </RowItem>
                    </RowView>
                    <RowView>
                      <RowItem>
                        <h4>Interest</h4>
                        <p>₦{numberWithCommas(request?.data?.data?.loan?.interest || '00.00')}</p>
                      </RowItem>

                      <RowItem className="end">
                        <h4>Repayment</h4>
                        <p>₦{numberWithCommas(request?.data?.data?.loan?.repayment || '00.00')}</p>
                      </RowItem>
                    </RowView>

                    <TermsView>
                      <div className="line">
                        <CheckboxInput
                          type="checkbox"
                          onChange={onChange}
                          onBlur={onBlur}
                          checked={value}
                          ref={ref}
                          name="terms"
                        />
                        <label htmlFor="terms">
                          I accept all the <button>terms and conditions</button>
                        </label>
                      </div>
                      {error && <span>{error?.message}</span>}
                    </TermsView>

                    <ButtonsView>
                      <button type="button" onClick={() => setShowModal(false)} className="cancel">
                        Cancel
                      </button>
                      <button type="button" className="send" onClick={handleSubmit(onSubmit)}>
                        {handleAcceptOffer.isLoading ? (
                          <Oval
                            color={theme?.colors?.white}
                            secondaryColor={theme?.colors?.layer2}
                            height={20}
                            width={20}
                            strokeWidth={3}
                          />
                        ) : (
                          'Accept offer'
                        )}
                      </button>
                    </ButtonsView>
                  </>
                )}
              </ModalContent>
            </Modal>
          </ModalView>
        )}
      </AnimatePresence>

      <FileViewModal
        showModal={showFileModal}
        setShowModal={setShowFileModal}
        requestId={requestId}
      />
    </>
  );
}

export default AcceptOfferModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9010;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
  }
`;

const Modal = styled(motion.div)`
  background-color: ${(props) => props.theme?.colors?.white};
  z-index: 30000;
  position: fixed;
  border-radius: 4px;
  max-width: 40%;
  padding: 30px 0px;
  padding-top: 0;
  padding-bottom: 20px;
  border-radius: 30px;
  min-height: 50%;
  max-height: 90%;
  overflow-y: scroll;

  @media ${device.mobile} {
    width: calc(100% - 32px);
    border-radius: 16px;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    width: 100%;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: ${(props) => props.theme?.colors?.secondary};
  }
`;

const InfoView = styled.div`
  margin-top: 20px;
  width: 100%;

  h4 {
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme?.colors?.secondary};
  }

  button {
    margin-top: 20px;
    color: ${(props) => props.theme?.colors?.primary};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
    text-decoration: underline;
  }
`;

const RowView = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;
const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme?.colors?.black};
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme?.colors?.secondary};
  }

  span {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme?.colors?.info};
    margin-top: 5px;
  }

  &.end {
    text-align: right;
  }
`;

const TermsView = styled.div`
  margin-top: 40px;

  .line {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  label {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${(props) => props.theme?.colors?.black};

    button {
      color: ${(props) => props.theme?.colors?.primary};
      border: none;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      transition: background-color 0.3s;
      text-decoration: underline;
    }
  }

  span {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${(props) => props.theme?.colors?.error};
  }
`;

const ButtonsView = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 180px;
    height: 64px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.send {
    color: ${(props) => props.theme?.colors?.white};
    background-color: ${(props) => props.theme?.colors?.primary};
    margin-left: 10px;
  }

  button.cancel {
    background-color: transparent;
    color: ${(props) => props.theme?.colors?.primary};
  }
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: ${(props) => props.theme?.colors?.primary};
  color: ${(props) => props.theme?.colors?.primary};
  border: 1px solid ${(props) => props.theme?.colors?.primary};
  stroke: ${(props) => props.theme?.colors?.primary};

  .line {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const LoaderView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  height: 100%;
`;
