/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import ManagePassword from './ManagePassword';
import TransactionPin from './TransactionPin';
import Auth2faSettings from './Auth2faSettings';

const tabs = [
  {
    name: 'Transaction PIN',
    path: 'view=transaction-pin',
    value: 'transaction-pin'
  },
  {
    name: '2FA',
    path: 'view=2fa',
    value: '2fa'
  },
  {
    name: 'Change Password',
    path: 'view=change-password',
    value: 'change-password'
  }
];

function Banking() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('view');

  return (
    <>
      <BankingStyles>
        <ContentView>
          <TabsView>
            {tabs.map((tabItem) => (
              <div key={tabItem.path} className="tab-item">
                <button
                  type="button"
                  className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                  onClick={() => setSearchParams(`tab=security&${tabItem?.path}`)}>
                  {tabItem.name}
                </button>
              </div>
            ))}
          </TabsView>
          <InfoView>
            {tab === 'change-password' && <ManagePassword />}
            {tab === 'transaction-pin' && <TransactionPin />}
            {tab === '2fa' && <Auth2faSettings />}
          </InfoView>
        </ContentView>
      </BankingStyles>
    </>
  );
}

export default Banking;

export const BankingStyles = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 0 40px 0 0;
  overflow-y: scroll; */

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }
`;

export const ContentView = styled.div`
  width: 100%;
  padding: 0px 0px 0 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    padding: 20px 0px;
    padding-top: 40px;
    flex-direction: column;
  }
`;

export const TabsView = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow-x: scroll;
  border-bottom: 1px solid ${({ theme }) => theme.colors?.layer3};

  @media ${device.phone} {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    border: none;
  }

  .tab-item {
    margin-right: 10px;
    display: flex;

    @media ${device.phone} {
      margin-bottom: 0;
      margin-right: 10px;
      width: 200px;
    }

    button {
      width: fit-content;
      height: 44px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
      transition: all 0.3s ease-in-out;
      padding: 12px 32px;
      border-radius: 8px;
      word-break: normal;
      white-space: nowrap;

      @media ${device.phone} {
        padding: 0;
        width: 150px;
        justify-content: center;
      }

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors?.primary};
        border-radius: 0;
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      border-bottom: 1px solid ${({ theme }) => theme.colors?.primary};
      border-radius: 0;
    }
  }
`;

export const InfoView = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
  }
`;
