/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as DropIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/profile.icon.svg';
import { ReactComponent as NotificationIcon } from '@/assets/icons/notification.icon.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/logout2.icon.svg';
import { ReactComponent as AccountSwitchIcon } from '@/assets/icons/account-switch.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { device } from '@/constants/breakpoints';
import { useMutation, useQuery } from '@tanstack/react-query';
import { logoutUser } from '@/services/authApi';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';
import { fetchCompany } from '@/services/userApi';
import useUser from '@/hooks/useUser';
import SwitchButton from './SwitchButton';
import NotificationsModal from './notifications/NotificationsModal';

const hostname = window.location.hostname.split('.')[0];

const url =
  hostname === 'lenders'
    ? 'https://lenders.billboxx.com'
    : hostname === 'localhost'
      ? 'http://localhost:3001'
      : 'https://lenders-staging.vercel.app';

function ProfileDropdown({ companyy }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  useOnClickOutside(ref, () => setShowDropdown(false));
  const companyId = secureLocalStorage.getItem('company');
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany,
    enabled: !companyy
  });

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <>
      <ProfileDropdownStyles>
        {user?.isLender && <SwitchButton userId={user?.id} />}
        <div className="notif-view" onClick={() => setShowNotificationsModal(true)}>
          <NotificationIcon />
        </div>
        <div className="avatar-view" onClick={() => setShowDropdown(true)}>
          {company?.data?.data?.logoUrl ? (
            <img src={company?.data?.data?.logoUrl} alt="company logo" />
          ) : (
            <ProfileIcon />
          )}
        </div>
        <div className="icon" onClick={() => setShowDropdown(true)}>
          <DropIcon />
        </div>

        <AnimatePresence mode="wait">
          {showDropdown && (
            <DropdownView
              ref={ref}
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <Link to="/settings?tab=profile">
                <div className="icon">
                  <ProfileIcon />
                </div>
                <span>Profile Settings</span>
              </Link>
              {/* <a href={`${url}?uid=${user?.id}`}>
                <div className="icon">
                  <AccountSwitchIcon className="switch-icon" />
                </div>
                <span>Lenders portal</span>
              </a> */}
              <button type="button" onClick={() => handleLogoutUser.mutate({})}>
                <div className="icon">
                  <LogoutIcon />
                </div>
                <span>{handleLogoutUser.isLoading ? 'Logging out...' : 'Logout'}</span>
              </button>
            </DropdownView>
          )}
        </AnimatePresence>
      </ProfileDropdownStyles>
      <NotificationsModal
        showModal={showNotificationsModal}
        setShowModal={setShowNotificationsModal}
      />
    </>
  );
}

export default ProfileDropdown;

const ProfileDropdownStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  z-index: 20;

  @media ${device.phone} {
    width: 100%;
  }

  .name-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    background-color: ${({ theme }) => theme.colors?.layer};
    padding: 10px 24px;
    border-radius: 8px;

    h3 {
      font-size: 1.125rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .avatar-view {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors?.white};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: 5px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 16px;
      object-fit: contain;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .notif-view {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    margin-left: 5px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  /* img {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    object-fit: cover;
    margin-left: 20px;
    margin-right: 15px;
  } */
`;

const DropdownView = styled(motion.div)`
  position: absolute;
  top: 55px;
  right: 0;
  background: ${({ theme }) => theme.colors?.white};
  border-radius: 8px;
  box-shadow: 0px 13.9303px 41.7909px rgba(0, 0, 0, 0.08);
  width: 250px;
  padding: 20px;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    transition: all 0.2s ease-in-out;

    .icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      background-color: ${({ theme }) => theme.colors?.primaryFocus};
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }

      .switch-icon {
        width: 20px;
        height: 20px;

        path {
          fill: ${({ theme }) => theme.colors?.primary};
        }
      }
    }

    span {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
`;
