import Button from '@/components/Button/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { setOnboardingProgress } from '@/redux/features/app.slice';
import { useEffect } from 'react';
import ProgressIndicator from './ProgressIndicator';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAccountTypes, fetchUser, updateUser } from '@/services/userApi';
import FetchLoader from '@/components/FetchLoader';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { device } from '@/constants/breakpoints';

const UserType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const [selectedUserType, setSelectedUserType] = useState(null);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: (data) => {
      setSelectedUserType(data?.data?.accountTypeId);
    },
    onError: (error) => {},
  });

  const userTypes = useQuery({
    queryKey: ['userTypes'],
    queryFn: fetchAccountTypes,
    onSuccess: (data) => {},
    onError: (error) => {},
  });

  useEffect(() => {
    if (page === 'user-type') {
      dispatch(setOnboardingProgress(20));
    }
  }, [page, dispatch]);

  const handleUpdateUser = useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      navigate('/onboarding?page=company-name');
      dispatch(setOnboardingProgress(30));
      // toast.success('Profile updated successfully!');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const onSubmit = () => {
    if (!selectedUserType) {
      toast.error('Please select a user type');
      return;
    } else {
      handleUpdateUser.mutate({
        accountType: selectedUserType,
      });
    }
  }

  if (user.isFetching || userTypes.isFetching) {
    return <FetchLoader />;
  }

  return (
    <UserInfoStyles>
      <div className="back-view">
        <button
          className="back"
          onClick={() => {
            navigate('/onboarding?page=user-info');
            dispatch(setOnboardingProgress(10));
          }}
        >
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>
        <div className="title-view">
          <h1>Tell us what type of company you are with?</h1>
          <p>
            A business owner or an employee making payments or an accounting firm managing payments?
          </p>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>
        <div className="form-view">
          {userTypes?.data?.data?.map((userType, index) => {
            {
              /* const Icon = userType?.icon; */
            }
            return (
              <div
                className={`option ${selectedUserType === userType?.id ? 'selected' : ''}`}
                key={`${userType?.id}-${index}`}
                onClick={() => setSelectedUserType(userType?.id)}
              >
                {selectedUserType === userType?.id && <CheckedIcon className="checked" />}
                {/* <div className="icon">
                  <Icon />
                </div> */}
                <div className="info">
                  <h3>{userType?.name}</h3>
                  <p>{userType?.description}</p>
                </div>
              </div>
            );
          })}

          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={onSubmit}
            >
              {handleUpdateUser.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>
    </UserInfoStyles>
  );
};

export default UserType;

const UserInfoStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      margin-bottom: 20px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          line-height: 29px;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          font-size: 1rem;
        }
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .option {
        width: 360px;
        height: 100px;
        display: flex;
        align-items: center;
        border-radius: 16px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.1s ease-in-out;

        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.colors?.layer};
          margin-right: 24px;
        }

        .info {
          width: calc(100% - 64px);

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
            margin-bottom: 8px;
          }

          p {
            font-size: 1rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.secondary};
            font-weight: 400;
          }
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
