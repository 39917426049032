/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-hot-toast'

import Button from '@/components/Button/Button'
import Input from '@/components/Input/Input'
import { setOnboardingProgress } from '@/redux/features/app.slice'
import { fetchUser, updateUser } from '@/services/userApi'
import ProgressIndicator from './ProgressIndicator'
import FetchLoader from '@/components/FetchLoader'
import PhoneNumberInput from '@/components/PhoneInput'
import { device } from '@/constants/breakpoints'

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phoneNumber: yup
      .string('Phone number is required')
      .required('Phone number is required'),
  })
  .required()

function UserInfo() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page')

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {},
  })

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.data?.data?.firstName,
      lastName: user?.data?.data?.lastName,
      phoneNumber: user?.data?.data?.phoneNumber,
    },
  })

  useEffect(() => {
    if (page === 'user-info') {
      dispatch(setOnboardingProgress(10))
    }
  }, [page, dispatch])

  useEffect(() => {
    reset({
      firstName: user?.data?.data?.firstName,
      lastName: user?.data?.data?.lastName,
      phoneNumber: user?.data?.data?.phoneNumber,
    })
  }, [user?.data?.data, reset])

  const handleUpdateUser = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
      navigate('/onboarding?page=user-type')
      dispatch(setOnboardingProgress(20))
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const onSubmit = (data) => {
    const formData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
    }

    const formData2 = {
      ...data,
      phoneNumber: data?.phoneNumber?.includes('+')
        ? data?.phoneNumber
        : `+${data?.phoneNumber}`,
    }

    if (user?.data?.data?.phoneNumber) {
      handleUpdateUser.mutate(formData)
    } else {
      handleUpdateUser.mutate(formData2)
    }
  }

  if (user?.isFetching) {
    return <FetchLoader />
  }

  return (
    <UserInfoStyles>
      <div className="row">
        <div className="indicator-view-mobile">
          <ProgressIndicator />
        </div>
        <div className="title-view">
          <h1>First, tell us about yourself</h1>
        </div>
        <div className="indicator-view">
          <ProgressIndicator />
        </div>

        <div className="form-view">
          {/* First name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="firstName">First name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter first name."
              {...register('firstName')}
              error={errors?.firstName?.message}
            />
            {errors?.firstName && (
              <span className="error-label">{errors?.firstName?.message}</span>
            )}
          </div>

          {/* Last name */}
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="lastName">Last name</label>
            </div>
            <Input
              type="text"
              placeholder="Enter last name."
              {...register('lastName')}
              error={errors?.lastName?.message}
            />
            {errors?.lastName && (
              <span className="error-label">{errors?.lastName?.message}</span>
            )}
          </div>

          {/* Phone number */}
          {!user?.data?.data?.phoneNumber ? (
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="phoneNumber">Phone number</label>
              </div>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={user?.data?.data?.phoneNumber}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <PhoneNumberInput
                    name={name}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    country="ng"
                    autoFormat
                    enableSearch
                    disableSearchIcon
                    enableAreaCodes
                    error={errors?.phoneNumber?.message}
                    className={`currency-input ${
                      errors?.phoneNumber && dirtyFields?.phoneNumber
                        ? ' has-error'
                        : dirtyFields?.phoneNumber
                        ? 'valid'
                        : ''
                    }`}
                  />
                )}
              />
              {errors?.phoneNumber && (
                <span className="error-label">
                  {errors?.phoneNumber?.message}
                </span>
              )}
              <div className="input-info-view">
                <span>
                  *We’ll use it to verify your account and keep payment secure.
                </span>
              </div>
            </div>
          ) : (
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="lastName">Phone number</label>
              </div>
              <Input type="text" value={user?.data?.data?.phoneNumber} />
            </div>
          )}

          {/* Button view */}
          <div className="button-view">
            <Button
              type="button"
              styleType="primary"
              width="100%"
              onClick={handleSubmit(onSubmit)}
            >
              {handleUpdateUser.isLoading ? (
                <Oval
                  color="#FFF"
                  secondaryColor="#ddd"
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>
    </UserInfoStyles>
  )
}

export default UserInfo

const UserInfoStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${device.phone} {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    .title-view {
      width: 40%;
      padding-right: 80px;

      @media ${device.phone} {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
          font-size: 1.5rem;
          max-width: 175px;
          line-height: 29px;
        }
      }
    }

    .indicator-view {
      @media ${device.phone} {
        width: 100%;
        display: none;
      }
    }

    .indicator-view-mobile {
      display: none;
      @media ${device.phone} {
        width: 100%;
        display: flex;
      }
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding: 0;
        margin-top: 40px;
      }

      .input-view {
        width: 100%;
        margin-bottom: 26px;

        .label-view {
          width: 100%;
          display: flex;
          margin-bottom: 8px;

          label {
            font-size: 1rem;
          }
        }

        .error-label {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.error};
          margin-top: 5px;
        }

        .input-info-view {
          width: 100%;
          display: flex;
          margin-top: 8px;

          span {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`
