import Input from '@/components/Input/Input';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { designationTypes, genderTypes } from '@/utils/data.utils';
import SearchDropdown from '@/components/SearchDropdown';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setPayLaterData } from '@/redux/features/app.slice';
import PhoneNumberInput from '@/components/PhoneInput';

const UserInfo = ({ userInfo, gender, setGender, setCurrentTab }) => {
  const dispatch = useDispatch();
  const { payLaterData } = useSelector(appSelector);
  const [designation, setDesignation] = useState({});

  const schema = yup
    .object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      phoneNumber: yup.string('Phone number is required').required('Phone number is required'),
      designation: (() => {
        let validation = yup.string();
        if (designation?.value === 'Others') {
          validation = validation.required('Designation is required');
        }
        return validation;
      })()
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      phoneNumber: userInfo?.phoneNumber,
      email: userInfo?.email
    }
  });

  useEffect(() => {
    reset({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      phoneNumber: userInfo?.phoneNumber,
      email: userInfo?.email
    });
  }, [reset]);

  const onSubmit = (data) => {
    if (gender === null) {
      toast.error('Please select your gender');
    } else {
      dispatch(
        setPayLaterData({
          ...payLaterData,
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          fullName: `${data?.firstName} ${data?.lastName}`,
          gender: gender?.value,
          designation: designation?.value === 'Others' ? data?.designation : designation?.value
        })
      );
      setCurrentTab((prev) => prev + 1);
    }
  };

  return (
    <FormView>
      <form>
        {/* Email */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="email">Email address</label>
          </div>
          <Input
            type="text"
            placeholder="Enter email address"
            {...register('email')}
            error={errors?.email?.message}
          />
          {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
        </div>

        {/* First name */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="firstName">First name</label>
          </div>
          <Input
            type="text"
            placeholder="Enter first name."
            {...register('firstName')}
            error={errors?.firstName?.message}
          />
          {errors?.firstName && <span className="error-label">{errors?.firstName?.message}</span>}
        </div>

        {/* Last name */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="lastName">Last name</label>
          </div>
          <Input
            type="text"
            placeholder="Enter last name."
            {...register('lastName')}
            error={errors?.lastName?.message}
          />
          {errors?.lastName && <span className="error-label">{errors?.lastName?.message}</span>}
        </div>

        <div className="input-view">
          <div className="label-view">
            <label htmlFor="website">Designation</label>
          </div>
          <SearchDropdown
            options={designationTypes}
            id="id"
            name="title"
            setValue={setDesignation}
            value={designation}
            placeholder="Select your designation"
          />
        </div>

        {designation?.value === 'Others' && (
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="designation">Enter your designation</label>
            </div>
            <Input
              type="text"
              placeholder="Enter your designation."
              {...register('designation')}
              error={errors?.designation?.message}
            />
            {errors?.designation && (
              <span className="error-label">{errors?.designation?.message}</span>
            )}
          </div>
        )}

        {/* Gender */}
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="website">Gender</label>
          </div>
          <SearchDropdown
            options={genderTypes}
            id="id"
            name="title"
            setValue={setGender}
            value={gender}
            placeholder="Select your gender"
          />
        </div>

        {/* Phone number */}
        {!userInfo?.phoneNumber ? (
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="phoneNumber">Phone number</label>
            </div>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={userInfo.phoneNumber}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <PhoneNumberInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  country="ng"
                  autoFormat
                  enableSearch
                  disableSearchIcon
                  enableAreaCodes
                  error={errors?.phoneNumber?.message}
                  className={`currency-input ${
                    errors?.phoneNumber && dirtyFields?.phoneNumber
                      ? ' has-error'
                      : dirtyFields?.phoneNumber
                        ? 'valid'
                        : ''
                  }`}
                />
              )}
            />
            {errors?.phoneNumber && (
              <span className="error-label">{errors?.phoneNumber?.message}</span>
            )}
          </div>
        ) : (
          <div className="input-view">
            <div className="label-view">
              <label htmlFor="lastName">Phone number</label>
            </div>
            <Input type="text" value={userInfo?.phoneNumber} />
          </div>
        )}

        <div className="continue">
          <button type="button" onClick={handleSubmit(onSubmit)}>
            Next
          </button>
        </div>
      </form>
    </FormView>
  );
};

export default UserInfo;

const FormView = styled.div`
  width: 100%;

  form {
    width: 100%;

    .input-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      input {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 5px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .continue {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 70px;

      button {
        width: 180px;
        height: 56px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border: none;
        cursor: pointer;
        transition: 0.3s ease;
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        border-radius: 16px;

        &:hover {
          background-color: ${({ theme }) => theme.colors?.primaryDark};
        }
      }

      .prev {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.primary};
        margin-right: 20px;
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }
    }
  }
`;
