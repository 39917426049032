import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useState } from 'react';

const SwitchButton = ({ userId }) => {
  const [lenderMode, setLenderMode] = useState(false);
  const hostname = window.location.host;

  const toggleSwitch = () => {
    setLenderMode(!lenderMode); // Toggle the lender mode state

    let url = ''; // Initialize the URL variable
    // Determine the correct URL based on the hostname
    if (hostname === 'billboxx-staging.vercel.app') {
      url = `https://lenders-staging.vercel.app?uid=${userId}`;
    } else if (hostname === 'app.billboxx.com') {
      url = `https://lenders.billboxx.com?uid=${userId}`;
    } else if (hostname === 'localhost:3001') {
      url = `http://localhost:3000?uid=${userId}`;
    }

    // Navigate to the appropriate URL
    if (url) {
      return (window.location.href = url);
    }
  };

  return (
    <SwitchContainer onClick={toggleSwitch}>
      {lenderMode ? <SwitchLabel2>Lender Mode</SwitchLabel2> : <SwitchLabel>User Mode</SwitchLabel>}
      <SwitchTrack isOn={lenderMode}>
        <SwitchKnob
          animate={{
            x: lenderMode ? 2 : 0
          }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 10
          }}
        />
      </SwitchTrack>
    </SwitchContainer>
  );
};

export default SwitchButton;

const SwitchContainer = styled.div`
  display: flex;
  width: 120px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
`;

const SwitchTrack = styled.div`
  width: 100%;
  height: 32px;
  background-color: ${(props) => (props.isOn ? '#E5F0FF' : '#e0e0e0')};
  border-radius: 20px;
  padding: 2px;
  display: flex;
  align-items: center;
  border: 1px solid #174078;
  justify-content: ${(props) => (props.isOn ? 'flex-end' : 'flex-start')};
`;

const SwitchKnob = styled(motion.div)`
  width: 28px;
  height: 28px;
  background-color: #174078;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
  font-family: Arial, sans-serif;
  font-size: 0.75rem;
  color: ${({ theme }) => theme?.colors?.secondary};
  position: absolute;
  right: 10px;
`;

const SwitchLabel2 = styled.span`
  margin-left: 10px;
  font-family: Arial, sans-serif;
  font-size: 0.75rem;
  color: ${({ theme }) => theme?.colors?.secondary};
  position: absolute;
  left: 0px;
`;
