/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

// import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as UncheckIcon } from '@/assets/icons/uncheck.icon.svg';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.icon.svg';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { ReactComponent as AscIcon } from '@/assets/icons/asc.icon.svg';
import { ReactComponent as DescIcon } from '@/assets/icons/desc.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useSortableData } from '@/hooks/useSortableData';
import InvoiceDetails from './InvoiceDetails';
import {
  approveInvoice,
  checkboxActionsInvoices,
  deleteInvoice,
  duplicateInvoice,
  fetchReceivables,
  markAsSent
} from '@/services/receivablesApi';
import ModalLoader from '@/components/ModalLoader';
import EditInvoiceForm from './EditInvoiceForm';
import PromptModal from '@/components/PromptModal';
import { device } from '@/constants/breakpoints';
import { fetchUser } from '@/services/userApi';
import FilterSort from '@/components/FilterSort';
import useDebounce from '@/hooks/useDebounce';
import TableLoader from '@/components/TableLoader';
import Pagination from '@/components/Pagination';
import SortingComponent from '@/components/SortingComponent';
import { trackEvent } from '@/utils/mixpanel';

function Drafts({ enabledFetch }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;
  const currentPage = searchParams.get('page');
  const [showMenu, setShowMenu] = useState(false);
  const [, setShowFilterMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
  const [showApprovePromptModal, setShowApprovePromptModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedReceivable, setSelectedReceivable] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showMultiSelectMenu, setShowMultiSelectMenu] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const debouncedsearch = useDebounce(search, 500);
  const ref = useRef();
  const ref2 = useRef();
  const filterRef = useRef();

  const handleFilterSortApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  useOnClickOutside(ref, () => setShowMenu(false));
  useOnClickOutside(ref2, () => setShowMultiSelectMenu(false));
  useOnClickOutside(filterRef, () => setShowFilterMenu(false));

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const invoices = useQuery({
    queryKey: [
      'invoices',
      {
        status: 'draft',
        page: currentPage,
        pageLimit,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchReceivables,
    enabled: enabledFetch
  });

  const onPageChange = (page) => {
    setSearchParams(`tab=draft&page=${page}`);
  };

  const { items, requestSort, sortConfig } = useSortableData(invoices?.data?.data?.items || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleSelectItems = (id) => {
    if (selectedInvoices.includes(id)) {
      const filtered = selectedInvoices.filter((item) => item !== id);
      setSelectedInvoices(filtered);
    } else {
      setSelectedInvoices((prev) => [...prev, id]);
    }
  };

  const handleSelectAllItems = () => {
    if (selectedInvoices.length === items.length) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(items.map((item) => item.id));
    }
  };

  const handleMarkAsSent = useMutation(markAsSent, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      navigate('/get-paid/invoices?tab=sent');
      toast.success('Invoice updated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleApproveInvoice = useMutation(({ id, data }) => approveInvoice(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      navigate('/get-paid/invoices?tab=receivables');
      toast.success('Invoice saved successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDeleteInvoice = useMutation(({ id, type }) => deleteInvoice(id, type), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      toast.success('Invoice deleted successfully');
      setShowPromptModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDuplicateInvoice = useMutation(({ id }) => duplicateInvoice(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      toast.success('Invoice duplicated successfully');
      navigate('/get-paid/invoices?tab=draft');
      trackEvent('Create Invoice', {});
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleCheckboxActions = useMutation(checkboxActionsInvoices, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (selectedAction === 'approve') {
        toast.success('Invoices approved successfully');
        navigate('/get-paid/invoices?tab=receivables');
      } else if (selectedAction === 'delete') {
        toast.success('Invoices deleted successfully');
        setShowDeletePromptModal(false);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <DraftsStyles>
      <div className="drafts-header">
        <div className="title-view">
          <h3>
            Total: ₦{numberWithCommas(invoices?.data?.data?.meta?.totalAmount || '00.00')} (
            {numberWithCommas2(invoices?.data?.data?.meta?.totalCount || '00.00')})
          </h3>
        </div>

        <div className="drafts-header__actions">
          <AnimatePresence>
            {selectedInvoices?.length > 0 && (
              <motion.button
                type="button"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="action-btn"
                onClick={() => setShowMultiSelectMenu(true)}>
                <MoreIcon className="more-icon" />
              </motion.button>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {showMultiSelectMenu && (
              <motion.div
                className="menu-actions"
                ref={ref2}
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}>
                {/* <button
                  type="button"
                  onClick={() => {
                    setSelectedAction('approve');
                    setShowApprovePromptModal(true);
                  }}>
                  Approve Invoices
                </button> */}
                {/* <button
                  type="button"
                  onClick={() => {
                    setSelectedAction('delete');
                    setShowDeletePromptModal(true);
                  }}>
                  Delete Invoices
                </button> */}
              </motion.div>
            )}
          </AnimatePresence>
          <SearchView>
            <input
              type="search"
              placeholder="Search invoices"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className="search-icon" />
          </SearchView>
          <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
          <FilterSort onApply={handleFilterSortApply} />
        </div>
      </div>

      {invoices?.isLoading ? (
        <TableLoader />
      ) : items?.length > 0 ? (
        <>
          <div className="drafts-table">
            <div className="drafts-table__header">
              <div className="drafts-table__header__item icon">
                <button type="button" onClick={handleSelectAllItems}>
                  {selectedInvoices?.length === items?.length ? <CheckIcon /> : <UncheckIcon />}
                </button>
              </div>
              <div
                className={`drafts-table__header__item vendor ${getClassNamesFor('customer')}`}
                onClick={() => requestSort('vendor')}>
                <span>
                  Customer{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`drafts-table__header__item bill ${getClassNamesFor(
                  'billOrInvoiceNumber'
                )}`}
                onClick={() => requestSort('billOrInvoiceNumber')}>
                <span>
                  Invoice No.{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`drafts-table__header__item date ${getClassNamesFor('createdAt')}`}
                onClick={() => requestSort('createdAt')}>
                <span>
                  Created{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`drafts-table__header__item date ${getClassNamesFor('dueDate')}`}
                onClick={() => requestSort('dueDate')}>
                <span>
                  Due Date{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div
                className={`drafts-table__header__item amount ${getClassNamesFor('amount')}`}
                onClick={() => requestSort('amount')}>
                <span>
                  Amount{' '}
                  <span className="icon">
                    <AscIcon className="up" />
                    <DescIcon className="down" />
                  </span>
                </span>
              </div>
              <div className="drafts-table__header__item action">
                <span></span>
              </div>
            </div>

            {/* ====== Table body ====== */}
            <div className="drafts-table__body">
              {items.map((draft, i) => (
                <div
                  className={
                    selectedInvoices.includes(draft?.id)
                      ? 'drafts-table__body-row selected-row'
                      : 'drafts-table__body-row'
                  }
                  key={draft.id}>
                  <div className="drafts-table__body-row__item icon">
                    <button
                      type="button"
                      onClick={() => {
                        handleSelectItems(draft?.id);
                      }}
                      className="checkbox">
                      {selectedInvoices.includes(draft?.id) ? <CheckIcon /> : <UncheckIcon />}
                    </button>
                  </div>
                  <div className="drafts-table__body-row__item vendor">
                    <span>{draft?.customer?.companyName}</span>
                  </div>
                  <div className="drafts-table__body-row__item bill">
                    <span>{draft?.billOrInvoiceNumber}</span>
                  </div>
                  <div className="drafts-table__body-row__item date">
                    <span>{format(new Date(draft?.createdAt), 'do MMM, yyyy')}</span>
                  </div>
                  <div className="drafts-table__body-row__item date">
                    <span>{format(new Date(draft?.dueDate), 'do MMM, yyyy')}</span>
                  </div>
                  <div className="drafts-table__body-row__item amount">
                    <span>₦{numberWithCommas(draft?.amount || '00.00')}</span>
                  </div>
                  <div className="drafts-table__body-row__item action">
                    <button
                      type="button"
                      className="btn view"
                      onClick={() => {
                        setShowDetails(true);
                        setSelectedReceivable(draft);
                      }}>
                      <ViewIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMenu(true);
                        setCurrentIndex(i);
                        setSelectedReceivable(draft);
                      }}
                      className="btn more">
                      <MoreIcon />
                    </button>

                    {/* ===== Dropdown Menu ====== */}
                    <AnimatePresence>
                      {showMenu && currentIndex === i ? (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          {draft?.items?.length > 0 ? (
                            <button
                              type="button"
                              onClick={() => {
                                navigate(`/get-paid/invoices/${draft?.id}`);
                              }}>
                              Edit invoice
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setShowEditModal(true);
                              }}>
                              Edit invoice
                            </button>
                          )}
                          {draft?.requiresApproval ? (
                            <button
                              type="button"
                              onClick={() => {
                                navigate(`/get-paid/invoices/request-approval?id=${draft?.id}`);
                              }}>
                              Send invoice
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                if (user?.data?.data?.hasBankAccount) {
                                  navigate(`/get-paid/invoices/request-payment?id=${draft?.id}`);
                                } else {
                                  toast.error('Please complete your compliance to send invoices');
                                }
                              }}>
                              Send invoice
                            </button>
                          )}
                          {/* <button
                            type="button"
                            onClick={() => {
                              handleMarkAsSent.mutate(draft?.id);
                            }}>
                            Mark as sent
                          </button> */}
                          <button
                            type="button"
                            onClick={() => {
                              handleDuplicateInvoice.mutate({
                                id: draft?.id
                              });
                            }}>
                            Duplicate invoice
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setShowDetails(true);
                            }}>
                            Review invoice
                          </button>
                          {draft?.isOrigin && (
                            <button
                              type="button"
                              onClick={() => {
                                setShowPromptModal(true);
                              }}>
                              Delete invoice
                            </button>
                          )}
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="listing">
            {items?.map((item, i) => (
              <div className="item" key={item?.id}>
                <div className="col1">
                  <span className="name">{item?.customer?.companyName}</span>
                  <span className="date">{format(new Date(item?.createdAt), 'do MMM, yyyy')}</span>
                  <span className="id">{item?.billOrInvoiceNumber}</span>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                    setSelectedReceivable(item);
                  }}
                  className="btn more">
                  <MoreIcon />
                </button>
                <span className="amount">₦{numberWithCommas(item?.amount || '00.00')}</span>
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      {item?.items?.length > 0 ? (
                        <button
                          type="button"
                          onClick={() => {
                            navigate(`/get-paid/invoices/${item?.id}`);
                          }}>
                          Edit invoice
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setShowEditModal(true);
                          }}>
                          Edit invoice
                        </button>
                      )}
                      {item?.requiresApproval ? (
                        <button
                          type="button"
                          onClick={() => {
                            navigate(`/get-paid/invoices/request-approval?id=${item?.id}`);
                          }}>
                          Send invoice
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            if (user?.data?.data?.hasBankAccount) {
                              navigate(`/get-paid/invoices/request-payment?id=${item?.id}`);
                            } else {
                              toast.error('Please complete your compliance to send invoices');
                            }
                          }}>
                          Send invoice
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          handleDuplicateInvoice.mutate({
                            id: item?.id
                          });
                        }}>
                        Duplicate invoice
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setShowDetails(true);
                        }}>
                        Review invoice
                      </button>
                      {item?.isOrigin && (
                        <button
                          type="button"
                          onClick={() => {
                            setShowPromptModal(true);
                          }}>
                          Delete invoice
                        </button>
                      )}
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </>
      ) : (
        <EmptyView>
          <EmptyIcon />
          <h3>Nothing to see here!</h3>
        </EmptyView>
      )}

      <div className="pagination">
        <Pagination
          itemsPerPage={pageLimit}
          totalItems={invoices?.data?.data?.meta?.totalCount}
          currentPage={Number(currentPage)}
          onPageChange={onPageChange}
        />
      </div>

      {/* ====== Details Modal ====== */}
      <InvoiceDetails
        showModal={showDetails}
        setShowModal={setShowDetails}
        invoice={selectedReceivable}
        setShowEditModal={setShowEditModal}
      />

      <EditInvoiceForm
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedInvoice={selectedReceivable}
      />

      {handleApproveInvoice.isLoading && <ModalLoader message="Saving invoice..." />}
      {handleDuplicateInvoice.isLoading && <ModalLoader message="Duplicating invoice..." />}
      {handleMarkAsSent.isLoading && <ModalLoader message="Updating invoice..." />}

      <PromptModal showModal={showPromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowPromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete invoice</h3>
          <p>When you delete an invoice, you lose all related data. This cannot be undone.</p>
          <button
            type="button"
            className="delete"
            onClick={() =>
              handleDeleteInvoice.mutate({
                id: selectedReceivable?.id,
                type: 'single'
              })
            }>
            {handleDeleteInvoice.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>

      {/* Delete prompt for checkboxes */}
      <PromptModal showModal={showDeletePromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeletePromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete Bills</h3>
          <p>Are you sure you want to delete the selected bills?</p>
          <button
            type="button"
            className="delete"
            onClick={() => {
              const data = {
                ids: selectedInvoices,
                action: 'delete'
              };
              handleCheckboxActions.mutate(data);
            }}>
            {handleCheckboxActions.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete Bills'
            )}
          </button>
        </div>
      </PromptModal>

      {/* Approve prompt for checkboxes */}
      <PromptModal showModal={showApprovePromptModal}>
        <div className="header">
          <button type="button" onClick={() => setShowApprovePromptModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon className="green-icon" />
          <h3>Approve Bills</h3>
          <p>Are you sure you want to approve the selected bills?</p>
          <button
            type="button"
            className="continue"
            onClick={() => {
              const data = {
                ids: selectedInvoices,
                action: 'ready'
              };
              handleCheckboxActions.mutate(data);
            }}>
            {handleCheckboxActions.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Approve Bills'
            )}
          </button>
        </div>
      </PromptModal>
    </DraftsStyles>
  );
}

export default Drafts;

const DraftsStyles = styled.div`
  width: 100%;
  min-height: 50vh;

  .pagination {
    margin-top: 50px;
  }

  .drafts-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${device.phone} {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .title-view {
      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .drafts-header__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      @media ${device.phone} {
        margin-bottom: 20px;
      }

      .action-btn {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        border: none;
        background-color: ${(props) => props.theme.colors?.layer2};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        cursor: pointer;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }

        .more-icon {
          width: 20px;
          height: 20px;
        }
      }

      .menu-actions {
        position: absolute;
        top: 42px;
        right: 0;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }
    }
  }

  .drafts-table {
    width: 100%;
    margin-top: 20px;

    @media ${device.phone} {
      display: none;
    }

    .drafts-table__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-radius: 6px;
      background: ${(props) => props.theme.colors?.layer2};

      .drafts-table__header__item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 600;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 3px;
          }
        }
      }

      .ascending {
        color: ${({ theme }) => theme.colors?.secondary};

        .up path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .descending {
        color: ${({ theme }) => theme.colors?.secondary};

        .down path {
          stroke: ${({ theme }) => theme.colors?.secondary};
          color: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .icon {
        flex: 0.3;
      }

      .vendor {
        flex: 2.5;
      }

      .bill {
        flex: 1;
      }

      .date {
        flex: 1.3;
      }

      .amount {
        flex: 1.2;
      }

      .action {
        flex: 0.5;
      }

      @media ${device.phone} {
        display: none;
      }
    }

    .drafts-table__body {
      width: 100%;
      margin-top: 20px;

      .drafts-table__body-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors?.white};

        &:nth-child(odd) {
          background-color: ${(props) => props.theme.colors?.layer3};
        }

        .drafts-table__body-row__item {
          flex: 1;
          display: flex;
          align-items: center;

          span {
            font-size: 0.875rem;
            color: ${(props) => props.theme.colors?.secondary};
            font-weight: 400;
          }
        }

        .icon {
          flex: 0.3;
        }

        .vendor {
          flex: 2.5;
        }

        .bill {
          flex: 1;
        }

        .date {
          flex: 1.3;
        }

        .amount {
          flex: 1.2;
        }

        .action {
          flex: 0.5;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;

          .btn {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .view {
            margin-right: 20px;
          }

          .menu-actions {
            position: absolute;
            top: 0px;
            right: 0;
            background-color: ${(props) => props.theme.colors?.white};
            border-radius: 6px;
            box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
            width: 180px;
            z-index: 100;
            overflow: hidden;

            button {
              width: 100%;
              padding: 16px 24px;
              border: none;
              background-color: ${(props) => props.theme.colors?.white};
              font-size: 0.875rem;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &:hover {
                background-color: ${(props) => props.theme.colors?.layer2};
              }
            }
          }
        }
      }

      .selected-row {
        background-color: ${(props) => props.theme.colors?.layer2};

        &:nth-child(odd) {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }

    .drafts-table__pagination {
      width: 100%;
      margin-top: 30px;
    }
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }
`;

const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: calc(100% - 80px);
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
