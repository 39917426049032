/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import * as yup from 'yup';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import useWallet from '@/hooks/useWallet';
import {
  calculateTransferCharge,
  fetchTransferBankList,
  resolveTransferBankAccount
} from '@/services/bankingApi';
import { numberWithCommas } from '@/utils/helpers';
import BankListDropdown from './BankListDropdown';
import PaymentOtpModal from './PaymentOtpModal';
import useDebounce from '@/hooks/useDebounce';
import theme from '@/constants/theme';
import TextArea from '@/components/TextArea/TextArea';

function AccountTransferModal({ showModal, setShowModal }) {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState({});
  const [accountData, setAccountData] = useState(null);
  const { userWallet } = useWallet();
  const [transferCharge, setTransferCharge] = useState(0);

  const schema = yup
    .object({
      amount: yup.string().required('Amount is required'),
      remark: yup.string().nullable()
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const amount = useWatch({
    control,
    name: 'amount'
  });

  const remark = useWatch({
    control,
    name: 'remark'
  });

  const debouncedAmount = useDebounce(amount, 500);

  const handleFetchFees = useMutation(calculateTransferCharge, {
    onSuccess: (data) => {
      setTransferCharge(data?.data?.total);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (debouncedAmount && debouncedAmount >= 100) {
      handleFetchFees.mutate({
        amount: parseInt(debouncedAmount, 10),
        category: 'transfer'
      });
    }
  }, [debouncedAmount]);

  const handleChangeInput = (event) => {
    const { value, maxLength } = event.target;
    const account = value.slice(0, maxLength);
    setAccountNumber(account);
  };

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: fetchTransferBankList
  });

  const handleResolveBankAccount = useMutation(resolveTransferBankAccount, {
    onSuccess: (data) => {
      setAccountData({
        ...data?.data,
        bankName: selectedBank?.bankName
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      setAccountData(null);
    }
  });

  useEffect(() => {
    if (accountNumber.length === 10) {
      if (!selectedBank?.bankCode) {
        toast.error('Please select a bank');
        return;
      }
      handleResolveBankAccount.mutate({
        accountNumber,
        bankCode: selectedBank?.bankCode,
        provider: 'providus'
      });
    }
  }, [accountNumber, selectedBank]);

  const onSubmit = () => {
    setShowModal(false);
    setShowOtpModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Transfer to other bank account</h3>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setAccountNumber('');
                      setSelectedBank({});
                      setAccountData(null);
                    }}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="heading-view">
                  <h3>Enter the account details of the recipient.</h3>
                </div>

                <FormView>
                  {/* First name */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="firstName">Select bank</label>
                    </div>
                    {banks?.isFetching ? (
                      <div>
                        <span>Loading banks...</span>
                      </div>
                    ) : (
                      <BankListDropdown
                        options={banks?.data?.data}
                        code="bankCode"
                        name="bankName"
                        setValue={setSelectedBank}
                        value={selectedBank}
                        placeholder="Select bank"
                      />
                    )}
                  </InputView>
                  {/* Last name */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="accountNumber">Account Number</label>
                    </div>
                    <Input
                      type="number"
                      maxLength="10"
                      placeholder="Enter account number"
                      value={accountNumber}
                      onChange={(e) => handleChangeInput(e)}
                    />
                  </InputView>
                  <div className="loader">
                    {handleResolveBankAccount.isLoading && (
                      <Oval
                        color="#92C22C"
                        secondaryColor="#EEFFC9"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    )}
                  </div>
                  {accountData && (
                    <div className="name">
                      <span>Account name</span>
                      <p>{accountData?.accountName}</p>
                    </div>
                  )}

                  {accountData && (
                    <InputView>
                      <label htmlFor="name">Amount</label>
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <CurrencyyInput
                            name={name}
                            placeholder="Enter amount"
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            prefix="₦"
                            onValueChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            error={!!errors?.amount?.message}
                            className={`currency-input ${
                              errors?.amount && dirtyFields?.amount
                                ? ' has-error'
                                : dirtyFields?.amount
                                ? 'valid'
                                : ''
                            }`}
                          />
                        )}
                      />

                      <span className="fees">
                        Fees:{' '}
                        {handleFetchFees?.isLoading ? (
                          <Oval color={theme.colors.primary} height={10} width={10} />
                        ) : (
                          `₦${numberWithCommas(transferCharge)}`
                        )}
                      </span>

                      {errors?.amount && (
                        <span className="error-label">{errors?.amount?.message}</span>
                      )}
                      {amount > userWallet?.currentBalance && (
                        <span className="error-label">
                          The amount you entered is greater than your current wallet balance of{' '}
                          <span className="strong">
                            ₦{numberWithCommas(userWallet?.currentBalance || '00.00')}
                          </span>
                        </span>
                      )}
                    </InputView>
                  )}

                  {/* Remark */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="remark">
                        Remark <span>(Optional)</span>
                      </label>
                    </div>
                    <TextArea {...register('remark')} placeholder="Enter remark" />
                  </InputView>
                  {/* ===== CTA ====== */}
                  <div className="action-btn">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        setShowModal(false);
                        setAccountNumber('');
                        setSelectedBank({});
                        setAccountData(null);
                      }}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={amount > userWallet?.currentBalance || !accountData}
                      onClick={handleSubmit(onSubmit)}>
                      Make transfer
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PaymentOtpModal
        showModal={showOtpModal}
        setShowModal={setShowOtpModal}
        selectedAccount={accountData}
        amount={amount}
        remark={remark}
        reset={reset}
        setAccountNumber={setAccountNumber}
        setSelectedBank={setSelectedBank}
        setAccountData={setAccountData}
      />
    </>
  );
}

export default AccountTransferModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 14px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .heading-view {
    width: 100%;
    margin-top: 30px;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      line-height: 19px;
    }
  }
`;

const FormView = styled.form`
  width: 100%;
  margin-top: 30px;

  .name {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;

    button {
      margin-left: 10px;
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .fees {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.primary};
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;
