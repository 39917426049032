/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import api from './api';

/**
 * * Create a bill ✅
 * @param {Array} data - Bill data as multipart/form-data
 * @returns {Promise} - Returns the created bill
 */
export const createBill = async (data) => {
  const response = await api.post(`/bills/create`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};

export const fetchBills = async ({ queryKey }) => {
  const [, { status, page, pageLimit, search, sortBy, fetch }] = queryKey;
  let queryParams = `status=${status}&page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/bills?${queryParams}`);
  return response.data;
};

export const fetchBill = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/bills/${id}`);
    return response.data;
  }
};

/**
 * * Generates a bill number
 * @returns {Promise} - Returns the bill number
 */
export const generateBillNumber = async () => {
  const response = await api.get(`/bills/generate-bill-number`);
  return response.data;
};

/**
 * * Update a bill ✅
 * @param {String} id - Bill id
 * @param {Object} data - Bill data
 * @returns {Promise} - Returns the updated bill
 */
export const updateBill = async (id, data) => {
  const response = await api.put(`/bills/${id}`, data);
  return response.data;
};

/**
 * * Extract invoice or bill details from a file.Supported file formats:[PNG, JPEG, PDF]. Maximum file size is 1MB ✅
 * @param {Object} data - File data
 * @returns {Promise} - Returns the extracted bill
 */
export const extractBill = async (data) => {
  const response = await api.post(`/bills/extract`, data);
  return response.data;
};

export const initiatePayment = async (data) => {
  const response = await api.post(`/transactions/initialize`, data);
  return response.data;
};

/**
 * * Approve a bill ✅
 * @param {*} billId - Bill id
 * @param {*} data - Approval data
 * @returns {Promise} - Returns the approved bill
 */
export const approveBill = async (billId, data) => {
  const response = await api.post(`/bills/${billId}/approval`, data);
  return response.data;
};

export const verifyPayment = async ({ queryKey }) => {
  const [, trxRef] = queryKey;
  const response = await api.get(`/transactions/${trxRef}/verify`);
  return response.data;
};

export const fetchPaymentAdvicePDF = async ({ queryKey }) => {
  const [, trxRef] = queryKey;
  const response = await api.get(`/wallet/transactions/payment-advice/${trxRef}`);
  return response.data;
};

/**
 * * Fetches all bills metrics ✅
 * @returns {Promise} - Returns the bills metrics
 */
export const fetchBillsMetric = async () => {
  const response = await api.get(`/bills/metrics`);
  return response.data;
};

/**
 * * Fetches all bills ✅
 * @param {*} param0 - Query key
 * @returns {Promise} - Returns the bills
 */
export const fetchOutflows = async ({ queryKey }) => {
  const [, { page, pageLimit, search, sortBy, fetch }] = queryKey;

  let queryParams = `page=${page}&perPage=${pageLimit}`;

  if (search) {
    queryParams += `&search=${search}`;
  }

  // Check both conditions together first
  if (fetch !== 'none' && sortBy !== 'none') {
    queryParams += `&sortBy=${sortBy}&fetch=${fetch}`;
  } else {
    // Use else to ensure that we do not add redundant parameters
    if (fetch !== 'none') {
      queryParams += `&fetch=${fetch}`;
    }
    if (sortBy !== 'none') {
      queryParams += `&sortBy=${sortBy}`;
    }
  }

  const response = await api.get(`/transactions/outflows?${queryParams}`);
  return response.data;
};

/**
 * * Exports all outflows ✅
 * @returns {Promise} - Returns the exported outflows
 */
export const exportOutflows = async (data) => {
  const response = await api.post(`/transactions/exports/`, data);
  return response.data;
};

export const exportAccountStatement = async (data) => {
  const response = await api.post(`/wallet/export-statement`, data);
  return response.data;
};

/**
 * * Creates bulk bills ✅
 * @param {*} data - File data
 * @returns {Promise} - Returns the created bills
 */
export const createBulkUpload = async (data) => {
  const response = await api.post(`/bills/create/bulk`, data);
  return response.data;
};

export const markBillAsPaid = async (data) => {
  const response = await api.post(`/transactions/mark-as-paid`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};

/**
 * * Deletes an bill ✅
 * @param {*} billId - Bill id
 * @returns {Promise} - Returns rest of the bills
 */
export const deleteBill = async (billId, type) => {
  const response = await api.delete(`/bills/${billId}/${type}`);
  return response.data;
};

export const restoreDeletedBill = async (billId) => {
  const response = await api.patch(`/bills/${billId}/restore`);
  return response.data;
};

export const duplicateBill = async (id) => {
  const response = await api.post(`/bills/${id}/duplicate`);
  return response.data;
};

export const checkboxActions = async (data) => {
  const response = await api.post(`/bills/approve-draft-bill`, data);
  return response.data;
};

export const fetchPaymentPDF = async ({ queryKey }) => {
  const [, billId] = queryKey;
  const response = await api.get(`/bills/pdf/${billId}`);
  return response.data;
};

export const fetchInvoicePaymentPDF = async ({ queryKey }) => {
  const [, billId] = queryKey;
  const response = await api.get(`/receivables/pdf/${billId}`);
  return response.data;
};
