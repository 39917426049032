import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import TimeoutModal from './TimeoutModal';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { logoutUser } from '@/services/authApi';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';
import { resetTrackedUser, trackEvent } from '@/utils/mixpanel';
import useUser from '@/hooks/useUser';

const exemptedRoutes = [
  '/login',
  '/signup',
  '/forgot-password',
  'verify-email',
  'email-activation',
  'password-reset',
  'onboarding',
  'payment',
  'verify-payment',
  'verify-subscription'
];

export function TimeoutProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useUser();
  const [isModalVisible, setModalVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const countdownRef = useRef(null);

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
      setModalVisible(false);
      trackEvent('User Logged Out', { userId: user?.id });
      resetTrackedUser();
    },
    onError: (error) => {
      // toast.error(error?.response?.data?.message);
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
      setModalVisible(false);
    }
  });

  const startCountdown = () => {
    countdownRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownRef.current);
          handleLogoutUser.mutate();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const onIdle = () => {
    if (!exemptedRoutes.includes(location.pathname)) {
      setModalVisible(true);
      setTimeLeft(30); // Set new countdown for 20 seconds
      startCountdown();
    }
  };

  const { reset } = useIdleTimer({
    timeout: 15 * 60 * 1000, // 15 minutes
    onIdle,
    debounce: 500,
    events: ['mousemove', 'keydown', 'click', 'scroll']
  });

  const onDismiss = () => {
    clearInterval(countdownRef.current);
    setTimeLeft(30);
    setModalVisible(false);
    reset();
  };

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname)) {
      reset(); // Reset the idle timer when route is exempted
    }
  }, [location.pathname, reset]);

  return (
    <>
      {children}
      <TimeoutModal
        isVisible={isModalVisible}
        setModalVisible={setModalVisible}
        timeLeft={timeLeft}
        onDismiss={onDismiss}
      />
    </>
  );
}
