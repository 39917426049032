/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import Input from '@/components/Input/Input';
import TextArea from '@/components/TextArea/TextArea';
import FilePicker from '@/components/FilePicker';
import { fetchVendors } from '@/services/vendorApi';
import FetchLoader from '@/components/FetchLoader';
import { createBill, generateBillNumber } from '@/services/billsApi';
import { appSelector } from '@/redux/features/app.slice';
import { device } from '@/constants/breakpoints';
import useUser from '@/hooks/useUser';
import { trackEvent } from '@/utils/mixpanel';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' }
];

const schema = yup
  .object({
    amount: yup.string().required('Bill amount is required'),
    dueDate: yup.string().required('Due date is required'),
    description: yup.string().nullable(),
    billOrInvoiceNumber: yup.string().required('Bill number is required')
  })
  .required();

function BillVendorFrom({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const { vendorToBill } = useSelector(appSelector);
  const queryClient = useQueryClient();
  const [frequency, setFrequency] = useState(billFrequency[0]);
  const [file, setFile] = useState(null);
  const [billOrInvoiceNumber, setBillOrInvoiceNumber] = useState('');

  const vendors = useQuery({
    queryKey: ['vendors', { page: 1, pageLimit: 1000 }],
    queryFn: fetchVendors
  });

  const getBillNumber = useQuery({
    queryKey: [''],
    queryFn: generateBillNumber,
    onSuccess: (data) => {
      setBillOrInvoiceNumber(data?.data?.billNumber);
    }
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      billOrInvoiceNumber
    }
  });

  useEffect(() => {
    reset({
      billOrInvoiceNumber
    });
  }, [billOrInvoiceNumber, reset]);

  const handleDraftBill = useMutation(createBill, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      navigate('/make-payments/bills?tab=draft');
      toast.success('Bill created successfully');
      trackEvent('Create Bill', {
        userId: user?.id
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleCreateBill = useMutation(createBill, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      navigate('/make-payments/bills?tab=approval');
      toast.success('Bill created successfully');
      trackEvent('Create Bill', {
        userId: user?.id
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('vendorId', vendorToBill?.id);
    formData.append('billOrInvoiceNumber', data?.billOrInvoiceNumber);
    formData.append('dueDate', new Date(data?.dueDate).toISOString());
    formData.append('amount', parseFloat(data?.amount));
    formData.append('description', data?.description ? data?.description : '');
    formData.append('frequency', frequency?.value);
    formData.append('document', file);
    formData.append('status', 'draft');

    handleDraftBill.mutate(formData);
  };

  const onSaveBill = (data) => {
    const formData = new FormData();
    formData.append('vendorId', vendorToBill?.id);
    formData.append('billOrInvoiceNumber', billOrInvoiceNumber);
    formData.append('dueDate', new Date(data?.dueDate).toISOString());
    formData.append('amount', parseFloat(data?.amount));
    formData.append('description', data?.description ? data?.description : '');
    formData.append('frequency', frequency?.value);
    formData.append('document', file);
    formData.append('status', 'pending-approval');

    handleCreateBill.mutate(formData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>

            {vendors?.isFetching || getBillNumber?.isFetching ? (
              <FetchLoader />
            ) : (
              <div className="form-view">
                <form>
                  {/* Vendor */}
                  <div className="input-view">
                    <label htmlFor="vendor">Vendor</label>
                    <Input value={vendorToBill?.companyName} />
                  </div>

                  {/* Bill amount */}
                  <div className="input-view">
                    <label htmlFor="name">Bill amount</label>
                    <Controller
                      name="amount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter bill amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          error={!!errors?.amount?.message}
                          className={`currency-input ${
                            errors?.amount && dirtyFields?.amount
                              ? ' has-error'
                              : dirtyFields?.amount
                                ? 'valid'
                                : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.billAmount && (
                      <span className="error-label">{errors?.billAmount?.message}</span>
                    )}
                  </div>

                  {/* Bill frequency */}
                  <div className="select-view">
                    <label htmlFor="billFrequency">Bill frequency</label>
                    <div className="options">
                      {billFrequency.map((item) => (
                        <div className="option" key={item?.id} onClick={() => setFrequency(item)}>
                          <div className="radio-button">
                            {frequency?.id === item?.id ? <span className="dot"></span> : null}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Bill number */}
                  <div className="input-view">
                    <label htmlFor="billOrInvoiceNumber">Bill (or Invoice) no.</label>
                    <Input type="text" {...register('billOrInvoiceNumber')} />
                    <div className="input-info-view">
                      <span className="info">*Bill or invoice number is auto generated</span>
                    </div>
                    {errors?.billOrInvoiceNumber && (
                      <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                    )}
                  </div>

                  {/* Due date */}
                  <div className="input-view">
                    <label htmlFor="name">Due date</label>

                    <Input type="date" {...register('dueDate')} error={errors?.dueDate?.message} />

                    {errors?.dueDate && (
                      <span className="error-label">{errors?.dueDate?.message}</span>
                    )}
                  </div>

                  {/* Description */}
                  <div className="input-view">
                    <label htmlFor="description">Description</label>
                    <TextArea
                      type="number"
                      {...register('description')}
                      error={!!errors?.description?.message}
                      placeholder="Enter description"
                    />

                    {errors?.description && (
                      <span className="error-label">{errors?.description?.message}</span>
                    )}
                  </div>

                  {/* Document */}
                  <FilePicker file={file} setFile={setFile} acceptsPDF />

                  {/* ===== CTA ====== */}
                  <div className="cta-view">
                    <button
                      type="button"
                      className="cta-view__save"
                      onClick={handleSubmit(onSaveBill)}>
                      {handleCreateBill.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Save and request approval'
                      )}
                    </button>
                    <button
                      type="button"
                      className="cta-view__draft"
                      onClick={handleSubmit(onSubmit)}>
                      {handleDraftBill.isLoading ? (
                        <Oval
                          color="#92C22C"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default BillVendorFrom;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 30px 16px;
    }

    .form-view {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 20px;

      form {
        width: 100%;
        max-width: 460px;

        .input-view {
          width: 100%;
          margin-bottom: 30px;

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .error-label {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.error};
            margin-top: 5px;
          }

          .input-info-view {
            width: 100%;
            display: flex;
            margin-top: 5px;

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }
        }

        .select-view {
          width: 100%;
          margin-bottom: 20px;

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;

            .option {
              width: 33.333%;
              display: flex;
              align-items: center;
              cursor: pointer;

              .radio-button {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                margin-right: 10px;

                .dot {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colors?.primary};
                }
              }

              p {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.colors?.secondary};
              }
            }
          }
        }

        .cta-view {
          width: 100%;
          display: flex;
          margin-top: 60px;
          justify-content: space-between;
          margin-bottom: 50px;

          button {
            width: 49%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 16px;
          }

          .cta-view__save {
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            margin-bottom: 20px;
            width: 69%;
          }

          .cta-view__draft {
            background-color: ${({ theme }) => theme.colors?.white};
            color: ${({ theme }) => theme.colors?.primary};
            border: 1px solid ${({ theme }) => theme.colors?.primary};
            width: 29%;
          }
        }
      }
    }
  }
`;
