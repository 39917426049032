/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { markBillAsPaid } from '@/services/billsApi';
import { numberWithCommas } from '@/utils/helpers';
import SearchDropdown from '@/components/SearchDropdown';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input/Input';
import FilePicker from '@/components/FilePicker';
import CurrencyyInput from '@/components/CurrencyInput';
import { device } from '@/constants/breakpoints';

const paymentMethods = [
  { id: 1, name: 'Bank Transfer', value: 'bank-transfer' },
  { id: 2, name: 'Debit Card', value: 'debit-card' },
  { id: 2, name: 'Credit Card', value: 'credit-card' },
  { id: 2, name: 'Cash', value: 'cash' },
  { id: 2, name: 'POS', value: 'pos' },
  { id: 2, name: 'Third Party', value: 'third-party' }
];

const paymentTypess = [
  { id: 1, name: 'Full payment', value: 'full' },
  { id: 2, name: 'Partial payment', value: 'partial' }
];

function MarkInvoiceAsPaid({ showModal, setShowModal, bill }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [paymentMethod, setPaymentMethod] = useState({});
  const [file, setFile] = useState(null);
  const [paymentType, setPaymentType] = useState('full');
  const [partialAmount, setPartialAmount] = useState(0);

  const schema = yup
    .object({
      paymentDate: yup.string().required('Payment date is required'),
      partialAmount: (() => {
        let validation = yup.string();
        if (paymentType === 'partial') {
          validation = validation.required('Amount is required');
        }
        return validation;
      })()
    })
    .required();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleMarkAsPaid = useMutation(markBillAsPaid, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (paymentType === 'full') {
        navigate('/get-paid/invoices?tab=paid');
      } else {
        navigate('/get-paid/invoices?tab=partially-paid');
      }
      toast.success('Bill marked as paid');
      setShowModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    if (paymentMethod?.value) {
      const formData = new FormData();
      formData.append('billId', bill?.id);
      formData.append('paymentMethod', paymentMethod?.value);
      formData.append('paidAt', new Date(data?.paymentDate).toISOString());
      formData.append(
        'amount',
        parseFloat(paymentType === 'full' ? bill?.balance : data?.partialAmount)
      );
      if (file) {
        formData.append('document', file);
      }
      handleMarkAsPaid.mutate(formData);
    } else {
      toast.error('Please select a payment method');
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Mark bill as paid</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <FormView>
                {/* <p>Are you sure you want to mark this bill as paid?</p> */}

                <div className="bill-items">
                  {/* <div className="row">
                    <div className="bill-item">
                      <span>Customer</span>
                      <p>
                        {bill?.customer?.companyName || bill?.customer?.name}
                      </p>
                    </div>
                    <div className="bill-item">
                      <span>Bill number</span>
                      <p>{bill?.billOrInvoiceNumber}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="bill-item">
                      <span>Created at</span>
                      <p>{format(new Date(bill?.createdAt), 'do MMM, yyyy')}</p>
                    </div>
                    <div className="bill-item">
                      <span>Due Date</span>
                      <p>{format(new Date(bill?.dueDate), 'do MMM, yyyy')}</p>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="bill-item">
                      <span>Amount paid</span>
                      <p className="amount">₦{numberWithCommas(bill?.paidAmount || '00.00')}</p>
                    </div>
                    <div className="bill-item">
                      <span>Balance</span>
                      <p className="amount">₦{numberWithCommas(bill?.balance)}</p>
                    </div>
                  </div>
                </div>

                <div className="select-view">
                  <label htmlFor="billFrequency">Payment type</label>
                  <div className="options">
                    {paymentTypess.map((item) => (
                      <div
                        className="option"
                        key={item?.id}
                        onClick={() => setPaymentType(item?.value)}>
                        <div className="radio-button">
                          {paymentType === item?.value ? <span className="dot"></span> : null}
                        </div>
                        <p>{item?.name}</p>
                      </div>
                    ))}
                  </div>
                </div>

                {paymentType === 'partial' && (
                  <div className="input-view">
                    <label htmlFor="name">Payment amount</label>

                    <Controller
                      name="partialAmount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter partial amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          value={value}
                          onBlur={() => setPartialAmount(value)}
                          error={errors?.partialAmount?.message}
                          className={`currency-input ${
                            errors?.partialAmount && dirtyFields?.partialAmount
                              ? ' has-error'
                              : dirtyFields?.partialAmount
                              ? 'valid'
                              : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.partialAmount && (
                      <span className="error-label">{errors?.partialAmount?.message}</span>
                    )}
                  </div>
                )}

                <div className="input-view">
                  <label htmlFor="vendor">Select payment method</label>
                  <SearchDropdown
                    options={paymentMethods}
                    id="id"
                    name="name"
                    setValue={setPaymentMethod}
                    value={paymentMethod}
                    placeholder="Select payment method"
                  />
                </div>

                <div className="input-view">
                  <label htmlFor="name">Payment date</label>

                  <Input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    {...register('paymentDate')}
                    error={errors?.dueDate?.message}
                  />

                  {errors?.paymentDate && (
                    <span className="error-label">{errors?.paymentDate?.message}</span>
                  )}
                </div>

                <div className="input-view">
                  <label htmlFor="name">{/* File <span>Optional</span> */}</label>

                  {/* Document */}
                  <FilePicker file={file} setFile={setFile} acceptsPDF />
                </div>

                <div className="cta">
                  <button type="button" onClick={handleSubmit(onSubmit)}>
                    {handleMarkAsPaid.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Mark as paid'
                    )}
                  </button>
                </div>
              </FormView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default MarkInvoiceAsPaid;

MarkInvoiceAsPaid.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  bill: PropTypes.object.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.activeTitle};
    margin-top: 10px;
  }

  .bill-items {
    width: 100%;
    margin-top: 20px;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bill-item {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        span {
          font-size: 0.9rem;
          color: ${(props) => props.theme.colors?.activeTitle};
        }

        .amount {
          font-size: 1.125rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }
    }
  }

  .select-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.875rem;
      font-weight: 400;

      span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .options {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 14px;

      .option {
        width: 38%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .radio-button {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
          margin-right: 10px;

          .dot {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors?.primary};
          }
        }

        p {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.secondary};
          margin: 0;
        }
      }
    }
  }

  .input-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
