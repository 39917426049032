import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { appSelector, setDisbursementData } from '@/redux/features/app.slice';
import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';
import TextArea from '@/components/TextArea/TextArea';
import useWallet from '@/hooks/useWallet';
import CurrencyyInput from '@/components/CurrencyInput';

export const paymentTypes = [
  {
    title: 'Full payment',
    description: 'Pay the full amount due',
    subText: '',
    value: 'full',
    icon: icons.fullPayIcon,
    available: true
  },
  {
    title: 'Partial payment',
    description: 'Pay a part of the amount due',
    subText: '',
    value: 'partial',
    icon: icons.partialPayIcon,
    available: true
  }
];

function PaymentTypeModal({
  showModal,
  setShowModal,
  setShowConfirmationModal,
  paymentMethod,
  fullAmount
}) {
  const dispatch = useDispatch();
  const { disbursedPaymentData } = useSelector(appSelector);
  const [paymentType, setPaymentType] = useState('full');
  const { userWallet } = useWallet();

  const schema = yup
    .object({
      remark: yup.string().optional(),
      partialAmount: (() => {
        let validation = yup.string();
        if (paymentType === 'partial') {
          validation = validation
            .required('Amount is required')
            .test(
              'is-valid-number',
              'Amount must be a valid number',
              (value) => !isNaN(Number(value))
            )
            .test('min-amount', 'Amount must be at least ₦100', (value) => Number(value) >= 100);
        }
        return validation;
      })()
    })
    .required();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const remark = useWatch({
    control,
    name: 'remark'
  });

  const partAmount = useWatch({
    control,
    name: 'partialAmount'
  });

  const handleConfirm = (data) => {
    dispatch(
      setDisbursementData({
        ...disbursedPaymentData,
        ...(remark && { remark }),
        amount: paymentType === 'partial' ? data?.partialAmount : fullAmount,
        paymentType
      })
    );
    setShowModal(false);
    setShowConfirmationModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Select disbursement type</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <ReactSVG src={icons.closeIcon} />
                  </button>
                </div>

                <FormView>
                  <div className="options">
                    {paymentTypes?.map((method) => {
                      return (
                        <div
                          className={`option ${
                            paymentType === method?.value && method?.available ? 'selected' : ''
                          }`}
                          key={method?.value}
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            if (method?.available) {
                              setPaymentType(method?.value);
                            } else return;
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              setPaymentType(method?.value);
                            }
                          }}>
                          {paymentType === method?.value && (
                            <ReactSVG src={icons.radioCheckedIcon} className="checked" />
                          )}
                          <div className={`icon ${method?.available ? '' : 'fade'}`}>
                            <ReactSVG src={method?.icon} />
                          </div>
                          <div className={`info ${method?.available ? '' : 'fade'}`}>
                            <h3>{method?.title}</h3>
                            <p>{method?.description}</p>
                            <br />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {paymentType === 'partial' && (
                    <div className="input-view">
                      <label htmlFor="name">Payment amount</label>

                      <Controller
                        name="partialAmount"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <CurrencyyInput
                            name={name}
                            placeholder="Enter partial amount"
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            prefix="₦"
                            onValueChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            error={errors?.partialAmount?.message}
                            className={`currency-input ${
                              errors?.partialAmount && dirtyFields?.partialAmount
                                ? ' has-error'
                                : dirtyFields?.partialAmount
                                ? 'valid'
                                : ''
                            }`}
                          />
                        )}
                      />

                      {errors?.partialAmount && (
                        <span className="error-label">{errors?.partialAmount?.message}</span>
                      )}

                      {paymentMethod === 'bank-transfer' &&
                        partAmount > userWallet?.currentBalance && (
                          <span className="error-label">
                            The amount you entered is greater than your current wallet balance of{' '}
                            <span className="strong">
                              ₦{numberWithCommas(userWallet?.currentBalance || '00.00')}
                            </span>
                          </span>
                        )}
                      {partAmount > fullAmount && (
                        <span className="error-label">
                          The amount you entered is greater than your total repayment amount of{' '}
                          <span className="strong">₦{numberWithCommas(fullAmount || '00.00')}</span>
                        </span>
                      )}
                    </div>
                  )}

                  <div className="input-view">
                    <label htmlFor="name">
                      Remark <span>(Optional)</span>
                    </label>

                    <TextArea {...register('remark')} placeholder="Enter remark" />

                    {errors?.remark && <span className="error-label"></span>}
                  </div>

                  <div className="cta">
                    <button type="button" onClick={handleSubmit(handleConfirm)}>
                      Confirm
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default PaymentTypeModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme?.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    max-width: 600px;
    padding: 30px 20px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 24px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.mobile} {
      max-width: calc(100% - 32px);
      padding: 20px 16px;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.mobile} {
    width: 100%;
    padding: 20px 0px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme?.colors?.activeTitle};
    margin-top: 10px;
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .option {
      width: 48%;
      height: 150px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      padding: 20px 10px;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: all 0.1s ease-in-out;
      margin-bottom: 10px;
      border: 1px solid ${({ theme }) => theme?.colors?.layer};

      .checked {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme?.colors?.layer};
        margin-right: 24px;
        margin-bottom: 20px;
      }

      .info {
        width: calc(100%);

        h3 {
          font-size: 1.125rem;
          font-weight: 500;
          color: ${({ theme }) => theme?.colors?.secondary};
          margin-bottom: 8px;
          text-align: center;
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 400;
          margin-bottom: 4px;
          text-align: center;
        }

        span {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 300;
        }

        .soon {
          font-size: 0.875rem;
          line-height: 19px;

          color: ${({ theme }) => theme?.colors?.secondary};
          font-weight: 300;
          margin-top: 10px;
          background-color: ${({ theme }) => theme?.colors?.inputBackground};
          opacity: 1;
          padding: 4px 8px;
          text-align: center;
        }
      }

      .fade {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    .selected {
      border: 1px solid ${({ theme }) => theme?.colors?.primary};
    }
  }

  .input-view {
    width: 100%;
    margin: 20px 0;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme?.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme?.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme?.colors?.primary};
      color: ${(props) => props.theme?.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
