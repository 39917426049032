import styled from 'styled-components';

const Button = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '64px'};
  color: ${({ theme, styletype }) =>
    styletype === 'secondary'
      ? theme.colors?.primary
      : styletype === 'alternate'
      ? theme.colors?.black
      : theme.colors?.white};
  background-color: ${({ theme, styletype }) =>
    styletype === 'secondary'
      ? theme.colors?.white
      : styletype === 'alternate'
      ? theme.colors?.inputBackground
      : theme.colors?.primary};
  border: 1px solid
    ${({ theme, styletype }) =>
      styletype === 'secondary'
        ? theme.colors?.primary
        : styletype === 'alternate'
        ? theme.colors?.inputBackground
        : theme.colors?.primary};
  border-radius: 16px;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, styletype }) =>
      styletype === 'secondary' ? theme.colors?.white : theme.colors?.primaryHover};
  }

  &:focus {
    outline: 4px solid ${({ theme }) => theme.colors?.primaryFocus};
  }
`;

export default Button;
