import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';

const CompleteModal = ({ showModal, children }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay"
        >
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default CompleteModal;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    width: 100vw;
    height: 100vh;

    @media ${device.phone} {
      width: 100%;
      padding: 0 16px;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 40px;

      .info-view {
        margin-top: 100px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 2rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};
          margin-top: 20px;

          @media ${device.phone} {
            width: 100%;
            text-align: center;
          }
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          margin-top: 10px;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            width: 100%;
            text-align: center;
          }
        }

        .button-view {
          margin-top: 40px;
          width: 230px;

          @media ${device.phone} {
            width: 100%;
          }
        }
      }
    }
  }
`;
