import Pagination from '@/components/Pagination';
import { device } from '@/constants/breakpoints';
import { fetchTransferHistory } from '@/services/bankingApi';
import { ReactComponent as ViewIcon } from '@/assets/icons/view.icon.svg';
import { ReactComponent as RefetchIcon } from '@/assets/icons/refresh.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import TransactionDetails from './TransactionDetails';
import useDebounce from '@/hooks/useDebounce';
import TableLoader from '@/components/TableLoader';
import { useSearchParams } from 'react-router-dom';
import TableRowsSelect from '@/components/TableRowSelect';

const statusClassMap = {
  successful: 'success',
  failed: 'failed',
  pending: 'pending'
};

const TransferHistoryTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get('limit');
  const currentPage = searchParams.get('page');
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [search, setSearch] = useState('');
  const debouncedsearch = useDebounce(search, 500);

  const transferHistory = useQuery({
    queryKey: [
      'transferHistory',
      {
        page: currentPage ? currentPage : 1,
        limit: limit ? limit : 10,
        search: debouncedsearch?.trim()
      }
    ],
    queryFn: fetchTransferHistory
  });

  const { refetch } = transferHistory;

  const handleRowsPerPageChange = (value) => {
    setSearchParams(`tab=transfer-funds&page=1&limit=${value}`);
  };

  const onPageChange = (page) => {
    setSearchParams(`tab=transfer-funds&page=${page}&limit=${limit ? limit : 10}`);
  };

  return (
    <>
      <TransferHistoryTableStyles>
        <div className="title-view">
          <h3>Transfer history</h3>
          <div className="filter-view">
            <SearchView>
              <input
                type="search"
                placeholder="Search transfer history"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon className="search-icon" />
            </SearchView>
            <button className="refetch" type="button" onClick={refetch}>
              <RefetchIcon />
            </button>
          </div>
        </div>

        {transferHistory?.isFetching ? (
          <TableLoader />
        ) : (
          <>
            <TableView>
              <TableHeader>
                <div className={`item recipient`}>
                  <span>Recipient </span>
                </div>
                <div className={`item amount`}>
                  <span>Amount </span>
                </div>
                <div className={`item type`}>
                  <span>Reference </span>
                </div>
                <div className={`item status`}>
                  <span>Status </span>
                </div>
                <div className={`item date`}>
                  <span>Date </span>
                </div>
                <div className="item action">
                  <span></span>
                </div>
              </TableHeader>

              <TableBody>
                {transferHistory?.data?.data?.items?.map((history, i) => (
                  <TableRow key={history.id}>
                    <div className="item recipient">
                      <span>{history?.accountName}</span>
                    </div>
                    <div className="item amount">
                      <span>₦{numberWithCommas(history?.amount || '00.00')}</span>
                    </div>
                    <div className="item type">
                      <span>{history?.reference}</span>
                    </div>
                    <div className={`item status ${statusClassMap[history.status]}`}>
                      <span>{history?.status}</span>
                    </div>
                    <div className="item date">
                      <span>{format(new Date(history?.createdAt), 'do MMM, yyyy')}</span>
                    </div>

                    <div className="item action">
                      <button
                        type="button"
                        className="btn view"
                        onClick={() => {
                          setSelectedTransaction(history);
                          setShowViewModal(true);
                        }}>
                        <ViewIcon />
                      </button>
                    </div>
                  </TableRow>
                ))}
              </TableBody>
            </TableView>

            <div className="listing">
              {transferHistory?.data?.data?.items?.map((history) => (
                <div className="item" key={history?.id}>
                  <div className="col1">
                    <span className="name">
                      {history?.accountName} -{' '}
                      <span className="account">{history?.accountNumber}</span>{' '}
                    </span>
                    <span className="date">
                      {format(new Date(history?.createdAt), 'do MMM, yyyy, h:mm a')}
                    </span>
                    <span className="id">{history?.reference}</span>
                  </div>
                  <button
                    type="button"
                    className="btn more"
                    onClick={() => {
                      setSelectedTransaction(history);
                      setShowViewModal(true);
                    }}>
                    <ViewIcon />
                  </button>
                  <span className="amount">₦{numberWithCommas(history?.amount || '00.00')}</span>
                </div>
              ))}
            </div>

            <div className="pagination">
              <Pagination
                itemsPerPage={limit ? parseInt(limit) : 10}
                totalItems={transferHistory?.data?.data?.meta?.totalCount}
                currentPage={transferHistory?.data?.data?.meta?.page}
                onPageChange={onPageChange}
              />
              <TableRowsSelect onChange={handleRowsPerPageChange} />
            </div>
          </>
        )}
      </TransferHistoryTableStyles>

      <TransactionDetails
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        transaction={selectedTransaction}
      />
    </>
  );
};

export default TransferHistoryTable;

const TransferHistoryTableStyles = styled.div`
  width: 100%;
  margin-top: 50px;

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .title-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media ${device.phone} {
      display: block;
    }

    h3 {
      font-size: 1.2rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }

    .filter-view {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media ${device.phone} {
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
      }

      button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: ${(props) => props.theme.colors?.layer2};
        color: ${(props) => props.theme.colors?.secondary};
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer3};
        }
      }
    }
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .listing {
    width: 100%;
    display: none;

    @media ${device.phone} {
      display: block;
    }

    .item {
      width: 100%;
      background: #f4f4f4;
      border-radius: 13px;
      padding: 20px;
      margin-top: 20px;
      min-height: 120px;
      position: relative;

      .col1 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .name {
          font-size: 0.9rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
          margin-bottom: 10px;
        }

        .date {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.black};
          margin-bottom: 5px;
        }

        .id {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.info};
        }

        .account {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 20px;
        right: 30px;
        background-color: ${(props) => props.theme.colors?.white};
        border-radius: 6px;
        box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
        width: 180px;
        z-index: 100;
        overflow: hidden;

        button {
          width: 100%;
          padding: 16px 24px;
          border: none;
          background-color: ${(props) => props.theme.colors?.white};
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background-color: ${(props) => props.theme.colors?.layer2};
          }
        }
      }

      .more {
        position: absolute;
        top: 20px;
        right: 10px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .amount {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;

const TableView = styled.div`
  width: 100%;
  margin-top: 20px;

  @media ${device.phone} {
    display: none;
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &.recipient {
      flex: 1.5;
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }

    &.action {
      flex: 0.5;
    }
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
    }

    &.recipient {
      flex: 1.5;
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 1;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    &.success {
      span {
        color: ${(props) => props.theme.colors?.success};
      }
    }

    &.failed {
      span {
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    &.pending {
      span {
        color: ${(props) => props.theme.colors?.warning};
      }
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }

    &.action {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .btn {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .view {
        margin-right: 20px;
      }
    }
  }

  .date {
    flex: 0.6;
  }
`;

const SearchView = styled.div`
  width: 300px;
  height: 50px;
  position: relative;
  margin-right: 10px;
  margin-top: 0;

  @media ${device.phone} {
    width: 68%;
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;
    margin: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.875rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;
