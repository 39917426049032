import styled from 'styled-components';
import bgImage from '@/assets/images/authbg.image.png';
import { device } from '@/constants/breakpoints';

export const LoginView = styled.div`
  width: 1300px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors?.white};
  justify-content: flex-end;
  padding: 4rem 0;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
`;
export const LoginLeftView = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    width: 100%;
  }
`;

export const LoginRightView = styled.div`
  width: 50%;
  height: 100vh;
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  @media ${device.phone} {
    display: none;
  }

  .title {
    padding: 0rem 0 0 5rem;
    margin-top: 2rem;
    color: #92c22c;
    font-size: 2.9rem;
    font-style: normal;
    font-weight: 1000;
    line-height: 93.5%;
    max-width: 38rem;
    text-shadow: 2px 4px 4px rgba(23, 64, 120, 1);
    -webkit-text-stroke: 1px ${(props) => props.theme.colors?.black};
  }

  .auth-frame {
    width: 100%;
    .auth-frame-hand {
      position: absolute;
      bottom: 0rem;
      left: 0;
    }

    .auth-frame-invoice {
      position: absolute;
      bottom: 4rem;
      right: -1rem;
    }
  }
`;

export const LogoView = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 2rem 0 0 5rem;

  @media ${device.phone} {
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
`;

export const TitleView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  margin-bottom: 3.4rem;

  @media ${device.phone} {
    width: 100%;
    align-items: center;
  }

  h3 {
    color: ${(props) => props.theme.colors?.secondary};
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

export const FormView = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  .error-label {
    color: ${(props) => props.theme.colors?.error};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }

  .password-info {
    color: ${(props) => props.theme.colors?.info};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

export const PasswordInputView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    color: ${(props) => props.theme.colors?.error};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }

  .password-info {
    color: ${(props) => props.theme.colors?.info};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

export const ButtonView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;

  .reset-view {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;

    span {
      font-size: 0.75rem;
      color: ${(props) => props.theme.colors?.info};
    }

    a {
      color: ${(props) => props.theme.colors?.primary};
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 0.3rem;
    }
  }
`;

export const RegisterView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4.75rem;

  a {
    color: ${(props) => props.theme.colors?.primary};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5rem;
  }
`;
