import PageLoader from '@/components/PageLoader'
import { device } from '@/constants/breakpoints'
import { fetchTotalsChart } from '@/services/dashboardApi'
import { numberWithCommas } from '@/utils/helpers'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import styled from 'styled-components'

const TotalPayablesChart = ({ data }) => {
  const [total, setTotal] = useState(0)
  const [paid, setPaid] = useState(0)
  const [unpaid, setUnpaid] = useState(0)
  const [progress, setProgress] = useState(0) //((paid / total) * 100);

  const totalsChart = useQuery({
    queryKey: ['totalsChart'],
    queryFn: fetchTotalsChart,
    onSuccess: (data) => {
      setTotal(data?.data?.payables?.total)
      setPaid(data?.data?.payables?.current)
      setUnpaid(data?.data?.payables?.overdue)
      setProgress(
        (
          (data?.data?.payables?.current / data?.data?.payables?.total) *
          100
        ).toFixed(2),
      )
    },
    onError: (error) => {},
  })

  return (
    <TotalPayablesChartView>
      <ChartView>
        <ChartTitle>
          <h3>Total Payables</h3>
        </ChartTitle>

        {totalsChart?.isFetching ? (
          <ChartLoader>
            <Oval color="#0EBE8E" height={40} width={40} />
          </ChartLoader>
        ) : (
          <ChartBody>
            <TotalView>
              <p>Total Unpaid Bills</p>
              <h3>₦{numberWithCommas(total || '00.00')}</h3>
            </TotalView>
            <ProgressBar progress={progress}>
              <div className="progress-bar"></div>
            </ProgressBar>
            <ChartFooter>
              <div className="col">
                <span>Current</span>
                <h3>₦{numberWithCommas(paid || '00.00')}</h3>
              </div>
              <div className="col">
                <span className="overdue">Overdue</span>
                <h3>₦{numberWithCommas(unpaid || '00.00')}</h3>
              </div>
            </ChartFooter>
          </ChartBody>
        )}
      </ChartView>
    </TotalPayablesChartView>
  )
}

export default TotalPayablesChart

const TotalPayablesChartView = styled.div`
  width: 48%;

  @media ${device.phone} {
    width: 100%;
    margin-bottom: 30px;
  }
`

const ChartView = styled.div`
  width: 100%;
`

const ChartTitle = styled.div`
  width: 100%;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }
`

const ChartBody = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 14px;
  margin-top: 10px;
`

const ChartLoader = styled.div`
  .loader {
    width: 100%;
    height: 200px;
    background-color: ${({ theme }) => theme.colors?.white};
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors?.secondary};
  border-radius: 6px;

  .progress-bar {
    width: ${({ progress }) => progress}%;
    height: 8px;
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 6px;
  }
`

const TotalView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors?.secondary};
  }
`

const ChartFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .col {
    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      text-transform: uppercase;
    }

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
      margin-top: 4px;
    }

    .overdue {
      color: ${({ theme }) => theme.colors?.error};
    }
  }
`
