import React from 'react';
import styled from 'styled-components';

const TabView = ({ selectedTab, setSelectedTab }) => {
  const tabs = ['ALL', 'TRANSACTIONS', 'UPDATES'];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <TabWrapper>
      {tabs.map((tab) => (
        <TabButton key={tab} isActive={selectedTab === tab} onClick={() => handleTabClick(tab)}>
          {tab}
        </TabButton>
      ))}
    </TabWrapper>
  );
};

export default TabView;

const TabWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 20px;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: ${(props) => (props.isActive ? props?.theme?.colors?.primaryFocus : 'transparent')};
  border: 1px solid
    ${(props) => (props.isActive ? props?.theme?.colors?.primary : props?.theme?.colors?.info)};
  color: ${(props) =>
    props.isActive ? props?.theme?.colors?.primary : props?.theme?.colors?.info};
  transition: background-color 0.3s ease;
  font-weight: 500;

  &:hover {
    background: ${(props) =>
      props.isActive ? props?.theme?.colors?.offWhite : props?.theme?.colors?.offWhite};
  }
`;
