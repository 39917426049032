import styled from 'styled-components';

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '64px'};
  padding: ${({ icon }) => (icon ? '0 16px 0 48px' : '0 16px')};
  background-color: ${({ theme, error }) =>
    error ? theme.colors?.errorBackground : theme.colors?.inputBackground};
  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors?.error : theme.colors?.inputBackground)};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors?.activeTitle};
  outline: none;
  font-size: 1rem;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  margin-top: 5px;

  &:focus {
    background-color: ${({ theme, error }) =>
      error ? theme.colors?.errorBackground : theme.colors?.white};
    border: 1px solid
      ${({ theme, error }) => (error ? theme.colors?.error : theme.colors?.activeTitle)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors?.placeholder};
    font-size: 1rem;
    font-weight: 300;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors?.layer4} !important;
    color: ${({ theme }) => theme.colors?.placeholder};
    opacity: 0.5;
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default Input;
