/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { newInvoiceMethods } from '@/utils/data.utils';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import NewInvoiceForm from './components/NewInvoiceForm';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';
import SubscriptionWarnModal from '@/components/SubscriptionWarnModal';

function NewInvoice() {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState(newInvoiceMethods[0]?.value);
  const [showForm, setShowForm] = useState(false);
  const [showUpgradeWarning, setShowUpgradeWarning] = useState(false);

  const handleContinue = () => {
    if (selectedMethod === 'manual') {
      setShowForm(true);
    }
    if (selectedMethod === 'upload-file') {
      navigate('/get-paid/invoices/new-invoice/upload-file');
    }

    if (selectedMethod === 'upload-csv') {
      navigate('/get-paid/new/bulk-upload-explained');
    }

    if (selectedMethod === 'create-invoice') {
      navigate('/get-paid/new/create-invoice');
    }
  };

  return (
    <NewInvoiceStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate('/get-paid/invoices?tab=draft');
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="title-view">
          <h1>Add an invoice</h1>
          <p>Choose your preferred way of adding invoices</p>
        </div>
        <div className="form-view">
          {newInvoiceMethods?.map((method) => {
            const Icon = method?.icon;
            return (
              <div
                className={`option ${selectedMethod === method?.value ? 'selected' : ''}`}
                key={method?.value}
                onClick={() => setSelectedMethod(method?.value)}>
                {selectedMethod === method?.value && <CheckedIcon className="checked" />}
                <div className="icon">
                  <Icon />
                </div>
                <div className="info">
                  <h3>{method?.title}</h3>
                  <p>{method?.description}</p>
                </div>
              </div>
            );
          })}

          {/* Button view */}
          <div className="button-view">
            <Button type="button" styleType="primary" width="100%" onClick={handleContinue}>
              Continue
            </Button>
          </div>
        </div>
      </div>

      <NewInvoiceForm showModal={showForm} setShowModal={setShowForm} />

      <SubscriptionWarnModal
        showModal={showUpgradeWarning}
        setShowModal={setShowUpgradeWarning}
        returnTo="get-paid/invoices/new-invoice"
        text="You cannot continue on this feature because it is not included in your current plan. Please upgrade to continue."
      />
    </NewInvoiceStyles>
  );
}

export default withAuthentication(NewInvoice);

const NewInvoiceStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 100px;

  @media ${device.phone} {
    margin-top: 30px;
    width: 100%;
    padding: 0px 16px;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      left: 16px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 60px;
    }

    .title-view {
      width: 40%;
      padding-right: 60px;

      @media ${device.phone} {
        padding-right: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          max-width: 80%;
        }
      }
    }

    .indicator-view {
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding-left: 0px;
        margin-top: 30px;
      }

      .option {
        width: 380px;
        display: flex;
        align-items: flex-start;
        border-radius: 16px;
        padding: 12px 10px;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;
        transition: all 0.1s ease-in-out;
        margin-bottom: 10px;

        .checked {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          background-color: ${({ theme }) => theme.colors?.layer};
          margin-right: 24px;
        }

        .info {
          width: calc(100% - 64px);

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors?.secondary};
            margin-bottom: 8px;
          }

          p {
            font-size: 1rem;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.secondary};
            font-weight: 300;
          }
        }
      }

      .selected {
        border: 1px solid ${({ theme }) => theme.colors?.primary};
      }

      .button-view {
        width: 230px;
        margin-top: 50px;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
