/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { numberWithCommas } from '@/utils/helpers';
import PDFViewer from '@/components/PDFViewer';
import { restoreDeletedBill } from '@/services/billsApi';
import { device } from '@/constants/breakpoints';
import PromptModal from '@/components/PromptModal';
import { restoreDeletedInvoice } from '@/services/receivablesApi';

function InvoiceDetails({ showModal, setShowModal, invoice }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showActions, setShowActions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowActions(false));

  const handleFileDownload = (url, filename) => {
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      });
  };

  const handleRestoreBill = useMutation(restoreDeletedInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      toast.success('Invoice restored successfully');
      setShowDeleteModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <div className="modal-content">
              <div className="header">
                <span></span>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>
              <div className="modal-content__title-view">
                <h3>Invoice Details</h3>
              </div>
              <div className="modal-content__values">
                <div className="modal-content__values-item">
                  <span>Customer Name</span>
                  <h3>{invoice?.customer?.companyName}</h3>
                </div>
                <div className="modal-content__values-item">
                  <span>Amount</span>
                  <h3>₦{numberWithCommas(invoice?.amount || '00.00')}</h3>
                </div>
                {invoice?.dueDate && (
                  <div className="modal-content__values-item">
                    <span>Due Date</span>
                    <h3>{format(new Date(invoice?.dueDate), 'do MMM, yyyy')}</h3>
                  </div>
                )}
                <div className="modal-content__values-item">
                  <span>invoice no.</span>
                  <h3>{invoice?.billOrInvoiceNumber}</h3>
                </div>
              </div>
              <div className="modal-content__row">
                <div className="file-view">
                  {invoice?.fileUrl?.includes('.pdf') ? (
                    <div className="pdf-view">
                      <PDFViewer file={invoice?.fileUrl} />
                    </div>
                  ) : (
                    <div className="image-view">
                      {invoice?.fileUrl ? (
                        <img src={invoice?.fileUrl} alt="invoice" />
                      ) : (
                        <p>No File to display</p>
                      )}
                    </div>
                  )}

                  {invoice?.fileUrl && (
                    <div className="download-view">
                      <button
                        onClick={() => {
                          handleFileDownload(invoice?.fileUrl, invoice?.fileUrl);
                        }}>
                        Download invoice <DownloadIcon />
                      </button>
                    </div>
                  )}
                </div>
                <div className="action-view">
                  <div className="balance">
                    <span>Balace due</span>
                    <h3>₦{numberWithCommas(invoice?.balance || '00.00')}</h3>
                  </div>

                  <div className="action">
                    {invoice?.status !== 'paid' && (
                      <button
                        type="button"
                        onClick={(e) => {
                          setShowActions(true);
                          e.stopPropagation();
                        }}>
                        Action <DownIcon />
                      </button>
                    )}
                    <AnimatePresence>
                      {showActions ? (
                        <motion.div
                          className="menu-actions"
                          ref={ref}
                          initial={{ y: -20 }}
                          animate={{ y: 0 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}>
                          <button
                            type="button"
                            onClick={() => {
                              setShowDeleteModal(true);
                            }}>
                            Restore invoice
                          </button>
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </div>

                  <div className="request">
                    <button
                      type="button"
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}>
                      Restore invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </ModalView>
      )}

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon className="green-icon" />
          <h3>Restore Invoice</h3>
          <p>
            Are you sure you want to restore this invoice?. Please ensure you have reviewed the
            invoice before restoring.
          </p>
          <button
            type="button"
            className="continue"
            onClick={() => handleRestoreBill.mutate(invoice?.id)}>
            {handleRestoreBill.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Restore Invoice'
            )}
          </button>
        </div>
      </PromptModal>
    </AnimatePresence>
  );
}

export default InvoiceDetails;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 60%;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 30px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
      border-radius: 16px;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px;

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          width: 100%;
        }
      }

      .modal-content__title-view {
        width: 100%;
        margin-top: 20px;

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .modal-content__values {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column;
        }

        .modal-content__values-item {
          width: 100%;

          @media ${device.phone} {
            width: 100%;
            margin-bottom: 20px;
          }

          span {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
          }

          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
            margin-top: 8px;
          }
        }
      }

      .modal-content__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column-reverse;
        }

        .file-view {
          width: 55%;

          @media ${device.phone} {
            width: 100%;
          }

          .download-view {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 500;
            }
          }

          .pdf-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;
            overflow: scroll;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            .react-pdf__Page {
              max-width: calc(100% - 1em);
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
              margin: 1em;
            }

            .react-pdf__Page canvas {
              max-width: 100%;
              height: auto !important;
            }

            .react-pdf__message {
              padding: 1em;
              color: white;
            }
          }

          .image-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            p {
              padding: 20px;
              text-align: center;
              color: ${(props) => props.theme.colors?.secondary};
            }

            .refresh {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              p {
                padding: 20px;
                text-align: center;
                color: ${(props) => props.theme.colors?.secondary};
              }

              button {
                color: ${(props) => props.theme.colors?.secondary};
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  margin-right: 5px;

                  path {
                    stroke: ${(props) => props.theme.colors?.secondary};
                  }
                }
              }
            }
          }
        }

        .action-view {
          width: 40%;
          height: 450px;
          position: relative;

          @media ${device.phone} {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
          }

          .balance {
            width: 100%;

            span {
              font-size: 0.875rem;
              font-weight: 400;
              color: ${(props) => props.theme.colors?.secondary};
            }

            h3 {
              font-size: 1.25rem;
              font-weight: 600;
              color: ${(props) => props.theme.colors?.secondary};
              margin-top: 8px;
            }
          }

          .action {
            width: 100%;
            margin-top: 30px;
            position: relative;

            button {
              width: 120px;
              height: 40px;
              background: ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.white};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                margin-left: 10px;

                path {
                  stroke: ${(props) => props.theme.colors?.white};
                }
              }
            }

            .menu-actions {
              position: absolute;
              top: 45px;
              left: 0;
              background-color: ${(props) => props.theme.colors?.white};
              border-radius: 6px;
              box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
              width: 180px;
              z-index: 100;
              overflow: hidden;

              button {
                width: 100%;
                padding: 16px 24px;
                border: none;
                background-color: ${(props) => props.theme.colors?.white};
                font-size: 0.875rem;
                color: ${(props) => props.theme.colors?.secondary};
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                  background-color: ${(props) => props.theme.colors?.layer2};
                }
              }
            }
          }

          .request {
            width: 100%;
            position: absolute;
            bottom: 0;

            button {
              width: 180px;
              height: 50px;
              background: ${(props) => props.theme.colors?.white};
              border: 1px solid ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.primary};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
`;
