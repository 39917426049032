/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components'
import { ReactComponent as ErrorIllustration } from '@/assets/images/error-illustration.image.svg'

export default function ErrorBoundary() {
  return (
    <ErrorView>
      <ErrorIllustration />
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </ErrorView>
  )
}

const ErrorView = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
`
