import { useEffect, useState } from 'react';

const useTimer = (initialMinutes, initialSeconds) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let interval;

    if (minutes === 0 && seconds === 0) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        if (seconds === 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  return { minutes, seconds, setMinutes, setSeconds };
};

export default useTimer;
