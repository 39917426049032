import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as BellIcon } from '@/assets/icons/reminder.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';

const SingleAlert = ({ notification, onRemove, onClick }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove();
    }, 10000);
    return () => clearTimeout(timer);
  }, [notification, onRemove]);

  return (
    <ToastContainer
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ duration: 0.2 }}
      onClick={onClick}>
      <div className="header">
        <BellIcon />
        <button className="close">
          <CloseIcon />
        </button>
      </div>
      <span>{notification?.title}</span> <p>{notification?.message}</p>
    </ToastContainer>
  );
};

export default SingleAlert;

const ToastContainer = styled(motion.div)`
  position: fixed;
  right: 30px;
  top: 80px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 350px;
  z-index: 30002;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.line};

  @media ${device.phone} {
    width: calc(100% - 32px);
    left: 16px;
    right: 16px;
    border-radius: 15px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.secondary};
  }

  p {
    font-size: 0.875rem;
    color: ${(props) => props.theme.colors.secondary};
    line-height: 19px;
    margin-top: 10px;
  }
`;
