import api from './api';

export const fetchTerms = async () => {
  const response = await api.get(`/bill-finance/terms/all`);
  return response.data;
};

export const fetchTerm = async ({ queryKey }) => {
  const [_key, { type }] = queryKey;
  const response = await api.get(`/bill-finance/terms/${type}`);
  return response.data;
};
