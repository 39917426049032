import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { format } from 'date-fns';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import { device } from '@/constants/breakpoints';
import useOnClickOutside from '@/hooks/useClickOutside';
import { useQuery } from '@tanstack/react-query';
import { fetchTransactionReceipt } from '@/services/bankingApi';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import toast from 'react-hot-toast';

function TransactionDetails2({ showModal, setShowModal, transaction }) {
  const [loadingPaymentAdvice, setLoadingPaymentAdvice] = useState(false);
  const [saveAs, setSaveAs] = useState('pdf');
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareRef = useRef();
  useOnClickOutside(shareRef, () => setShowShareOptions(false));

  const fetchPaymentAdvice = useQuery({
    queryKey: ['paymentAdvice', transaction?.identifier],
    queryFn: fetchTransactionReceipt,
    enabled: !!transaction?.identifier
  });

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Session Id copied to clipboard');
  };

  const handleFileDownload = (url, filename) => {
    setLoadingPaymentAdvice(true);
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        setLoadingPaymentAdvice(false);
        fileDownload(res.data, newFileName);
      })
      .catch(() => {
        setLoadingPaymentAdvice(false);
      });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Transaction details</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <FormView>
                {/* <p>Are you sure you want to mark this transaction as paid?</p> */}

                <div className="transaction-items">
                  <div className="row">
                    <div className="transaction-item">
                      <span>Amount</span>
                      <p className="amount">₦{numberWithCommas(transaction?.amount || '00.00')}</p>
                    </div>
                    <div className="transaction-item">
                      <span>{transaction?.event === 'CREDIT' ? 'Sender' : 'Recipient'}</span>
                      <p>
                        {transaction?.event === 'CREDIT' && transaction?.senderName
                          ? transaction?.senderName
                          : transaction?.event === 'DEBIT' && transaction?.receiverName
                            ? transaction?.receiverName
                            : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="transaction-item">
                      <span>Type</span>
                      <p className={`type ${transaction?.event?.toLowerCase()}`}>
                        {transaction?.event}
                      </p>
                    </div>
                    <div className="transaction-item">
                      <span>Reference</span>
                      <p>{transaction?.identifier || 'Nil'}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="transaction-item">
                      <span>Status</span>
                      <p className={`status ${transaction?.status?.toLowerCase()}`}>
                        {transaction?.status}
                      </p>
                    </div>
                    <div className="transaction-item">
                      <span>Channel</span>
                      <p>{transaction?.channel}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="transaction-item">
                      <span>Remark</span>
                      <p className="">{transaction?.remark}</p>
                    </div>
                    <div className="transaction-item">
                      <span>Time stamp</span>
                      <p>{format(new Date(transaction?.createdAt), 'do MMM, yyyy, h:mm a')}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="transaction-item">
                      <span>Session ID</span>
                      <p className="">
                        {transaction?.sessionId || 'N/A'}{' '}
                        {transaction?.sessionId && (
                          <button
                            className="icon-copy"
                            onClick={() => {
                              copyToClipboard(transaction?.sessionId);
                            }}>
                            <CopyIcon />
                          </button>
                        )}
                      </p>
                    </div>
                    {transaction?.note && (
                      <div className="transaction-item">
                        <span>Note</span>
                        <p className="">{transaction?.note}</p>
                      </div>
                    )}
                  </div>
                </div>
              </FormView>
              <div className="cta">
                {fetchPaymentAdvice?.isLoading ? (
                  <ShareButton type="button">
                    <span>
                      <Oval color="#92c22c" width={20} height={20} />
                      Loading receipt...
                    </span>
                  </ShareButton>
                ) : (
                  fetchPaymentAdvice?.data?.data?.url && (
                    <ShareButton
                      type="button"
                      onClick={() => setShowShareOptions(true)}
                      disabled={!fetchPaymentAdvice?.data?.data?.url}>
                      Download Receipt
                    </ShareButton>
                  )
                )}
                <AnimatePresence>
                  {showShareOptions && (
                    <motion.div
                      className="menu-actions"
                      ref={shareRef}
                      initial={{ y: 20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -20, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          setSaveAs('png');
                          handleFileDownload(
                            fetchPaymentAdvice?.data?.data?.url.replace('.pdf', '.png'),
                            `payment-advice-${transaction?.identifier}.png`
                          );
                        }}>
                        {loadingPaymentAdvice && saveAs === 'png'
                          ? 'Downloading...'
                          : ' Download Image'}
                      </button>
                      <button
                        onClick={() => {
                          setSaveAs('pdf');
                          handleFileDownload(
                            fetchPaymentAdvice?.data?.data?.url,
                            `payment-advice-${transaction?.identifier}.pdf`
                          );
                        }}>
                        {loadingPaymentAdvice && saveAs === 'pdf'
                          ? 'Downloading...'
                          : ' Download PDF'}
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default TransactionDetails2;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .cta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 20px;
    position: relative;

    @media ${device.phone} {
      width: 100%;
    }

    .menu-actions {
      position: absolute;
      top: -40px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      button {
        width: 100%;
        padding: 0 20px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0;
        height: 40px;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer};
        }
      }
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.secondary};
    margin-top: 10px;
    text-transform: capitalize;
    font-weight: 500;
  }

  .transaction-items {
    width: 100%;
    margin-top: 20px;

    .row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .transaction-item {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        span {
          font-size: 0.9rem;
          color: ${(props) => props.theme.colors?.activeTitle};
        }

        p {
          word-wrap: break-word;
        }

        .amount {
          font-size: 1.125rem;
          font-weight: 600;
        }

        .icon-copy {
          margin-left: 10px;
          svg {
            width: 16px;
            height: 16px;
          }
        }

        .status {
          &.successful {
            color: ${(props) => props.theme.colors?.success};
            font-weight: 600;
          }
          &.pending {
            color: ${(props) => props.theme.colors?.warning};
            font-weight: 600;
          }
          &.declined {
            color: ${(props) => props.theme.colors?.error_200};
            font-weight: 600;
          }
        }

        .type {
          &.credit {
            color: ${(props) => props.theme.colors?.success};
            font-weight: 600;
          }
          &.debit {
            color: ${(props) => props.theme.colors?.error_200};
            font-weight: 600;
          }
        }
      }
    }
  }

  .input-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const ShareButton = styled.button`
  width: 100%;
  height: 64px;
  background-color: ${(props) => props.theme.colors?.white};
  color: ${(props) => props.theme.colors?.primary};
  border-radius: 16px;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid ${(props) => props.theme.colors?.primary};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const LoaderView = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
`;
