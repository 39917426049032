/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as UpgradeIcon } from '@/assets/icons/upgrade.icon.svg';
import { device } from '@/constants/breakpoints';
import { useNavigate } from 'react-router-dom';

export default function SubscriptionWarnModal({ showModal, setShowModal, returnTo, text }) {
  const navigate = useNavigate();
  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Upgrade plan</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <ContentView>
                  <div className="icon">
                    <UpgradeIcon />
                  </div>
                  <div className="warn-view">
                    <p>{text}</p>
                  </div>

                  <div className="proceed-view">
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        navigate(
                          `/settings?tab=subscriptions&view=plans&state=monthly&returnTo=${returnTo}`
                        );
                      }}>
                      Upgrade plan
                    </button>
                  </div>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 12px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    padding: 20px 0px;
    padding-top: 40px;
    flex-direction: column;
  }

  .icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .warn-view {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.primaryFocus};
    padding: 20px;
    border-radius: 10px;

    p {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 19px;
      max-width: 100%;
      text-align: center;
    }
  }

  .proceed-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    button {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 10px;
      padding: 15px 0px;
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryHover};
      }
    }
  }
`;
