/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { Oval } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg'
import Input from '@/components/Input/Input'
import { device } from '@/constants/breakpoints'
import { createReminders } from '@/services/remindersApi'

const paymentTypess = [
  { id: 1, name: 'Before due date', value: 'before' },
  { id: 2, name: 'After due date', value: 'after' },
]

function AddReminder({ showModal, setShowModal }) {
  const queryClient = useQueryClient()
  const [command, setCommand] = useState('before')

  const schema = yup
    .object({
      days: yup.string().required('Number of days is required'),
    })
    .required()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleAddReminder = useMutation(createReminders, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reminders'] })
      toast.success('Reminder created successfully')
      setShowModal(false)
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const onSubmit = (data) => {
    const formData = {
      days: parseInt(data.days, 10),
      command,
      enabled: false,
      type: 'bills',
    }
    handleAddReminder.mutate(formData)
  }

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay"
        >
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}
          >
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Add reminder</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <FormView>
                <div className="input-view">
                  <label htmlFor="name">Number of days</label>

                  <Input
                    type="number"
                    {...register('days')}
                    placeholder="1"
                    min={1}
                    error={errors?.days?.message}
                  />

                  {errors?.days && (
                    <span className="error-label">{errors?.days?.message}</span>
                  )}
                </div>

                <div className="select-view">
                  <label htmlFor="billFrequency">When to send</label>
                  <div className="options">
                    {paymentTypess.map((item) => (
                      <div
                        className="option"
                        key={item?.id}
                        onClick={() => setCommand(item?.value)}
                      >
                        <div className="radio-button">
                          {command === item?.value ? (
                            <span className="dot"></span>
                          ) : null}
                        </div>
                        <p>{item?.name}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="cta">
                  <button type="button" onClick={handleSubmit(onSubmit)}>
                    {handleAddReminder.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Add reminder'
                    )}
                  </button>
                </div>
              </FormView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  )
}

export default AddReminder

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 480px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }
`

const FormView = styled.div`
  width: 100%;
  margin-top: 20px;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.activeTitle};
    margin-top: 10px;
  }

  .bill-items {
    width: 100%;
    margin-top: 20px;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bill-item {
        width: 48%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        span {
          font-size: 0.9rem;
          color: ${(props) => props.theme.colors?.activeTitle};
        }

        .amount {
          font-size: 1.125rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }
    }
  }

  .select-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.875rem;
      font-weight: 400;

      span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .options {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 14px;

      .option {
        width: 48%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .radio-button {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primaryFocus};
          margin-right: 10px;

          .dot {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors?.primary};
          }
        }

        p {
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.secondary};
          margin: 0;
        }
      }
    }
  }

  .input-view {
    width: 100%;
    margin-bottom: 20px;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      opacity: 1;
      display: block;
      -webkit-appearance: button;
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 60px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
